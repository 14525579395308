import React, {FC} from "react";
import EmailLoginInstruments from "./EmailLoginInstruments";
import {SchoolRegisterForm} from "./SchoolRegisterForm";
import {school_registration_form} from "../../text/Literals";
import DownloadPanel from "../tools/DownLoadPanel";

export interface NoProps {

}

const SchoolRegisterInstrument : FC<NoProps> = props => {
    return <EmailLoginInstruments content={SchoolRegisterForm} title={school_registration_form} finalContent={<DownloadPanel/>}/>
}

export default SchoolRegisterInstrument;