import {SimpleRequestData} from "../../adaptors/RequestData";
import {Method} from "../../model/Request";
import {DataType} from "../../actions/data";
import {FormAdaptor} from "../../adaptors/FormAdaptor";
import {FormFieldAdaptor, StringFieldAdaptor, StringTaggedAdaptor} from "../../adaptors/FieldAdaptor";
import {cancel_label, image_search_prefix, entity_id } from "../../text/Literals";
import {IContentAdaptor, ImageAdaptor, ImageSize} from "../../adaptors/ImageAdaptor";
import {Intent} from "@blueprintjs/core";
import {IS3content} from "./S3ContentType";

export interface Tag{
    key : string
    value : string
}

export function getTagValue(tags : Tag[], key : string){
    const index = tags.findIndex(tag => tag.key === key);
    return index >= 0 ? tags[index].value : '';
}

export interface IImageSearchRequest{
    prefix : string
    tags : Tag []
    links : number
}

export interface S3Object {
    key: string
    tags : Tag []
    link ?: string
}

export function toContentAdaptor(contentType : IS3content, data : S3Object, handler : ()=> void = ()=>{}, size: ImageSize = ImageSize.Large) : IContentAdaptor{
    if(contentType.type === DataType.Images) {
        return new ImageAdaptor(data.link!,
            data.key,
            data.key,
            Intent.PRIMARY,
            size,
            handler,
            false,
            true);
    }
    return {
        link: data.link!,
        hint: data.key,
        intent: Intent.PRIMARY,
        rawHint : true,
        onClick :handler
    }
}

export function toSingleContentAdaptor(contentType : IS3content, data : S3Object, handler : ()=> void = ()=>{}, size: ImageSize = ImageSize.Large) : IContentAdaptor{
    if(contentType.type === DataType.Images) {
        return new ImageAdaptor(data.link!,
            data.key,
            cancel_label,
            Intent.PRIMARY,
            size,
            handler);
    }
    return {
        link: data.link!,
        hint: cancel_label,
        intent: Intent.PRIMARY,
        onClick :handler
    }

}

export class S3SearchRequest extends SimpleRequestData implements IImageSearchRequest{
    prefix : string
    tags : Tag []
    links : number

    prefixAdaptor ?: FormFieldAdaptor<string>;
    tagAdaptors : FormFieldAdaptor<string>[];

    constructor(tagNames: string[], prefix: string = '', links: number = 10) {
        super();
        this.prefix = prefix;
        this.tags = tagNames.map(tag => ({'key' : tag, 'value' : ''}));
        this.links = links;
        this.fields = ["tags","prefix","links","key","value"];
        this.tagAdaptors = [];
        this.adaptor = this.createFormAdaptor();
    }

    setPrefix = (val : string)=>{
        this.prefix = val;
        this.onDataChanged!(this.createFormAdaptor());
    }

    setTag=(val : string, tagName :string)=>{
        const index = this.tags.findIndex(tag =>(tag.key === tagName));
        this.tags[index].value = val;
        this.onDataChanged!(this.createFormAdaptor());
    }

    initPrefixAdaptor =() :FormFieldAdaptor<string> =>{
        this.prefixAdaptor =
            new StringFieldAdaptor(image_search_prefix,this.setPrefix,this.prefix);
        return this.prefixAdaptor;
    };

    initTagAdaptors =()=>{
        this.tagAdaptors = this.tags.map(
            tag => new StringTaggedAdaptor(tag.key,this.setTag,tag.key, tag.value)
        );
        return this.tagAdaptors;
    }

    getMethod(): Method {
        return Method.PUT;
    }

    getType=(): DataType=> {
        return DataType.Images;
    };

    createFormAdaptor() :FormAdaptor{
        return  new FormAdaptor(
            [
                this.initPrefixAdaptor(),
                ...this.initTagAdaptors()
            ],
            this
        )
    }
}

export class UpdateTagsRequest extends SimpleRequestData {
    key : string
    tags : Tag []

    keyAdaptor : FormFieldAdaptor<string>;
    tagAdaptors : FormFieldAdaptor<string>[];

    constructor(key : string, tagNames: string[], tags : Tag []) {
        super();
        this.key = key;
        this.tags = tagNames.map(tag => ({'key' : tag, 'value' :  getTagValue(tags,tag)}));
        console.log(tags);
        this.fields = ["tags","key","key","value"];
        this.tagAdaptors = [];
        this.keyAdaptor = this.initKeyAdaptor();
        this.adaptor = this.createFormAdaptor();
    }

    setTag=(val : string, tagName :string)=>{
        const index = this.tags.findIndex(tag =>(tag.key === tagName));
        this.tags[index].value = val;
        this.onDataChanged!(this.createFormAdaptor());
    }

    initKeyAdaptor =() :FormFieldAdaptor<string> =>{
        this.keyAdaptor =
            new StringFieldAdaptor(entity_id ,val => {},this.key,true);
        return this.keyAdaptor;
    };

    initTagAdaptors =()=>{
        this.tagAdaptors = this.tags.map(
            tag => new StringTaggedAdaptor(tag.key,this.setTag,tag.key, tag.value)
        );
        return this.tagAdaptors;
    }

    getMethod(): Method {
        return Method.PUT;
    }

    getType=(): DataType=> {
        return DataType.ImageUpdateTags;
    };

    createFormAdaptor() :FormAdaptor{
        return  new FormAdaptor(
            [
                this.keyAdaptor,
                ...this.initTagAdaptors()
            ],
            this
        )
    }
}