import {AppData, BasicResponse} from "../model/AppData";
import {
    DataType,
    DownloadsData,
    FirebaseCourseDataAction,
    FirebaseCourseStatusAction,
    FirebaseCoverageAction,
    FirebaseHomeworkAction,
    FirebaseReviewListAction,
    FirebaseStudentsHomeworkAction,
    FirebaseSubscribersAction,
    RawDataAction,
    S3DataAction
} from "../actions/data";
import {baseVocabulary} from "../text/baseVocabulary";
import {CourseHomeworkLinks, CourseLinks} from "../api/course/CourseLinks";
import {FreeDlcDescription, IDlc} from "../api/Dlc";
import {PROCESS, REQUEST, ROOT} from "../service/Literals";
import {CourseData} from "../model/courses/Course";
import {firebaseUsername} from "../service/Firebase";
import {AnalyticsResponse} from "../api/analytics/AnaliticsData";
import {AppToaster} from "../service/toaster";
import {review_status_request, unprocessable_request} from "../text/Literals";

// eslint-disable-next-line import/no-anonymous-default-export
export default function (
    data: AppData =
        {   vocabulary : baseVocabulary, videoLinks :{},fileLinks :{}, homeworkVideoLinks : {},
            firebaseHomeworkAnswers :{}, students :{}, freeCoursesDescriptions :[],
            homeworkFileLinks : {},firebaseMessages :{},firebaseHomework : {}, firebaseStudentsHomework :{},
            coursesCoverage : {},courseStatuses:{},reviewContent :{}, software :[], imagesPage : 0,analytics :{items : [],total : 0}},
    action: RawDataAction) :AppData{
    switch (action.type) {

        /*
            Admin
         */

        case DataType.GetPlayer:
        case DataType.DisablePlayer:
        case DataType.EnablePlayer:
        case DataType.UnVerifyPlayer:
        case DataType.VerifyPlayer:
        case DataType.DecreaseBounds:
        case DataType.AuthorizeDevice:
        case DataType.Purchase:
            const player = JSON.parse(action.raw);
            return {
                ...data,
                player : player,
                schoolUser : data.schoolUser && data.schoolUser.user && data.schoolUser.user.username === player.username ? {
                    school : data.schoolUser.school,
                    user : player
                } : data.schoolUser
            };
        case DataType.Dlc:
        case DataType.ArchiveDlc:
        case DataType.UnarchiveDlc:
            return {
                ...data,
                dlc : JSON.parse(action.raw)
            };
        case DataType.AllDlc:
            const dlc: IDlc[] = JSON.parse(action.raw);
            // console.log(res);
            return {
                ...data,
                dlc: dlc.filter(d => d.owner === ROOT),
                customDlc: dlc.filter(d => d.owner !== ROOT)
            };
        case DataType.ProductCheck:
            return {
                ...data,
                productHardwareCheck : JSON.parse(action.raw)
            }
        case DataType.AuthorizeDeviceHd:
        case DataType.DecreaseBoundsDevice:
        case DataType.Hardware:
            return {
                ...data,
                hardwareUsers : JSON.parse(action.raw)
            }
        case DataType.SchoolAll:
        case DataType.SchoolAddComputers:
        case DataType.MySchool:
        case DataType.SchoolTeacher:
            console.log(JSON.parse(action.raw));
            return {
                ...data,
                schools : JSON.parse(action.raw)
            };
        case DataType.School:
            return {
                ...data,
                schoolsMetric : JSON.parse(action.raw)
            };
        case DataType.SchoolUser:
            return {
                ...data,
                schoolUser : JSON.parse(action.raw)
            };
        case DataType.SchoolApply:
            const keyResponse = JSON.parse(action.raw);
            return {
                ...data,
                registeredKey : keyResponse ? keyResponse.key : undefined
            };
        case DataType.SchoolMail:
            const mailResponse = JSON.parse(action.raw);
            return {
                ...data,
                mailResponse
            };
        case DataType.DeleteUser:
            return {
                ...data,
                player : undefined
            };
        case DataType.Software:
        case DataType.SoftwareLicenses:
            return {
                ...data,
                software : JSON.parse(action.raw)
            };
        case DataType.PersonsAll:
            return {
                ...data,
                persons : JSON.parse(action.raw)
            };
        case DataType.CreateImpersonalSchool:
            const response = JSON.parse(action.raw);
            return {
                ...data,
                impersonalSchools : response.data.usernames
            };

        /*
            Common
         */
        /*
               Images
         */
        case DataType.ImageWasDeleted:
            // console.log(action.raw);
            const imageId = action.raw;
            return {
                ...data,
                images : data.images ? data.images.filter(image => image.key !== imageId) : undefined,
                imagesPage : 0
            };

        case DataType.ImageWasUploaded:
            const image = (action as S3DataAction).object;
//            console.log(image);
            if (!data.images){
                return {
                    ...data,
                    images : [image]
                }
            }

            const images = []
            let found = false
            for(let im of data.images){
                images.push(im.key === image.key ? image : im)
                if(im.key === image.key){
                    found = true;
                }
            }
            if (!found){
                images.push(image)
            }

            return {
                ...data,
                images,
                imagesPage : 0
            };

        case DataType.Images:
           // console.log(action.raw);
            return {
                ...data,
                images : JSON.parse(action.raw),
                imagesPage : 0
            };

        /*
               Sounds
         */
        case DataType.SoundWasDeleted:
            // console.log(action.raw);
            const soundID = action.raw;
            return {
                ...data,
                sounds : data.sounds ? data.sounds.filter(image => image.key !== soundID) : undefined,
            };

        case DataType.SoundWasUploaded:
            const sound = (action as S3DataAction).object;
//            console.log(image);
            if (!data.sounds){
                return {
                    ...data,
                    sounds : [sound]
                }
            }

            const sounds = []
            found = false
            for(let im of data.sounds){
                sounds.push(im.key === sound.key ? sound : im)
                if(im.key === sound.key){
                    found = true;
                }
            }
            if (!found){
                sounds.push(sound)
            }

            return {
                ...data,
                sounds,
            };

        case DataType.Sounds:
            // console.log(action.raw);
            return {
                ...data,
                sounds: JSON.parse(action.raw)
            };

        case DataType.ImagesTags:
            return {
                ...data,
                tagNames : JSON.parse(action.raw).tags
            };

        case DataType.FactTags:
            //console.log(action.raw);
            return {
                ...data,
                factTags : JSON.parse(action.raw).tags
            };
        case DataType.SchoolHardwareTags:
            // console.log(action.raw);
                return {
                    ...data,
                    schoolHardwareTags : JSON.parse(action.raw).tags
                };
        case DataType.Vocabulary:
            return {
                ...data,
                vocabulary :{...data.vocabulary,...JSON.parse(action.raw)}
            };
        case DataType.Facts:
          //  console.log(JSON.parse(action.raw));
            return {
                ...data,
                facts : JSON.parse(action.raw)
            };
        case DataType.Downloads:
            const downloadsAction : DownloadsData = action as DownloadsData;
          //  console.log(downloadsAction.downloads);
            return {
                ...data,
                downloads : downloadsAction.downloads
            };

        case DataType.VerifyEmail:
            const resp : BasicResponse = JSON.parse(action.raw);
          //  console.log(resp);
            return {
                ...data,
                verificationResponse : resp
            };

        case DataType.Vitrines:
            return {
                ...data,
                defaultVitrine : JSON.parse(action.raw)
            };
        /*
            Courses
         */

        case DataType.GetUploadVideoLink:
        case DataType.GetUploadFileLink:
        case DataType.GetStudentUploadFilesLink:
        case DataType.GetStudentUploadVideoLink:
        case DataType.GetAdminImageLinks:
        case DataType.GetAdminSoundLinks:
//           if(action.raw) console.log(JSON.parse(action.raw));
            return {
                ...data,
                currentUpload : action.raw.length > 0 ? JSON.parse(action.raw) : undefined
            };
        case DataType.GetVideoLink:
            const kv =JSON.parse(action.raw);
            return {...data, videoLinks : {...data.videoLinks,...kv}};
        case DataType.GetFileLink:
            const links =JSON.parse(action.raw);
            return {...data, fileLinks : {...data.fileLinks,...links}};
        case DataType.GetCourseLinks:
        case DataType.GetReviewLinks:
            const allLinks : CourseHomeworkLinks =JSON.parse(action.raw);
            return allLinks.homework ?
                {...data, fileLinks : {...data.fileLinks,...allLinks.files}, videoLinks : {...data.videoLinks,...allLinks.video},
                homeworkFileLinks : {...data.homeworkFileLinks, ...allLinks.homework!.files},
                homeworkVideoLinks : {...data.homeworkVideoLinks, ...allLinks.homework!.video}} :
                {...data, fileLinks : {...data.fileLinks,...allLinks.files}, videoLinks : {...data.videoLinks,...allLinks.video}};
        case DataType.GetTeachersLinks:
            const lnk : CourseLinks = JSON.parse(action.raw);
            // console.log(lnk);
            return {...data, fileLinks : {...lnk.files},videoLinks :{...lnk.video}};
        case DataType.FirebaseMessages:
            const fireAction : FirebaseCourseDataAction = action as FirebaseCourseDataAction;
            let firebaseMessages= {...data.firebaseMessages};
            if(firebaseMessages[action.raw]){
                const updates : (string|undefined)[] =fireAction.messages.map(ms=>ms.id);
                firebaseMessages[action.raw] = [...fireAction.messages,
                    ...firebaseMessages[action.raw].filter(ms=>updates.indexOf(ms.id)<0)];
            }else {
                firebaseMessages[action.raw] = fireAction.messages;
            }
            // console.log(firebaseMessages);
            return {...data, firebaseMessages };
        case DataType.MarkMessagesRed:
            const fireMessages= {...data.firebaseMessages};
            for(let key in fireMessages){
                for(let msg of fireMessages[key]){
                    msg.new = false;
                }
            }
        //    console.log(fireMessages);
            return {...data,firebaseMessages: fireMessages };
        case DataType.FirebaseHomework:
            const homeworkAction : FirebaseHomeworkAction = action as FirebaseHomeworkAction;
            let firebaseHomework= {...data.firebaseHomework};
            if (firebaseHomework[action.raw]){
                firebaseHomework[action.raw] = {...firebaseHomework[action.raw],...homeworkAction.homework};
            } else{
                firebaseHomework[action.raw] = homeworkAction.homework;
            }
            return {...data,firebaseHomework};
        case DataType.FirebaseStudentsHomework:
            const studentsHomeworkAction : FirebaseStudentsHomeworkAction = action as FirebaseStudentsHomeworkAction;
            let firebaseStudentsHomework = {...data.firebaseStudentsHomework};
            if (firebaseStudentsHomework[action.raw]){
                const studentsUpdates : string[] = studentsHomeworkAction.homework.map(hw=>hw.student);
                firebaseStudentsHomework[action.raw] = [
                    ...firebaseStudentsHomework[action.raw].filter(hw=>studentsUpdates.indexOf(hw.student)<0),
                    ...studentsHomeworkAction.homework];
            } else {
                firebaseStudentsHomework[action.raw] = studentsHomeworkAction.homework;
            }
  //          console.log(firebaseStudentsHomework);
            return {...data,firebaseStudentsHomework};
        case DataType.FirebaseSubscribers:
            const subAction : FirebaseSubscribersAction = action as FirebaseSubscribersAction;
            let students = {...data.students};
            if(students[action.raw]){
                students[action.raw] = [...students[action.raw].filter(s=>subAction.students.indexOf(s)<0), ...subAction.students]
            } else {
                students[action.raw] = subAction.students;
            }
//            console.log(students);
            return {...data,students};
        case DataType.CoursesCoverage:
            const coverageAction : FirebaseCoverageAction = action as FirebaseCoverageAction;
            let coursesCoverage = {...data.coursesCoverage};
            coursesCoverage[action.raw] = coverageAction.coverage;
            // console.log(coursesCoverage);
            return {...data,coursesCoverage};
        case DataType.CourseStatus:
            const statusAction : FirebaseCourseStatusAction = action as FirebaseCourseStatusAction;
            let courseStatuses = {...data.courseStatuses};
            courseStatuses[action.raw] = statusAction.status;
            console.log(courseStatuses);
            return {...data,courseStatuses};
        case DataType.ListReview:
            const reviewListAction : FirebaseReviewListAction = action as FirebaseReviewListAction;
            let courseReviewStatuses = {...data.courseStatuses,...reviewListAction.statuses};
           // console.log(courseReviewStatuses);
            return {...data,courseStatuses :courseReviewStatuses};
        case DataType.ListReviewRemove:
            const removeListAction : FirebaseReviewListAction = action as FirebaseReviewListAction;
            let courseReviews = {...data.courseStatuses};
            Object.keys(removeListAction.statuses).forEach(key=> delete courseReviews[key]);
         //   console.log(courseReviews);
            return {...data,courseStatuses :courseReviews};
        case DataType.StartReview:
            let reviewContent = {...data.reviewContent};
            const course : CourseData = JSON.parse(action.raw);
            reviewContent[course.id] = course;
            let oldStatuses = {...data.courseStatuses};
            if(oldStatuses[course.id]) {
                oldStatuses[course.id].reviewer = firebaseUsername();
                oldStatuses[course.id].status = PROCESS;
            }
            console.log(reviewContent);
            return {...data,reviewContent, courseStatuses : oldStatuses};
         case DataType.AbandonReview:
            let review = {...data.reviewContent};
            delete review[action.raw];
             let statuses = {...data.courseStatuses};
             if(statuses[action.raw]) {
                 statuses[action.raw].reviewer = undefined;
                 statuses[action.raw].status = REQUEST;
             }
            return {...data,reviewContent : review,courseStatuses : statuses};
        case DataType.FinishReview:
            let rev = {...data.reviewContent};
            delete rev[action.raw];
            return {...data,reviewContent : rev};
        case DataType.GetReviews:
        //    console.log(JSON.parse(action.raw));
            return {...data,reviewContent : JSON.parse(action.raw)};
        case DataType.FreeCourses:
            const freeCoursesDescriptions : FreeDlcDescription [] = JSON.parse(action.raw);
            console.log(freeCoursesDescriptions);
            return {...data,freeCoursesDescriptions};
         /*
           Sensors Analytics
         */
        case DataType.ClearSensorAnalytics:
            return {...data, sensorUsageMetrics : undefined, sensorsProductReport : undefined}
        case DataType.SensorUsageMetrics:
          //  console.log(JSON.parse(action.raw));
            return  {...data, sensorUsageMetrics : JSON.parse(action.raw)}
        case DataType.SensorProductReport:
            console.log(JSON.parse(action.raw));
            return  {...data, sensorsProductReport : JSON.parse(action.raw)}
         /*
            Analytics
          */
        case DataType.AnalyticsGetDateUsers:
            console.log(action.raw);
            const analytics: AnalyticsResponse= JSON.parse(action.raw);
            console.log(analytics);
            return {...data, analytics};

        case DataType.Void:
            AppToaster.show({
                intent: "success",
                icon : 'thumbs-up',
                message: action.raw ? action.raw : data.vocabulary[review_status_request],
            });
            return  data;

        case DataType.ServerError:
            AppToaster.show({
                intent: "danger",
                icon: 'error',
                message: action.raw ? action.raw : data.vocabulary[unprocessable_request],
            });
            return  data;
        default : return data;

    }
}