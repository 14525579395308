/*
    Adaptor between data and gui button
 */
import {IconName} from "@blueprintjs/icons";
import {MaybeElement} from "@blueprintjs/core/src/common/props";
import {Intent} from "@blueprintjs/core";
import {DataType} from "../actions/data";
import {Request} from "../model/Request";

export interface IButtonAdaptor {
    type : ButtonType;
    label ?: string;
    icon ?: IconName | MaybeElement;
    intent : Intent;
}

export enum ButtonType {
    Functional,
    Request
}

export class ButtonAdaptor implements IButtonAdaptor{
    type : ButtonType;
    label : string;
    icon ?: IconName | MaybeElement;
    intent : Intent;
    _onClick : ()=>void;

    constructor(label: string,  intent:  Intent, _onClick : ()=>void, icon : IconName | MaybeElement|undefined = undefined) {
        this.label = label;
        this.icon = icon;
        this.intent = intent;
        this.type = ButtonType.Functional;
        this._onClick = _onClick;
    }

    click=()=>  {
        this._onClick();
    }
}

export class RequestButtonAdapter implements IButtonAdaptor{
    type : ButtonType;
    label : string;
    icon ?: IconName | MaybeElement;
    intent : Intent;
    dataType : DataType;
    _getRequest :(token : string) => Request;

    constructor(label: string,  intent:  Intent, _getRequest : (token : string)=> Request,
                dataType : DataType, icon : IconName | MaybeElement|undefined = undefined) {
        this.label = label;
        this.icon = icon;
        this.intent = intent;
        this._getRequest = _getRequest;
        this.type = ButtonType.Request;
        this.dataType = dataType
    }

    getRequest=(token : string) : Request => {
        return this._getRequest(token);
    }

}