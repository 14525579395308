import React, {FC} from "react";
import {S3Object, toSingleContentAdaptor, UpdateTagsRequest} from "../../api/content/S3Search";
import {ImageSize} from "../../adaptors/ImageAdaptor";
import PostRequestForm from "../forms/PostRequestForm";
import {Intent} from "@blueprintjs/core/lib/esm/common/intent";
import {Txt} from "../tools/Text";
import {interface_label_16, tags_label} from "../../text/Literals";
import {Method} from "../../model/Request";
import {IS3content} from "../../api/content/S3ContentType";
import ContentViewer from "../viewers/ContentViewer";

interface ImageEditorProps{
    object : S3Object
    type : IS3content
    onExit ?: ()=>void
    ok ?: ()=>void
    imagesTags : string []
    url ?: string
    method ?: Method
}

const ImageEditor : FC<ImageEditorProps> = props => {
    return <div className='tiles-container-fixed image-editor-container'>
        <div className='tiles-half'>
            <ContentViewer
                className='success App'
                adaptor={toSingleContentAdaptor(props.type, props.object,props.onExit,ImageSize.XLarge)}
                type={props.type}
                title={props.object.key}
            />
        </div>
        <div className='tiles-half'>
            <PostRequestForm
                default={true}
                intent={Intent.WARNING}
                url= {props.url ? props.url : props.type.tags}
                label={new Txt().dict(tags_label)}
                type={props.type.tags}
                help={''}
                data={new UpdateTagsRequest(props.object.key, props.imagesTags, props.object.tags)}
                cancel={props.onExit}
                ok={props.ok}
                method={props.method ? props.method : Method.PUT}
                buttonText={interface_label_16}
            />
        </div>
    </div>
}

export default ImageEditor;