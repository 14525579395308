import { ThunkAction } from "redux-thunk";
import {AppState} from "../store/configureStore";
import {Request} from "../model/Request";
import {Action} from "redux";
import {User} from "../model/User";
import {InstrumentType, InstrumentDataAction, useInstrument,closeInstrument, InstrumentActionType} from "../actions/instruments";
import {sendRequest} from "./sendRequest";
import {Entity} from "../model/Entity";
import getDownloads from "./getDownloads";

import {firebaseSend} from "./firebase";
import {isAdmin} from "../model/PathVariables";

// eslint-disable-next-line import/no-anonymous-default-export
export default (
    type : InstrumentType,
    user : User| undefined = undefined,
    entity: Entity|undefined = undefined,
    clear : boolean = false
): ThunkAction<void, AppState, null, Action> => async (dispatch,getState) => {
    if(clear) dispatch (closeInstrument());
    dispatch(useInstrument(type,entity));
    const instrumentRequest : InstrumentDataAction = new InstrumentDataAction(type,entity,getState().settings, getState().user);
    //console.log(instrumentRequest);
    instrumentRequest.firebaseMessages.forEach(msg=>dispatch(firebaseSend(msg)));
    switch (instrumentRequest.actionType) {
        case InstrumentActionType.GetRequest:
        default:
            const requests : Request [] = instrumentRequest.getRequest(user? user.token : getState().user.token,getState().settings.language);
            requests.forEach((request,index) => dispatch(sendRequest(request,instrumentRequest.dataType[index])));
            return;
        case InstrumentActionType.GetDownloads:
            dispatch(getDownloads(isAdmin(getState().settings.mode)));
            return ;
    }

}

export const sendInstrumentRequest =(    type : InstrumentType,
                                          entity: Entity|undefined = undefined): ThunkAction<void, AppState, null, Action> => async (dispatch,getState) => {
    const user : User = getState().user;
    const instrumentRequest : InstrumentDataAction = new InstrumentDataAction(type,entity,getState().settings, user);
    const requests : Request [] = instrumentRequest.getRequest(user? user.token : getState().user.token,getState().settings.language);
    requests.forEach((request,index) => dispatch(sendRequest(request,instrumentRequest.dataType[index])));
};
