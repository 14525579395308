import React, {MouseEventHandler, PureComponent} from 'react';
import {Dialog, IconName, Intent, MenuItem} from "@blueprintjs/core";
import {Text, Txt} from "./Text";
import {MaybeElement} from "@blueprintjs/core/src/common/props";
import {SimpleOptionsFieldAdaptor} from "../../adaptors/FieldAdaptor";
import {IItemModifiers} from "@blueprintjs/select/src/common/itemRenderer";
import KeyValueViewer from "../viewers/KeyValueViewer";

interface DetailedOptionProps {
    intent : Intent;
    icon : IconName | MaybeElement;
    adaptor : SimpleOptionsFieldAdaptor;
    option : string;
    handleClick: MouseEventHandler<HTMLElement>;
    modifiers: IItemModifiers;
    getText :(option : string | undefined) => string;
}

interface DetailedOptionState {
    isExpanded : boolean;
}

class DetailedOption extends PureComponent<DetailedOptionProps,DetailedOptionState>{

    constructor(props: DetailedOptionProps, context: any) {
        super(props, context);
        this.state = {isExpanded : false}
    }

    handleExpand=()=>{
        this.setState({isExpanded : true});
        setInterval(this.handleCollapse,3000);
    };

    handleCollapse=()=>{
        this.setState({isExpanded :false});
    };

    render(){
        const option = this.props.option;
        return <div>
                    <MenuItem
                            icon={this.props.icon}
                            intent={this.props.intent}
                            text={this.props.getText(option)}
                            active={this.props.modifiers.active}
                            onClick={this.props.handleClick}
                            labelElement={this.props.adaptor.descriptors && this.props.adaptor.descriptors[option] ?
                                    <div className='color-text-primary' onMouseOver={this.handleExpand}>
                                        <Text text={new Txt().txt(this.props.adaptor.descriptors[option].alias,this.props.adaptor.descriptors[option].isCustom)}/>
                                    </div>: null}
                    />
                    <Dialog
                        canEscapeKeyClose={true}
                        canOutsideClickClose={true}
                        isOpen={this.state.isExpanded}
                        onClose={this.handleCollapse}
                        className='bp3-dark'>
                        <div className="bp3-dialog-body">
                        {this.props.adaptor.descriptors && this.props.adaptor.descriptors[option] ?
                            this.props.adaptor
                                .descriptors[option].views
                                .map((kv,index)=><KeyValueViewer key={index} kv={kv} token='' inline={false} />)
                            :null}
                        </div>
                    </Dialog>
                </div>
    }
}

export default DetailedOption;