import React, {FC} from 'react';
import {connect} from "react-redux";
import {AppState} from "../store/configureStore";
import {Button, Callout, Classes, Intent, IProps} from "@blueprintjs/core";
import {Exception, ExceptionType, ExceptionViewInfo, getExceptionViewInfo} from "../actions/error";
import logout from "../thunks/logout";
import {Vocabulary} from "../model/AppData";

import {authorization_label_11} from "../text/Literals";


interface ServerExceptionInfoProps extends IProps{
    exception : Exception,
    logoutButton ?: boolean;
    logout : any;
    vocabulary : Vocabulary;
    hideNoError ?: boolean;
}

const ServerExceptionInfo :FC<ServerExceptionInfoProps> = props => {
    const viewInfo : ExceptionViewInfo = getExceptionViewInfo(props.exception);
    const useLogout : boolean = !!props.logoutButton && props.exception.type === ExceptionType.Unauthorized;
    const hidden  = props.hideNoError && props.exception.type === ExceptionType.NoException;
    return (
        <div className={props.exception.type === ExceptionType.Processing && !hidden ? '':'faded-out'}>
            <Callout
                className={props.className}
                intent={viewInfo.intent}
                icon={viewInfo.icon}
                title={viewInfo.title ? props.vocabulary[viewInfo.title] : undefined}
            > {props.vocabulary[viewInfo.message]}
            </Callout>
            {useLogout ?
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                    <Button
                        intent={Intent.SUCCESS}
                        icon='log-in'
                        onClick={props.logout}>
                        {props.vocabulary[authorization_label_11]}
                    </Button>
                </div>:''}
        </div>
    );
};

const mapStateToProps = (state : AppState) =>({exception : state.error, vocabulary : state.data.vocabulary});
export default connect(mapStateToProps,{logout})(ServerExceptionInfo);