import {Action} from "redux";
import {FullBucketDescription} from "../service/S3";
import {FirebaseCourseData, FirebaseSignedHomework} from "../model/common/FirebaseMessage";
import {Vocabulary} from "../model/AppData";
import {LessonsCoverage} from "../model/common/LessonCoverage";
import {CourseStatuses, ICourseStatus} from "../model/courses/CourseReviewStatus";
import {S3Object} from "../api/content/S3Search";

export enum DataType {
    Unknown = '/',
    GetPlayer = 'admin/user/',
    DisablePlayer = 'admin/user/disable/',
    EnablePlayer = 'admin/user/enable/',
    UnVerifyPlayer = 'admin/user/unverify/',
    VerifyPlayer = 'admin/user/verify/',
    DecreaseBounds = 'admin/bounces/decrease/',
    DecreaseBoundsDevice = 'admin/bounces/decrease/device/',
    AuthorizeDevice = '/admin/user/devices/authorize/',
    AuthorizeDeviceHd = '/admin/hd/authorize/',
    Dlc  = '/admin/dlc',
    AllDlc  = '/admin/all/dlc',
    ArchiveDlc = '/admin/dlc/archive/',
    UnarchiveDlc = '/admin/dlc/unarchive/',
    Purchase ='admin/purchases/',
    Hardware = 'admin/hd/',
    Devices = 'admin/hardware/',
    Partners = 'partners',
    Persons = 'persons',
    PersonsAll = 'persons/all',
    PersonMail = "/persons/mail",
    MyPartners = 'partners/my',
    AuthPartners = 'partners/auth',
    ProductCheck = 'admin/product/',
    EmailLogin = 'login/email',
    MySchool = 'school/my',
    SchoolApply = 'school/apply',
    School ='school/',
    SchoolAll ='school/all',
    SchoolUser ='school/user/',
    SchoolAddComputers ="school/extra/",
    SchoolMail = "/school/mail",
    SchoolTeacher = "/school/teacher",
    CreateImpersonalSchool = '/schools/impersonal',
    DeleteUser ="admin/del",
    AddClassroom="classroom",
    FactTags = "tags/fact_tags",
    SaveHomeWork = "homework",
    SaveLessons = "classroom/homework",
    SaveClassroomMessage = "classroom/message",
    Vocabulary = "vocabulary",
    Facts = "homework/facts/",
    TeachersCourses = 'teacher/courses',
    StudentsCourses = 'courses',
    Downloads = 'downloads',
    GetUploadVideoLink ="teacher/video/url/",
    GetVideoLink = "video/url",
    GetUploadFileLink ="teacher/files/url/",
    GetTeachersLinks ="teacher/links/",
    GetAdminImageLinks ="res/images/",
    GetAdminSoundLinks ="res/sounds/",
    GetFileLink = "files/url",
    GetCourseLinks ="links/",
    GetReviewLinks ="review/links/",
    SchoolHardwareTags = "tags/school_hardware",
    ImagesTags = "tags/images",
    GetStudentUploadVideoLink ="students/video/",
    GetStudentUploadFilesLink ="students/files/",
    FirebaseMessages = "messages",
    FirebaseHomework = "firebase/homework",
    FirebaseStudentsHomework = "firebase/students/homework",
    MarkMessagesRed = "firebase/messages/red",
    FirebaseSubscribers ='firebase/subscribers',
    ChangePassword='change/password',
    CreateOperationToken = 'create/token',
    InvalidateContent = 'invalidate',
    CoursesCoverage = 'courses coverage',
    SendToReview = 'teacher/courses/review',
    CourseStatus = 'course status',
    ListReview = 'list review',
    ListReviewRemove = 'list review remove',
    StartReview='admin/review/start/',
    AbandonReview ='abandon review',
    GetReviews='admin/review',
    FinishReview = 'finish review',
    Software = 'software/all',
    FreeCourses = 'free',
    TakeFreeCourse ='free/',
    DeleteDraft = 'teacher/courses/delete',
    VerifyEmail = 'verify/',
    Vitrines = 'vitrines',
    Void = 'void',
    ServerError = 'server error',
    SoftwareLicenses = 'licenses/software',
    /**
     * Sensor Analytics
     */
    ClearSensorAnalytics = 'clear sensor analytics',
    SensorUsageMetrics = 'sensors/users/',
    SensorProductReport = 'sensors/product/',
    /**
     * Analytics
     */
    AnalyticsGetDateUsers = '/date/users',
    AnalyticsGetRaw = '/raw/',

    /**
     * Content
     */
    Images = 'res/images',
    ImageUpdateTags= 'res/images/tags',
    ImageWasDeleted ='image was deleted',
    ImageWasUploaded ='image was uploaded',

    Sounds = 'res/sounds',
    SoundUpdateTags= 'res/sounds/tags',
    SoundWasDeleted ='sound was deleted',
    SoundWasUploaded ='sound was uploaded'
}

export interface RawDataAction extends Action<DataType>{
    raw : string;
}

export interface DownloadsData extends RawDataAction{
    downloads : FullBucketDescription;
}

export interface FirebaseCourseDataAction extends RawDataAction{
    messages : FirebaseCourseData;
}

export interface FirebaseHomeworkAction extends RawDataAction{
    homework : Vocabulary;
}

export interface FirebaseStudentsHomeworkAction extends RawDataAction{
    homework : FirebaseSignedHomework[];
}

export interface FirebaseSubscribersAction extends RawDataAction{
    students : string[]
}

export interface FirebaseCoverageAction extends RawDataAction{
    coverage : LessonsCoverage;
}

export interface FirebaseCourseStatusAction extends RawDataAction{
    status : ICourseStatus;
}

export interface FirebaseReviewListAction extends RawDataAction{
    statuses : CourseStatuses;
}

export interface S3DataAction extends RawDataAction{
    object : S3Object;
}

export interface CreateImpersonalSchoolResponse extends RawDataAction {
    usernames : string[];
}

export function onObjectWasUploaded(object: S3Object, type : DataType = DataType.ImageWasUploaded) :S3DataAction{
    return {
        type,
        raw : '',
        object
    }
}

export function clearData(type : DataType, id : string) : RawDataAction {
    return {
        type,
        raw : id
    }
}

export function receiveRawData(type : DataType, raw: string) :RawDataAction {
    return {type, raw};
}

export function receiveDownloads(downloads: FullBucketDescription):DownloadsData {
    return {
        type :DataType.Downloads,
        downloads,
        raw:''
    };
}

export function receiveFirebaseMessages(topic : string,messages : FirebaseCourseData)  {
    return {
        type : DataType.FirebaseMessages,
        raw : topic,
        messages
    }
}
export function receiveCourseStatus(topic : string,status : ICourseStatus)  {
    return {
        type : DataType.CourseStatus,
        raw : topic,
        status
    }
}
export function receiveReviewList(statuses : CourseStatuses)  {
    return {
        type : DataType.ListReview,
        raw : '',
        statuses
    }
}
export function receiveReviewListRemoves(statuses : CourseStatuses)  {
    return {
        type : DataType.ListReviewRemove,
        raw : '',
        statuses
    }
}
export function receiveCoursesCoverage(topic : string,coverage : LessonsCoverage)  {
    return {
        type : DataType.CoursesCoverage,
        raw : topic,
        coverage
    }
}

export function receiveFirebaseHomework(topic : string,homework : Vocabulary) : FirebaseHomeworkAction {
    return {
        type : DataType.FirebaseHomework,
        raw : topic,
        homework
    }
}

export function receiveFirebaseStudentsHomework(topic : string,homework : FirebaseSignedHomework[] ) :FirebaseStudentsHomeworkAction {
    return {
        type : DataType.FirebaseStudentsHomework,
        raw : topic,
        homework
    }
}

export function receiveSubscribers(topic : string, students : string[]) {
    return {
        type : DataType.FirebaseSubscribers,
        raw : topic,
        students
    }
}

export function onObjectDelete(id : string, type : DataType =DataType.ImageWasDeleted ) :RawDataAction {
    return {
        type,
        raw : id
    }
}

export function markMessagesRed() :RawDataAction {
    return {
        type : DataType.MarkMessagesRed,
        raw : ''
    }
}

export function abandonData(type : DataType) : RawDataAction {
    return {
        type,
        raw : ''
    }
}

export function gotVoid(raw: string) : RawDataAction {
    return {
        type: DataType.Void,
        raw
    }
}

export function gotServerError(raw: string) : RawDataAction {
    return {
        type: DataType.ServerError,
        raw
    }
}
