import {MongoEntity} from "./course/Entity";
import {SimpleRequestData} from "../adaptors/RequestData";
import {FormAdaptor, IFormAdaptor} from "../adaptors/FormAdaptor";
import {NotEmptyStringFiled, PictureUrlAdaptor, UrlAdaptor} from "../adaptors/FieldAdaptor";
import {link_url_label, not_empty_field, picture_url_label, vitrine_button_label} from "../text/Literals";

export interface IVitrine extends MongoEntity{
    pictureUrl : string;
    storeUrl : string;
    description: string;
}

export class Vitrine extends SimpleRequestData implements IVitrine{
    readonly _id : string = 'default';
    pictureUrl : string;
    storeUrl : string;
    description: string;
    pictureAdaptor ?: PictureUrlAdaptor;
    urlAdaptor ?: UrlAdaptor;
    descriptionAdaptor ?: NotEmptyStringFiled;

    constructor(pictureUrl: string, storeUrl: string, description: string) {
        super();
        this.pictureUrl = pictureUrl;
        this.storeUrl = storeUrl;
        this.description = description;

        this.fields = ['_id','pictureUrl','storeUrl','description']
        this.adaptor = this.createFormAdaptor();
    }

    update =(model : IVitrine) : Vitrine=>{
        if(model.pictureUrl !== this.pictureUrl){
            this.pictureUrl = model.pictureUrl;
            this.initPictureAdaptor();
        }
        if(model.storeUrl !== this.storeUrl){
            this.storeUrl = model.storeUrl;
            this.initUrlAdaptor();
        }

        if(model.description !== this.description){
            this.description = model.description;
            this.initDescriptionAdaptor();
        }

        this.change();
        return this;
    }

    change = ()=>{
        this.onDataChanged!(new FormAdaptor([
            this.pictureAdaptor!,
            this.urlAdaptor!,
            this.descriptionAdaptor!,
        ],this))
    }

    createFormAdaptor=() : IFormAdaptor =>{
        return new FormAdaptor([
            this.initPictureAdaptor(),
            this.initUrlAdaptor(),
            this.initDescriptionAdaptor()
        ],this);
    };

    setPicture = (val : string)=>{
        this.pictureUrl = val;
        this.initPictureAdaptor();
        this.change();
    }

    setUrl = (val : string)=>{
        this.storeUrl = val;
        this.initUrlAdaptor();
        this.change();
    }

    setDescription = (val : string)=>{
        this.description = val;
        this.initDescriptionAdaptor();
        this.change();
    }

    initPictureAdaptor =()=>{
        this.pictureAdaptor = new PictureUrlAdaptor(picture_url_label,this.setPicture,not_empty_field,this.pictureUrl);
        return this.pictureAdaptor;
    }

    initUrlAdaptor = ()=>{
       this.urlAdaptor = new UrlAdaptor(link_url_label,this.setUrl,not_empty_field,this.storeUrl);
       return this.urlAdaptor;
    }

    initDescriptionAdaptor = ()=>{
        this.descriptionAdaptor = new NotEmptyStringFiled(vitrine_button_label,this.setDescription,not_empty_field,this.description);
        return this.descriptionAdaptor;
    }


}