import React, {PureComponent, ReactNode} from 'react';
import {connect} from "react-redux";
import {AppState} from "../../store/configureStore";
import {Process, ProcessType} from "../../actions/process";
import {Card, Elevation, Intent} from "@blueprintjs/core";
import {Exception, ExceptionType} from "../../actions/error";
import {contains, ITools} from "../../actions/tools";
import {InstrumentType} from "../../actions/instruments";

interface ProcessDisplayProps {
    process : Process,
    exception : Exception,
    intent ?: Intent;
    tools : ITools
    atTop ?: boolean;
    children?: ReactNode | undefined
}

interface ProcessDisplayState {
    isOpen : boolean
}

class ProcessDisplay extends PureComponent<ProcessDisplayProps,ProcessDisplayState>{

    timer ?: number;
    constructor(props: Readonly<ProcessDisplayProps>) {
        super(props);
        this.state = {
            isOpen : false
        }
    }
    close =()=>{
        // this.setState({...this.state, isOpen : this.props.process.type === ProcessType.WaitingForServerResponse });
        this.setState({...this.state, isOpen : false});
    };

    open=()=>{
        if(this.props.exception.type === ExceptionType.NoException || this.props.exception.type === ExceptionType.Processing || this.timer) return;

        this.setState({...this.state, isOpen : true});
        this.timer=setTimeout(()=>{
            this.setState({...this.state, isOpen : false});
            this.timer = undefined;
        },3000) as any;
    };

    componentDidUpdate(prevProps: Readonly<ProcessDisplayProps>, prevState: Readonly<ProcessDisplayState>, snapshot?: any): void {
        this.open();
    }

    render() {
        const isOpen = this.props.process.type === ProcessType.WaitingForServerResponse ? true : this.state.isOpen;
        const className = this.props.atTop || contains(this.props.tools,InstrumentType.UploadDialog) ? 'position-top' : 'position-middle';
        return isOpen?
            <Card interactive={true} elevation={Elevation.FOUR}
                              className={'instruments-progressbar--container ' + className} onClick={this.close}>
            { this.props.children}
            </Card> : null
    }
}

const mapStateToProps = (state : AppState)=>({
    process : state.process,
    exception : state.error,
    tools : state.tools
});

export default connect(mapStateToProps)(ProcessDisplay);
