
export const start_download = 'start_download';
export const for_school_use ='for_school_use';
export const for_home_use='for_home_use';
export const choose_platform='choose_platform';
export const platform_label='platform_label';
export const choose_language='choose_language';
export const notify_new_version='notify_new_version';
export const new_version='new_version';
export const elder_versions='elder_versions';

export const theory_label = "theory_label";
export const test_label = "test_label";
export const presentation_label = "presentation_label";
export const video_label = "video_label";
export const attachment_label = "attachment_label";

export const server_unreachable = 'server_unreachable';
export const no_network = 'no_network';
export const operation_completed = 'operation_completed';
export const operation_success = 'operation_success';
export const authorization_failure = 'authorization_failure';
export const bad_credentials = 'bad_credentials';
export const operation_denied = 'operation_denied';
export const permission_required = 'permission_required';
export const interface_label_301 = 'interface_label_301';
export const contact_administration = 'contact_administration';
export const operation_error = 'operation_error';
export const unprocessable_request = 'unprocessable_request';
export const object_missing = 'object_missing';
export const wrong_password = 'wrong_password';
export const wrong_password_confirmation = 'wrong_password_confirmation';
export const bad_request = 'bad_request';
export const bad_request_data = 'bad_request_data';
export const conflict = 'conflict';
export const duplicated_id = 'duplicated_id';
export const data_in_use = 'data_in_use';
export const not_found = 'not_found';
export const no_data_found = 'no_data_found';
export const processing = 'processing';
export const waiting_server_response = 'waiting_server_response';

export const wrong_format = 'wrong_format';
export const number_format_required = 'number_format_required';
export const not_empty_field = 'not_empty_field';
export const nothing_selected = 'nothing_selected';
export const uniq_value_required = 'uniq_value_required';
export const not_valid_email = 'not_valid_email';
export const not_valid_phone = 'not_valid_phone';

export const no_homework= 'no_homework';
export const name_label= 'name_label';
export const entity_id = 'entity_id';
export const message_for_class = 'message_for_class';
export const message_from_teacher = 'message_from_teacher';
export const homework_label = 'homework_label';
export const edit_course = 'edit_course';
export const join_classroom_link = 'join_classroom_link';
export const picture_url="picture_url";
export const teachers_nickname="teachers_nickname";
export const school_label ="school_label";
export const archived_class = "archived_class";


export const username_label='username_label';
export const authorization_label_4 ='authorization_label_4';
export const delay_minutes = 'delay_minutes';

export const tier_label = 'tier_label';
export const included_label = 'included_label';
export const included_short_label = 'included_short_label';
export const archived_label = 'archived_label';
export const do_archive = 'do_archive';
export const do_unarchive = 'do_unarchive';

//export const add_user_dlc = 'Добавить товар игроку';
export const enabled_user = 'enabled_user';
export const disable_user = 'disable_user';
export const disabled_user = 'disabled_user';
export const enable_user = 'enable_user';
export const email_verified = 'email_verified';
export const email_not_verified = 'email_not_verified';
export const email_doesnt_exist = 'email_doesnt_exist';
export const email_exists = 'email_exists';
export const registration_date = 'registration_date';
export const device_model = 'device_model';
export const software_products = 'software_products';
export const software_product_label = 'software_product_label';
export const authorization_enabled = 'authorization_enabled';
export const disable_authorization = 'disable_authorization';
export const authorization_disabled = 'authorization_disabled';
export const enable_authorization = 'enable_authorization';
export const invalid_email_registration = 'invalid_email_registration';
export const invalid_email_limit = `invalid_email_limit:`;// /n = new line
export const decrease_counter = 'decrease_counter';
export const operation_system = 'operation_system';
export const processor_type = 'processor_type';
export const memory_size = 'memory_size';
export const gpu_device_name = 'gpu_device_name';
export const gpu_device_version = 'gpu_device_version';
export const gpu_memory_size = 'gpu_memory_size';

export const message_from_student = 'message_from_student';

export const total_label = 'total_label';
export const registered_this_month = 'registered_this_month';
export const registered_today = 'registered_today';

export const product_key = 'product_key';
export const city_label ='city_label';
export const address_label = 'address_label';
export const email_label ='email_label';
export const phone_label = 'phone_label';
export const device_number = 'device_number';
export const master_version = 'master_version';
export const has_master = 'has_master';
export const no_master = 'no_master';
export const add_computers = 'add_computers';
export const additional_computers ="additional_computers";

export const courses_label = `courses_label`;

export const send_request = 'send_request';
export const common_caption_26 = 'common_caption_26';
export const add_label = 'add_label';
export const available_alternatives = 'available_alternatives';
export const create_dlc = 'create_dlc';
export const edit_dlc = 'edit_dlc';
export const dlc_id_required = 'dlc_id_required';
export const edit_label = 'edit_label';
export const register_label = 'register_label';
export const logout_label = 'logout_label';

export const change_message = 'change_message';

export const delete_user = 'delete_user';
export const username_password_required = 'username_password_required';
export const input_username = 'input_username';
export const username_input_required = 'username_input_required';

export const search_for_school = 'search_for_school';
export const search_for_person = 'search_for_person';
export const school_search_help = 'school_search_help';
export const person_search_help = 'person_search_help';
export const register_school = 'register_school';
export const create_impersonal_schools = 'create_impersonal_schools';
export const register_manager = 'register_manager';
export const register_user = 'register_user';
export const edit_school = 'edit_school';
export const edit_user = 'edit_user';
export const edit_manager = 'edit_manager';
export const organization_label = 'authorization_label_8';
export const extra_info = 'extra_info';
export const new_password = 'authorization_label_48';
export const schools_found = 'schools_found';
export const users_found = 'users_found';
export const license_purchases = 'license_purchases';
export const license_purchase_date = 'license_purchase_date';
export const licenses_not_purchased = 'licenses_not_purchased';
export const sellerLabel = 'seller';
export const cancelled = 'cancelled';
export const active = 'active';
export const state = 'state';
export const canceller = 'canceller';
export const canceldate = 'canceldate';
export const information_about_schools = 'information_about_schools';

export const create_impersonal_schools_quantity = 'create_impersonal_schools_quantity';
export const sellerNameLabel = 'seller_name_label';
export const created_impersonal_schools = 'created_impersonal_schools';
export const download_impersonal_schools = 'download_impersonal_schools';

export const classroom_creation = 'classroom_creation';
export const classroom_edit = 'classroom_edit';
export const classroom_edit_help = 'classroom_edit_help';
export const create_classroom = 'create_classroom';
export const edit_classroom = 'edit_classroom';
export const no_classroom_here = 'no_classroom_here';
export const press_create_classroom = 'press_create_classroom';

export const cancel_purchase = 'cancel_purchase';
export const players_dlc = 'players_dlc';
export const no_players_dlc = 'no_players_dlc';
export const add_player_dlc = 'add_player_dlc';
export const select_dlc = 'select_dlc';
export const add_user_dlc = 'add_user_dlc';

export const desktop_label = 'desktop_label';
export const handheld_label = 'handheld_label';
export const unknown_device_type = 'unknown_device_type';
export const show_less = 'show_less';
export const show_more = 'show_more';

export const bad_input = 'bad_input';
export const hide_label = "hide_label";
export const show_label = "show_label";
export const authorization_label_54 = "authorization_label_54";
export const authorization_label_55 = "authorization_label_55";
export const authorization_label_11 = 'authorization_label_11';
export const registration_label = 'registration_label';
export const change_to_register = 'change_to_register';
export const change_to_login = 'change_to_login';
export const password_doesnt_match = 'password_doesnt_match';

export const press_enter_send = 'press_enter_send';

export const course_label ="course_label";
export const draft_label ="draft_label";
export const lessons_label = "lessons_label";

export const is_homework_active = 'is_homework_active';
export const homework_task = 'homework_task';
export const homework_active = 'homework_active';
export const homework_inactive = 'homework_inactive';
export const task_description = 'task_description';
export const pictures_set = 'pictures_set';
export const task_label = 'task_label';
export const cardsapp_label_2 = 'cardsapp_label_2';
export const task_type = "task_type";
export const all_course = 'all_course';
export const classroom_label = 'classroom_label';
export const classrooms_label = 'classrooms_label';
export const school_classroom_control = 'school_classroom_control';
export const schools_label = 'schools_label';
export const school_instrument = "school_instrument";
export const school_administration = 'school_administration';
export const dlc_title = 'dlc_title';
export const player_info = 'player_info';
export const dlc_stock_list = "dlc_stock_list";
export const admin_tools_label = 'admin_tools_label';
export const player_info_description = "player_info_description";
export const player_devices_count = "player_devices_count";

export const name_label_1 = "name_label_1";
export const interface_label_16 = "interface_label_16";
export const name_label_2 = "name_label_2";
export const name_label_3  = "name_label_3";
export const name_label_4 = "name_label_4";
export const name_label_5 = "name_label_5";
export const name_label_6 = "name_label_6";

export const show_old_versions = "show_old_versions";
export const hide_old_versions = "hide_old_versions";
export const upload_file = "upload_file";
export const choose_local_file= "choose_local_file";
export const cancel_label = "cancel_label";
export const override_file = "override_file";
export const additional_languages = "additional_languages";
export const course_description ="course_description";
export const do_activate  = 'do_activate';
export const do_inactivate = 'do_inactivate';
export const open_file = 'open_file';
export const lesson_topic = 'lesson_topic';
export const lesson_text ='lesson_text';
export const no_video_lesson ="no_video_lesson";
export const no_file_lesson = "no_file_lesson";
export const lesson_file = "lesson_file";
export const create_course = "create_course";
export const course_creation = "course_creation";
export const modules_label = "modules_label";

export const homework_nothing = 'homework_nothing';
export const homework_video = 'homework_video';
export const homework_tests = 'homework_tests';
export const homework_file = 'homework_file';
export const purchased_courses ='purchased_courses';
export const get_school_metrics ='get_school_metrics';
export const hide_school_metrics='hide_school_metrics';
export const hardware_shipment_type='hardware_shipment_type';
export const download_additional_content = 'download_additional_content';
export const page_classwork = "page_classwork";
export const no_homework_file = "no_homework_file";
export const no_homework_video = "no_homework_video";
export const homework_file_label ="homework_file_label";
export const homework_video_label ="homework_video_label";
export const messages_label = 'messages_label';
export const students_message='students_message';
export const description_label = "description_label";
export const full_picture ='full_picture';
export const thumbnail_picture='thumbnail_picture';
export const dlc_view_details = 'dlc_view_details';
export const key_not_found='key_not_found';
export const delete_user_form ='delete_user_form';
export const operation_time_out ='operation_time_out';
export const server_time_out ='server_time_out';
export const wrong_file_type = 'wrong_file_type';
export const add_player_course = 'add_player_course';
export const select_course_dlc = 'select_course_dlc';
export const add_user_course = 'add_user_course';
export const course_owner = 'course_owner';
export const total_lessons = 'total_lessons';
export const current_product_version="current_product_version";
export const lesson_caption = 'lesson_caption';
export const homework_of_student ="homework_of_student";
export const all_students_homework ='all_students_homework';
export const current_course_homework = 'current_course_homework';
export const current_lesson_homework = 'current_lesson_homework';
export const course_structure = 'course_structure';
export const teachers_message = 'teachers_message';
export const teachers_message_homework = 'teachers_message_homework';
export const teachers_homework_review = 'teachers_homework_review';
export const do_answer = 'do_answer';
export const preview_label = "preview_label";
export const press_to_update = 'press_to_update';
export const teachers_answer ='teachers_answer';
export const common_label_156 ='common_label_156';
export const total_students_course = 'total_students_course';
export const new_students_registered = "new_students_registered";
export const new_homework_received = "new_homework_received";
export const new_messages_received = "new_messages_received";
export const message_was_sent = "message_was_sent";
export const homework_was_sent = "homework_was_sent";
export const modules_quantity = "modules_quantity";
export const lessons_quantity = 'lessons_quantity';
export const module_caption = "module_caption";
export const add_course_structure = "add_course_structure";
export const add_course_modules = "add_course_modules";
export const do_create_dlc = "do_create_dlc";
export const edit_course_structure ="edit_course_structure";
export const do_add ="do_add";
export const homework_viewer = "homework_viewer";
export const upload_video = "upload_video";
export const choose_local_video = "choose_local_video";
export const review_label = "review_label";
export const do_study = "do_study";
export const do_change_password ="authorization_label_59";
export const password_change = "authorization_label_53";
export const wrong_password_length = "authorization_label_56";
export const password_doesnt_match_confirmation = "authorization_label_60";
export const confirm_password = "authorization_label_55";
export const password_change_failed = "authorization_label_58";
export const password_changed = "authorization_label_57";
export const forgot_password = "forgot_password";
export const send_email_to ="authorization_label_44";
export const link_was_send = "link_was_send";
export const upload_failed = 'upload_failed';
export const file_uploaded = 'file_uploaded';
export const no_need_to_save = 'no_need_to_save';
export const need_to_save = 'need_to_save';
export const send_to_review = 'send_to_review';
export const review_description = 'review_description';
export const review_message = 'review_message';
export const review_sent = 'review_sent';
export const review_status  = 'review_status' ;
export const review_status_request = 'review_status_request' ;
export const review_status_process = 'review_status_process' ;
export const review_status_rejected = 'review_status_rejected' ;
export const review_status_accepted = 'review_status_accepted' ;
export const review_time = 'review_time';
export const review_list_label = 'review_list_label';
export const review_status_none = 'review_status_none';
export const review_start = 'review_start';
export const review_under_list = 'review_under_list';
export const review_pass = 'review_pass';
export const review_see_original = 'review_see_original';
export const review_see_draft  = 'review_see_draft';
export const review_no_original = 'review_no_original';
export const review_deny = 'review_deny';
export const review_accept= 'review_accept';
export const review_reject_message = 'review_reject_message';
export const review_reject_description = 'review_reject_description';
export const review_no_original_short = 'review_no_original_short';
export const review_courses_added = 'review_courses_added';
export const review_courses_changed = 'review_courses_changed';
export const file_modification_deny = 'file_modification_deny';
export const file_modification_prohibited = 'file_modification_prohibited';
export const file_new_name = 'file_new_name';
export const file_used_name = 'file_used_name';
export const analytics_label = 'analytics_label';
export const events_label = 'events_label';
export const from_label = 'from_label';
export const to_label = 'to_label';
export const select_products = 'select_products';
export const editor_record_label = 'editor_record_label';
export const details_button_label = 'details_button_label';
export const free_courses = 'free_courses';
export const get_free_courses = 'get_free_courses';
export const free_label = 'free_label';
export const free_course_added = 'free_course_added';
export const delete_draft_course = 'delete_draft_course';
export const course_delete = 'course_delete';
export const label_backward = 'code_label_backward';
export const register_link = 'authorization_label_12';
export const select_program_language = 'select_program_language';
export const vitrine_label = 'vitrine_label';
export const vitrine_info = 'vitrine_info';
export const picture_url_label = 'picture_url_label';
export const link_url_label = 'link_url_label';
export const vitrine_button_label = 'vitrine_button_label';
export const region_label = 'region_label';
export const contact_label ='contact_label';
export const position_label = 'authorization_label_9';
export const start_analytics_processor = 'start_analytics_processor';
export const changes_saved = "changes_saved";
export const input_email = "interface_label_623";
export const login_with_email = "login_with_email";
export const continue_label = 'continue_label';
export const access_code_description = 'access_code_description';
export const input_access_code = 'input_access_code';
export const code_accepted = 'code_accepted';
export const register_info_hint = 'register_info_hint';
export const school_register_success = 'school_register_success';
export const your_product_key = 'your_product_key';
export const too_many_registrations = 'too_many_registrations';
export const comment_label = 'comment_label';
export const school_found_message = 'school_found_message';
export const school_not_found = 'school_not_found';
export const trial_period = 'trial_period';
export const license_expiration = 'license_expiration';
export const cards_app_school = 'cards_app_school';
export const logopedia = 'logopedia';
export const logopedia_label = 'authorization_label_105'
export const school = 'school';
export const robot_key ='robot_key';
export const trial_not_allowed = 'trial_not_allowed';
export const register_shd = 'register_shd';
export const register_shd_comment = 'register_shd_comment';
export const add_trial_school = 'add_trial_school';
export const add_trial = 'add_trial';
export const add_or_register = 'add_or_register';
export const shd_trial = 'shd_trial';
export const logopedia_trial = 'logopedia_trial';
export const add_trial_success = 'add_trial_success';
export const not_selected_trial_product = 'not_selected_trial_product'
export const register_shd_new = 'register_shd_new';
export const shd_for_students = 'shd_for_students';
export const shd_for_teachers = 'shd_for_teachers';
export const download_from_site = 'download_from_site';
export const download_selected_schools ='download_selected_schools';
export const download_selected_users ='download_selected_users';
export const email_list_users ='email_list_users';
export const register_more_school = 'register_more_school';
export const interface_language = 'interface_language';
export const language_name = 'language_name';
export const school_name = 'school_name';
export const manager_name = 'manager_name';
export const user_name_label = 'user_name_label';
export const with_licenses = 'with_licenses';
export const without_licenses = 'without_licenses';
export const will_expire_licenses = 'will_expire_licenses';
export const email_bounced = 'email_bounced';
export const email_subject = 'email_subject';
export const email_body = 'email_body';
export const email_recipients = 'email_recipients';
export const email_list_label = 'email_list_label';
export const email_sent_count = 'email_sent_count';
export const invalid_count = 'invalid_count';
export const mx_not_found = 'mx_not_found';
export const email_list = 'email_list';
export const invalid_or_bounced = 'invalid_or_bounced';
export const invalid_count_filtered = 'invalid_count_filtered';
export const no_school_user = 'no_school_user';
export const mail_sent_results = 'mail_sent_results';
export const school_registration_form = 'school_registration_form';
export const drozdov_registration_form = 'drozdov_registration_form';
export const school_view_form = 'school_view_form';
export const all_downloads = 'all_downloads';
export const all_product_downloads = 'all_product_downloads';
export const hardware_info = 'cardsapp_speech_61';
export const input_hardware_id = 'input_hardware_id';
export const hardware_search = 'hardware_search';
export const product_check_label  ='product_check_label';
export const sensors_label = 'sensors_label';
export const username_get_metrics = 'username_get_metrics';
export const usage_metrics_label = 'usage_metrics_label';
export const sensor_usage_count = 'sensor_usage_count';
export const last_sensor_usage = 'last_sensor_usage';
export const sensor_type_label = 'sensor_type_label';
export const product_report_label = 'product_report_label';
export const sensor_report_title = 'sensor_report_title';
export const not_user_count = 'not_user_count';
export const not_user_label = 'not_user_label';
export const total_product_users = 'total_product_users';
export const sensor_user_count = 'sensor_user_count';
export const teacher_accounts = 'authorization_label_80';
export const full_name_label = 'authorization_label_7';
export const create_teacher_account = 'authorization_label_97';
export const add_teacher = 'interface_label_584';
export const remove_teacher = 'interface_label_582';
export const images_label = 'interface_label_702';
export const content_label = 'page_classwork';
export const administration_managers = 'administration_managers';
export const manager_information = 'manager_information';
export const managers = 'interface_label_645';
export const users_administration = 'users_administration';
export const users = 'users_label';
export const image_search_title = 'common_label_1010';
export const image_search_prefix = 'common_label_1011';
export const tags_label = 'interface_label_520';
export const delete_label = 'common_caption_26';
export const sounds_label = 'cardsapp_speech_44';
export const sound_search_title = 'common_label_1012';
export const data_copied = 'data_copied';
export const copy_ids = 'copy_ids';
export const copy_emails_label = 'copy_emails_label';
export const un_verify_user = 'un_verify_user';
export const verify_user = 'verify_user';
export const expired_licenses = 'expired_licenses';
export const active_licenses = 'active_licenses';
export const del_test_devices = 'del_test_devices';
export const del_test_help = 'del_test_help';
