import {DataType} from "../../actions/data";
import {FileTypes} from "../../service/FileService";
import {image_search_title, sound_search_title} from "../../text/Literals";

export interface IS3content{
    type : DataType
    tags : DataType
    onDelete : DataType
    onUpdate : DataType
    getLink : DataType
    title : string
    fileType : FileTypes
}

export const S3ContentImage : IS3content = {
    type :     DataType.Images,
    tags : DataType.ImageUpdateTags,
    onDelete : DataType.ImageWasDeleted,
    onUpdate  : DataType.ImageWasUploaded,
    getLink : DataType.GetAdminImageLinks,
    title : image_search_title,
    fileType : FileTypes.SVG
}

export const S3ContentAudio : IS3content = {
    type :  DataType.Sounds,
    tags : DataType.SoundUpdateTags,
    onDelete : DataType.SoundWasDeleted,
    onUpdate  : DataType.SoundWasUploaded,
    getLink : DataType.GetAdminSoundLinks,
    title : sound_search_title,
    fileType : FileTypes.AUDIO
}