import {Action} from "redux";
import {IconName, Intent} from "@blueprintjs/core";
import {MaybeElement} from "@blueprintjs/core/src/common/props";
import {
    authorization_failure,
    bad_credentials,
    bad_request,
    bad_request_data,
    conflict,
    contact_administration,
    data_in_use,
    duplicated_id,
    no_data_found,
    no_network,
    not_found,
    object_missing,
    operation_completed,
    operation_denied,
    operation_error,
    operation_success,
    permission_required,
    processing,
    server_unreachable,
    unprocessable_request,
    interface_label_301,
    waiting_server_response,
    wrong_password,
    wrong_password_confirmation,
    operation_time_out,
    server_time_out,
    password_change_failed,
    file_modification_deny,
    file_modification_prohibited, too_many_registrations, trial_not_allowed, invalid_or_bounced
} from "../text/Literals";

export enum ExceptionType {
    SystemMessage = - 100,
    TimeOut = -2,
    Processing = -1,
    NoNetwork = 0,
    PasswordsNotTheSame = 77,
    NoException = 200,
    WrongRequest = 400,
    Unauthorized = 401,
    PaymentRequired = 402,
    Forbidden = 403,
    NotFound = 404,
    Conflict = 409,
    Gone = 410,
    BadEmail = 411,
    DisabledUser = 418,
    WrongTempToken = 422,
    FailedDependency = 424,
    ToManyOperations = 429,
    ModificationProhibited = 451,
    ServerException = 500
}

export interface Exception extends Action<ExceptionType>{
    message : string | undefined;
}

export function onAuthorizationException(message: string | undefined = undefined) : Exception{
    return {
        type : ExceptionType.Unauthorized,
        message
    }
}

export function noException() : Exception {
    return {
        type : ExceptionType.NoException,
        message : undefined
    }
}

export function serverException(message  :string):Exception {
    return {
        type : ExceptionType.ServerException,
        message
    }
}

export function onException(type : ExceptionType, message ?: string):Exception {
    return {type,message}
}

export function systemMessage(message : string) : Exception{
    return {
        type : ExceptionType.SystemMessage,
        message
    }
}

export interface ExceptionViewInfo {
    title ?: string;
    message : string;
    intent : Intent,
    icon ?: IconName | MaybeElement;
}

export function getExceptionViewInfo(exception: Exception) : ExceptionViewInfo{
    const intent : Intent = Intent.DANGER;
    const icon : IconName = 'issue';
    switch (exception.type) {
        case ExceptionType.NoNetwork:
            return  {title : server_unreachable, message : no_network, intent};
        case ExceptionType.NoException:
            return {
                title : operation_completed,
                message : operation_success,
                intent :Intent.SUCCESS,
                icon : 'saved'
            };
        case ExceptionType.SystemMessage:
            return {
                title : undefined,
                message : exception.message!,
                intent :Intent.PRIMARY,
                icon : 'info-sign'
            };
        case ExceptionType.Unauthorized:
            return {
                title : authorization_failure,
                message : exception.message !== undefined ? exception.message : bad_credentials,
                intent,
                icon : 'lock'};
        case ExceptionType.Forbidden:
            return {
                title : operation_denied,
                message : exception.message !== undefined ? exception.message : permission_required,
                intent,
                icon : 'lock'};
        case ExceptionType.DisabledUser:
            return {
                title : interface_label_301,
                message : exception.message !== undefined ? exception.message : contact_administration,
                intent,
                icon : 'lock'};
        case ExceptionType.WrongTempToken:
            return {
                title : operation_error,
                message : password_change_failed,
                intent,
                icon};
        case ExceptionType.ModificationProhibited:
            return {
                title : file_modification_deny,
                message : file_modification_prohibited,
                intent,
                icon};
        default:
        case ExceptionType.ServerException:
            return {
                title : operation_error,
                message : exception.message !== undefined ? exception.message : unprocessable_request,
                intent,
                icon};
        case ExceptionType.Gone:
            return {
                title : operation_error,
                message : object_missing,
                intent,
                icon};
        case ExceptionType.PasswordsNotTheSame:
            return {
                title : wrong_password,
                message : exception.message !== undefined ? exception.message : wrong_password_confirmation,
                intent,
                icon};
        case ExceptionType.TimeOut:
            return {
                title : operation_time_out,
                message : server_time_out,
                intent,
                icon};
        case ExceptionType.WrongRequest:
            return {
                title : bad_request,
                message : exception.message !== undefined ? exception.message : bad_request_data,
                intent,
                icon: 'error'};
        case ExceptionType.Conflict:
            return {
                title : conflict,
                message : exception.message !== undefined ? exception.message : duplicated_id,
                intent,
                icon};
        case ExceptionType.FailedDependency:
            return {
                title : operation_error,
                message : exception.message !== undefined ? exception.message : data_in_use,
                intent,
                icon};
        case ExceptionType.ToManyOperations:
            return {
                title : operation_error,
                message : too_many_registrations,
                intent,
                icon};
        case ExceptionType.PaymentRequired:
            return {
                title : operation_error,
                message : trial_not_allowed,
                intent,
                icon};
        case ExceptionType.BadEmail:
            return {
                title : operation_error,
                message : invalid_or_bounced,
                intent,
                icon};
        case ExceptionType.NotFound:
            return {
                title : not_found,
                message : exception.message !== undefined ? exception.message : no_data_found,
                intent : Intent.WARNING,
                icon : 'warning-sign'};
        case ExceptionType.Processing:
            return {
                title : processing,
                message : waiting_server_response,
                intent : Intent.PRIMARY,
                icon : 'globe-network'};
    }
}