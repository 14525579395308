import React, { Component } from "react";
import {AppState} from "../../store/configureStore";
import {connect} from "react-redux";
import {Button, Card, Elevation} from "@blueprintjs/core";
import {Intent} from "@blueprintjs/core/lib/esm/common/intent";
import PostRequestForm from "../forms/PostRequestForm";
import {DataType} from "../../actions/data";

import {
    data_copied,
    edit_label, edit_manager,
    operation_success,
    register_label, register_manager,
} from "../../text/Literals";
import {Vocabulary} from "../../model/AppData";
import {Txt} from "../tools/Text";
import {AppToaster} from "../../service/toaster";
import {MailResponse} from "../../api/MailRequest";
import {ConvertManager, IManager, Manager} from "../../api/Manager";

interface ManagerAdminInstrumentProps {
    managers ?: IManager[];
    token : string;
    vocabulary : Vocabulary
}

interface ManagerAdminInstrumentState {
    manager ?: Manager;
    hideSearch : boolean;
    expanded : Set<string>;
    hideMetrics : boolean;
    recipients : string [];
    invalidCount ?:number;
    mailResponse ?: MailResponse;
    waitForManagerUpdate ?: boolean;
}

class ManagerAdminInstrument extends Component<ManagerAdminInstrumentProps, ManagerAdminInstrumentState> {
    constructor(props: Readonly<ManagerAdminInstrumentProps>) {
        super(props);
        this.state = {
            manager : undefined,
            hideSearch : false,
            expanded : new Set<string>(),
            hideMetrics : false,
            recipients : []
        }
    }

    message = (msg : string, intent : Intent) => {
        AppToaster.show({
            icon : "warning-sign",
            intent : intent,
            message : this.props.vocabulary[msg]
        });
    }

    handleEditManager = (manager : IManager | undefined) => {
        if (manager === undefined) {
            this.setState({...this.state, manager});
            return;
        }

        this.setState({
            ...this.state,
            manager : ConvertManager(manager)})
        console.log(this.state)
    };


    componentDidUpdate(prevProps: Readonly<ManagerAdminInstrumentProps>, prevState : Readonly<ManagerAdminInstrumentState>, snapshot?: any) {
        if(!this.state.waitForManagerUpdate){
            return
        }

        if (prevState.manager && this.state.manager && this.props.managers && this.props.managers.length === 1 && this.state.manager === prevState.manager){
            this.setState({...this.state, waitForManagerUpdate : false, manager : ConvertManager(this.props.managers[0])})
        }
    }

    handleMailTo =(recipients : string[]) =>{
        if (recipients.length === 0){
            this.message(operation_success, Intent.SUCCESS);
            return;
        }
        this.setState({...this.state, recipients})
    }

    handleCopyIds = ()=>{
        if(! this.props.managers || this.props.managers.length === 0){
            return
        }

        navigator.clipboard.writeText(this.props.managers.map(school => school.username).join(",")).then(
            () => { this.message(data_copied, Intent.PRIMARY); }
        )
    }

    handleCloseMail = ()=>{
        this.setState({...this.state,recipients : [],invalidCount : undefined})
    }

    handleMailSent = (msg ?: string) => {
        if (msg) {
            this.setState({...this.state, mailResponse: JSON.parse(msg)})
        }
        this.handleCloseMail();
    }

    handleCloseMailResult = () => {
        this.setState({...this.state,mailResponse : undefined})
    }

    handleCreateManager = () => {
        this.handleEditManager({
            username : '',
            organization : '',
            name : '',
            phone : '',
            position : '',
            description : ''
        });
    };

    startSearch = () => {
        this.setState({
            ...this.state,
        })
        console.log(this.state)
    };

    stopSearch = () => {
        this.setState({
            ...this.state,
        })
    };

    hideSearch = (hideSearch : boolean) => {
        this.setState({
            ...this.state,
            hideSearch
        })
    };

    handleCloseEdit = () => {
        this.handleEditManager(undefined);
    };

    isExpanded = (manager : IManager) : boolean => {
        return this.state.expanded.has(manager.username)
    };

    handleExpand=(manager : IManager) =>{
        if (this.isExpanded(manager)){
            this.state.expanded.delete(manager.username);
        } else{
            this.state.expanded.add(manager.username);
        }

        this.setState({
            ...this.state
        })
    };

    handleSave = () => {
        this.setState({...this.state, waitForManagerUpdate : true});
        this.handleCloseEdit();
        this.message(operation_success, Intent.SUCCESS);
    };

    handleHideMetrics = () => {
        this.setState({...this.state, hideMetrics : true})
    };

    handleShowMetrics = () => {
        this.setState({...this.state, hideMetrics : false})
    };

    render() {
        return <Card interactive={false} elevation={Elevation.ZERO} className='horizontal-margin'>
            <div className='tiles-container-adjustable'>
                {  this.state.manager === undefined ?
                    <Button
                        intent={Intent.SUCCESS}
                        icon={"add"}
                        className='tiles-one-third'
                        onClick={() => {
                            this.handleCreateManager()
                        }}>{this.props.vocabulary[register_manager]}</Button> : null
                }
            </div>
            {
                this.state.manager === undefined ? null :
                <div>
                    <PostRequestForm
                        default={true}
                        intent={Intent.PRIMARY}
                        url= {DataType.Partners}
                        label={new Txt().dict(this.state.manager.isNew ? register_manager : edit_manager)}
                        type={this.state.manager.getType()}
                        help={''}
                        data={this.state.manager}
                        method={this.state.manager.getMethod()}
                        cancel={this.handleCloseEdit}
                        buttonText={this.state.manager.isNew ? register_label : edit_label}
                        ok ={this.handleSave}
                    />
                </div>
            }
        </Card>
    }
}

const mapStateToProps = (state: AppState) => ({
    managers : state.data.managers,
    token : state.user.token,
    vocabulary : state.data.vocabulary
});
export default connect(
    mapStateToProps
)(ManagerAdminInstrument);