import {IntentKV, KV, TimestampKV} from "../KV";
import {ACCEPTED, NONE, PROCESS, REJECTED, REQUEST, REVIEW_STATUS} from "../../service/Literals";
import {description_label, review_status, review_time} from "../../text/Literals";
import {Intent} from "@blueprintjs/core";

export interface ICourseReviewStatus {
    status : string;
    time : number;
    message : string;
    name ?: string;
    reviewer ?: string;
}

export interface ICourseStatus extends ICourseReviewStatus{
    image : string;
    description : string;
}

export interface CourseStatuses {
    [key:string] : ICourseStatus
}

export function getCourseStatusKV(status : ICourseStatus|undefined): KV[] {
    status = status && status.status ? status : {
        status: NONE,
        time : new Date().getTime(),
        message : '',
        image : '',
        description : ''
    };
    let intent: Intent = Intent.NONE;
    switch (status.status) {
        case REQUEST:
            intent = Intent.PRIMARY;
            break;
        case PROCESS:
            intent = Intent.WARNING;
            break;
        case REJECTED:
            intent = Intent.DANGER;
            break;
        case ACCEPTED:
            intent = Intent.SUCCESS;
            break;
        case NONE:
            intent = Intent.NONE;
    }
    return [
        new IntentKV(review_status,REVIEW_STATUS+status.status,intent),
        new TimestampKV(review_time,status.time),
        {key : description_label,value : status.message}
    ]
}