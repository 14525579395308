import React, {PureComponent, RefObject} from "react";
import InstrumentContainer from "../InstrumentContainer";
import {InstrumentType} from "../../actions/instruments";
import {EmbeddedCourseViewer} from "../courses/CourseViewer";
import {Vocabulary} from "../../model/AppData";
import {AppState} from "../../store/configureStore";
import {connect, Provider} from "react-redux";
import {useTool} from "../../actions/tools";
import {Alignment, Button, Intent, Navbar, Tab, Tabs, Tooltip} from "@blueprintjs/core";
import {hasClasswork, hasHomework, ILesson} from "../../model/courses/Lesson";
import {
    course_creation,
    download_additional_content,
    homework_label,
    messages_label, operation_success,
    page_classwork,
    review_accept,
    review_deny, review_no_original, review_no_original_short,
    review_see_draft,
    review_see_original,
} from "../../text/Literals";
import DownloadButton from "../buttons/DownloadButton";
import {LessonPage} from "../courses/LessonPlayer";
import {CoursePosition} from "../../actions/settings";
import {Course} from "../../model/courses/Course";
import {checkCourseId} from "../courses/AbstractCourseViewer";
import {Text, Txt} from "../tools/Text";
import {sendRequest} from "../../thunks/sendRequest";
import {Method, Request} from "../../model/Request";
import {CourseReviewRequest} from "../../model/courses/CourseReviewRequest";
import {DataType} from "../../actions/data";
import {clearData} from "../../actions/data";
import {AppToaster} from "../../service/toaster";
import {closeInstrument} from "../../actions/instruments";
import store from "../../store/store";


interface CourseViewerInstrumentProps {
    position : CoursePosition;
    course : Course;
    lesson ?:ILesson;
    fileLinks : Vocabulary;
    isPreview ?: boolean;
    isReview ?: boolean;
    onClose ?:()=>void;
    sendRequest : any;
    clearData : any;
    closeInstrument : any;
    useTool : any;
    token : string;
}

interface CourseViewerInstrumentState {
    page : LessonPage;
    isPrevious : boolean;
}

class CourseViewerInstrument extends PureComponent<CourseViewerInstrumentProps,CourseViewerInstrumentState>{
    containerRef : RefObject<HTMLDivElement>;

    constructor(props: Readonly<CourseViewerInstrumentProps>) {
        super(props);
        this.state ={page : page_classwork, isPrevious : false};
        this.containerRef = React.createRef();
    }

    handleChangeTab=(page: LessonPage)=>{
        this.setState({...this.state,page})
    };

    handleShowPrevious=()=>{
        this.setState({...this.state,isPrevious: false})
    };

    handleShowNew=()=>{
        this.setState({...this.state,isPrevious: true})
    };

    handleAccept=()=>{
        const request : CourseReviewRequest = new CourseReviewRequest(this.props.course.id,this.props.course.getName());
        this.props.sendRequest(new Request(request.message(),DataType.GetReviews,Method.POST,this.props.token),DataType.FinishReview,undefined,()=>{
            console.log("accepted");
            this.props.clearData(DataType.FinishReview,this.props.course.id)
            AppToaster.show({
                intent: "success",
                icon: "globe",
                message: <Provider store={store}><Text text={new Txt().dict(operation_success)}/></Provider>
                //props.vocabulary[review_sent]
            });
            this.props.closeInstrument();
        })
    };

    handleReject=()=>{
        this.props.useTool(InstrumentType.CourseReview,InstrumentType.ReviewRejectWindow)
    };

    render(){
//        console.trace("___render course viewer instrument ____");
        const fileUrl: string | undefined =this.props.lesson? (this.props.lesson.file ? this.props.fileLinks[this.props.lesson.file] : undefined) : undefined;
        const  instrument : InstrumentType = this.props.isReview ? InstrumentType.CourseReview : (this.props.isPreview ? InstrumentType.No :InstrumentType.StudentsCourses);
        return(
            <InstrumentContainer
                fullscreen={true}
                onTop={true}
                instrument={instrument}
                title={new Txt().dict(course_creation)}
                onClose={this.props.onClose}
                containerRef={this.containerRef}
                background={this.props.isReview && ! this.state.isPrevious ? 'bp3-dark': ' '}
                titleFactory={()=>(
                    <Navbar >
                        {/*<Navbar.Group className=''>*/}
                        {/*    <Navbar.Heading>*/}
                        {/*        {''}*/}
                        {/*    </Navbar.Heading>*/}
                        {/*</Navbar.Group>*/}
                        <Navbar.Group align={Alignment.LEFT}>

                            <Tabs
                                animate={true}
                                id="navbar"
                                large={false}
                                onChange={this.handleChangeTab}
                                selectedTabId={this.state.page}
                            >
                                {this.props.lesson && hasClasswork(this.props.lesson)? <Tab id={page_classwork} title={<Text text={new Txt().dict(page_classwork)}/>}/>: null }
                                {this.props.lesson && hasHomework(this.props.lesson) ?<Tab id={homework_label} title={<Text text={new Txt().dict(homework_label)}/>}/> : null}
                                {this.props.isReview? null :<Tab id={messages_label} title={<Text text={new Txt().dict(messages_label)}/>}/>}
                            </Tabs>
                            {
                                fileUrl ?
                                    <DownloadButton
                                        className='block'
                                        url={fileUrl}
                                        intent={Intent.SUCCESS}
                                        minimal={true}
                                        title={new Txt().dict(download_additional_content)}/>
                                    : null
                            }
                        </Navbar.Group>
                        {
                            this.props.isReview ?
                            <Navbar.Group align={Alignment.RIGHT}>
                                {this.state.isPrevious ?
                                    <div className='tiles-container-fixed'>
                                            <Tooltip content={<Text text={new Txt().dict(review_see_draft)} />}
                                                 className='instruments-tool-button--4' wrapperTagName='div' targetTagName='div'  targetClassName='fill-width'>
                                            <Button
                                                className='fill-width'
                                                icon='learning'
                                                intent={Intent.NONE}
                                                onClick={this.handleShowPrevious}>
                                                {<Text text={new Txt().dict(review_see_draft)} />}
                                            </Button>
                                        </Tooltip>
                                    </div>:
                                    <div className='tiles-container-fixed'>
                                        {   this.props.course.hasPrevious() ?
                                            <Tooltip content={<Text text={new Txt().dict(review_see_original)}/>}
                                                     className='instruments-tool-button--4' wrapperTagName='div'
                                                     targetTagName='div' targetClassName='fill-width'>
                                                <Button
                                                    className='fill-width'
                                                    icon='learning'
                                                    intent={Intent.PRIMARY}
                                                    onClick={this.handleShowNew}>
                                                    {<Text text={new Txt().dict(review_see_original)}/>}
                                                </Button>
                                            </Tooltip> :
                                            <Tooltip content={<Text text={new Txt().dict(review_no_original)}/>}
                                                     className='instruments-tool-button--4' wrapperTagName='div'
                                                     targetTagName='div' targetClassName='fill-width'>
                                                <Button
                                                    className='fill-width'
                                                    intent={Intent.NONE}
                                                    minimal={true}
                                                    disabled={true}
                                                >
                                                <Text text={new Txt().dict(review_no_original_short)}/>
                                                </Button>
                                            </Tooltip>
                                        }
                                            <Tooltip content={<Text text={new Txt().dict(review_accept)} />}
                                                     className='instruments-tool-button--4' wrapperTagName='div' targetTagName='div'  targetClassName='fill-width'>
                                                <Button
                                                    className='fill-width'
                                                    icon='tick'
                                                    intent={Intent.SUCCESS}
                                                    onClick={this.handleAccept}>
                                                    {<Text text={new Txt().dict(review_accept)} />}
                                                </Button>
                                            </Tooltip>
                                            <Tooltip content={<Text text={new Txt().dict(review_deny)} />}
                                                     className='instruments-tool-button--4' wrapperTagName='div' targetTagName='div'  targetClassName='fill-width'>
                                                <Button
                                                    className='fill-width'
                                                    icon='error'
                                                    intent={Intent.DANGER}
                                                    onClick={this.handleReject}>
                                                    {<Text text={new Txt().dict(review_deny)} />}
                                                </Button>
                                            </Tooltip>
                                    </div>
                                }

                            </Navbar.Group> : null
                            }
                    </Navbar>
                )}>
                <EmbeddedCourseViewer course={this.state.isPrevious ? this.props.course.getPrevious() : this.props.course} position = {this.props.position} page={this.state.page}  containerRef={this.containerRef}/>
            </InstrumentContainer>
        )
    }
}

let course : Course = new Course();
let position : CoursePosition = new CoursePosition();
let lesson : ILesson | undefined;

const mapStateToProps = (state: AppState) => {
    if(state.instruments.type === InstrumentType.StudentsCourses || state.instruments.type === InstrumentType.CourseReview) {
        let prevCourse = course;
        course = checkCourseId(course, state, state.instruments.type);
        if (course.id !== "") {
            let prevPosition = position;
            position = position && position.is(state.settings.coursesPositions[course.id]) ? position : new CoursePosition(state.settings.coursesPositions[course.id], course.contentData.modules);
            if (course !== prevCourse || position !== prevPosition) {
                lesson = course.contentData.modules[position.selectedModule] ?
                    course.contentData.modules[position.selectedModule].lessons[position.selectedLesson] : undefined;
            }
        }
    }
    return {
        fileLinks : state.data.fileLinks,
        token : state.user.token,
        course,
        position,
        lesson
    }
};

const mapStateToPropsEmbedded = (state: AppState) => {
    return {
        fileLinks : state.data.fileLinks,
        token : state.user.token
    }
};

export default connect(mapStateToProps,{useTool,sendRequest,clearData,closeInstrument})(CourseViewerInstrument);
export const CourseViewerInstrumentEmbedded = connect(mapStateToPropsEmbedded,{useTool,sendRequest,clearData,closeInstrument})(CourseViewerInstrument);