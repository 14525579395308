/* This class handles Classroom data from  db*/
import { Lesson} from "../model/courses/Lesson";
import {ButtonAdaptor, IButtonAdaptor} from "../adaptors/ButtonAdaptors";
import {Intent} from "@blueprintjs/core";
import {archived_class, cardsapp_label_2, edit_label} from "../text/Literals";
import {MODE_JOIN, VAR_DELIMITER} from "../service/Literals";
import {ClipBoardKV, ImageAdaptorsKV, KV, ObjectKV, PlainTextKV} from "../model/KV";
import {homework_label, join_classroom_link, message_from_teacher, no_homework} from "../text/Literals";

export interface Classroom {
    save:  string;
    settings:  string;
    id:  number;
    school:  string;
    admin:  string;
    name:  string;
    avatar:  string;
    message:  string;
    homework:  string;
    project:  string;
    role:  string;
    nickname:  string;
    myHomework:  string;
    mySave:  string;
    myProject:  string;
    myMessage : string;
    archived : boolean;
}

export class TeachersClassroom {
    admin: string;
    avatar: string;
    homework: Lesson;
    id: number;
    message: string;
    name: string;
    project: string;
    save: string;
    school: string;
    settings: string;
    nickname:  string;
    archived : boolean;

    constructor(model : Classroom) {
        this.admin = model.admin;
        this.avatar = model.avatar;
        this.homework = new Lesson(model.homework);
        this.id = model.id;
        this.message = model.message;
        this.name = model.name;
        this.project = model.project;
        this.save = model.save;
        this.school = model.school;
        this.settings = model.settings;
        this.nickname = model.nickname;
        this.archived = model.archived;
    }

    getKV = (): KV[]=>{
        return this.archived ?[
            new PlainTextKV(this.name, archived_class,true)
        ] : [
            // {key : ID, value :this.id},
            {key: this.name, value: true,isNegative : false},
            // {key : MESSAGE, value : this.message},
            // {key : HOME_WORK, value: this.homework.getLesson(0)!.task},
            new ClipBoardKV(join_classroom_link,window.location.href+MODE_JOIN+VAR_DELIMITER+this.id)
        ]
    };

    getStudentsKV = ():KV[]=>{
      return this.hasHomeWork() ? [
          {key : message_from_teacher, value : this.message},
          new ObjectKV(homework_label, this.homework.getStudentKV()),
          new ImageAdaptorsKV(cardsapp_label_2,this.homework.tags,Lesson.mapDeckImages )
      ] : [
          {key : message_from_teacher, value : this.message},
          {key : no_homework, value : true}
      ];
    };

    /*
     *  Data for buttons on teachers control
     */

    getButtons= (clickHandle :ClassroomEditFunction[]):IButtonAdaptor[]=>{
        return [
            // new ButtonAdaptor(edit_course,Intent.SUCCESS,()=>{clickHandle[0](this)},'document'),
            new ButtonAdaptor(edit_label,Intent.SUCCESS,()=>{clickHandle[1](this)},'edit')
        ];
    };

    getHomeworkAsString=():string=>{
        return this.homework.toBase64();
    };

    hasHomeWork=():boolean=>{
      return this.homework.isActive;
    }
}

export type ClassroomEditFunction = (classroom : TeachersClassroom)=>void;
