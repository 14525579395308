export interface Coverage {
    covered ?: boolean;
    started ?: boolean;
}

export interface LessonsCoverage {
    [key : string] : Coverage;
}

export interface CoursesCoverage {
    [key: string] : LessonsCoverage;
}

export enum CoverageState {
    NotStarted,
    Started,
    Finished
}

export function isCovered(id : string, coverage : CoursesCoverage|undefined) : CoverageState {
    if(!coverage) return CoverageState.NotStarted;
    for(let course in coverage){
        for(let lesson in coverage[course]){
            if(lesson === id){
                return coverage[course][lesson].covered ? CoverageState.Finished : (coverage[course][lesson].started ? CoverageState.Started : CoverageState.NotStarted)
            }
        }
    }
    return CoverageState.NotStarted;
}