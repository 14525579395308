import React,{FC} from "react";
import {MailResponse} from "../../api/MailRequest";
import {Button, Card, Classes, Dialog, Intent} from "@blueprintjs/core";
import {Txt,Text} from "./Text";
import {
    email_bounced,
    email_sent_count,
    invalid_count,
    label_backward, mail_sent_results,
    mx_not_found,
    no_school_user
} from "../../text/Literals";
import {Elevation} from "@blueprintjs/core/lib/esm/common/elevation";


interface MailSendResultsProps{
    res : MailResponse,
    close : ()=> void;
}

const MailSendResults : FC<MailSendResultsProps> = props => {
    const totalError = props.res.invalid + props.res.bounced + props.res.noMX + props.res.notFound;
    return  <Dialog
        title={<Text text={new Txt().dict(mail_sent_results)}/>}
        transitionDuration={200}
        isOpen={true}
        canEscapeKeyClose={true}
        canOutsideClickClose={true}
        className="login-container"
        onClose={props.close}
    >
        <div className="bp3-dialog-body">
            <Card className='success' elevation={Elevation.THREE}>
                <Text text={new Txt().dict(email_sent_count).txt(props.res.sent + '')}/>
            </Card>
            { totalError ?
                <Card className='error' elevation={Elevation.THREE}>
                    { props.res.invalid ?
                        <Text className='block' text={new Txt().dict(invalid_count).txt(props.res.invalid + '')}/>
                        : null}
                    { props.res.bounced ?
                        <Text className='block' text={new Txt().dict(email_bounced).txt(': '+props.res.bounced )}/>
                        : null}
                    { props.res.noMX ?
                        <Text className='block' text={new Txt().dict(mx_not_found).txt(props.res.noMX + '')}/>
                        : null}
                    { props.res.notFound ?
                        <Text className='block' text={new Txt().dict(no_school_user).txt(props.res.notFound + '')}/>
                        : null}
                </Card> :null
            }
        </div>
        <div className={Classes.DIALOG_FOOTER}>
            < div className={Classes.DIALOG_FOOTER_ACTIONS}>
                <Button
                    intent={Intent.PRIMARY}
                    icon='arrow-left'
                    onClick={props.close}>
                    <Text text={new Txt().dict(label_backward)}/>
                </Button>
            </div>
        </div>
    </Dialog>
}

export default MailSendResults;