import React, {FC} from 'react';
import {Process, ProcessType} from "../actions/process";
import {Intent, ProgressBar} from "@blueprintjs/core";
import {connect} from "react-redux";
import {AppState} from "../store/configureStore";

export interface ProgressBarProps {
    process : Process,
    intent : Intent,
    className ?: string;
}

const WaitSeverProgressBar : FC<ProgressBarProps> = props => {
    if( props.process.type !== ProcessType.WaitingForServerResponse )return null;
    const progress : number| undefined = props.process.upload ? props.process.upload.loaded/props.process.upload.total : undefined;
    return (
            <div className={props.className}>
                {props.process.upload ?<div>
                    {props.process.upload.loaded+'/'+props.process.upload.total}
                </div> : null}
                 <ProgressBar intent={props.intent} className='instruments-info-bar' value={progress}/>
            </div>
    );

};

const mapStateToProps = (state : AppState)=>({
    process : state.process
});

export default connect(mapStateToProps)(WaitSeverProgressBar);
