import React from "react";
import LessonPlayer from "./LessonPlayer";
import LessonNavigator from "./LessonNavigator";
import {connect} from "react-redux";
import {Intent} from "@blueprintjs/core";
import changeCoursePosition from "../../thunks/changeCoursePosition";
import {AbstractCourseViewer} from "./AbstractCourseViewer";
import {CoursePosition} from "../../actions/settings";

class CourseViewer extends AbstractCourseViewer{

    render() {
 //       console.trace("___render course viewer ____");
        return this.props.course.contentData.modules[this.props.position.selectedModule] ? <div className='sidebar-layout'>
            <LessonPlayer
                lesson={this.props.course.contentData.modules[this.props.position.selectedModule].lessons[this.props.position.selectedLesson]}
                onBackward={this.onBackward}
                onForward={this.onForward}
                disableBackward={this.isBackwardDisabled()}
                disableForward={this.isForwardDisabled()}
                courseId={this.props.course.id}
                page={this.props.page}/>
            <LessonNavigator
                modules={this.props.course.contentData.modules}
                onSelect={this.handleSelect}
                intent={Intent.PRIMARY}
                selectedModule={this.props.position.selectedModule}
                selectedLesson={this.props.position.selectedLesson}
                />
        </div> : null;
    }

    setPositions=(position : CoursePosition)=>{
        const uuid :string= this.props.course.contentData.modules[position.selectedModule].lessons[position.selectedLesson].uuid;
        if(position.selectedLesson !== this.props.position.selectedLesson || position.selectedModule !== this.props.position.selectedModule){
            if(this.props.containerRef && this.props.containerRef.current){
                console.log("scroll up from" +this.props.containerRef.current.scrollTop);
                this.props.containerRef.current.scrollTop = 0;
            }
        }
        this.props.changeCoursePosition(this.props.course.id,position.selectedModule,position.selectedLesson, uuid);
    };
}

export const EmbeddedCourseViewer = connect(undefined,{changeCoursePosition})(CourseViewer);