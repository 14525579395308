import {KV, RequestButtonKV} from "../../model/KV";
import {Intent} from "@blueprintjs/core/lib/esm/common/intent";
import {Method, Request} from "../../model/Request";
import {DataType} from "../../actions/data";

export interface ProductCheckResult{
    product : string,
    id : string,
    keys : number
}

export function checkResultsKV(results : ProductCheckResult[],type : DataType, selected : string|undefined) : KV[]{
    return results.map( result=> new RequestButtonKV(result.id,result.keys + '',Intent.PRIMARY,
            new Request(
                undefined,type + result.id,
                Method.GET
            ), type, selected === result.id)
    )
}

// export function checkResultsKV(results : ProductCheckResult[],action :(key: string)=>void) : KV[]{
//     return results.map( result=> new ButtonKV(result.id,result.keys + '',Intent.PRIMARY,action))
// }

