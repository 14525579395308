import {SimpleRequestData} from "../adaptors/RequestData";
import {FormAdaptor, IFormAdaptor} from "../adaptors/FormAdaptor";
import {
    FieldAdaptor,
    FormFieldAdaptor,
    NotEmptyStringFiled, PhoneFieldAdaptor,
    StringFieldAdaptor, ValidEmailFieldAdaptor,
} from "../adaptors/FieldAdaptor";
import {
    email_label,
    extra_info,
    manager_name,
    not_empty_field,
    organization_label,
    phone_label, position_label,
} from "../text/Literals";
import {Method} from "../model/Request";

export interface IManager {
    username : string;
    name : string;
    phone : string;
    organization : string;
    position : string;
    description ?: string;
}

export function ConvertManager(manager : IManager) {
    return new Manager(
        manager.username,
        manager.organization,
        manager.name,
        manager.phone,
        manager.position,
        manager.description
    );
}

export class Manager extends SimpleRequestData implements IManager {
    name : string;
    organization : string;
    phone : string;
    position : string;
    username : string;
    description ?: string;
    isNew : boolean;
    onDataChanged ?:(adaptor: IFormAdaptor) => void;
    usernameAdaptor ?: FormFieldAdaptor<string>;
    nameAdaptor ?: FormFieldAdaptor<string>;
    positionAdaptor ?: FormFieldAdaptor<string>;
    phoneAdaptor ?: FormFieldAdaptor<string>;
    organizationAdaptor ?: FormFieldAdaptor<string>;
    descriptionAdaptor ?: FormFieldAdaptor<string>;
    adaptor : IFormAdaptor;

    constructor(username : string,  organization : string, name : string, phone : string, position : string, description : string | undefined = undefined) {
        super();
        this.username = username;
        this.name = name;
        this.organization = organization;
        this.position = position;
        this.phone = phone;
        this.description = description;
        this.isNew = username.length === 0;
        this.adaptor = this.createFormAdaptor();
    }

    initUsernameAdaptor = () : FormFieldAdaptor<string> => {
        this.usernameAdaptor = new ValidEmailFieldAdaptor(email_label, this.setUsername, not_empty_field, this.username, !this.isNew);
        return this.usernameAdaptor;
    };

    initNameAdaptor = () : FormFieldAdaptor<string> => {
        this.nameAdaptor = new NotEmptyStringFiled(manager_name, this.setName, not_empty_field, this.name);
        return this.nameAdaptor;
    };

    initPhoneAdaptor = () : FormFieldAdaptor<string> => {
        this.phoneAdaptor = new PhoneFieldAdaptor(phone_label, this.setPhone, not_empty_field, this.phone);
        return this.phoneAdaptor;
    };

    initOrganizationAdaptor = () => {
        this.organizationAdaptor = new NotEmptyStringFiled(organization_label, this.setOrganization, not_empty_field, this.organization);
        return this.organizationAdaptor;
    };

    initPositionAdaptor = () : FormFieldAdaptor<string> =>{
        this.positionAdaptor = new NotEmptyStringFiled(position_label, this.setPosition, not_empty_field, this.position);
        return this.positionAdaptor;
    };

    initDescriptionAdapter=()=> {
        this.descriptionAdaptor = new StringFieldAdaptor(extra_info, this.setDescription, this.description);
        return this.descriptionAdaptor;
    };

    setUsername = (value: string) => {
        this.username = value;
        this.onDataChanged!(new FormAdaptor(
            [
                this.nameAdaptor!,
                this.initUsernameAdaptor(),
                this.positionAdaptor!,
                this.phoneAdaptor!,
                this.organizationAdaptor!,
                this.descriptionAdaptor!,
            ],
            this
        ));
    };

    setName = (value : string) => {
        this.name = value;
        this.onDataChanged!(new FormAdaptor(
            [
                this.initNameAdaptor(),
                this.usernameAdaptor!,
                this.positionAdaptor!,
                this.phoneAdaptor!,
                this.organizationAdaptor!,
                this.descriptionAdaptor!,
            ],
            this
        ));
    };

    setPhone = (value : string) => {
        this.phone = value;
        this.onDataChanged!(new FormAdaptor(
            [
                this.nameAdaptor!,
                this.usernameAdaptor!,
                this.positionAdaptor!,
                this.initPhoneAdaptor(),
                this.organizationAdaptor!,
                this.descriptionAdaptor!,
            ],
            this
        ));
    };

    setOrganization = (value: string) => {
        this.organization = value;
        this.onDataChanged!(new FormAdaptor(
            [
                this.nameAdaptor!,
                this.usernameAdaptor!,
                this.positionAdaptor!,
                this.phoneAdaptor!,
                this.initOrganizationAdaptor()!,
                this.descriptionAdaptor!,
            ],
            this
        ));
    };

    setPosition = (value : string) => {
        this.position = value;
        this.onDataChanged!(new FormAdaptor(
            [
                this.nameAdaptor!,
                this.usernameAdaptor!,
                this.initPositionAdaptor(),
                this.phoneAdaptor!,
                this.organizationAdaptor!,
                this.descriptionAdaptor!,
            ],
            this
        ));
    };

    setDescription = (value : string) => {
        this.description = value;
        this.onDataChanged!(new FormAdaptor(
            [
                this.nameAdaptor!,
                this.usernameAdaptor!,
                this.positionAdaptor!,
                this.phoneAdaptor!,
                this.organizationAdaptor!,
                this.initDescriptionAdapter(),
            ],
            this
        ));
    };

    connect(connector : (adaptor : IFormAdaptor) => void) : void {
        this.onDataChanged = connector;
    }

    getFieldAdaptors() : FieldAdaptor[] {
        return[
            this.initNameAdaptor(),
            this.initUsernameAdaptor(),
            this.initPositionAdaptor(),
            this.initPhoneAdaptor(),
            this.initOrganizationAdaptor(),
            this.initDescriptionAdapter()
        ];
    }

    getFormAdaptor() : IFormAdaptor {
        return this.adaptor;
    }
    createFormAdaptor() : IFormAdaptor {
        return new FormAdaptor( this.getFieldAdaptors(),this);
    }
    getMethod() : Method {
        return this.isNew ? Method.POST : Method.PUT;
    }

    message = () : string => {
        let str = JSON.stringify(this,['username','name', 'position','phone','organization','description']);
        return str;
    };
}