import store from "../store/store";
export const KEY_NOT_FOUND = 'key not found in the dictionary';

export function getFromVocabulary(index : string) :string {
    const val = store.getState().data.vocabulary[index];
    if(val === undefined) {
        console.log(index);
    }
    return val === undefined ? KEY_NOT_FOUND : val;
}

