export const VERSION = 'version';

export function checkVersionChanged(newVersion : string) :boolean {
    const prevVersion = localStorage.getItem(VERSION);
    if(prevVersion === newVersion) return false;
    localStorage.setItem(VERSION,newVersion);
    clearCache();
    return true;
}

export function getVersion() {
    return localStorage.getItem(VERSION);
}

export function clearCache() {
    console.log('Clearing cache and hard reloading...');
    if (caches) {
        caches.keys().then(function(names) {
            for (let name of names) caches.delete(name);
        });
    }
    window.location.reload();
}

export function forceUpdate() {
    localStorage.setItem(VERSION,'');
    clearCache();
}