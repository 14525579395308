import {SearchRequest, SimpleRequestData} from "../adaptors/RequestData";
import {
    EmailFieldAdaptor,
    FieldAdaptor,
    FormFieldAdaptor, isEmail,
    NotEmptyStringFiled,
    PhoneFieldAdaptor,
    StringFieldAdaptor
} from "../adaptors/FieldAdaptor";
import {
    email_label,
    extra_info,
    not_empty_field,
    phone_label,
    user_name_label
} from "../text/Literals";
import {FormAdaptor, IFormAdaptor} from "../adaptors/FormAdaptor";
import {Method} from "../model/Request";
import {DataType} from "../actions/data";
import {IExpire} from "./School";
import {ButtonAdaptor, IButtonAdaptor} from "../adaptors/ButtonAdaptors";
import {Intent} from "@blueprintjs/core";

export interface IPerson {
    username : string;
    name : string;
    phone : string;
    description ?: string;
    licenses : string[];
}

export interface IFullPerson extends IPerson {
    expire ?: IExpire;
    licensePurchaseData ?: LicensePurchaseData[];
    bounced : boolean;
}

export class LicensePurchaseData {
    software : string;
    date : Date;
    seller : string;
    canceled : boolean;
    canceler : string;
    canceldate : Date;

    constructor(software : string, date : Date, seller : string, canceled : boolean, canceler : string, canceldate : Date) {
        this.software = software;
        this.date = date;
        this.seller = seller;
        this.canceled = canceled;
        this.canceler = canceler;
        this.canceldate = canceldate;
    }
}

export function ConvertPerson(person : IPerson) {
    return new Person(
        person.username,
        person.name,
        person.phone,
        person.description,
        person.licenses
    );
}

export class Person extends SimpleRequestData implements IPerson {
    username : string;
    name : string;
    phone : string;
    description ?: string;
    licenses : string[];
    isNew : boolean;

    nameAdaptor ?: FormFieldAdaptor<string>;
    phoneAdaptor ?: FormFieldAdaptor<string>;
    emailAdaptor ?: EmailFieldAdaptor;
    descriptionAdaptor ?: FormFieldAdaptor<string>;
    adaptor : IFormAdaptor;

    constructor(username : string, name : string, phone : string,
                description : string | undefined = undefined,
                licenses : string[]) {
        super();
        this.username = username;
        this.name = name;
        this.phone = phone;
        this.description = description;
        this.licenses = licenses;
        this.isNew = username.length === 0;
        this.adaptor = this.createFormAdaptor();
    }

    initNameAdaptor = () : FormFieldAdaptor<string> => {
        this.nameAdaptor = new NotEmptyStringFiled(user_name_label, this.setName, not_empty_field, this.name);
        return this.nameAdaptor;
    };

    initEmailAdaptor = () : EmailFieldAdaptor => {
        this.emailAdaptor = new EmailFieldAdaptor(email_label, this.setEmail, not_empty_field, this.username, !this.isNew);
        return this.emailAdaptor;
    };

    initPhoneAdaptor = () : FormFieldAdaptor<string> => {
        this.phoneAdaptor = new PhoneFieldAdaptor(phone_label, this.setPhone, not_empty_field, this.phone);
        return this.phoneAdaptor;
    };

    initDescriptionAdapter = () => {
        this.descriptionAdaptor = new StringFieldAdaptor(extra_info, this.setDescription, this.description);
        return this.descriptionAdaptor;
    };

    setName = (value : string) => {
        this.name = value;
        this.onDataChanged!(new FormAdaptor(
            [
                this.emailAdaptor!,
                this.initNameAdaptor(),
                this.phoneAdaptor!,
                this.descriptionAdaptor!
            ],
            this
        ));
    };

    setEmail = (value: string) => {
        this.username = value;
        this.onDataChanged!(new FormAdaptor(
            [
                this.initEmailAdaptor(),
                this.nameAdaptor!,
                this.phoneAdaptor!,
                this.descriptionAdaptor!
            ],
            this
        ));
    };

    setPhone = (value : string) => {
        this.phone = value;
        this.onDataChanged!(new FormAdaptor(
            [
                this.emailAdaptor!,
                this.nameAdaptor!,
                this.initPhoneAdaptor(),
                this.descriptionAdaptor!
            ],
            this
        ));
    };

    setDescription = (value : string) => {
        this.description = value;
        this.onDataChanged!(new FormAdaptor(
            [
                this.emailAdaptor!,
                this.nameAdaptor!,
                this.phoneAdaptor!,
                this.initDescriptionAdapter()
            ],
            this
        ));
    };

    getFieldAdaptors() : FieldAdaptor[] {
        return[
            this.initEmailAdaptor(),
            this.initNameAdaptor(),
            this.initPhoneAdaptor(),
            this.initDescriptionAdapter()
        ];
    }

    getFormAdaptor() : IFormAdaptor {
        return this.adaptor;
    }
    createFormAdaptor() : IFormAdaptor {
        return new FormAdaptor( this.getFieldAdaptors(),this);
    }

    getMethod() : Method {
        return this.isNew ? Method.POST : Method.PUT;
    }

    getType=(): DataType=> {
        return this.isNew ? DataType.Persons : DataType.PersonsAll;
    };

    message = () : string => {
        return JSON.stringify(this, ['username', 'name', 'phone', 'description', 'licenses']);
    };
}

export function getPersonButtonsAdaptor(person : IFullPerson,
                                        clickHandle : (person : (IFullPerson | undefined)) => void,
                                        expandHandle:(person : IFullPerson) => void,
                                        isExpanded : boolean,
                                        mailTo: (recipients : string[]) => void ) : IButtonAdaptor[] {

    const invalid = !isEmail(person.username) || person.bounced;
    return [
        new ButtonAdaptor('',Intent.PRIMARY,() => {clickHandle(person)},'edit'),
        new ButtonAdaptor('',
            invalid ? Intent.DANGER : Intent.PRIMARY,
            invalid ? () => mailTo([]) : () => mailTo([person.username]),
            'envelope'),
        new ButtonAdaptor('',isExpanded ? Intent.DANGER : Intent.SUCCESS,() => { expandHandle(person) },isExpanded ? 'collapse-all' : 'expand-all')
    ]
}

export class PersonSearchRequest extends SearchRequest {
    username : string;
    name : string;
    phone : string;
    description : string;

    usernameAdaptor : FormFieldAdaptor<string>;
    nameAdaptor : FormFieldAdaptor<string>;
    phoneAdaptor : FormFieldAdaptor<string>;
    readonly fields : string[] = ['username','name','phone','description'];

    constructor() {
        super();
        this.username = '';
        this.name = '';
        this.phone = '';
        this.description = '';
        this.usernameAdaptor = this.initUsernameAdaptor();
        this.nameAdaptor = this.initNameAdaptor();
        this.phoneAdaptor = this.initPhoneAdaptor();
    }

    getFieldAdaptors() : FieldAdaptor[] {
        return [
            this.usernameAdaptor,
            this.nameAdaptor,
            this.phoneAdaptor
        ];
    }

    initUsernameAdaptor = () => {
        this.usernameAdaptor = new StringFieldAdaptor(email_label, this.setUsername, this.username);
        return this.usernameAdaptor;
    };

    setUsername = (value : string) => {
        this.username = value;
        this.initUsernameAdaptor();
        this.onDataChanged!(this.getFormAdaptor());
    };

    initNameAdaptor = () => {
        this.nameAdaptor = new StringFieldAdaptor(user_name_label, this.setName, this.name);
        return this.nameAdaptor;
    };

    setName = (value :string) => {
        this.name = value;
        this.initNameAdaptor();
        this.onDataChanged!(this.getFormAdaptor());
    };

    initPhoneAdaptor = () => {
        this.phoneAdaptor = new StringFieldAdaptor(phone_label, this.setPhone, this.phone);
        return this.phoneAdaptor;
    };

    setPhone = (value : string) => {
        this.phone = value;
        this.initPhoneAdaptor();
        this.onDataChanged!(this.getFormAdaptor());
    };

    getType() : DataType {
        return DataType.PersonsAll;
    }
}