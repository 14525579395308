import {
    FormAdaptor,
    IFormAdaptor
} from "../adaptors/FormAdaptor";
import {Method} from "../model/Request";
import {DataType} from "../actions/data";
import {
    not_empty_field,
    homework_label,
    archived_class,
    teachers_nickname,
    message_for_class, picture_url, school_label, name_label
} from "../text/Literals";
import {utf8_to_b64} from "../service/encoder";
import {Lesson} from "../model/courses/Lesson";
import {SimpleRequestData} from "../adaptors/RequestData";
import {
    EncodedObjectAdaptor, FieldAdaptor,
    FormFieldAdaptor,
    NotEmptyStringFiled,
    SimpleFieldAdaptor
} from "../adaptors/FieldAdaptor";

export interface IAddClassroomRequest {
     id ?: number;
     school :  string;
     name :  string;
     avatar ?:  string;
     msg ?:  string;
     homework ?:  string;
     nickname :  string;
     archived : boolean;
}

export class ClassroomRequest extends SimpleRequestData implements IAddClassroomRequest{
    id ?: number;
    school: string;
    name: string;
    msg : string;
    avatar : string;
    homework : string;
    nickname: string;
    archived : boolean;
    isNew: boolean;
    adaptor : IFormAdaptor;
    onDataChanged ?: (adaptor :IFormAdaptor) => void;

    schoolAdaptor ?: NotEmptyStringFiled;
    nameAdaptor ?: NotEmptyStringFiled;
    msgAdaptor ?: FormFieldAdaptor<string>;
    avatarAdaptor ?: FormFieldAdaptor<string>;
    homeworkAdaptor ?: FormFieldAdaptor<string>;
    nicknameAdaptor ?: NotEmptyStringFiled;
    archivedAdaptor ?: FormFieldAdaptor<boolean>;

    constructor(school: string, name : string, nickname : string, msg : string = "", avatar : string="",
                homework : string = utf8_to_b64('{}'), id : number|undefined = undefined, archived : boolean = false) {
        super();
        this.school = school;
        this.name = name;
        this.nickname=nickname;
        this.msg = msg;
        this.avatar =avatar === null ? "" : avatar;
        this.homework = homework;
        this.archived = archived;
        this.isNew = id === undefined;
        this.id = id;

        this.adaptor = this.createFormAdaptor();
    }

    setSchool=(value: string)=>{
        this.school = value;
    };

    setName=(value: string)=>{
        this.name = value;
        this.onDataChanged!(this.addAdaptor(new FormAdaptor([
                this.schoolAdaptor!,
                this.initNameAdaptor(),
                this.avatarAdaptor!,
                this.msgAdaptor!,
                this.homeworkAdaptor!,
                this.nicknameAdaptor!
            ],
            this),this.archivedAdaptor!));
    };

    setAvatar=(value: string)=>{
        this.avatar = value;
        this.onDataChanged!(this.addAdaptor(new FormAdaptor([
                this.schoolAdaptor!,
                this.nameAdaptor!,
                this.initAvatarAdaptor(),
                this.msgAdaptor!,
                this.homeworkAdaptor!,
                this.nicknameAdaptor!
            ],
            this),this.archivedAdaptor!));
    };

    setMsg=(value: string)=>{
        this.msg = value;
        this.onDataChanged!(this.addAdaptor(new FormAdaptor([
                this.schoolAdaptor!,
                this.nameAdaptor!,
                this.avatarAdaptor!,
                this.initMsgAdaptor(),
                this.homeworkAdaptor!,
                this.nicknameAdaptor!
            ],
            this),this.archivedAdaptor!));
    };

    setNickname=(value: string)=>{
        this.nickname = value;
        this.onDataChanged!(this.addAdaptor(new FormAdaptor([
                this.schoolAdaptor!,
                this.nameAdaptor!,
                this.avatarAdaptor!,
                this.msgAdaptor!,
                this.homeworkAdaptor!,
                this.initNicknameAdaptor()
            ],
            this),this.archivedAdaptor!));
    };

    setHomework=(value: string)=>{
        this.homework = value;
        this.onDataChanged!(this.addAdaptor(new FormAdaptor([
                this.schoolAdaptor!,
                this.nameAdaptor!,
                this.avatarAdaptor!,
                this.msgAdaptor!,
                this.initHomeworkAdaptor(),
                this.nicknameAdaptor!
            ],
            this),this.archivedAdaptor!));
    };

    setArchived=(value : boolean)=>{
        this.archived = value;
        this.onDataChanged!(this.addAdaptor(new FormAdaptor([
                this.schoolAdaptor!,
                this.nameAdaptor!,
                this.avatarAdaptor!,
                this.msgAdaptor!,
                this.homeworkAdaptor!,
                this.nicknameAdaptor!
            ],
            this),this.initArchivedAdaptor()));
    };

    initSchoolAdaptor=()=>{
      this.schoolAdaptor = new NotEmptyStringFiled(school_label,this.setSchool,not_empty_field,this.school,true);
      return this.schoolAdaptor;
    };

    initNameAdaptor=()=>{
        this.nameAdaptor = new NotEmptyStringFiled(name_label,this.setName,not_empty_field,this.name,false);
        return this.nameAdaptor;
    };

    initAvatarAdaptor=()=>{
        this.avatarAdaptor = new SimpleFieldAdaptor<string>(picture_url,this.setAvatar,this.avatar);
        return this.avatarAdaptor;
    };

    initMsgAdaptor=()=>{
        this.msgAdaptor = new SimpleFieldAdaptor<string>(message_for_class,this.setMsg,this.msg);
        return this.msgAdaptor;
    };

    initNicknameAdaptor=()=>{
        this.nicknameAdaptor = new NotEmptyStringFiled(teachers_nickname,this.setNickname,not_empty_field,this.nickname,false);
        return this.nicknameAdaptor;
    };

    initHomeworkAdaptor=()=>{
        this.homeworkAdaptor = new EncodedObjectAdaptor(homework_label,this.setHomework,this.homework,Lesson.fromEncodedString,'home');
        return this.homeworkAdaptor;
    };

    initArchivedAdaptor=()=>{
        this.archivedAdaptor = new SimpleFieldAdaptor<boolean>(archived_class,this.setArchived,this.archived);
        return this.archivedAdaptor;
    };

    addAdaptor=(formAdaptor : FormAdaptor,fieldAdaptor : FieldAdaptor)=>{
      if(this.isNew) return formAdaptor;
      formAdaptor.add(fieldAdaptor);
      return formAdaptor;
    };

    connect(connector: (adaptor: IFormAdaptor) => void): void {
        this.onDataChanged =connector;
    }

    getFormAdaptor(): IFormAdaptor {
        return this.adaptor;
    }

    getMethod(): Method {
        return this.isNew ? Method.POST : Method.PUT;
    }

    getType(): DataType {
        return DataType.AddClassroom;
    }

    message=(): string=> {
        return JSON.stringify(this,this.isNew ?
            ['school','name','avatar','msg','homework',"nickname",'archived'] :
            ['id','school','name','avatar','msg','homework',"nickname",'archived']
        );
    };

    createFormAdaptor=() :IFormAdaptor => {
        return this.addAdaptor(new FormAdaptor([
            this.initSchoolAdaptor(),
            this.initNameAdaptor(),
            this.initAvatarAdaptor(),
            this.initMsgAdaptor(),
            this.initHomeworkAdaptor(),
            this.initNicknameAdaptor()
        ],this),this.initArchivedAdaptor());
    };
}


