import React, {FC, memo, ReactNode} from 'react';
import {Role, User} from "../model/User";
import {connect} from "react-redux";
import {AppState} from "../store/configureStore";
import {Text} from "@blueprintjs/core";

interface RoleBasedContainerProps {
    user : User;
    title ?: string;
    required ?: Role;
    excluded ?: Role[];
    requiredModes : string[];
    mode ?: string;
    children ?: ReactNode | undefined
}

const RoleBasedContainer : FC<RoleBasedContainerProps> = props => {
    let matches = props.required === undefined
        ? true
        : props.user.authorities.map(role => role as unknown as string).indexOf(Role[props.required]) >= 0;
    if (matches && props.excluded !== undefined) {
        for (let excluded in props.excluded) {
            if ( props.user.authorities.map(role => role as unknown as string).indexOf(Role[props.excluded[excluded]]) >= 0) {
                matches = false;
                break;
            }
        }
    }
    matches = matches && props.requiredModes.some(md => md === props.mode);
    //console.log(props.title + ' -> '  +matches )
    return (matches ? <div> {props.title === undefined ? null : <Text>{props.title}</Text>}
                {props.children} </div> : null);
};

const mapStateToProps = (state : AppState) => ({
    user : state.user,
    mode : state.settings.mode
});

export default connect(
    mapStateToProps
)(memo(RoleBasedContainer));