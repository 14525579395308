export interface IEntity {
    id : string | number | undefined;
    label : string;
    data ?: any;
}

export class Entity implements IEntity{
    id: string | number | undefined;
    label: string;
    data ?: any;

    constructor(model : IEntity|undefined=undefined) {
        this.id = model === undefined ? undefined : model.id;
        this.label = model === undefined ? '' : model.label;
        if(model) this.data = model.data;
    }

    isUndefined=():boolean=>{
        return this.id === undefined;
    };

    idAsString=():string=>{
        if(this.id === undefined) return '';
        if(typeof (this.id) === "number") return this.id+'';
        return this.id;
    };

    idAsNumber=():number=>{
        if(typeof (this.id) === "number") return this.id;
        return -1;
    }
}