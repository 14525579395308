import React, {FC, memo, ReactNode} from 'react';
import {ButtonGroup, Callout, Intent} from "@blueprintjs/core";
import {isPortrait} from "../service/SystemInfo";
import {Txt,Text} from "./tools/Text";

interface ButtonOrganizerProps {
    minimal ?: boolean;
    title ?: Txt;
    large ?: boolean;
    intent ?: Intent;
    buttonSize ?: ButtonSizes;
    children ?: ReactNode;
    buttonGroupStyle ?: string;
}

export enum ButtonSizes {
    Tiny = 'tiles-item-small',
    Small = 'tiles-item-sizable',
    Medium = 'tiles-item',
    Large ='tiles-one-third',
    XLarge = 'tiles-half',
    Flexible = 'tiles-item-flex'
}

const ButtonOrganizer : FC<ButtonOrganizerProps> = props => {
    const buttonSize : ButtonSizes = props.buttonSize === undefined ? ButtonSizes.Tiny : props.buttonSize;
    let buttonGroupStyle = props.buttonGroupStyle !== undefined ? props.buttonGroupStyle : 'tiles-item-flex tiles-container-adjustable';
    return (
        <div className='tiles-container-adjustable'>
            {props.title === undefined ? null : <div className='tiles-item-small'>
                <Callout intent={props.intent} icon={null}>
                   <Text text={props.title}/>
                </Callout>
            </div>}
            <div  className={ buttonGroupStyle } >
                <ButtonGroup minimal={!!props.minimal} className={buttonSize} large={!!props.large} vertical={isPortrait()} fill={true}>
                    {props.children}
                </ButtonGroup>
            </div>
        </div>
    );
};

export default memo(ButtonOrganizer);