export function toStorageFormat(phone : string):string{
    let res = phone.replace(/\D/g,'')
    if (res.charAt(0) === '8'){
        res = res.replace('8','7');
    }
    return '+' + res;
}

export const MIN_PHONE_LENGTH = 8;

export function isValid(phone: string|undefined) : boolean{
    if (!phone || phone.length < MIN_PHONE_LENGTH){
        return false;
    }
    return phone.replace(/\D/g,'').length >= MIN_PHONE_LENGTH;
}

export function toScreenFormat(phone : string|undefined):string{
    if (! phone || phone.length < 2 || phone.replace(/\D/g,'').length < 2){
        return phone || '';
    }

    let digital = toStorageFormat(phone!);

    let res = '';

    for(let i =0 ; i<digital.length-1; i++){
        res += digital.charAt(i);
        if( i === 1 || i===4 || i ===7 || i===9 || i === 11){
            res += ' ';
        }
    }

    return res + digital.charAt(digital.length-1);
}
