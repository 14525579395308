import React, {FC} from 'react';
import {ExpireTagsAdaptor, LimitedTagsAdaptor, TagsAdaptor} from "../../adaptors/FieldAdaptor";
import TagRequestView from "./TagRequestView";
import {Button, Callout, Card, Elevation} from "@blueprintjs/core";
import {active_licenses, available_alternatives, expired_licenses} from "../../text/Literals";
import {AppState} from "../../store/configureStore";
import {connect} from "react-redux";
import {Vocabulary} from "../../model/AppData";
import {Intent} from "@blueprintjs/core/lib/esm/common/intent";
import {IExpire} from "../../api/School";
import ButtonOrganizer, {ButtonSizes} from "../ButtonOrganizer";
import {Text, Txt} from "../tools/Text";
import {KV} from "../../model/KV";

interface TagsEditFieldProps {
    adaptor : TagsAdaptor,
    vocabulary : Vocabulary
}

const TagsEditField : FC<TagsEditFieldProps> = props => {
    let intent : Intent | undefined;
    if (props.adaptor instanceof LimitedTagsAdaptor){
        intent = props.adaptor.intent;
    }
    let expire : IExpire | undefined;
    let expired : string[] | undefined;
    if (props.adaptor instanceof ExpireTagsAdaptor){
        expire = props.adaptor.expire;
        expired = props.adaptor.expired;
    }
    function expiredInfo(){
        return expired && expired.length > 0 && <ButtonOrganizer title={new Txt().dict(expired_licenses)} minimal={true} intent={Intent.DANGER} buttonSize={ButtonSizes.Small} buttonGroupStyle = 'tiles-item-flex tiles-container-start'>
                {expired.map(product => (
                    <Button icon='outdated' key = {product} intent={Intent.DANGER} minimal={true} text={product}/>
                ))}
            </ButtonOrganizer>
    }

    function getInfo(){
        let adaptors : KV[];
        adaptors = props.adaptor.getTagsKV();

        if (adaptors.length < 1 || !expired)
            return <div>
                { licenseTags(adaptors) }
            </div>

        return <div className='tiles-container-start'>
                    <div className='tiles-item-small tiles-container-adjustable'>
                        <Callout intent={Intent.SUCCESS} icon={null}>
                            <Text text={new Txt().dict(active_licenses)}/>
                        </Callout>
                    </div>
                    <div>
                        { licenseTags(adaptors) }
                    </div>
        </div>

    }

    function licenseTags(adaptors : KV[]){
        return <TagRequestView
            expire={expire}
            useVocabulary={props.adaptor.useVocabulary}
            label={props.vocabulary[props.adaptor.name] }
            adaptors={adaptors}/>
    }

    return (
        <div>
            <Card interactive={false} elevation={Elevation.THREE}>
                { expiredInfo() }
                { getInfo() }
            </Card>
            { props.adaptor.disabled ? null :
            <Card interactive={false} elevation={Elevation.THREE}>
                <TagRequestView
                    intent={intent}
                    useVocabulary={props.adaptor.useVocabulary}
                    label={props.vocabulary[available_alternatives] }
                    adaptors={props.adaptor.getOtherTagsKV()}/>
            </Card>}
        </div>);
};
const mapStateToProps = (state: AppState) => ({
    vocabulary : state.data.vocabulary
});
export default connect(mapStateToProps)(TagsEditField)