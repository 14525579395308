import React, {PureComponent, ReactNode} from 'react';
import {connect} from "react-redux";
import {Button, Classes, FormGroup, Intent} from "@blueprintjs/core";
import {DataType} from "../../actions/data";
import {Method, Request} from "../../model/Request";
import {AppState} from "../../store/configureStore";
import {Process} from "../../actions/process";
import RequestButton from "../RequestButton";
import {IFormAdaptor} from "../../adaptors/FormAdaptor";
import RequestInputField from "./RequestInputField";
import {AppData, Vocabulary} from "../../model/AppData";
import {FirebaseRequestData, RequestData} from "../../adaptors/RequestData";
import {cancel_label, interface_label_16, send_request} from "../../text/Literals";
import {sendRequest} from "../../thunks/sendRequest";
import {Text,Txt} from "../tools/Text";

interface RequestFormProps {
    token : string;
    url : string;
    intent : Intent;
    label ?: Txt;
    help ?: string;
    type : DataType;
    process : Process;
    data : RequestData;
    appData : AppData;
    method ?: Method;
    cancel ?:()=>void;
    ok ?:(msg ?: string)=>void;
    buttonText ?: string;
    default ?: boolean;
    contrast ?: boolean;
    persistent ?: boolean;
    sendRequest : any;
    vocabulary : Vocabulary;
    level ?: number;
    masterPost ?:()=>void;
    simple ?: boolean;
    children?: ReactNode | undefined
}

interface PostRequestFormState {
    adapter : IFormAdaptor;
}

class PostRequestForm extends PureComponent<RequestFormProps,PostRequestFormState>{

    constructor(props: Readonly<RequestFormProps>) {
        super(props);
        this.state={
            adapter : props.data.getFormAdaptor()
        };
        props.data.connect(this.handleDataChanged);
    }
    getRequest=()=>{
      //  console.log(this.state.adapter.message());
        if(this.props.data instanceof FirebaseRequestData){
            return this.props.data.getFirebaseMessage();
        }
        return new Request(this.state.adapter.message(),this.props.url, this.props.method === undefined ?
            Method.POST : this.props.method,this.props.token);
    };

    handleDataChanged = (adapter : IFormAdaptor)=>{
        this.setState({adapter });
    };

    cancel = ()=>{
        if(this.props.cancel !== undefined) this.props.cancel();
    };

    save =(msg : string|undefined=undefined)=>{
        this.state.adapter.save();
        if(this.props.persistent)
            return;
        if(this.props.ok === undefined) {
            this.cancel();
            return;
        }
        this.props.ok(msg);
    };

    UNSAFE_componentWillReceiveProps(nextProps : RequestFormProps) {
        if(this.props.data !== nextProps.data){
            this.setState({
                adapter : nextProps.data.getFormAdaptor()
            });
            nextProps.data.connect(this.handleDataChanged);
        }
    }

    post=()=>{
         this.state.adapter.saveNoPost();
        if(this.props.masterPost === undefined){
            this.props.sendRequest(this.getRequest(),this.props.type,undefined,this.save);
            return;
        }

        this.props.masterPost();
    };

    render(){
        this.state.adapter.setData(this.props.appData);
   //     console.log("render request form "+this.props.data.message());
        return (
            <div className={!this.props.contrast ? 'bp3-ui-text' : 'light-back'}>
                <FormGroup
                    intent={this.props.intent}
                    label={this.props.label? <p className='bp3-text-large card-caption'>
                        <Text text={this.props.label}/></p> :null}
                    helperText={this.props.help ? this.props.vocabulary[this.props.help] : undefined}>

                    {this.state.adapter.fields.map((field,index) =>(
                        <div className='form-field' key={index}>
                            <RequestInputField adaptor={field}
                                               intent={this.props.intent}
                                               level={this.props.level === undefined ? undefined : this.props.level + 1}
                                               post={this.post}
                                               />
                        </div>
                    ))}
                </FormGroup>
                < div className={Classes.DIALOG_FOOTER_ACTIONS}>
                    {this.props.cancel ?
                        <Button
                            intent={Intent.DANGER}
                            icon='cross'
                            onClick={this.props.cancel}>
                            {this.props.vocabulary[cancel_label]}
                        </Button> :null}
                    {this.props.method !== Method.SAVE ?
                        <RequestButton
                            disabled={!this.state.adapter.isValid() || !this.state.adapter.wasChanged()}
                            intent={this.props.intent}
                            type={this.props.type}
                            icon='key-enter'
                            request={this.getRequest()}
                            default={this.props.default}
                            onSuccess={this.save}
                            simple={this.props.simple}
                        >
                            {this.props.buttonText ? this.props.vocabulary[this.props.buttonText] : this.props.vocabulary[send_request]}
                        </RequestButton>
                        :
                        <Button
                            disabled={!this.state.adapter.isValid() || !this.state.adapter.wasChanged()}
                            intent={Intent.SUCCESS}
                            icon='saved'
                            onClick={() => this.save()}>
                            {this.props.vocabulary[this.props.buttonText ? this.props.buttonText : interface_label_16]}
                        </Button>
                    }
                </div>
            </div>);
    };
}
const mapStateToProps = (state: AppState) => ({
    token : state.user.token,
    process :state.process,
    appData : state.data,
    vocabulary : state.data.vocabulary
});
export default connect(
    mapStateToProps,
    {sendRequest}
)(PostRequestForm)

export const TokenRequestForm = connect((state : AppState)=>({
    process :state.process,
    appData : state.data,
    vocabulary : state.data.vocabulary
}), {sendRequest})(PostRequestForm)