import {
    FormAdaptor,
    IFormAdaptor
} from "../adaptors/FormAdaptor";
import {Method} from "../model/Request";
import {DataType} from "../actions/data";
import {SimpleRequestData} from "../adaptors/RequestData";
import {NotEmptyStringFiled, NotNegativeIntegerField} from "../adaptors/FieldAdaptor";
import {delay_minutes, not_empty_field, authorization_label_4, username_label} from "../text/Literals";

export interface IDeleteUserRequest {
    username : string;
    password : string;
    delay : number;
}

export class DeleteUserRequest extends SimpleRequestData implements IDeleteUserRequest{
    username: string;
    password: string ='';
    delay: number = 0;
    onDataChanged ?: (adaptor: IFormAdaptor) => void;
    adaptor: IFormAdaptor;
    private usernameAdaptor ?: NotEmptyStringFiled;
    private passwordAdaptor ?: NotEmptyStringFiled;
    private delayAdaptor ?: NotNegativeIntegerField;


    constructor(username :string|undefined = undefined) {
        super();
        this.username = username === undefined ? '' : username;
        this.adaptor = this.createFormAdaptor();
    }

    getFormAdaptor(): IFormAdaptor {
       return  this.adaptor;
    }

    getMethod(): Method {
        return Method.DELETE;
    }

    getType(): DataType {
        return DataType.DeleteUser;
    }

    message=(): string=> {
        return JSON.stringify(this,['username','password','delay']);
    };

    setUsername =(value : string): void =>{
        this.username=value;
        this.onDataChanged!(new FormAdaptor(
            [
                this.initUsernameAdaptor(),
                this.passwordAdaptor!,
                this.delayAdaptor!],
            this
        ));
    };

    checkUsername=(value:string| undefined)=>{
        if(value === undefined || this.username === value) return;
        this.setUsername(value);
    };

    setPassword =(value : string): void =>{
        this.password=value;
        this.onDataChanged!(new FormAdaptor(
            [
                this.usernameAdaptor!,
                this.initPasswordAdaptor(),
                this.delayAdaptor!],
            this
        ));
    };

    setDelay =(value : number): void =>{
        this.delay=value;
        this.onDataChanged!(new FormAdaptor(
            [
                this.usernameAdaptor!,
                this.passwordAdaptor!,
                this.initDelayAdaptor()],
            this
        ));
    };

    initUsernameAdaptor=():NotEmptyStringFiled=>{
        this.usernameAdaptor = new NotEmptyStringFiled(username_label,this.setUsername,not_empty_field,this.username);
        return this.usernameAdaptor;
    };

    initPasswordAdaptor=():NotEmptyStringFiled=>{
        this.passwordAdaptor = new NotEmptyStringFiled(authorization_label_4,this.setPassword,not_empty_field,this.password);
        return this.passwordAdaptor;
    };

    initDelayAdaptor=():NotNegativeIntegerField=>{
        this.delayAdaptor = new NotNegativeIntegerField(delay_minutes,this.setDelay,this.delay);
        return this.delayAdaptor;
    };

    createFormAdaptor() :IFormAdaptor {
        return new FormAdaptor([
            this.initUsernameAdaptor(),
            this.initPasswordAdaptor(),
            this.initDelayAdaptor()
        ],this);
    }
}
