import React, {FC,memo} from "react";
import {IVitrine, Vitrine} from "../../api/Vitrine";
import {AppState} from "../../store/configureStore";
import {connect} from "react-redux";
import {Card} from "@blueprintjs/core";
import PostRequestForm from "../forms/PostRequestForm";
import {Intent} from "@blueprintjs/core/lib/esm/common/intent";
import {DataType} from "../../actions/data";
import {Txt} from "../tools/Text";
import {edit_label, vitrine_info} from "../../text/Literals";
import {closeInstrument} from "../../actions/instruments";

interface DefaultVitrineEditorProps {
    vitrine ?: IVitrine;
    closeInstrument : any;
}

let vitrine : Vitrine;

const DefaultVitrineEditor : FC<DefaultVitrineEditorProps> = props => {
    if(!props.vitrine)
        return null;

    vitrine = new Vitrine(props.vitrine.pictureUrl,props.vitrine.storeUrl,props.vitrine.description);

    return <Card>
        <PostRequestForm
            default={true}
            intent={Intent.WARNING}
            url= {DataType.Vitrines}
            label={new Txt().dict(vitrine_info)}
            type={DataType.Vitrines}
            // help={this.state.dlc.isNew ? this.props.vocabulary[dlc_id_required]: ''}
            data={vitrine}
            method={vitrine.getMethod()}
            cancel={props.closeInstrument}
            buttonText={edit_label}
        >

        </PostRequestForm>
    </Card>;
}

const mapStateToProps = (state : AppState) =>({vitrine : state.data.defaultVitrine})

export default connect(mapStateToProps,{closeInstrument})(memo(DefaultVitrineEditor));