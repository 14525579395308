import React,{FC,memo} from "react";
import {ILesson} from "../../model/courses/Lesson";
import {Classes, Icon, IconName, Intent} from "@blueprintjs/core";
import {MaybeElement} from "@blueprintjs/core/src/common/props";
import {CoursesCoverage, isCovered,CoverageState} from "../../model/common/LessonCoverage";
import {AppState} from "../../store/configureStore";
import {connect} from "react-redux";

interface LessonIconProps {
    lesson : ILesson,
    intent : Intent,
    coverage ?: CoursesCoverage;
}

const LessonIcon :FC<LessonIconProps> = props => {
    const lessonIcon : IconName | MaybeElement = 'document';
    const lessonVideo : IconName | MaybeElement = 'video';
    const notStarted : IconName | MaybeElement = 'circle';
    const started : IconName | MaybeElement = 'bookmark';
    const covered : IconName | MaybeElement = 'tick-circle';
    const coverageState = isCovered(props.lesson.uuid,props.coverage);
    let icon: IconName | MaybeElement;
    let intent : Intent;
    switch (coverageState) {
        default:
        case CoverageState.NotStarted:
            intent = Intent.NONE;
            icon = notStarted;
            break;
        case CoverageState.Started:
            intent = Intent.WARNING;
            icon  = started;
            break;
        case CoverageState.Finished:
            intent = Intent.SUCCESS;
            icon =covered;
            break;
    }
    const className : string = Classes.TREE_NODE_ICON +' tiles-fixed-half';
    return  <div className='tiles-container-fixed margin-end'>
                <Icon intent={intent} icon={icon} className={className}/>
                <Icon intent={props.intent} icon={props.lesson.video ? lessonVideo :lessonIcon} className={className}/>
            </div>
};

const mapStateToProps = (state : AppState)=>{
  return {
      coverage : state.data.coursesCoverage
  }
};

export default connect(mapStateToProps)(memo(LessonIcon));