import {FirebaseRequestData} from "../../adaptors/RequestData";
import {FormAdaptor, IFormAdaptor} from "../../adaptors/FormAdaptor";
import {HomeworkKey, HomeworkType, ILesson} from "./Lesson";
import {FileFieldAdaptor, VideoFieldAdaptor} from "../../adaptors/FieldAdaptor";
import {homework_file_label, homework_video_label, no_homework_file, no_homework_video} from "../../text/Literals";
import {DataType} from "../../actions/data";
import {FirebaseMessage, FirebaseMessageContent, FirebaseMessageType} from "../common/FirebaseMessage";
import {Method} from "../Request";


export class HomeworkRequest extends FirebaseRequestData{
    task : string;
    type : HomeworkKey;
    video ?: string;
    file ? : string;
    uuid : string;
    course : string;

    constructor(lesson : ILesson, course : string, fromFirebase : string|undefined) {
        super();
        this.task = lesson.task;
        this.type = lesson.type;
        this.uuid = lesson.uuid;
        this.course = course;
        this.video = fromFirebase;
        this.file = fromFirebase;

        this.adaptor = this.createFormAdaptor();
    }

    setFile = (value : string)=>{
        this.file = value;
        this.onDataChanged!(this.createFormAdaptor());
    };

    setVideo = (value : string)=>{
        this.video = value;
        this.onDataChanged!(this.createFormAdaptor());
    };

    createFormAdaptor(): IFormAdaptor {
        const fileAdaptor = this.getFileAdaptor();
        return fileAdaptor ? new FormAdaptor([
            fileAdaptor
        ],this) : new FormAdaptor([],this);
    }

    getFileAdaptor=() : FileFieldAdaptor | undefined=>{
        if(this.type !== HomeworkType.homework_file && this.type !== HomeworkType.homework_video) return undefined;
        return this.type === HomeworkType.homework_file ?  new FileFieldAdaptor(homework_file_label,this.setFile,this.file,no_homework_file, DataType.GetStudentUploadFilesLink,this.course) :
            new VideoFieldAdaptor(homework_video_label,this.setVideo,this.video,no_homework_video, DataType.GetStudentUploadVideoLink,this.course);
    };

    getFirebaseMessage=(): FirebaseMessage => {
        let content : string ='';
        let  type : FirebaseMessageType;
        switch (this.type) {
            case HomeworkType.homework_video:
                type = FirebaseMessageType.Video;
                content = this.video!;
                break;
            case HomeworkType.homework_file:
                type = FirebaseMessageType.File;
                content = this.file!;
                break;
            case HomeworkType.homework_tests:
                type = FirebaseMessageType.Tests;
                break;
            default:
                type = FirebaseMessageType.Text
        }

        const messageContent : FirebaseMessageContent= {
            lesson : this.uuid,
            content ,
            type : type,
        };
        return new FirebaseMessage(this.course,FirebaseMessageType.MyHomework,messageContent, Method.PUT);
    };
}