import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
// import * as serviceWorker from './serviceWorker';
import {Provider} from "react-redux";
import store from "./store/store";
import { BrowserRouter, Route, Switch} from 'react-router-dom';
import ChangePasswordWindow from "./components/tools/ChangePasswordWindow";
import EmailVerificationWindow from "./components/routes/EmailVerificationWindow";
import Level1Router from "./components/routes/Level1Router";

const Root = () => (
    <Provider store={store}>
        <BrowserRouter>
            <Switch>
                <Route path="/verify/:token" component={EmailVerificationWindow} />
                <Route path="/change-password/:token/:language?" component={ChangePasswordWindow} />
                <Route path='*' >
                    <Level1Router />
                </Route>
            </Switch>
        </BrowserRouter>
    </Provider>
);

ReactDOM.render(<Root />, document.getElementById('root'));
// serviceWorker.register();

