import {Intent} from "@blueprintjs/core";

// eslint-disable-next-line import/no-anonymous-default-export
export default function (intent: Intent) {
    switch (intent) {
        case "danger": return 'color-danger';
        case "primary": return 'color-primary';
        case "success": return 'color-success';
        case "warning": return 'color-warning';
        default: return '';
    }
}

export function getTextColor (intent: Intent) {
    switch (intent) {
        case "danger": return 'color-text-danger';
        case "primary": return 'color-text-primary';
        case "success": return 'color-text-success';
        case "warning": return 'color-text-warning';
        default: return '';
    }
}