import {SimpleRequestData} from "../../adaptors/RequestData";
import {DataType} from "../../actions/data";
import {FormAdaptor} from "../../adaptors/FormAdaptor";
import {Method} from "../Request";
import {NotEmptyStringFiled, StringFieldAdaptor} from "../../adaptors/FieldAdaptor";
import {not_empty_field, review_message, review_reject_message} from "../../text/Literals";

interface ICourseReviewRequest {
    id : string;
    name : string;
    msg : string;
}

export class CourseReviewRequest extends SimpleRequestData implements ICourseReviewRequest{
    id : string;
    name : string;
    msg : string;

    constructor(id: string, name : string) {
        super();
        this.id = id;
        this.msg = '';
        this.name = name;
        this.fields= ['id','name','msg'];

        this.adaptor = this.createFormAdaptor();
    }
    getType(): DataType {
        return DataType.SendToReview;
    };

    createFormAdaptor=()=>{
        this.adaptor = new FormAdaptor([
            this.initMsgAdaptor()
        ],this);
        return  this.adaptor;
    };

    getMethod(): Method {
        return  Method.PUT;
    }

    initMsgAdaptor=() : StringFieldAdaptor =>{
        return new StringFieldAdaptor(review_message,this.setMsg,this.msg)
    };

    setMsg=(val : string)=> {
        this.msg = val;
        this.onDataChanged!(this.createFormAdaptor())
    }
}

export class CourseReviewRejectRequest extends CourseReviewRequest{

    initMsgAdaptor=() : StringFieldAdaptor =>{
        return new NotEmptyStringFiled(review_reject_message,this.setMsg,not_empty_field,this.msg)
    };

    getType(): DataType {
        return DataType.GetReviews;
    };

    getMethod(): Method {
        return  Method.DELETE;
    }

}