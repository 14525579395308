import React, {PureComponent, RefObject} from "react";
import {AppState} from "../../store/configureStore";
import {connect} from "react-redux";
import {User} from "../../model/User";
import {Button, Callout, Card, Elevation, Intent} from "@blueprintjs/core";
import {Classroom, ClassroomEditFunction, TeachersClassroom} from "../../api/Classroom";
import KeyValueViewer from "../viewers/KeyValueViewer";
import {ClassroomRequest} from "../../api/ClassroomRequest";
import PostRequestForm from "../forms/PostRequestForm";
import {DataType} from "../../actions/data";
import FormButton from "../forms/FormButton";
import {Entity} from "../../model/Entity";
import {
    classroom_creation,
    classroom_edit,
    classroom_edit_help,
    create_classroom,
    edit_classroom, no_classroom_here, press_create_classroom
} from "../../text/Literals";
import {Vocabulary} from "../../model/AppData";
import {Txt} from "../tools/Text";

/*
    Teachers control centers. Classroom and course editing
 */
interface TeacherCabinetProps {
    user : User;
    entity : Entity;
    vocabulary : Vocabulary
}

interface TeacherCabinetState {
    addRequest ?: ClassroomRequest,
    nickname : string
}

class TeacherCabinetInstrument extends PureComponent<TeacherCabinetProps,TeacherCabinetState>{
    myRef : RefObject<HTMLDivElement>;

    constructor(props: Readonly<TeacherCabinetProps>) {
        super(props);
        const nickname = props.user.classrooms === undefined || props.user.classrooms.length === 0 ? "" : props.user.classrooms[0].nickname;
        this.state={addRequest : undefined,nickname};
        this.myRef = React.createRef()
    }

    handleAddClassroom=()=>{
      const addRequest : ClassroomRequest = new ClassroomRequest(this.props.entity.idAsString(),"",this.state.nickname) ;
      this.setState({...this.state,
          addRequest})
    };

    handleEditClassroom=(classRoom:TeachersClassroom)=>{
        const addRequest : ClassroomRequest = new ClassroomRequest(this.props.entity.idAsString(),
            classRoom.name,classRoom.nickname,classRoom.message,classRoom.avatar,
            classRoom.getHomeworkAsString(),classRoom.id, classRoom.archived) ;
        this.setState({...this.state,
            addRequest});
    };

    handleEditCourse=(classRoom:TeachersClassroom)=> {

    };

    handleCloseAddRequest=()=>{
        this.setState({...this.state, addRequest:undefined})
    };

    handleScroll = () => {
        if(this.state.addRequest === undefined || this.state.addRequest.isNew) return;
            setTimeout(() => {
                this.myRef.current!.scrollIntoView({ behavior: 'smooth' })
            }, 300)
    };

    componentDidMount = () => this.handleScroll();

    componentDidUpdate = () => this.handleScroll();

    render(){

        const user : User = this.props.user;
        const classrooms : Classroom [] = user.getSchoolClassrooms(this.props.entity.idAsString());
        const editors : ClassroomEditFunction[] = [];
        editors.push(this.handleEditCourse, this.handleEditClassroom);
        return <div ref={this.myRef}>

            {  this.state.addRequest === undefined ?
                <Button intent={Intent.SUCCESS} icon={"add"} onClick={() => {
                    this.handleAddClassroom()
                }}>{this.props.vocabulary[create_classroom]}</Button> : null
            }
            { this.state.addRequest === undefined ? null :
                <PostRequestForm
                    default={true}
                    intent={Intent.PRIMARY}
                    url= {DataType.AddClassroom}
                    label={new Txt().dict(this.state.addRequest.isNew ? classroom_creation: classroom_edit)}
                    type={DataType.AddClassroom}
                    help={classroom_edit_help}
                    data={this.state.addRequest}
                    method={this.state.addRequest.getMethod()}
                    cancel={this.handleCloseAddRequest}
                    buttonText={this.state.addRequest.isNew ? create_classroom : edit_classroom}
                /> }

            {classrooms === undefined || classrooms.length === 0 ?
                this.state.addRequest !== undefined ? null :  <Callout
                    className='instruments-progressbar--space'
                    intent={Intent.WARNING}
                    title={this.props.vocabulary[no_classroom_here]}
                    icon='warning-sign'
                >
                    {this.props.vocabulary[press_create_classroom]}.
                </Callout>
                : <div className='tiles-container'>
                {
                    classrooms.map(classroom =>{
                    const teachersClassroom : TeachersClassroom = new TeachersClassroom(classroom);
                    return <Card interactive={true} elevation={Elevation.TWO}
                                 className={teachersClassroom.archived? 'tiles-one-third':'tiles-one-third'} key={classroom.id}>
                        <Button icon='people'  intent={teachersClassroom.archived? Intent.DANGER :Intent.SUCCESS } minimal={true} className='card--title-icon'/>
                        <div className='tiles-container'>
                        {teachersClassroom.getKV().map(kv=>
                            <div className='tiles-item' key={kv.key}>
                                <KeyValueViewer
                                    token={user.token}
                                    inline={true}
                                    kv={kv}/></div>)
                        }
                            <div className='form-item-row form--button-container'>
                                {this.state.addRequest === undefined ?teachersClassroom.getButtons(editors).map((adaptor,index) =>
                                    (<FormButton
                                        className='form-item-row'
                                        adaptor={adaptor}
                                        token={this.props.user.token}
                                        key={index}/>) ) : null}
                            </div>
                        </div>
                    </Card>;
                    })
                }</div>
            }
        </div>
    }
}

const mapStateToProps = (state: AppState) => ({
    user : state.user,
    entity : state.instruments.entity,
    vocabulary : state.data.vocabulary
});

export default connect(
    mapStateToProps
)(TeacherCabinetInstrument);