import React, {PureComponent} from 'react';
import GetRequestForm from '../forms/GetRequestForm';
import {Intent} from "@blueprintjs/core/lib/esm/common/intent";
import {DataType} from "../../actions/data";
import PlayerViewer from "../viewers/PlayerViewer";
import PostRequestForm from "../forms/PostRequestForm";
import {DeleteUserRequest} from "../../api/DeleteUserRequest";
import {Button} from "@blueprintjs/core";
import {AppState} from "../../store/configureStore";
import {connect} from "react-redux";
import {Player} from "../../api/Player";
import {
    common_caption_26,
    delete_user, delete_user_form,
    input_username,
    username_input_required,
    username_password_required
} from "../../text/Literals";
import {Vocabulary} from "../../model/AppData";
import {Txt} from "../tools/Text";

interface PlayerViewerState {
    deleteRequest : DeleteUserRequest,
    showDeleteForm : boolean;
}
interface PlayerViewerInstrumentProps {
    player ?: Player;
    vocabulary : Vocabulary
}
 class PlayerViewerInstrument extends PureComponent<PlayerViewerInstrumentProps,PlayerViewerState> {

    constructor(props: Readonly<PlayerViewerInstrumentProps>) {
        super(props);
        this.state={
            deleteRequest:new DeleteUserRequest(),
            showDeleteForm : false,}
    }

    handleShowDelete=(showDeleteForm : boolean,username : string|undefined = undefined)=>{
        if(username === undefined) {
            this.setState({...this.state,showDeleteForm});
            return;
        }
        const deleteRequest: DeleteUserRequest = new DeleteUserRequest(username);
        this.setState({showDeleteForm,deleteRequest});
    };

    render() {
        return (
            <div>
                {  this.state.showDeleteForm ? null :
                    <div className={'form-item-element form--button-container'}>
                        <Button
                            intent={Intent.DANGER}
                            icon={"delete"}
                            className='form-item-row'
                            onClick={() => {
                            this.handleShowDelete(true,this.props.player === undefined ? undefined : this.props.player.username);
                        }}>{this.props.vocabulary[delete_user_form]}</Button>
                    </div>
                }
                { this.state.deleteRequest === undefined || ! this.state.showDeleteForm ? null :
                <PostRequestForm
                    intent={Intent.WARNING}
                    default={false}
                    url= {DataType.DeleteUser}
                    label={new Txt().dict(delete_user)}
                    type={DataType.DeleteUser}
                    help={username_password_required}
                    data={this.state.deleteRequest}
                    method={this.state.deleteRequest.getMethod()}
                    cancel={()=>this.handleShowDelete(false)}
                    buttonText={common_caption_26}
                /> }
                <GetRequestForm
                    intent={Intent.SUCCESS}
                    url={DataType.GetPlayer}
                    label={input_username}
                    type={DataType.GetPlayer}
                    help={username_input_required}
                />
                <PlayerViewer/>
            </div>
        );
    }
}
const mapStateToProps = (state: AppState) => ({
    player : state.data.player,
    vocabulary : state.data.vocabulary
});
export default connect(
    mapStateToProps
)(PlayerViewerInstrument);