import React,{FC} from "react";
import {Button, Intent} from "@blueprintjs/core";
import {Txt,Text} from "../tools/Text";


interface DownloadButtonProps {
    url : string;
    title ?: Txt;
    intent : Intent;
    minimal ?: boolean;
    className ?: string;
}

 const DownloadButton : FC<DownloadButtonProps> = props => {
    const className = props.className ? props.className : 'form-item-row';
    return (
        <a href={props.url} download target='_blank' rel="noopener noreferrer" className={className}>
            <Button icon='download' intent={props.intent} className='fill-width' minimal={!!props.minimal}>
                <Text text={props.title}/>
            </Button>
        </a>
    )
};

export  default DownloadButton;