import {decodeObject, encodeObject} from "../service/encoder";
import {EmbeddedObject} from "./EmbededObject";

export interface IEncodedObject {
    toBase64() :string;
}

export abstract class EncodedObject extends EmbeddedObject implements  IEncodedObject{
    plain : boolean;

    protected constructor(encoded : string|undefined = undefined,fields : string [] = [],
                          onSave:(val : any)=>void = ()=>{}, plain : boolean = false, model : any = undefined ) {
        super(model,fields,onSave);
        this.plain = plain;
        if(encoded === undefined || encoded===null || encoded.length ===0){
            return;
        }
        this.model = this.plain ? model : decodeObject(encoded);
        // console.log(encoded);
        // console.log("***decoding****");
        // console.log(this.model);
    }

    toBase64(): string{
        return this.plain ?  JSON.stringify(this,this.fields) : encodeObject(this,this.fields);
    };

    message(): string {
        return this.toBase64();
    }

    /*
      Object is converted and saved
     */
    save=()=>{
        this.saveNoPost();
        if(this.post) this.post();
    };

    saveNoPost =()=>{
        if(this.plain){
            this.savePlain();
        }else {
            this.saveAsString();
        }
    };

    saveAsString() {
        this.onSave(this.toBase64());
    }
}