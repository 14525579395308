import React, {FC} from "react";
import {IContentAdaptor} from "../../adaptors/ImageAdaptor";
import {Vocabulary} from "../../model/AppData";
import {AppState} from "../../store/configureStore";
import {connect} from "react-redux";
import {Callout, Tooltip} from "@blueprintjs/core";
import {AudioPlayer} from "../players/AudioPlayer";

interface SoundViewerProps {
    className ?: string
    adaptor : IContentAdaptor;
    vocabulary : Vocabulary;
}

const SoundViewer : FC<SoundViewerProps> = props => {
    return (
        <div className={props.className}>
            <Tooltip
                content={props.adaptor.hint ? (props.adaptor.rawHint ? props.adaptor.hint : props.vocabulary[props.adaptor.hint]  ): undefined}
                intent={props.adaptor.intent}
                wrapperTagName='div'
                targetTagName='div'>
                    <Callout
                        intent={props.adaptor.intent}
                        className={'base-margin'}
                        onClick={props.adaptor.onClick}
                        icon= {null}
                    >
                        <AudioPlayer url={props.adaptor.link}/>
                    </Callout>
            </Tooltip>
        </div>);
}

const mapStateToProps = (state: AppState) => ({
    vocabulary : state.data.vocabulary
});
export default connect (mapStateToProps) (SoundViewer);