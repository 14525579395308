import React, {FC, memo, ReactNode} from 'react';
import {connect} from "react-redux";
import {Button, IconName, Intent, Tooltip} from "@blueprintjs/core";
import {InstrumentType} from "../actions/instruments";
import {MaybeElement} from "@blueprintjs/core/src/common/props";
import useInstrument from '../thunks/useInstrument';
import {Entity} from "../model/Entity";
import {useTool} from "../actions/tools";
import {Txt,Text} from "./tools/Text";


interface InstrumentButtonProps {
    intent : Intent,
    type : InstrumentType,
    icon ?: IconName | MaybeElement;
    description ?: Txt;
    useInstrument : any;
    entity ?: Entity,
    minimal ?: boolean,
    useTool : any;
    tool ?: InstrumentType;
    children ?: ReactNode;
}

const InstrumentButton : FC <InstrumentButtonProps> = props => {
    const button =  (<Button
                        className='instrument-button'
                        minimal={props.minimal}
                        icon={props.icon}
                        intent={props.intent}
                        onClick={()=>{
                                        props.useInstrument(props.type,undefined,props.entity);
                                        if(props.tool) props.useTool(props.type,props.tool)
                                        }}>
                            {props.children}
                    </Button>);
    return props.description === undefined ? button :(
        <Tooltip content={<Text text={props.description}/>} className='inline'>
            {button}
        </Tooltip>
    );
};

export default connect(
    undefined,
    {useInstrument, useTool}
)(memo(InstrumentButton));