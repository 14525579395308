import React, {Component} from 'react';
import {setPassword, setUsername} from "../actions/user";
import {User} from "../model/User";
import {Exception, ExceptionType, noException, onException} from "../actions/error";
import {login} from "../thunks/login";
import {
    Button,
    Callout,
    Classes,
    Dialog, FormGroup,
    InputGroup,
    Intent,
    ProgressBar,
    Tooltip
} from "@blueprintjs/core";
import {Process, ProcessType} from "../actions/process";
import {connect} from "react-redux";
import {AppState} from "../store/configureStore";
import ServerExceptionInfo from './ServerExceptionInfo';
import {getBackGroundStyle} from "../actions/settings";
import {
    bad_input,
    change_to_login,
    change_to_register,
    hide_label,
    authorization_label_54,
    input_username,
    authorization_label_11,
    password_doesnt_match,
    authorization_label_4,
    registration_label,
    authorization_label_55,
    show_label,
    username_label,
    forgot_password,
    common_label_156,
    send_email_to,
    link_was_send
} from "../text/Literals";
import {Vocabulary} from "../model/AppData";
import changeLanguage from "../thunks/changeLanguage";
import LanguagePicker from "./tools/LanguagePicker";
import {ImageSize} from "../adaptors/ImageAdaptor";
import PostRequestForm from "./forms/PostRequestForm";
import {Txt} from "./tools/Text";
import {CreateOperationTokenRequest} from "../api/CreateOperationTokenRequest";
import {AppToaster} from "../service/toaster";

interface LoginProps {
    user : User;
    error : Exception;
    process : Process;
    login : any;
    mode ?: string;
    joinClassroom ?: number;
    setUsername: typeof setUsername;
    setPassword: typeof setPassword;
    noException : typeof noException;
    onException : typeof  onException;
    vocabulary : Vocabulary;
    language : string;
    changeLanguage : any;
    registrationFirst : boolean;
}
interface LoginDialogState {
    showPassword: boolean,
    register : boolean,
    restorePassword: boolean,
    repeatPassword : string,
    errorMessage : string | undefined;
}

class LoginDialog extends Component <LoginProps,LoginDialogState>{

    constructor(props: Readonly<LoginProps>) {
        super(props);
        this.state={
            restorePassword : false,
            showPassword : false,
            register : false,
            repeatPassword : '',
            errorMessage : undefined
        }
    }

    private handleLockClick = () => this.setState({ ...this.state,showPassword: !this.state.showPassword });

    componentDidMount(){
        document.body.addEventListener('keydown', this.handleKeyDown);
    };

    componentWillUnmount() {
        document.body.removeEventListener('keydown', this.handleKeyDown);
    };

    handleKeyDown=(event :KeyboardEvent) =>{
        if (event.code === 'Enter' && !this.props.user.areCredentialsEmpty() &&
            this.props.process.type !== ProcessType.WaitingForServerResponse ) {
            this.handleLogin();
        }
    };

    handleLogin = ()=>{
        this.props.login(this.props.user, this.props.mode, this.props.joinClassroom,this.getRegister());
    };

    getLoginButtonCaption =() : string=>{
      return this.props.vocabulary[this.getRegister() ? registration_label : authorization_label_11];
    };

    handleChangeMode=()=>{
        this.setState({ ...this.state,register: !this.state.register })
    };

    showRestorePassword=()=>{
        this.setState({ ...this.state,restorePassword : true })
    };

     hideRestorePassword=()=>{
        this.setState({ ...this.state,restorePassword : false })
    };

    handlePasswordChange = (val: string)=>{
        this.props.setPassword(val);
        if(this.isPasswordNotMatched()){
            this.setState({...this.state,errorMessage : this.props.vocabulary[password_doesnt_match]});
            this.props.onException(ExceptionType.PasswordsNotTheSame);
            return;
        }
        this.props.noException();
        this.setState({...this.state,errorMessage : undefined});
    };

    isPasswordNotMatched=()=> {
        return this.getRegister() ?
            this.props.user.isPasswordNotMatched(this.state.repeatPassword):
            this.props.user.areCredentialsEmpty();
    };

    handlePasswordRepeatChange =(val : string)=>{
        if(this.props.user.isPasswordNotMatched(val)){
            this.setState({...this.state,
                errorMessage : this.props.vocabulary[password_doesnt_match],
                repeatPassword : val});
            this.props.onException(ExceptionType.PasswordsNotTheSame);
            return;
        }
        this.setState({...this.state,errorMessage : undefined,
            repeatPassword : val});
        this.props.noException();
    };

    handleRestoreMailWasSend=()=>{
        AppToaster.show({
            intent: "primary",
            message: this.props.vocabulary[link_was_send],
            action : {
                onClick :()=>{
                    this.hideRestorePassword()
                }
            }
        });
        this.hideRestorePassword();
    };

    render(){

        const lockButton = (
            <Tooltip content={`${this.props.vocabulary[this.state.showPassword ? hide_label : show_label]} ${this.props.vocabulary[authorization_label_4]}`} >
                <Button
                    icon={this.state.showPassword  ? "unlock" : "lock"}
                    intent={Intent.PRIMARY}
                    minimal={true}
                    onClick={this.handleLockClick}
                />
            </Tooltip>
        );
        const usernameIcon =(
            <Tooltip content={<p>{this.props.vocabulary[username_label]}</p>}>
                <Button
                    icon='user'
                    intent={Intent.PRIMARY}
                    minimal={true}
                    onClick={()=>{}}/>
            </Tooltip>);
        const restorePassword : CreateOperationTokenRequest = new CreateOperationTokenRequest(this.props.user.username,this.props.language);
        const error =  !this.props.error || this.props.error.type  === ExceptionType.NoException ? null :
            <ServerExceptionInfo className='login--text-span' hideNoError={true}/>;
        return (
            <Dialog
                isOpen={!this.props.user.isLoggedIn}
                className={getBackGroundStyle(this.props.mode)+ " login-container"}
                canEscapeKeyClose={false}
                canOutsideClickClose={false}
                backdropClassName='login-background'
               >
                { this.state.restorePassword ?
                    <div className="bp3-dialog-body">
                        {error}
                        <PostRequestForm
                            default={true}
                            intent={Intent.PRIMARY}
                            url= {restorePassword.getType()}
                            label={new Txt().dict(send_email_to)}
                            type={restorePassword.getType()}
                            data={restorePassword}
                            method={restorePassword.getMethod()}
                            buttonText={common_label_156}
                            level={0}
                            cancel={this.hideRestorePassword}
                            ok = {this.handleRestoreMailWasSend}
                        />
                    </div>
                    : <div>
                <div className="bp3-dialog-body">

                    <div className='card--title-icon inline'>
                        <LanguagePicker onSelect={(language)=>this.props.changeLanguage(language)}
                                        selected={this.props.language}
                                        size={ImageSize.XTiny}
                                        intent={Intent.PRIMARY}
                        />
                    </div>
                    {error}
                    { this.state.errorMessage && this.getRegister() ?
                        <Callout
                            className='login--text-span'
                            intent={Intent.DANGER}
                            icon='issue'
                            title={this.props.vocabulary[bad_input]}
                        > {this.state.errorMessage}
                        </Callout> : null
                    }

                    <FormGroup
                     label={<span className='login--text-span'> {this.props.vocabulary[username_label]}:</span>}
                    >
                        <InputGroup
                            className="login-fields"
                            intent={Intent.PRIMARY}
                            placeholder={this.props.vocabulary[input_username]}
                            autoComplete="new-password"
                            rightElement={usernameIcon}
                            onChange={(event : React.FormEvent<HTMLInputElement>)=>
                            {this.props.setUsername(event.currentTarget.value);
                            this.props.noException()}}
                        />
                    </FormGroup>
                    <FormGroup
                        label={<span className='login--text-span'> {this.props.vocabulary[authorization_label_4]}: </span>}
                    >

                    <InputGroup
                        className="login-fields"
                        intent={this.isPasswordNotMatched() ? Intent.DANGER :Intent.PRIMARY}
                        placeholder={this.props.vocabulary[authorization_label_54]}
                        rightElement={lockButton}
                        autoComplete="new-password"
                        type={this.state.showPassword ? "text" : "password"}
                        onChange={(event : React.FormEvent<HTMLInputElement>)=>
                        {
                            this.handlePasswordChange(event.currentTarget.value);
                        }}
                    />
                    </FormGroup>
                    {   this.getRegister()?
                        <div>
                            <span className='login--text-span'> {this.props.vocabulary[authorization_label_55]}: </span>
                            <InputGroup
                                className="login-fields"
                                intent={this.isPasswordNotMatched() ? Intent.DANGER :Intent.PRIMARY}
                                placeholder={this.props.vocabulary[authorization_label_55]}
                                rightElement={lockButton}
                                autoComplete="new-password"
                                type={this.state.showPassword ? "text" : "password"}
                                onChange={(event: React.FormEvent<HTMLInputElement>) => {
                                    this.handlePasswordRepeatChange(event.currentTarget.value);
                                }}
                            />
                        </div> : null
                    }

                </div>
                <div className={Classes.DIALOG_FOOTER}>
                    {
                        this.props.process.type === ProcessType.WaitingForServerResponse ?
                        <ProgressBar intent={Intent.PRIMARY} className="login-fields"/>
                        :
                        < div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <Button
                            icon={this.getRegister()? "new-person":"log-in"}
                            intent={this.getRegister()? Intent.SUCCESS :Intent.PRIMARY}
                            disabled={this.isPasswordNotMatched()}
                            onClick={this.handleLogin}>
                                {this.getLoginButtonCaption()}
                            </Button>
                        </div>
                    }

                </div>
                        <div className={Classes.DIALOG_FOOTER}>
                            {
                                this.props.process.type === ProcessType.WaitingForServerResponse ?
                                    <ProgressBar intent={Intent.PRIMARY} className="login-fields"/>
                                    :
                                    < div className={Classes.DIALOG_FOOTER_ACTIONS}>
                                        {this.getRegister()? null :
                                            <Button
                                                minimal={true}
                                                intent={ Intent.DANGER }
                                                onClick={this.showRestorePassword}
                                            >
                                                {this.props.vocabulary[forgot_password]}
                                            </Button>
                                        }
                                        <Button
                                            minimal={true}
                                            intent={this.getRegister()? Intent.PRIMARY :Intent.SUCCESS}
                                            onClick={this.handleChangeMode}
                                        >
                                            {this.props.vocabulary[this.getRegister()? change_to_login : change_to_register]}
                                        </Button>
                                    </div>
                            }

                        </div>
                </div>}

            </Dialog>
        );
    }

    private getRegister=() :boolean => {
        return this.props.registrationFirst ? ! this.state.register : this.state.register;
    }
}

const mapStateToProps = (state: AppState) => ({
    user : state.user,
    error : state.error,
    process :state.process,
    mode : state.settings.mode,
    vocabulary : state.data.vocabulary,
    language : state.settings.language,
    registrationFirst : !! state.settings.registrationFirst
});

export default connect(
    mapStateToProps,
    {login,setUsername,setPassword,noException,onException,changeLanguage}
)(LoginDialog);
