import React, {FC} from 'react';
import {Callout, Tooltip} from "@blueprintjs/core";
import {IImageAdaptor, ImageSize} from "../../adaptors/ImageAdaptor";
import {FlagSVG} from "./FlagSVG";
import {AppState} from "../../store/configureStore";
import {connect} from "react-redux";
import {Vocabulary} from "../../model/AppData";

interface ImageViewerProps {
    className ?: string
    adaptor : IImageAdaptor;
    vocabulary : Vocabulary;
}

const ImageViewer : FC<ImageViewerProps> = props =>  {
    let parentClass='image-parent';
    let imageClass ='image';
    switch (props.adaptor.size) {
        case ImageSize.Medium:
            parentClass='image-parent-x2';
            imageClass='image-x2';
            break;
        case ImageSize.Large:
            parentClass='image-parent-x3';
            imageClass='image-x3';
            break;
        case ImageSize.XLarge:
            parentClass='image-parent-x4';
            imageClass='image-x4';
            break;
        case ImageSize.Small:
            parentClass='image-parent-s';
            imageClass='image-s';
            break;
        case ImageSize.Tiny:
            parentClass='image-parent-xs';
            imageClass='image-xs';
            break;
        case ImageSize.XTiny:
            parentClass='image-parent-xxs';
            imageClass='image-xxs';
            break;
        case ImageSize.Normal:
        default:
            parentClass='image-parent';
            imageClass='image';
            break;
    }
    return (
        <div className={props.className}>
            <Tooltip
                content={props.adaptor.hint ? (props.adaptor.rawHint ? props.adaptor.hint : props.vocabulary[props.adaptor.hint]  ): undefined}
                intent={props.adaptor.intent}
                wrapperTagName='div'
                targetTagName='div'>

                    { props.adaptor.isSvg ?
                        <div
                            className={'base-margin '+parentClass}
                            onClick={props.adaptor.onClick}>
                            <div className={imageClass + ' inline'}  >
                                 <FlagSVG id={props.adaptor.link} alt={props.adaptor.alt} size={props.adaptor.size}/>
                            </div>
                        </div>:
                        <Callout
                            intent={props.adaptor.intent}
                            className={'base-margin '+parentClass}
                            onClick={props.adaptor.onClick}
                            icon= {null}
                        >
                        <img className={imageClass} src={props.adaptor.link} alt={props.adaptor.alt}/>
                </Callout>}
            </Tooltip>
        </div>);
};

const mapStateToProps = (state: AppState) => ({
    vocabulary : state.data.vocabulary
});
export default connect (mapStateToProps)(ImageViewer);