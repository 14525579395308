import {Action} from "redux";
import {ThunkAction} from "redux-thunk";
import {AppState} from "../store/configureStore";
import {FirebaseMessage} from "../model/common/FirebaseMessage";
import {check, connect, get, head, options, post, put, save} from "../service/Firebase";
import {ExceptionType, noException, onException} from "../actions/error";
import {becomeIdle, onRequestSent} from "../actions/process";
import {Method} from "../model/Request";
import {
    receiveCoursesCoverage,
    receiveFirebaseHomework,
    receiveFirebaseMessages,
    receiveFirebaseStudentsHomework,
    receiveSubscribers
} from '../actions/data';

export const firebaseSend =(message : FirebaseMessage,
                            onSuccess ?: ()=>void, visible : boolean = true): ThunkAction<void, AppState, null, Action> => async dispatch => {
    function onError(e :any) {
         console.log(e);
        try{
            dispatch(onException(e.status));
        }catch (e2) {
            console.log(e2);
        }
        dispatch(becomeIdle());
    }
    try{
        if(visible) {
            dispatch(onRequestSent());
            dispatch(onException(ExceptionType.Processing));
        }
        switch (message.method) {
            case Method.GET:
                get(message)
                    .then(
                        res=> dispatch(receiveFirebaseMessages(message.topic,res))
                    )
                    .catch(
                        e=> onError(e)
                    );
                break;
            case Method.OPTIONS:
                options(message)
                    .then(
                        res=> {
                            dispatch(receiveSubscribers(message.topic,res))
                        }
                    )
                    .catch(
                        e=> onError(e)
                    );
                break;
            case Method.HEAD:
                head(message)
                    .then(
                        res => {
                            dispatch(receiveFirebaseHomework(message.topic,res))
                        }
                    )
                    .catch(
                        e => onError(e)
                    );
                break;
            case Method.CONNECT:
                connect(message)
                    .then(
                        res => {
                            dispatch(receiveFirebaseStudentsHomework(message.topic,res))
                        }
                    )
                    .catch(
                        e => onError(e)
                    );
                break;
            case Method.CHECK:
                check(message)
                    .then(
                        res => {
                            dispatch(receiveCoursesCoverage(message.topic,res))
                        }
                    )
                    .catch(
                        e => onError(e)
                    );
                break;
            default:
            case Method.POST:
                console.log(await post(message));
                break;
            case Method.PUT:
                console.log(await put(message));
                break;
            case Method.SAVE:
                console.log(await save(message));
                break;
        }
        if(onSuccess) onSuccess();
        if(visible) {
            dispatch(becomeIdle());
            dispatch(noException());
        }
    }catch (e) {
        onError(e);
    }

};