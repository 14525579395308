import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import {
    FirebaseCourseData,
    FirebaseMessage,
    FirebaseMessageContent,
    FirebaseMessageType, FirebaseSignedHomework
} from "../model/common/FirebaseMessage";
import {Vocabulary} from "../model/AppData";
import {VAR_DELIMITER, COURSES, SUBSCRIBERS, REVIEW} from "./Literals";
import {LessonsCoverage} from "../model/common/LessonCoverage";
import {CourseStatuses, ICourseStatus} from "../model/courses/CourseReviewStatus";

const firebaseConfig = {
    apiKey: "AIzaSyCCyM-QK4Rkyl7ARY59dUB7abnm10aK8lk",
    authDomain: "scientific-enterteiment.firebaseapp.com",
    databaseURL: "https://scientific-enterteiment.firebaseio.com",
    projectId: "scientific-enterteiment",
    storageBucket: "scientific-enterteiment.appspot.com",
    messagingSenderId: "909923380905",
    appId: "1:909923380905:web:4db5a458f1a18e8b60c3b8",
    measurementId: "G-77W5X6FXF2"
};

firebase.initializeApp(firebaseConfig);

export const firestore = firebase.firestore();

let afterLogin:  Promise<firebase.auth.UserCredential>;

export function firebaseLogin(token: string) : Promise<firebase.auth.UserCredential>{
    afterLogin= firebase.auth().signInWithCustomToken(token);
    return afterLogin;
}

export function firebaseLogout() {
    firebase.auth().signOut().then(function() {
    }).catch(function(error) {
        console.log(error);
    });
}

export function firebaseUsername() : string|undefined {
    return firebase.auth().currentUser ? firebase.auth().currentUser!.uid : undefined;
}

export function getMyReviewCount(statuses : CourseStatuses) :number {
   return  Object.keys(statuses)
       .map(key=>statuses[key].reviewer)
       .filter(reviewer=> reviewer && reviewer === firebase.auth().currentUser!.uid)
       .length
}

export function prepareMessage(msg : FirebaseMessage) : Promise<void> {
    return new Promise<void>((resolve, reject) => {
        if(firebase.auth().currentUser){
            msg.setUser(firebase.auth().currentUser!.uid);
            msg.setTime();
            resolve();
            return;
        }
        afterLogin
            .then(
                res => {
                    msg.setUser(firebase.auth().currentUser!.uid);
                    msg.setTime();
                    resolve();
                }
            )
            .catch(
                error=>{console.log(error); reject();}
            );
    });
}

export function prepare() : Promise<void> {
    return new Promise<void>((resolve, reject) => {
        if(firebase.auth().currentUser){
            resolve();
            return;
        }
        afterLogin
            .then(
                res => {
                    resolve();
                }
            )
            .catch(
                error=>{console.log(error); reject();}
            );
    });
}

function updateCourseStatus(msg: FirebaseMessage) {
    firestore
        .collection(msg.topic)
        .doc("status")
        .set({updated: msg.content.time}).then();
}

export async function post(msg : FirebaseMessage) : Promise<string>{
    try{
        await prepareMessage(msg);
        return new Promise<string>(async function(resolve, reject)  {
            firestore
                .collection(msg.topic)
                .add(msg.content).then(value => {
                updateCourseStatus(msg);
                resolve('ok')}).catch(error=>{reject(error)})
        });
    }catch (e) {
        return new Promise(function (resolve, reject) {
            reject("No firebase user");
        });
    }
}

function prepareObjectUpdate(msg: FirebaseMessage ,obj : any) {
    obj[
            msg.type === FirebaseMessageType.MessageAnswer ? FirebaseMessageType.MessageAnswer : (
                msg.type === FirebaseMessageType.HomeworkReview ?
                FirebaseMessageType.HomeworkReview + VAR_DELIMITER + msg.content.lesson :
                msg.content.lesson
                )] = msg.content.content;
    obj[TIME] = new Date().getTime();
    switch (msg.type) {
        case FirebaseMessageType.Text:
        case FirebaseMessageType.HomeworkReview:
        case FirebaseMessageType.MessageAnswer:
            return msg.content.type;
        default:
        case FirebaseMessageType.Video:
        case FirebaseMessageType.File:
        case FirebaseMessageType.Tests:
        case FirebaseMessageType.MyHomework:
            obj[TYPE]= FirebaseMessageType.MyHomework;
            return msg.content.user!;
        case FirebaseMessageType.LessonCovered:
            return msg.content.user;
    }
}

export async function put(msg: FirebaseMessage): Promise<string> {
    try {
        await prepareMessage(msg);
        let obj : any={};
        const id = prepareObjectUpdate(msg,obj);
        const docRef : firebase.firestore.DocumentReference = firestore
            .collection(msg.topic)
            .doc(id);
        return updateDocument(docRef, obj);
    }catch (e) {
        return new Promise(function (resolve, reject) {
            reject("No firebase user");
        });
    }
}

function updateDocument(docRef: firebase.firestore.DocumentReference, obj: any) {
    return new Promise<string>(async function (resolve, reject) {
        docRef.get().then(doc => {
            if (doc.exists) {
                docRef
                    .update(obj).then(value => {
                    resolve('ok')
                }).catch(error => {
                    reject(error)
                })
            } else {
                docRef
                    .set(obj).then(value => {
                    resolve('ok')
                }).catch(error => {
                    reject(error)
                })
            }
        }).catch(error => reject(error));
    });
}

export async function save(msg: FirebaseMessage): Promise<string> {
    try {
        await prepareMessage(msg);
        let obj : any={};
        const docRef : firebase.firestore.DocumentReference = firestore
            .collection(msg.topic)
            .doc(msg.content.user)
            .collection(msg.type)
            .doc(msg.content.lesson);
        obj[msg.content.type] = true;
        return updateDocument(docRef, obj);
    }catch (e) {
        return new Promise(function (resolve, reject) {
            reject("No firebase user");
        });
    }
}

export async function options(msg: FirebaseMessage): Promise<string[]> {
    try {
        await prepareMessage(msg);
        const ref : firebase.firestore.CollectionReference = firestore
            .collection(COURSES)
            .doc(msg.topic)
            .collection(SUBSCRIBERS);
        return new Promise<string[]>(async function(resolve, reject)  {
            ref.get().then(querySnap=>{
                const res : string []=[];
                querySnap.forEach(doc=>res.push(doc.id));
                resolve(res);
            }).catch(error =>{
                console.log(error);
                reject(error)
            });
        });
    }catch (e) {
        return new Promise(function (resolve, reject) {
            reject("No firebase user");
        });
    }
}

export function getReviewReference() : firebase.firestore.CollectionReference{
    return firestore
        .collection(REVIEW)
}

export async function getCourseStatus(topic : string) :Promise<ICourseStatus> {
    try {
        await prepare();
        return new Promise<ICourseStatus>(async function(resolve, reject) {
            firestore
                .collection(COURSES)
                .doc(topic)
                .get()
                .then(doc=>resolve(doc.data() as ICourseStatus)

                )
                .catch(reason => {
                reject(reason);
            })

        });
    }catch (e) {
        console.log(e);
        return new Promise(function (resolve, reject) {
            reject("No firebase user");
        });
    }
}

export async function getCourseStatusSubscription(topic : string) : Promise<firebase.firestore.DocumentReference> {
    try {
        await prepare();
        return new Promise<firebase.firestore.DocumentReference>(async function(resolve, reject) {
            resolve (firestore
                .collection(COURSES)
                .doc(topic))

        });
    }catch (e) {
        console.log(e);
        return new Promise(function (resolve, reject) {
            reject("No firebase user");
        });
    }
}

export async function get(msg: FirebaseMessage): Promise<FirebaseCourseData> {
    try {
        await prepareMessage(msg);
        if(!msg.query) return new Promise(function (resolve, reject) {
            reject("Query is undefined");
        });
        return new Promise<FirebaseCourseData>(async function(resolve, reject) {
            query(firestore
                .collection(msg.topic),msg)
                .get()
                .then(querySnap=>{
                    const res :FirebaseCourseData = [];
                    //     console.log(querySnap.size);
                    querySnap.forEach(doc=>{
                        //   console.log(doc.data());
                        const data : FirebaseMessageContent = doc.data() as FirebaseMessageContent;
                        data.id = doc.id;
                        data.topic = msg.topic;
                        res.push(data)
                    });
                    resolve(res);
                })
                .catch(reason => {
                    reject(reason);
                })
        });
    }catch (e) {
        console.log(e);
        return new Promise(function (resolve, reject) {
            reject("No firebase user");
        });
    }
}

export async function head(msg: FirebaseMessage): Promise<Vocabulary>{
    try {
        await prepareMessage(msg);
        console.log(msg);
        return new Promise<Vocabulary>(function (resolve,reject) {
            firestore
                .collection(msg.topic)
                .doc(msg.content.user)
                .get()
                .then(doc=> {
                    resolve(doc.data() as Vocabulary);})
                .catch(error=>reject(error));
        })
    }catch (e) {
        return new Promise(function (resolve, reject) {
            reject("No firebase user");
        });
    }
}

export async function check(msg: FirebaseMessage): Promise<LessonsCoverage>{
    try {
        await prepareMessage(msg);
        console.log(msg);
        return new Promise<LessonsCoverage>(function (resolve,reject) {
            firestore
                .collection(msg.topic)
                .doc(msg.content.user)
                .collection(msg.type)
                .get()
                .then(collection=> {
                    const res : LessonsCoverage ={};
                    collection.forEach(doc=>{
                        res[doc.id] =doc.data();
                    });
                    resolve(res);})
                .catch(error=>reject(error));
        })
    }catch (e) {
        return new Promise(function (resolve, reject) {
            reject("No firebase user");
        });
    }
}

export async function connect(msg: FirebaseMessage): Promise<FirebaseSignedHomework[]>{
    try {
        await prepareMessage(msg);

        return new Promise<FirebaseSignedHomework[]>(function (resolve,reject) {
            query(firestore
                .collection(msg.topic),msg)
                .get()
                .then(docs=> {
                    const res :FirebaseSignedHomework[] = [];
                    docs.forEach(doc=>{
                        const  homework : Vocabulary = doc.data() as Vocabulary;
                        const time : number = +homework[TIME];
                        delete homework[TIME];
                        delete homework[TYPE];
                        res.push({
                            student : doc.id,
                            homework,
                            time
                        })
                    });
                    resolve(res);

                })
                .catch(error=>reject(error));
        })
    }catch (e) {
        return new Promise(function (resolve, reject) {
            reject("No firebase user");
        });
    }
}

export const TIME = 'time';
export const TYPE = 'type';
export function query(collection : firebase.firestore.CollectionReference, msg : FirebaseMessage) : firebase.firestore.Query {
    let result : firebase.firestore.Query;
    switch (msg.type) {
        case FirebaseMessageType.Subscribers:
        case FirebaseMessageType.NewMessages:
            result  = collection
                .where(TIME,">",new Date().getTime()-msg.query!.params[0])
                .orderBy(TIME);
            break;
        case FirebaseMessageType.LessonMessages:
            result = collection
                .where('lesson','==',msg.content.lesson);
            break;
        case FirebaseMessageType.MyHomework:
            result = collection
//                .where(TIME,">",new Date().getTime()-msg.query!.params[0])
                .where(TYPE,'==',FirebaseMessageType.MyHomework);
            break;
        case FirebaseMessageType.NewSubscribers:
                result = collection;
                break;
        default:
            result= collection
                .where("user","==", msg.content.user);
    }
    if(msg.query && msg.query.params.length >=2 && typeof msg.query.params[1] === 'number') {
        result.limit(msg.query.params[1]);
    }
    return result;
}
