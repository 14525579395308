import React, {FC, memo} from "react";
import {Button, Callout, Card, Icon, Intent} from "@blueprintjs/core";
import {Elevation} from "@blueprintjs/core/lib/esm/common/elevation";
import {Text, Txt} from "./Text";
import {do_study, review_pass, review_under_list} from "../../text/Literals";
import {AppState} from "../../store/configureStore";
import {connect} from "react-redux";
import {getCourseName, ReviewContent} from "../../model/courses/Course";
import {Method, Request} from "../../model/Request";
import {DataType} from "../../actions/data";
import {sendRequest} from "../../thunks/sendRequest";
import {clearData} from "../../actions/data";
import {InstrumentType} from "../../actions/instruments";
import {Entity} from "../../model/Entity";
import useInstrument from "../../thunks/useInstrument";

interface UnderReviewPanelProps {
    reviewContent : ReviewContent;
    sendRequest : any;
    clearData : any;
    useInstrument : any;
    token : string
}

const UnderReviewPanel : FC<UnderReviewPanelProps>= props =>  {
    const keys = Object.keys(props.reviewContent);
    if(keys.length === 0)return null;
    return  <Card interactive={true} elevation={Elevation.TWO} className='base-margin base-width tiles-container-adjustable'>
        <Callout intent={Intent.WARNING} icon={null}>
            <Text text={new Txt().dict(review_under_list)}/>
        </Callout>
        {keys
            .map(key=>
                <Card interactive={true} elevation={Elevation.THREE} className='tiles-item tiles-container-vertical' key={key}>
                    <div className='tiles-item'>
                        <Icon intent={Intent.WARNING} icon='learning' className='tiles-one-third'/>
                        <Text text={new Txt().txt(getCourseName(props.reviewContent[key]))} className='tiles-item-row color-warning'/>
                    </div>
                    <Text text={new Txt().txt(key)} className='tiles-one-third'/>
                    <Button
                        intent={Intent.WARNING}
                        icon='eye-open'
                        className='tiles-one-third'
                        onClick={()=>{
                            props.useInstrument(InstrumentType.CourseReview,undefined,new Entity({id: key,label : getCourseName(props.reviewContent[key])!}));
                        }}
                    >
                        <Text text={new Txt().dict(do_study)}/>
                    </Button>
                    <Button
                        intent={Intent.DANGER}
                        icon='cross'
                        className='tiles-one-third'
                        onClick={()=>{
                            props.sendRequest(new Request(undefined,DataType.StartReview+key,Method.DELETE,props.token),DataType.StartReview,undefined,()=>{
                                console.log("review abandoned");
                                props.clearData(DataType.AbandonReview,key)
                            })
                        }}
                    >
                        <Text text={new Txt().dict(review_pass)}/>
                    </Button>
                </Card>)}
    </Card>
};

const mapStateToProps = (state : AppState)=>({
    reviewContent : state.data.reviewContent,
    token : state.user.token
});

export default connect(mapStateToProps,{sendRequest,clearData,useInstrument})(memo(UnderReviewPanel))