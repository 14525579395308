import React, { Component } from "react";
import {
    ConvertPerson,
    getPersonButtonsAdaptor,
    IFullPerson,
    IPerson, LicensePurchaseData,
    Person,
    PersonSearchRequest
} from "../../api/Person";
import {Vocabulary} from "../../model/AppData";
import {Button, Callout, Card, Elevation} from "@blueprintjs/core";
import {Intent} from "@blueprintjs/core/lib/esm/common/intent";
import {
    active,
    canceldate, cancelled, canceller, copy_emails_label,
    data_copied,
    download_selected_users,
    edit_label,
    edit_user,
    email_label,
    email_list_users, extra_info,
    invalid_or_bounced, license_purchase_date, license_purchases, operation_success,
    person_search_help,
    phone_label,
    register_label,
    register_user,
    search_for_person, sellerLabel,
    send_request, software_product_label, state,
    user_name_label,
    users_found
} from "../../text/Literals";
import PostRequestForm from "../forms/PostRequestForm";
import {DataType} from "../../actions/data";
import {Txt} from "../tools/Text";
import {AppState} from "../../store/configureStore";
import {connect} from "react-redux";
import {CSVLink} from "react-csv";
import {personsCSV} from "../../service/CsvMapping";
import {isEmail} from "../../adaptors/FieldAdaptor";
import {AppToaster} from "../../service/toaster";
import {MailResponse} from "../../api/MailRequest";
import MailSendTool from "../tools/MailSendTool";
import MailSendResults from "../tools/MailSendResults";
import FormButton from "../forms/FormButton";
import KeyValueViewer from "../viewers/KeyValueViewer";
import {KV, ObjectKV, PlainTextKV} from "../../model/KV";

interface PersonRegInstrumentProps {
    persons ?: IFullPerson[];
    vocabulary : Vocabulary;
    token : string;
}

interface PersonRegInstrumentState {
    person ?: Person;
    personSearch ?: PersonSearchRequest;
    hideSearch : boolean;
    waitForPersonUpdate ?: boolean;
    recipients : string [];
    invalidCount ?: number;
    mailResponse ?: MailResponse;
    expanded : Set<string>;
}

class PersonRegInstrument extends Component<PersonRegInstrumentProps, PersonRegInstrumentState> {
    constructor(props: Readonly<PersonRegInstrumentProps>) {
        super(props);
        this.state = {
            person : undefined,
            hideSearch : false,
            expanded : new Set<string>(),
            recipients : []
        }
    }

    message = (msg : string, intent : Intent) => {
        AppToaster.show({
            icon : "warning-sign",
            intent : intent,
            message : this.props.vocabulary[msg]
        });
    }

    handleEditPerson = (person : IPerson | undefined) => {
        if (person === undefined) {
            this.setState({...this.state, person});
            return;
        }

        this.setState({
            ...this.state,
            person : ConvertPerson(person)})
        console.log(this.state)
    };

    handleCreatePerson = () => {
        this.handleEditPerson({
            name : '',
            phone : '',
            description : '',
            licenses : ['naurasha_home'],
            username : ''
        });
    };

    handleCloseEdit = () => {
        this.handleEditPerson(undefined);
    };

    handleSave = () => {
        this.setState({...this.state, waitForPersonUpdate : true});
        this.handleCloseEdit();
        this.message(operation_success, Intent.SUCCESS);
    };

    startSearch = () => {
        this.setState({
            ...this.state,
            personSearch : new PersonSearchRequest()
        })
        console.log(this.state)
    };

    stopSearch = () => {
        if (this.props.persons !== undefined)
            this.handleCloseEdit();
        this.setState({
            ...this.state,
            personSearch : undefined
        })
    };

    hideSearch = (hideSearch : boolean) => {
        this.setState({
            ...this.state,
            hideSearch
        })
    };

    componentDidUpdate(prevProps: Readonly<PersonRegInstrumentProps>, prevState : Readonly<PersonRegInstrumentState>, snapshot?: any) {
        if (!this.state.waitForPersonUpdate) {
            return
        }

        if (prevState.person && this.state.person && this.props.persons && this.props.persons.length === 1 && this.state.person === prevState.person) {
            this.setState({...this.state, waitForPersonUpdate : false, person : ConvertPerson(this.props.persons[0])})
        }
    }

    isExpanded = (person : IFullPerson) : boolean => {
        return this.state.expanded.has(person.username)
    };

    handleExpand = (person : IFullPerson) =>{
        if (this.isExpanded(person)) {
            this.state.expanded.delete(person.username);
        } else {
            this.state.expanded.add(person.username);
        }

        this.setState({
            ...this.state
        })
    };

    handleMailTo = (recipients : string[]) => {
        if (recipients.length === 0) {
            this.message(invalid_or_bounced, "danger");
        } else {
            this.setState({...this.state, recipients})
        }
    }

    handleMailToSelected = () => {
        const count = this.props.persons!.length;
        const recipients = this.props.persons!
            .map(person => person.username)
            .filter(email => isEmail(email));

        const invalidCount = count - recipients.length ;
        if (invalidCount !== this.state.invalidCount)
            this.setState({...this.state, invalidCount })

        this.handleMailTo(recipients);
    }

    handleCopyIds = () => {
        if (!this.props.persons || this.props.persons.length === 0) {
            return
        }

        navigator.clipboard.writeText(this.props.persons.map(person => person.username).join(",")).then(
            () => {
                this.message(data_copied, "primary");
            }
        )
    }

    handleCloseMail = () => {
        this.setState({...this.state, recipients : [], invalidCount : undefined})
    }

    handleMailSent = (msg ?: string) => {
        if (msg) {
            this.setState({...this.state, mailResponse : JSON.parse(msg)})
        }
        this.handleCloseMail();
    }

    handleCloseMailResult = () => {
        this.setState({...this.state, mailResponse : undefined})
    }

    getPersonShortKVList(person : IFullPerson, full : boolean) : KV[] {
        if (full) {
            return [
                {key : email_label, value : person.username},
                {key : user_name_label, value : person.name, isNegative : person.bounced},
                {key : phone_label, value : person.phone},
                {key : extra_info, value : person.description},
                new ObjectKV(license_purchases, this.getLicensePurchaseKV(person.licensePurchaseData, this.props.vocabulary))
            ];
        } else {
            return [
                new PlainTextKV(this.props.vocabulary[email_label], person.username),
                new PlainTextKV(this.props.vocabulary[user_name_label], person.name),
            ];
        }
    }

    getLicensePurchaseKV = (data : LicensePurchaseData[] | undefined, vocabulary : Vocabulary) : KV[] => {
        if (data == null)
            return [];
        return data.flatMap(function(data) {
            return !data.canceled ? [
                new PlainTextKV(vocabulary[software_product_label], data.software),
                new PlainTextKV(vocabulary[license_purchase_date], data.date.toString()),
                new PlainTextKV(vocabulary[sellerLabel], data.seller),
                new PlainTextKV(vocabulary[state], vocabulary[active]),
            ] : [
                new PlainTextKV(vocabulary[software_product_label], data.software),
                new PlainTextKV(vocabulary[license_purchase_date], data.date.toString()),
                new PlainTextKV(vocabulary[sellerLabel], data.seller),
                new PlainTextKV(vocabulary[state], vocabulary[cancelled]),
                new PlainTextKV(vocabulary[canceller], data.canceler),
                new PlainTextKV(vocabulary[canceldate], data.canceldate?.toString())
            ];})
    };

    render() {
        return <Card interactive={false} elevation={Elevation.ZERO} className='horizontal-margin'>
            <div className='tiles-container-adjustable'>
                {
                    this.state.person === undefined ?
                        <Button
                            intent={Intent.SUCCESS}
                            icon={"add"}
                            className='tiles-one-third'
                            onClick={ () => { this.handleCreatePerson() } }
                            >{ this.props.vocabulary[register_user] }
                        </Button> : null
                }
                {   this.state.personSearch === undefined ?
                    <Button
                        intent={Intent.PRIMARY}
                        icon={"geosearch"}
                        className='tiles-one-third'
                        onClick={this.startSearch}>
                        {this.props.vocabulary[search_for_person]}
                    </Button> : null
                }
            </div>
            {
                this.state.person === undefined ? null :
                    <div>
                        <PostRequestForm
                            default={true}
                            intent={Intent.PRIMARY}
                            url= {DataType.Persons}
                            label={new Txt().dict(this.state.person.isNew ? register_user : edit_user)}
                            type={this.state.person.getType()}
                            help={''}
                            data={this.state.person}
                            method={this.state.person.getMethod()}
                            cancel={this.handleCloseEdit}
                            buttonText={this.state.person.isNew ? register_label : edit_label}
                            ok ={this.handleSave}
                        />
                    </div>
            }
            {
                this.state.personSearch === undefined ? null :
                    <PostRequestForm
                        default={true}
                        intent={Intent.SUCCESS}
                        url= {DataType.PersonsAll}
                        label={new Txt().dict(search_for_person)}
                        type={DataType.PersonsAll}
                        help={person_search_help}
                        data={this.state.personSearch}
                        method={this.state.personSearch.getMethod()}
                        cancel={this.stopSearch}
                        buttonText={send_request}
                    />
            }
            {
                this.props.persons === undefined ? null :
                    <Card interactive={false} elevation={Elevation.THREE} className='instruments-cards--space tiles-container'>
                        <div className='form-item-row form--button-container'>
                            <Callout intent={Intent.PRIMARY}
                                     icon='person'
                                     className='card-caption bp3-text-large '
                                     title={this.props.vocabulary[users_found] + " " + this.props.persons.length}
                            >
                                {
                                    this.props.persons.length === 0 ? null :
                                    <div >
                                        <CSVLink
                                            data={this.props.persons}
                                            filename='SelectedUsers.csv'
                                            className ='inline common-margin'
                                            headers={personsCSV}>
                                            <Button
                                                icon='import'
                                                intent={Intent.PRIMARY}
                                                onClick={() => { }}>
                                                {this.props.vocabulary[download_selected_users]}
                                            </Button>
                                        </CSVLink>
                                        <Button
                                            className ='inline common-margin'
                                            icon='envelope'
                                            intent={Intent.PRIMARY}
                                            onClick={this.handleMailToSelected}>
                                            {this.props.vocabulary[email_list_users]}
                                        </Button>
                                        <Button
                                            className ='inline common-margin'
                                            icon='clipboard'
                                            intent={Intent.PRIMARY}
                                            onClick={this.handleCopyIds}>
                                            { this.props.vocabulary[copy_emails_label]}
                                        </Button>
                                    </div>
                                }
                            </Callout>
                            {   this.state.hideSearch ?
                                <Button
                                    className='card-caption-button'
                                    icon='expand-all'
                                    intent={Intent.SUCCESS}
                                    onClick={() => {this.hideSearch(false)}}/>
                                :
                                <Button
                                    className='card-caption-button'
                                    icon='collapse-all'
                                    intent={Intent.DANGER}
                                    onClick={()=>{this.hideSearch(true)}}/>
                            }
                        </div>
                        {
                            this.state.hideSearch ? null :
                            this.props.persons.map((person, index) =>(
                                <Card interactive={true}
                                      elevation={Elevation.ONE}
                                      key={index}
                                      className={this.isExpanded(person) ? 'form-item-row form--item-container--columns' : 'tiles-item form--item-container'}>
                                    {
                                        this.getPersonShortKVList(person ,this.isExpanded(person)).map(kv => (
                                            <div className='form-item-element'>
                                                <KeyValueViewer
                                                    token={this.props.token}
                                                    kv={kv}
                                                />
                                            </div>))
                                    }
                                    <div className={(this.isExpanded(person) ? 'full-row' : 'form-item-row' ) + ' form--center-button-container'}>
                                        {
                                            getPersonButtonsAdaptor(person,
                                                this.handleEditPerson,
                                                this.handleExpand,
                                                this.isExpanded(person),
                                                this.handleMailTo).map((adaptor,index) =>
                                                <FormButton
                                                    className='form-button-4'
                                                    adaptor={adaptor}
                                                    token={this.props.token}
                                                    key={index}/>)
                                        }
                                    </div>
                                </Card>))
                        }
                    </Card>
            }
            <MailSendTool
                recipients={this.state.recipients}
                close={this.handleCloseMail}
                onSuccess={this.handleMailSent}
                invalidCount={this.state.invalidCount}
                dataType={DataType.PersonMail}
            />
            {
                this.state.mailResponse ?
                    <MailSendResults
                        res={this.state.mailResponse}
                        close={this.handleCloseMailResult} />
                    : null
            }
        </Card>
    }
}

const mapStateToProps = (state: AppState) => ({
    token : state.user.token,
    vocabulary : state.data.vocabulary,
    persons : state.data.persons
});

export default connect(
    mapStateToProps
)(PersonRegInstrument);