import React, {FC, memo, ReactNode} from "react";
import {Vocabulary} from "../../model/AppData";
import {connect} from "react-redux";
import {AppState} from "../../store/configureStore";

export interface IWord {
    value : string;
    isPlain: boolean;
}
export interface IText {
    getText(vocabulary : Vocabulary):string;
}

export class Txt implements  IText{
    private readonly _words : IWord [];

    constructor(model : Txt|undefined = undefined) {
        this._words =model ? [...model._words] :[];
    }

    txt=(value : string|null|undefined, isPlain : boolean = true):Txt=>{
        if(!value) return this;
        this._words.push(txt(value,isPlain));
        return this;
    };

    dict =(value : string):Txt=>{
        this._words.push(dict(value));
        return this;
    };

    getText(vocabulary: Vocabulary): string {
        let res : string = "";
        this._words.forEach( word=>{res +=word.isPlain ? word.value : vocabulary[word.value]});
        return res;
    }

    pop=()=>{
        this._words.pop();
        return this;
    }

}

export function txt(value : string, isPlain : boolean = true) : IWord{
    return {value, isPlain};
}

export function dict(value : string) : IWord{
    return {value, isPlain : false}
}

interface TextProps {
    text ?: Txt;
    landscape ?: boolean;
    vocabulary : Vocabulary;
    className ?: string;
    useDiv ?: boolean;
    children?: ReactNode | undefined
}

const TextJSX : FC<TextProps> = props => {
    return props.text ? (props.useDiv ?
        <div className={props.landscape ? 'landscape-only '+props.className: props.className}>{props.text.getText(props.vocabulary)}</div>:
        <span className={props.landscape ? 'landscape-only '+props.className: props.className}>{props.text.getText(props.vocabulary)}</span> ): null;
};


export const Text=connect((state: AppState)=>({vocabulary : state.data.vocabulary}))(memo(TextJSX));