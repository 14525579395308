import {Classroom} from "./Classroom";
import {FormAdaptor, IFormAdaptor} from "../adaptors/FormAdaptor";
import {DataType} from "../actions/data";
import {FormFieldAdaptor, StringFieldAdaptor} from "../adaptors/FieldAdaptor";
import {SimpleRequestData} from "../adaptors/RequestData";
import {message_from_student} from "../text/Literals";

export interface ISaveClassroomValueRequest {
    id : number;
    value : string;
}

export class SaveMessageRequest extends SimpleRequestData implements ISaveClassroomValueRequest{
    id: number;
    value: string;
    adaptor : IFormAdaptor;
    valueAdaptor ?: FormFieldAdaptor<string>;
    onDataChanged ?: (adaptor :IFormAdaptor) => void;

    constructor(model : Classroom) {
        super();
        this.fields = ['id','value'];
        this.id = model.id;
        this.value = model.myMessage;
        if(!this.value) this.value='';
        this.adaptor = this.createFormAdaptor();
    }

    setValue=(val : string)=>{
        this.value = val;
        this.onDataChanged!(
            this.createFormAdaptor()
        );
    };

    initValueAdaptor = (): FormFieldAdaptor<string>=>{
       this.valueAdaptor = new StringFieldAdaptor(message_from_student,this.setValue,this.value);
       return this.valueAdaptor;
    };

    createFormAdaptor(): IFormAdaptor {
        return new FormAdaptor([this.initValueAdaptor()],this);
    }

    getType(): DataType {
        return DataType.SaveClassroomMessage;
    }
}

export class SaveHomeworkRequest extends SimpleRequestData implements ISaveClassroomValueRequest{
    id: number;
    value: string;
    adaptor : IFormAdaptor;
    onDataChanged ?: (adaptor :IFormAdaptor) => void;

    constructor(model : Classroom) {
        super();
        this.fields = ['id','value'];
        this.id = model.id;
        this.value = model.myMessage;
        this.adaptor = this.createFormAdaptor();
    }

    getType(): DataType {
        return DataType.SaveClassroomMessage;
    }

    createFormAdaptor(): IFormAdaptor {
        return new FormAdaptor([],this);
    }
}
