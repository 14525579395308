import {Action} from "redux";
import {AuthResponse} from "../api/AuthResponse";

export enum UserActionType {
    ReceiveToken = "Receive token",
    LogOut = "Log out",
    SetCredentials = "Set username and password",
    SetUsername = "Set username",
    SetPassword = "Set password",
    ReceiveAuth = "Receive auth data"
}

export interface ReceiveTokenAction extends Action <UserActionType>{
    token : string;
}

export interface SetCredentialsAction extends Action <UserActionType>{
    username : string;
    password : string;
}
export interface SetUsernameAction extends Action <UserActionType>{
    username : string;
}

export interface SetPasswordAction extends Action <UserActionType>{
    password : string;
}

export interface ReceiveAuthAction extends Action <UserActionType>{
    auth : AuthResponse;
}

export type UserAction = Action <UserActionType> | ReceiveTokenAction | SetCredentialsAction | SetUsernameAction | SetPasswordAction |
    ReceiveAuthAction;

export function logout() : UserAction{
    return {type : UserActionType.LogOut}
}

export function setUsername(username :string) : UserAction {
    return {
        type : UserActionType.SetUsername,
        username
    }
}

export function setPassword(password : string) : UserAction {
    return {
        type : UserActionType.SetPassword,
        password
    }
}

export function receiveAuth(auth : AuthResponse) : UserAction {
    return {
        type : UserActionType.ReceiveAuth,
        auth
    }
}