import { ThunkAction } from "redux-thunk";
import {AppState} from "../store/configureStore";
import {Action} from "redux";
import {closeInstrument} from "../actions/instruments";
import {noException} from "../actions/error";
import {logout} from "../actions/user";
import {firebaseLogout} from "../service/Firebase";
import {STORED_AUTH} from "../service/Literals";

// eslint-disable-next-line import/no-anonymous-default-export
export default(): ThunkAction<void, AppState, null, Action> =>  dispatch => {
    localStorage.removeItem(STORED_AUTH);
    dispatch(logout());
    dispatch(closeInstrument());
    firebaseLogout();
    dispatch(noException());
}