import React, {FC} from "react";
import {Dialog, Intent} from "@blueprintjs/core";
import {
 register_link
} from "../../text/Literals";
import {DataType} from "../../actions/data";
import {Method} from "../../model/Request";
import {TokenRequestForm} from "../forms/PostRequestForm";
import {Txt} from "../tools/Text";
import {SchoolRegisterRequest} from "../../api/School";
import {LoggedInWithEmailProps} from "./EmailLoginInstruments";


export const SchoolRegisterForm : FC<LoggedInWithEmailProps> = props => {
        if (!props.email) return null;
        return <Dialog
            transitionDuration={200}
            isOpen={true}
            canEscapeKeyClose={false}
            canOutsideClickClose={false}
            className="login-container"
            backdropClassName='login-background'
        >
            {props.exception}
            <div className="bp3-dialog-body">
                <TokenRequestForm
                    default={true}
                    intent={Intent.PRIMARY}
                    url= {DataType.SchoolApply}
                    label={new Txt().dict(register_link)}
                    type={DataType.SchoolApply}
                    help={''}
                    data={new SchoolRegisterRequest(props.email)}
                    method={Method.PUT}
                    token = {props.token!}
                    buttonText={register_link}
                    ok ={props.onSuccess}
                    cancel={props.reset}
                />
            </div>
        </Dialog>

}
