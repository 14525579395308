import React, {PureComponent} from "react";
import {FirebaseMessageContent} from "../../model/common/FirebaseMessage";
import {Button, Card, Elevation, Icon, Intent, Pre} from "@blueprintjs/core";
import {AppState} from "../../store/configureStore";
import {connect} from "react-redux";
import {Vocabulary} from "../../model/AppData";
import {
    course_label, do_answer,
    lesson_caption, teachers_answer
} from "../../text/Literals";
import {User} from "../../model/User";
import {Course, getCourseName} from "../../model/courses/Course";
import {AnswerMessageRequest} from "../../model/courses/AnswerMessageRequest";
import PostRequestForm from "../forms/PostRequestForm";
import {getDisplayDateTime} from "../../service/TimeService";

interface MessageViewerProps {
    content : FirebaseMessageContent
    className ?: string;
    vocabulary : Vocabulary;
    user : User;
    embedded ?: boolean;
}

interface MessageViewerState {
    expanded :boolean;
}

class MessageViewer extends PureComponent<MessageViewerProps,MessageViewerState>  {

    constructor(props: Readonly<MessageViewerProps>) {
        super(props);
        this.state={
            expanded : false
        }
    }

    getLessonCation=(): string=> {
        if (!this.props.content.topic) return "";
        const course: Course = new Course(this.props.user.getCourseById(this.props.content.topic));
        const lesson = course.contentData.findLessonById(this.props.content.lesson);
        return lesson ? ", " + this.props.vocabulary[lesson_caption] + ": " + lesson.topic : ""
    };

    handleExpand=(expanded : boolean)=>{
        this.setState({expanded});
    };

    render() {
        const content = this.props.content;
        const isTeacher = content.topic && this.props.user.getTeachersCourseById(content.topic);
        const courseName = content.topic ? getCourseName(this.props.user.getCourseById(content.topic)) : '';
        const request: AnswerMessageRequest | undefined = isTeacher && content.id && content.topic ?
            new AnswerMessageRequest(content.topic, content.lesson, content.answer, content.id) : undefined;
        return <Card interactive={true} elevation={Elevation.FOUR}
                     className={this.props.className ? this.props.className + ' tiles-container-space' : ' tiles-container-space'}>
            <div className='tiles-item  middle-back inline'>
                {this.props.embedded ? null :<Pre>
                    <Icon icon='manual' intent={Intent.SUCCESS} className='tiny-margin'/>
                    {this.props.vocabulary[course_label] + ": " + courseName+ this.getLessonCation()}
                </Pre> }
                <Pre>
                    <Icon icon='user' intent={Intent.PRIMARY} className='tiny-margin'/>
                    {content.user}
                </Pre>
                {  content.time ?
                    <Pre>
                        <Icon icon='time' intent={Intent.PRIMARY} className='tiny-margin'/>
                        {getDisplayDateTime(content.time)}
                    </Pre> : null
                }
            </div>
            <Pre className={(request && !this.state.expanded ?'tiles-item-last ' : 'tiles-item-row ') + (content.new ? 'message-new' : 'message-red')}>
                                        {content.content}
            </Pre>
            {request && !this.state.expanded ?
                <Button className='tiles-item-flex' intent={Intent.SUCCESS} minimal={true} icon='key-enter'
                        onClick={() => {
                            this.handleExpand(true)
                        }}>
                    {this.props.vocabulary[do_answer]}
                </Button> : null
            }
            {request && this.state.expanded ?
                <div className='tiles-item-row'>
                    <PostRequestForm
                        default={true}
                        intent={Intent.SUCCESS}
                        url={''}
                        type={request.getType()}
                        data={request}
                        method={request.getMethod()}
                        buttonText={do_answer}
                        level={0}
                        cancel={()=>{
                            this.handleExpand(false);
                        }}
                    />
                </div> : null
            }
            {
                content.answer ? <Pre className='tiles-item-row'>
                    {this.props.vocabulary[teachers_answer]+': '}
                    <span className='message-review'>{content.answer}</span>
                </Pre> :null
            }
        </Card>
    }
}
const mapStateToProps = (state: AppState) => ({
    vocabulary : state.data.vocabulary,
    user : state.user
});

export default connect(mapStateToProps) (MessageViewer);