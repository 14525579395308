import React, {FC, memo} from "react";
import {Button, Callout, Card, Icon, Intent} from "@blueprintjs/core";
import {Elevation} from "@blueprintjs/core/lib/esm/common/elevation";
import {Text, Txt} from "./Text";
import {review_list_label, review_start} from "../../text/Literals";
import {CourseStatuses} from "../../model/courses/CourseReviewStatus";
import {AppState} from "../../store/configureStore";
import {REQUEST} from "../../service/Literals";
import {connect} from "react-redux";
import {sendRequest} from "../../thunks/sendRequest";
import {Method, Request} from "../../model/Request";
import {DataType} from "../../actions/data";
import {clearData} from "../../actions/data";

interface ReviewPanelProps {
    courseStatuses : CourseStatuses;
    sendRequest : any;
    clearData : any;
    token : string;
}

const ReviewPanel : FC<ReviewPanelProps>= props =>  {
    const requestKeys = Object.keys(props.courseStatuses)
        .filter(key=>props.courseStatuses[key].status === REQUEST && props.courseStatuses[key].name);
    if(requestKeys.length === 0)return null;
    return  <Card interactive={true} elevation={Elevation.TWO} className='base-margin base-width tiles-container-adjustable'>
        <Callout intent={Intent.DANGER} icon={null}>
            <Text text={new Txt().dict(review_list_label)}/>
        </Callout>
            {requestKeys
                .map(key=>
                    <Card interactive={true} elevation={Elevation.THREE} className='tiles-item tiles-container-vertical' key={key}>
                        <div className='tiles-item'>
                            <Icon intent={Intent.DANGER} icon='learning' className='tiles-one-third'/>
                            <Text text={new Txt().txt(props.courseStatuses[key].name)} className='tiles-item-row color-danger'/>
                        </div>
                        <Text text={new Txt().txt(key)} className='tiles-one-third'/>
                        {/*<Text text={new Txt().txt(props.data.owner)} className='tiles-item'/>*/}
                        <Button
                            intent={Intent.DANGER}
                            icon='eye-open'
                            className='tiles-one-third'
                            onClick={()=>{
                                props.sendRequest(new Request(undefined,DataType.StartReview+key,Method.GET,props.token),DataType.StartReview,undefined,()=>{
                                    console.log("review started");
                                })
                            }}
                        >
                            <Text text={new Txt().dict(review_start)}/>
                        </Button>
                    </Card>)}
    </Card>
};

const mapStateToProps = (state : AppState)=>({
    courseStatuses : state.data.courseStatuses,
    token : state.user.token
});

export default connect(mapStateToProps,{sendRequest,clearData})(memo(ReviewPanel))