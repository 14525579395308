import React, {Component} from 'react';
import {connect} from "react-redux";
import {Button, Classes, FormGroup, InputGroup, Intent, MenuItem} from "@blueprintjs/core";
import {DataType} from "../../actions/data";
import {Method, Request} from "../../model/Request";
import {AppState} from "../../store/configureStore";
import RequestButton from "../RequestButton";
import {Vocabulary} from "../../model/AppData";
import {operation_success, send_request} from "../../text/Literals";
import {Select} from "@blueprintjs/select";
import {AppToaster} from "../../service/toaster";

interface RequestFormProps {
    token : string;
    url : string;
    intent ?: Intent;
    label : string;
    help ?: string;
    type : DataType;
    vocabulary : Vocabulary;
    options ?: string[]
    method ?: Method
    onSuccess ?: ()=>void
}

interface OneStringState {
    input : string;
}
const StringSelector = Select.ofType<string>();

class GetRequestForm extends Component<RequestFormProps,OneStringState>{

    constructor(props: Readonly<RequestFormProps>) {
        super(props);
        this.state={
            input : ''
        }
    }

    success = ()=>{
        this.setState({...this.state, input : ''})
        AppToaster.show({
            intent:  Intent.SUCCESS,
            message:  this.props.vocabulary[operation_success],
            icon : 'saved'
        });
        if (this.props.onSuccess){
            this.props.onSuccess()
        }
    }

    getStringSelector=(options : string[])=> {
        const intent = Intent.SUCCESS;

        return <StringSelector
            popoverProps = {{
                popoverClassName : 'select-popover',
                targetTagName : 'div',
                fill : true,
                boundary : 'window'
            }}
            items={options}
            onItemSelect={option =>  this.setState({input:option})}
            filterable={false}
            itemRenderer={(option, {handleClick, modifiers}) =>
                <MenuItem
                    key = {option}
                    text={option}
                    active={modifiers.active}
                    onClick={handleClick}
                    shouldDismissPopover={false}
                />

            }>
            <Button
                intent={intent}
                icon='desktop'
                rightIcon="caret-down"
                text={this.state.input ? this.state.input : this.props.vocabulary[this.props.label]}
            />
        </StringSelector>;
    }

    getRequest=()=>{
        return new Request(undefined,this.props.url + this.state.input,
            this.props.method || Method.GET,this.props.token);
    };
    render(){
        return (
            <div className='bp3-ui-text'>
                <FormGroup
                    intent={this.props.intent}
                    label={this.props.vocabulary[this.props.label]}
                    helperText={this.props.help ? this.props.vocabulary[this.props.help] : undefined}
                    >
                    { this.props.options ?
                        this.getStringSelector(this.props.options) :
                        <InputGroup
                            value={this.state.input}
                            intent={this.props.intent}
                            onChange={(event : React.FormEvent<HTMLInputElement>)=>{
                                this.setState({input:event.currentTarget.value})
                            }}
                    />}
                </FormGroup>
                < div className={Classes.DIALOG_FOOTER_ACTIONS}>
                    <RequestButton
                        disabled={this.state.input === undefined || this.state.input.length === 0}
                        intent={Intent.SUCCESS}
                        type={this.props.type}
                        icon='key-enter'
                        request={this.getRequest()}
                        default={true}
                        onSuccess={this.success}
                    >
                        {this.props.vocabulary[send_request]}
                    </RequestButton>
                </div>
            </div>);
    }
}
const mapStateToProps = (state: AppState) => ({
    token : state.user.token,
    vocabulary : state.data.vocabulary
});
export default connect(
    mapStateToProps
)(GetRequestForm)