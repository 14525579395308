import {configureStore} from "./configureStore";
import {Action} from "redux";
import {IToolsAction} from "../actions/tools";
import {IInstruments, InstrumentType} from "../actions/instruments";
import {IEntity} from "../model/Entity";
import {STORED_INSTRUMENTS} from "../service/Literals";

const store = configureStore();

export function dispatch(action : Action) {
    store.dispatch(action);
}

export function getLanguage() : string {
    return store.getState().settings.language;
}

export default store;

export function dispatchShowFacts() {
    const action : IToolsAction = {
        type : true,
        tool :InstrumentType.FactViewer,
        instrument : store.getState().instruments.type
    };
    dispatch(action);
}

export function dispatchOpenCourse(topic : string| undefined) {
    const entity : IEntity =  {id : topic, label : ''};
    const type = InstrumentType.TeachersCourses;
    const action : IInstruments ={type,entity};
    //console.log(topic);
    localStorage.setItem(STORED_INSTRUMENTS, JSON.stringify({type,entity}));
    dispatch(action);
}