import React, {FC, memo} from 'react';
import {Button, Callout, Card, Divider, Intent, TextArea} from "@blueprintjs/core";
import RequestButton from "../RequestButton";
import {
    ButtonKV,
    ClipBoardKV,
    DownloadKV,
    ImageAdaptorsKV,
    IntentKV,
    isRequestKV,
    KV, LinkKV,
    ObjectKV,
    PlainTextKV,
    RequestButtonKV,
    RequestKV,
    StringArrayKV, TextArrayKV,
    TimestampKV,
    VocabularyTextKV
} from "../../model/KV";
import ImageViewer from "./ImageViewer";
import {Vocabulary} from "../../model/AppData";
import {AppState} from "../../store/configureStore";
import {connect} from "react-redux";
import DownloadButton from "../buttons/DownloadButton";
import {Txt} from "../tools/Text";
import {getDisplayDateTime} from "../../service/TimeService";
import {getHome} from "../../api/ServerAddress";

interface KeyValueViewerProps {
    kv : KV,
    token : string;
    inline  ?: boolean;
    vocabulary : Vocabulary
}

export function getRGBStyle(isPositive : boolean|undefined) :string{
    if(isPositive === undefined) return '';
    return isPositive ? ' kv-color--green ':' kv-color--red ';
}

const KeyValueViewer : FC<KeyValueViewerProps> = props => {
    const isBool : boolean= typeof (props.kv.value) === 'boolean';
    const  isNegative : boolean = (isBool && ! props.kv.value) || !!props.kv.isNegative;
    const baseStyle = props.inline ?' kv--inline':' kv-block';
    let text : HTMLTextAreaElement;
    if (props.kv instanceof ClipBoardKV){
        return (<Callout className={'kv '+baseStyle}>
            <span className={getRGBStyle(!isNegative) +'bp3-text-large '+baseStyle}>{props.vocabulary[props.kv.key] }</span>
            <Divider/>
            <TextArea
                className={'bp3-ui-text ' + baseStyle}
                readOnly={true}
                inputRef={
                    (ref: HTMLTextAreaElement | null) => {
                        if (ref !== null) text = ref;
                    }}
                value={props.kv.value}/>
            <Button
                minimal={true}
                icon='duplicate'
                intent={Intent.PRIMARY}
                onClick={()=>{
                    if(!text) return;
                    text.select();
                    document.execCommand('copy');
                }}
            />
        </Callout>);
    }
    if(props.kv instanceof ObjectKV){
        return (<Card className={'kv '+baseStyle}>
            <span className={getRGBStyle(!isNegative) +'bp3-text-large '+baseStyle}>{props.vocabulary[props.kv.key] }</span>
            <Divider/>
            {props.kv.value.map((kv,index) =><KeyValueViewer kv={kv} token={props.token} key={index} vocabulary={props.vocabulary}/>)}
        </Card>);
    }
    if(props.kv instanceof ImageAdaptorsKV){
        return (
            <Card className={'kv kv-block'}>
                <span className={getRGBStyle(!isNegative) +'bp3-text-large '+baseStyle}>{props.vocabulary[props.kv.key] }</span>
                <Divider/>
                    <div className='tiles-container'>
                        {props.kv.adaptors.map((adaptor,index) =><ImageViewer className='tiles-item' adaptor={adaptor} key={index}/>)}
                    </div>
                <Divider/>
            </Card>
        );
    }
    if (props.kv instanceof LinkKV){
        const linkKV : LinkKV = props.kv as  LinkKV;
        return <Callout className={'kv '+ baseStyle}>
            <a href={getHome() + linkKV.path + linkKV.key} target='_blank' rel="noopener noreferrer" >
                <Button intent={Intent.SUCCESS} className='fill-width'>
                    {linkKV.key}
                </Button>
            </a>

            <Divider/>
            <span className={'bp3-ui-text '+baseStyle}>
                    {linkKV.value}
                </span>
        </Callout>

    }

    if(props.kv instanceof DownloadKV){
        const downloadKv : DownloadKV = props.kv as DownloadKV;
        return (
            <div className='tiles-item-row form--button-container' >
                <DownloadButton url={downloadKv.url} title={new Txt().dict(downloadKv.key)} intent={Intent.SUCCESS}/>
            </div>
        );
    }
    if (props.kv instanceof ButtonKV){
        const buttonKV : ButtonKV = props.kv as ButtonKV;
        return <Callout className={'kv '+ baseStyle}>
            <Button intent={buttonKV.intent} onClick={()=>{buttonKV.action(buttonKV.key)}}>{buttonKV.key}</Button>
            <Divider/>
            <span className={'bp3-ui-text '+baseStyle}>
                    {buttonKV.value}
                </span>
        </Callout>
    }

    if (props.kv instanceof RequestButtonKV){
        const requestButtonKV : RequestButtonKV = props.kv as RequestButtonKV;
        requestButtonKV.request.authorization = props.token
        return <Callout className={'kv '+ baseStyle} intent={requestButtonKV.isNegative ? Intent.PRIMARY : Intent.NONE} icon={null}>
            <RequestButton
                disabled={false}
                intent={requestButtonKV.intent}
                type={requestButtonKV.type}
                request={requestButtonKV.request}
                default={false}
            >
                {requestButtonKV.key}
            </RequestButton>
            <Divider/>
            <span className={'bp3-ui-text '+baseStyle}>
                    {requestButtonKV.value}
                </span>
        </Callout>
    }

    if (isRequestKV(props.kv)) {
        const requestKv : RequestKV = props.kv as RequestKV;
        return (
            <Callout className={'kv '+ baseStyle}>
                {requestKv.key && requestKv.key.length > 0 ?
                    <div>
                    <span
                        className={getRGBStyle(requestKv.isValueAcceptable()) +'bp3-text-large '+baseStyle}>{props.vocabulary[props.kv.key] }</span>
                        <Divider/>
                    </div> : null}
                <RequestButton
                    disabled={false}
                    intent={requestKv.isValueAcceptable() ? Intent.DANGER : Intent.SUCCESS }
                    type={requestKv.request}
                    request={requestKv.generateRequest(props.token)}
                >
                    {props.vocabulary[requestKv.label]}
                </RequestButton>
            </Callout>
        );
    }

    if(isBool ) return (
            <Callout  className={'kv '+baseStyle}>
                <span className={getRGBStyle(!isNegative) +'bp3-text-large '+baseStyle}>{props.vocabulary[props.kv.key] }</span>
            </Callout>);
    if(props.kv instanceof  IntentKV){
        const key =  props.vocabulary[props.kv.key] ;
        const val =  props.vocabulary[props.kv.value] ;
        const kv : IntentKV = props.kv as IntentKV;
        return (<Callout className={'kv '+baseStyle} intent={kv.intent} icon='pin'>
            <span className={'bp3-text-large '+baseStyle}>{key}</span>
            <Divider/>
            <span className={'bp3-ui-text '+baseStyle}>
                    {val}
                </span>
        </Callout>);
    }
    if(props.kv instanceof  PlainTextKV){
        const key = props.kv instanceof VocabularyTextKV ? props.vocabulary[props.kv.key] || props.kv.key  : props.kv.key;
        const val = props.kv instanceof VocabularyTextKV ? props.vocabulary[props.kv.value] || props.kv.value : props.kv.value;
        const content = <div>
            <span className={getRGBStyle(!isNegative) +'bp3-text-large '+baseStyle}>{key}</span>
            <Divider/>
            <span className={'bp3-ui-text '+baseStyle}>
                    {val}
                </span>
        </div>;
        return <Callout className={'kv '+baseStyle} icon={null} intent={props.kv.isNegative ? Intent.DANGER : Intent.NONE}>
                {content}
            </Callout>
    }
    if (props.kv instanceof  StringArrayKV){
        const kv : StringArrayKV = props.kv as StringArrayKV;
        const key =  props.vocabulary[props.kv.key];
        const itemStyle = kv instanceof TextArrayKV ? 'tiles-item-row' : 'tiles-item-flex'
        return (<Callout className={'kv tiles-container '+baseStyle} intent={Intent.NONE} icon={null}  title={key}>
            <Divider/>
            {kv.value.map(v => <Button minimal={false} key={v} intent={Intent.PRIMARY} className={itemStyle}>{v}</Button>)}
        </Callout>);
    }

    if(props.kv instanceof  TimestampKV){
        const kv : TimestampKV = props.kv as TimestampKV;
        const key =  props.vocabulary[props.kv.key] ;
        const val =  getDisplayDateTime(kv.value);
        return (<Callout className={'kv '+baseStyle} intent={Intent.PRIMARY} icon='time' >
            <span className={'bp3-text-large '+baseStyle}>{key}</span>
            <Divider/>
            <span className={'bp3-ui-text '+baseStyle}>
                    {val}
                </span>
        </Callout>);
    }
    return (<Callout className={'kv '+baseStyle}>
                <span className={getRGBStyle(!isNegative) +'bp3-text-large '+baseStyle}>{props.vocabulary[props.kv.key] }</span>
                <Divider/>
                <span className={'bp3-ui-text '+baseStyle}>
                    {props.kv.value}
                </span>
             </Callout>);
};
const mapStateToProps = (state: AppState) => ({
    vocabulary : state.data.vocabulary
});
export default connect (mapStateToProps) (memo(KeyValueViewer));
