import React,{Component} from 'react';
import {Fact, getImageAdaptor, getPickImageAdaptor, getSpeech} from "../../api/course/Fact";
import {connect} from "react-redux";
import {AppState} from "../../store/configureStore";
import ImageViewer from "../viewers/ImageViewer";
import {AudioPlayer} from "./AudioPlayer";
import {Card, Elevation, Intent} from "@blueprintjs/core";
import {ImagePicker} from "./ImagePicker";

interface FactPlayerProps {
    facts ?: Fact[];
}

interface FactPlayerState {
    position : number;
}

class FactPlayer extends Component<FactPlayerProps,FactPlayerState>{

    constructor(props: Readonly<FactPlayerProps>) {
        super(props);
        this.state = {
            position : 0
        }
    }
    handlePick=(position : number)=>{
        this.setState({position});
    };
    render(){
        console.log(this.props.facts);
        return this.props.facts === undefined ? null :
            <div >
                <ImageViewer className='base-margin' adaptor={getImageAdaptor(this.props.facts[this.state.position])}/>
                <AudioPlayer url={getSpeech(this.props.facts[this.state.position])}/>
                <Card interactive={false} elevation={Elevation.THREE} className='instruments-content-x'>
                    <ImagePicker intent={Intent.SUCCESS} pick={this.handlePick} adaptors={this.props.facts.map(fact=>getPickImageAdaptor(fact))}/>
                </Card>
            </div>
    }
}

const mapStateToProps=(state :AppState)=>({
   facts : state.data.facts
});

export default connect(mapStateToProps)(FactPlayer);
