import React,{FC} from "react";
import {ImageSize} from "../../adaptors/ImageAdaptor";
import {getVMin, isPortrait} from "../../service/SystemInfo";
import {IndexedSVG} from "../tools/ScaledSvg";

interface FlagSVGProps {
    id ?: string;
    size ?: ImageSize;
    alt ?: string;
    onClick ?: ()=>void;
}

export const FlagSVG :FC<FlagSVGProps> = props => {
    const vMin = getVMin();
    const portrait = isPortrait();
    const svgProps={
        width : 14.6,
        height : 14.6,
        onClick : props.onClick === undefined ? ()=>{} : props.onClick,
        alt : props.alt
    };
    switch (props.size) {
        default:
        case undefined:
        case ImageSize.Normal:
            svgProps.width = portrait ? 14.6 : 9.6;
            svgProps.height = portrait ? 14.6 : 9.6;
        break;
        case ImageSize.Medium:
            svgProps.width = portrait ? 29.2  : 19.2;
            svgProps.height = portrait ? 29.2 : 19.2;
            break;
        case ImageSize.Large:
            svgProps.width = portrait ? 39.2 : 29.2;
            svgProps.height = portrait ? 39.2 : 29.2;
            break;
        case ImageSize.Small:
            svgProps.width = portrait ? 9.6 : 6.7;
            svgProps.height = portrait ? 9.6 : 6.7;
            break;
        case ImageSize.Tiny:
            svgProps.width = portrait ? 4.8 : 3.8;
            svgProps.height = portrait ? 4.8 : 3.8;
            break;
        case ImageSize.XTiny:
            svgProps.width = portrait ? 3.5 : 2.5;
            svgProps.height = portrait ? 3.5 : 2.5;
            break;
    }
    svgProps.width *=vMin;
    svgProps.height *=vMin;
    return <IndexedSVG svgProps={svgProps} id={props.id}/>;
};
