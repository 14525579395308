import {Action} from "redux";
import {Method, Request} from "../model/Request";
import {DataType} from "./data";
import {Entity, IEntity} from "../model/Entity";
import {FirebaseMessage} from "../model/common/FirebaseMessage";
import {Settings} from "./settings";
import {User} from "../model/User";
import {STORED_INSTRUMENTS} from "../service/Literals";

export enum InstrumentType {
    No = "No instrument",
    PlayerViewer = "Player Viewer",
    DlcEditor = "Dlc Editor",
    SchoolViewer = 'School Viewer',
    ManagerAdminViewer = 'ManagerAdminViewer',
    PersonManagerViewer = 'PersonManagerViewer',
    SchoolUser = 'School User Tool',
    SchoolRegister = 'School self registration tool',
    TeachersCabinet = 'Teachers Cabinet',
    ClassRoom = 'Class room',
    FactViewer='Fact viewer',
    TeachersCourses = 'Teachers Courses',
    StudentsCourses = 'Students Courses',
    Downloads ='Downloads',
    Notifications = 'Notifications',
    ReviewRejectWindow = 'Reject Review Window',
    CourseReview = 'Course review',
    Analytics = 'Analytics',
    FreeCourses = 'Free courses',
    Vitrines = 'Vitrines instrument',
    MySchool = 'My school instrument',
    TrialShd = 'Trial school Drozdov instrument',
    ManagerReg = 'ManagerRegistration',
    Hardware = 'Hardware info instrument',
    Sensors = 'Sensor usage instrument',
    /*
        Content
    */
    Images = 'Images',
    Sounds = 'Sounds',
    /*
      Tools
     */
    UploadDialog = 'Upload dialog',
    TemplateEditor = 'Template editor',
    ReviewWindow = 'Review Window',
    DeleteCourseTool = 'Delete course window',
    SchoolMail = 'School email form'
}

export interface IInstruments extends Action<InstrumentType>{
    entity : IEntity;
}

export class Instruments implements IInstruments{
    private readonly _type: InstrumentType;
    private readonly _entity : Entity;

    constructor(type: InstrumentType, entity: IEntity|undefined = undefined) {
        this._type = type;
        this._entity =new Entity(entity);
    }

    get type(): InstrumentType {
        return this._type;
    }

    get entity(): Entity{
        return this._entity;
    }

    matches(instrument: InstrumentType): boolean {
        return this._type === instrument || instrument === InstrumentType.No;
    }
}

export function useInstrument(type : InstrumentType, e: Entity|undefined = undefined) : IInstruments {
    const entity : IEntity = e === undefined ? {id : undefined, label :''} : {id : e.id, label : e.label, data : e.data};
    localStorage.setItem(STORED_INSTRUMENTS, JSON.stringify({type,entity}));
    return {type,entity};
}

export function closeInstrument() :IInstruments {
    localStorage.removeItem(STORED_INSTRUMENTS);
    return {
        type : InstrumentType.No,
        entity : {
            id : undefined,
            label : ''
        }
    }
}
export enum InstrumentActionType {
    GetRequest,
    GetDownloads
}

export class InstrumentDataAction {
    instrumentType : InstrumentType;
    dataType : DataType[];
    pathVar ?: string;
    sendLanguage : boolean;
    actionType : InstrumentActionType;
    firebaseMessages : FirebaseMessage[];

    constructor(instrumentType: InstrumentType,entity: Entity|undefined = undefined, settings : Settings, user : User) {
        this.instrumentType = instrumentType;
        this.sendLanguage = false;
        this.firebaseMessages = [];
        this.dataType =[];
        switch (instrumentType) {
            case InstrumentType.PlayerViewer:
                this.dataType.push(DataType.AllDlc);
                this.actionType = InstrumentActionType.GetRequest;
                break;
            case InstrumentType.DlcEditor:
                this.dataType.push(DataType.Dlc);
                this.dataType.push(DataType.Software);
                this.actionType = InstrumentActionType.GetRequest;
                break;
            case InstrumentType.SchoolViewer:
                this.dataType.push(DataType.SchoolHardwareTags);
                this.dataType.push(DataType.SoftwareLicenses);
                this.actionType = InstrumentActionType.GetRequest;
                break;
            case InstrumentType.ManagerAdminViewer :
                this.dataType.push(DataType.Partners);
                this.actionType = InstrumentActionType.GetRequest;
                break;
            case InstrumentType.PersonManagerViewer :
                this.dataType.push(DataType.Persons);
                this.actionType = InstrumentActionType.GetRequest;
                break;
            case InstrumentType.TeachersCourses:
                this.dataType.push(DataType.GetTeachersLinks);
                this.actionType = InstrumentActionType.GetRequest;
                break;
            case InstrumentType.TeachersCabinet:
                this.dataType.push(DataType.FactTags);
                this.actionType = InstrumentActionType.GetRequest;
                break;
            case InstrumentType.ClassRoom:
                this.dataType.push(DataType.Facts);
                this.pathVar= entity!.idAsNumber()+'';
                this.sendLanguage = true;
                this.actionType = InstrumentActionType.GetRequest;
                break;
            case InstrumentType.StudentsCourses:
                const courseId = entity!.idAsString();
                this.firebaseMessages.push(FirebaseMessage.getMyHomework(courseId));
                const pos = settings.coursesPositions[courseId];
                if(pos){
                    this.firebaseMessages.push(FirebaseMessage.getMyLessonMessages(courseId,pos.uuid));
                }
                this.dataType.push(DataType.GetCourseLinks);
                this.pathVar= entity!.idAsString();
                this.sendLanguage = false;
                this.actionType = InstrumentActionType.GetRequest;
                break;
            case InstrumentType.CourseReview:
                this.dataType.push(DataType.GetReviewLinks);
                this.pathVar= entity!.idAsString();
                this.sendLanguage = false;
                this.actionType = InstrumentActionType.GetRequest;
                break;
            case InstrumentType.Notifications:
                this.actionType=InstrumentActionType.GetRequest;
                break;
            case InstrumentType.Analytics:
            case InstrumentType.Sensors:
            case InstrumentType.Hardware:
                this.actionType=InstrumentActionType.GetRequest;
                this.dataType.push(DataType.Software);
                break;
            case InstrumentType.Downloads:
                this.dataType.push(DataType.Downloads);
                this.actionType = InstrumentActionType.GetDownloads;
                break;
            case InstrumentType.FreeCourses:
                this.dataType.push(DataType.FreeCourses);
                this.actionType=InstrumentActionType.GetRequest;
                break;
            case InstrumentType.SchoolUser:
                this.dataType.push(DataType.SchoolUser);
                this.dataType.push(DataType.SchoolHardwareTags);
                this.dataType.push(DataType.SoftwareLicenses);
                this.dataType.push(DataType.AllDlc);
                this.pathVar = entity!.idAsString();
                this.actionType=InstrumentActionType.GetRequest;
                break;
            case InstrumentType.Vitrines:
                this.dataType.push(DataType.Vitrines);
                this.pathVar = '/default';
                this.actionType=InstrumentActionType.GetRequest;
                break;
            case InstrumentType.MySchool:
                this.dataType.push(DataType.MySchool);
                this.actionType=InstrumentActionType.GetRequest;
                break;
            case InstrumentType.Images:
            case InstrumentType.Sounds:
                this.dataType.push(DataType.ImagesTags);
                this.actionType = InstrumentActionType.GetRequest;
                break;
            default:
                this.actionType=InstrumentActionType.GetRequest;
        }
    }

    getRequest(token: string, language : string):Request []{
        return this.dataType.map((type, index) =>
            new Request(undefined,
                index === 0 ? this.getUrl(language, type) : this.getBlankUrl(language, type) ,
                Method.GET,
                token));
    }

    getUrl= (language: string, dataType: DataType) :string=>{
        const  res : string = this.pathVar === undefined ? dataType : dataType + this.pathVar;
        return this.sendLanguage ? res +'/'+language: res;
    }

    getBlankUrl= (language: string, dataType: DataType) :string=>{
        const  res : string = dataType ;
        return this.sendLanguage ? res +'/'+language: res;
    }
}
