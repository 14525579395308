import {DataType} from "../actions/data";
import {Method, Request, StatRequest} from "./Request";
import {IImageAdaptor} from "../adaptors/ImageAdaptor";
import {Intent} from "@blueprintjs/core";

export interface KV {
    key: string;
    value: any;
    isNegative?: boolean;
}

export interface IRequestKV extends KV {
    request: DataType,
    label: string
}

export function isRequestKV(kv: KV): boolean {
    return "request" in kv;
}

export class PlainTextKV implements KV{
    key: string;
    value: string;
    isNegative: boolean;

    constructor(key: string, value: string, isNegative: boolean=false) {
        this.key = key;
        this.value = value;
        this.isNegative = isNegative;
    }
}
export class CallOutKV extends PlainTextKV{

}

export class VocabularyTextKV extends PlainTextKV{

}

export class VocabularyCallOutKV extends CallOutKV{

}

export class IntentKV extends VocabularyTextKV{
    intent: Intent;

    constructor(key: string, value: string,  intent:Intent) {
        super(key, value, false);
        this.intent = intent;
    }
}

export class ButtonKV extends PlainTextKV{
    intent : Intent;
    action : (key :string)=>void

    constructor(key: string, value: string, intent: Intent, action: (key: string) => void) {
        super(key, value, false);
        this.intent = intent;
        this.action = action;
    }
}

export class RequestButtonKV extends PlainTextKV{
    intent : Intent;
    request : Request;
    type : DataType;

    constructor(key: string, value: string, intent: Intent, request : Request, type: DataType,selected : boolean) {
        super(key, value, selected);
        this.intent = intent;
        this.request = request;
        this.type = type;
    }
}

export class LinkKV extends PlainTextKV{
    path : string
    constructor(key: string, value: string, path: string) {
        super(key, value, false);
        this.path = path;
    }
}

export class TimestampKV implements KV{
    key: string;
    value: number;
    isNegative: boolean;

    constructor(key: string, value: number, isNegative: boolean=false) {
        this.key = key;
        this.value = value;
        this.isNegative = isNegative;
    }
}


export class RequestKV implements IRequestKV {
    protected readonly _key: string;
    protected readonly _value: any;
    protected readonly _request: DataType;
    protected readonly _label: string;
    protected readonly _additionalValues: string [];
    protected readonly _method: Method;
    isNegative ?: boolean;

    constructor(key: string, value: any, request: DataType, label: string, additionalValues ?: string[],
                method: Method = Method.GET, isNegative: boolean | undefined = undefined) {
        this._key = key;
        this._value = value;
        this._request = request;
        this._label = label;
        this._additionalValues = additionalValues === undefined ? [] : additionalValues;
        this._method = method;
        this.isNegative = isNegative;
    }

    addValue(val: string): void {
        this._additionalValues.push(val);
    }

    protected jointValues(): string {
        return this._additionalValues.length === 0 ? '' : this._additionalValues.join('/');
    }

    generateRequest(auth: string, message ?: string): Request {
        return new Request(message, this.request + this.jointValues(), this._method, auth);
    }

    isValueAcceptable(): boolean {
        if (typeof (this.value) === 'boolean') return this.value;
        return this.isNegative === undefined || !this.isNegative;
    }

    get key(): string {
        return this._key;
    }

    get value(): any {
        return this._value;
    }

    get request(): DataType {
        return this._request;
    }

    get label(): string {
        return this._label;
    }
}

export class ValidatedRequestKv extends RequestKV {
    private readonly validator: (val: any) => boolean;

    constructor(key: string, value: any, request: DataType, label: string, validator: (val: any) => boolean, additionalValues ?: string[]) {
        super(key, value, request, label, additionalValues);
        this.validator = validator;
    }

    isValueAcceptable(): boolean {
        return this.validator(this.value);
    }
}

export class StatRequestKV extends RequestKV{
    generateRequest(auth: string, message ?: string): Request {
        return new StatRequest(message, this.request + this.jointValues(), this._method, auth);
    }
}

export class DownloadKV implements KV{
    url : string;
    isNegative: boolean;
    key: string;
    value: any;

    constructor(url: string, key: string) {
        this.url = url;
        this.key = key;
        this.isNegative = false;
    }
}

export class SaveKV implements KV {
    key: string;
    value: any;
    onSave: (value: any) => void;
    isNegative: boolean;

    constructor(key: string, value: any, onSave: (value: any) => void, isNegative: boolean = false) {
        this.key = key;
        this.value = value;
        this.isNegative = isNegative;
        this.onSave = onSave;
    }

    isValueAcceptable(): boolean {
        if (typeof (this.value) === 'boolean') return this.value;
        return this.isNegative === undefined || !this.isNegative;
    }

    save = (value: any) => {
        this.onSave(value);
    }
}

export class ClipBoardKV implements KV {
    isNegative: boolean;
    key: string;
    value: any;

    constructor(key: string, value: any) {
        this.key = key;
        this.value = value;
        this.isNegative = false;
    }
}

export class ObjectKV implements KV {
    key: string;
    isNegative: boolean;
    value: KV[];

    constructor(key: string, value: KV[], isNegative: boolean = false) {
        this.key = key;
        this.isNegative = isNegative;
        this.value = value;
    }
}

export class ArrayObjectKV implements KV {
    key: string;
    isNegative: boolean;
    value: KV[][];

    constructor(key: string, value: KV[][], isNegative: boolean = false) {
        this.key = key;
        this.isNegative = isNegative;
        this.value = value;
    }
}

export class StringArrayKV implements KV{
    key: string;
    value: string[];
    label : string;
    isNegative: boolean;


    constructor(key: string, value: string[], label: string|undefined=undefined, isNegative: boolean = false) {
        this.key = key;
        this.value = value;
        this.label = label === undefined ? key : label;
        this.isNegative = isNegative;
    }
}

export class TextArrayKV extends StringArrayKV{

}

export class LinksKeysKV extends StringArrayKV{
    prefix : string;
    extension : string;
    protected _links : string[];

    constructor(key: string, value: string[], prefix: string, extension: string=PNG, label: string|undefined=undefined, isNegative: boolean=false) {
        super(key, value, label, isNegative);
        this.prefix = prefix;
        this.extension = extension;
        this._links = value.map(link=>prefix+link+extension);
    }

    get links(): string[] {
        return this._links;
    }
}

export class ImageAdaptorsKV extends StringArrayKV{
    mapper : (val :string) =>IImageAdaptor;
    protected _adaptors : IImageAdaptor [];


    constructor(key: string, value: string[], mapper: (val: string) => IImageAdaptor, label: string | undefined = undefined, isNegative: boolean = false) {
        super(key, value, label, isNegative);
        this.mapper = mapper;
        this._adaptors = value.map(mapper);
    }


    get adaptors(): IImageAdaptor[] {
        this._adaptors = this.value.map(this.mapper);
        return this._adaptors;
    }
}

export const PNG='.png';