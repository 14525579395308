import {NoProps} from "./SchoolRegisterInstrument";
import React, {FC} from "react";
import {
    confirm_password, new_password, register_label, register_manager
} from "../../text/Literals";
import {Callout, Dialog, Intent,} from "@blueprintjs/core";
import {DataType} from "../../actions/data";
import {Txt} from "../tools/Text";
import {Method} from "../../model/Request";
import {TokenRequestForm} from "../forms/PostRequestForm";
import {RequestData} from "../../adaptors/RequestData";
import {FormAdaptor, IFormAdaptor} from "../../adaptors/FormAdaptor";
import {FormFieldAdaptor, NotEmptyStringFiled} from "../../adaptors/FieldAdaptor";
import ManagerRegTokenInstrument, {ManagerRegToken} from "./ManagerRegTokenInstrument";

const ManagerRegInstrument : FC<NoProps> = () => {
    return <ManagerRegTokenInstrument
        content = { ManagerReg }
        title = { register_manager }
    />
}

class ManagerAuthRequest implements RequestData {
    password : string;
    confirmPassword : string;
    token ?: string;
    adaptor : FormAdaptor;
    passwordAdaptor ?: FormFieldAdaptor<string>;
    passwordConfirmAdaptor ?: FormFieldAdaptor<string>;
    onDataChanged ?: (adaptor: IFormAdaptor) => void;
    wasChanged() : boolean {
        return !this.isPasswordNotMatched();
    }

    isPasswordNotMatched() : boolean {
        return this.password.length < 5 || this.password.length > 16 || this.password !== this.confirmPassword;
    }

    connect(connector : (adaptor : IFormAdaptor) => void) : void {
        this.onDataChanged = connector;
    }

    getFormAdaptor() : IFormAdaptor {
        return this.adaptor;
    }

    getMethod(): Method {
        return Method.POST;
    }

    getType(): DataType {
        return DataType.Partners;
    }

    message() : string {
        return JSON.stringify(this,['token','password']);
    }

    constructor(token : string | undefined) {
        this.token = token;
        this.password = '';
        this.confirmPassword = '';
        this.adaptor = this.createFormAdaptor();
    }

    initPasswordAdaptor = () => {
        this.passwordAdaptor = new NotEmptyStringFiled(new_password, this.setPassword, '', this.password);
        return this.passwordAdaptor;
    };

    setPassword = (value : string) => {
        this.password = value;
        this.onDataChanged!(new FormAdaptor(
            [
                this.initPasswordAdaptor(),
                this.passwordConfirmAdaptor!
            ],
            this
        ));
    }

    initConfirmPasswordAdaptor = () => {
        this.passwordConfirmAdaptor = new NotEmptyStringFiled(confirm_password, this.setConfirmPassword, '', this.confirmPassword);
        return this.passwordConfirmAdaptor;
    };

    setConfirmPassword = (value : string) => {
        this.confirmPassword = value;
        this.onDataChanged!(new FormAdaptor(
            [
                this.passwordAdaptor!,
                this.initConfirmPasswordAdaptor()
            ],
            this
        ));
    }

    createFormAdaptor() : FormAdaptor {
        return new FormAdaptor([
            this.initPasswordAdaptor(),
            this.initConfirmPasswordAdaptor()
        ], this)
    }
}

const ManagerReg : FC<ManagerRegToken> = props => {
    return <Dialog
        transitionDuration={200}
        isOpen={true}
        canEscapeKeyClose={false}
        canOutsideClickClose={false}
        className="login-container-wide"
        backdropClassName='login-background'>
        <div className="bp3-dialog-body" >
            <Callout>
                <TokenRequestForm
                    default = { true }
                    intent = { Intent.PRIMARY }
                    url = { DataType.AuthPartners }
                    label = { new Txt().dict(register_manager).txt(" " ) }
                    type = { DataType.AuthPartners }
                    help = { '' }
                    method = { Method.POST }
                    token = { props.token! }
                    data = { new ManagerAuthRequest(props.token) }
                    buttonText = { register_label }
                    ok = { props.onSuccess }
                />
            </Callout>
        </div>
    </Dialog>
}

export default ManagerRegInstrument;