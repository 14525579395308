export function isPortrait(): boolean {
    return window.matchMedia("(orientation: portrait)").matches;
//    return window.screen.height > window.screen.width; TODO - screen rotation
}
export interface Vector {
    x: number,
    y: number;
}

export function getScreenSize(): Vector {
    return {
        x: window.screen.width,
        y: window.screen.height
    }
}

export function getVMin() : number {
    return isPortrait() ? getScreenSize().x / 100.0 : getScreenSize().y / 100.0;
}

export const BASE_SCREEN_SIZE :Vector = {
    x : 1920,
    y : 1080
};

export function scaleToScreen(vector : Vector) : Vector {
   const screenSize = getScreenSize();
   return {
       x : vector.x * screenSize.x/BASE_SCREEN_SIZE.x,
       y : vector.y * screenSize.x/BASE_SCREEN_SIZE.x
   }
}