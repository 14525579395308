import {EmbeddedObject} from "../EmbededObject";
import {FormAdaptor, IFormAdaptor} from "../../adaptors/FormAdaptor";
import {NotNegativeIntegerField} from "../../adaptors/FieldAdaptor";
import {lessons_quantity, modules_quantity} from "../../text/Literals";

export interface BasicStructure {
    modules : number;
    units : number;
}

export class BasicStructureObject extends EmbeddedObject implements BasicStructure{
    modules : number;
    units : number;

    constructor(model: any) {
        super(model, ['modules','units']);
        if(model){
            this.modules = model.modules;
            this.units = model.units;
        } else {
            this.modules = 1;
            this.units = 1;
            this.model = {modules: this.modules, units : this.units}
        }
        this.adaptor = this.createFormAdaptor();
    }

    setModules = (val: number)=>{
        this.modules = val;
        this.onDataChanged!(this.createFormAdaptor());
    };

    setUnits = (val: number)=>{
        this.units = val;
        this.onDataChanged!(this.createFormAdaptor());
    };

    createFormAdaptor=(): IFormAdaptor =>{
        return new FormAdaptor([
            new NotNegativeIntegerField(modules_quantity,this.setModules,this.modules),
            new NotNegativeIntegerField(lessons_quantity,this.setUnits,this.units)
        ],this);
    };

    expandModel(): void {
        this.modules = this.model.modules;
        this.units = this.model.units;
    }

    updateModel(): void {
        this.model.modules = this.modules;
        this.model.units = this.units;
    }

    wasChanged(): boolean {
        return true;
    }
}