import React, {FC} from 'react';
import {Button, IconName, Intent} from "@blueprintjs/core";
import {MaybeElement} from "@blueprintjs/core/src/common/props";
import {AppState} from "../../store/configureStore";
import {Vocabulary} from "../../model/AppData";
import {connect} from "react-redux";
import {do_activate, do_inactivate, edit_label} from "../../text/Literals";

interface EmbeddedObjectMinimalProps {
    isNegative ?: boolean;
    intent : Intent;
    icon : IconName | MaybeElement;
    text : string;
    handler : ()=>void;
    changer ?: ()=>void;
    remover ?: ()=> void;
    vocabulary : Vocabulary;
}

const EmbeddedObjectMinimal : FC<EmbeddedObjectMinimalProps> = props => {
    return (
        <div className='form-item-row form--button-container'>
            <Button
                className='form-button'
                intent={props.isNegative ? Intent.DANGER : props.intent}
                minimal={true}
                icon={props.icon}
                onClick={props.changer ? props.changer : ()=>{} }
            >
                { props.vocabulary[props.isNegative ? do_activate : do_inactivate]}
            </Button>
            <div className='form-button'>
                <div className="bp3-callout"
                >
                    {props.text}
                </div>
            </div>
            <Button
                className='form-button'
                minimal={true}
                intent={props.intent}
                icon='edit'
                onClick={props.handler}>
                {props.vocabulary[edit_label]}
            </Button>
            {props.remover ? <Button className='form-button' minimal={true} intent={Intent.DANGER} icon='trash' onClick={props.remover}/> : null}
        </div>
    );
};


const mapStateToProps = (state : AppState) =>({ vocabulary : state.data.vocabulary});
export default connect (mapStateToProps) (EmbeddedObjectMinimal);