import React,{FC} from 'react';
import { BrowserRouter, Route, Switch} from 'react-router-dom';
import App from "../../App";


interface LevelRouterProps {

}

const Level1Router : FC<LevelRouterProps> = props => {
    return <BrowserRouter>
            <Switch>
                <Route exact={true} path='/:mode?' component={App}/>
                <Route exact={true} path='/:mode/:instrument' component={App}/>
                <Route path='/:mode/:instrument/:entity' component={App}/>
            </Switch>
        </BrowserRouter>

}

export default Level1Router;