import React, {FC,memo} from 'react';
import {connect} from "react-redux";
import {Instruments, InstrumentType, useInstrument} from "../../actions/instruments";
import {closeTool} from "../../actions/tools";
import {AppState} from "../../store/configureStore";
import {BasicStructure, BasicStructureObject} from "../../model/common/BasicStructureEditor";
import PostRequestForm from "../forms/PostRequestForm";
import {Intent} from "@blueprintjs/core/lib/esm/common/intent";
import {addModules, Course, CourseContent, CourseData} from "../../model/courses/Course";
import {Vocabulary} from "../../model/AppData";
import {add_course_structure, do_add, edit_course_structure} from "../../text/Literals";
import {Txt} from "../tools/Text";

interface CourseTemplateEditorProps {
    structure : BasicStructureObject;
    instruments : Instruments;
    vocabulary : Vocabulary;
    useInstrument : any;
    closeTool : any;
}

const CourseTemplateEditor : FC<CourseTemplateEditorProps> = props => {

    props.structure.onSave = data => {
        const structure : BasicStructure = data as BasicStructure;
        const content : CourseContent = new CourseContent();
        content.modules = [];
        addModules(content,structure.modules,structure.units,props.vocabulary);
        const course : CourseData = {
            id :"",
            isDraft : true,
            content : content.message(),
            draft : content.message()
        };
        console.log(content);
        props.useInstrument(props.instruments.type,{id: props.instruments.entity.id, label : props.instruments.entity.label,data :course})
    };
    function close() {
        props.closeTool(props.instruments.type,InstrumentType.TemplateEditor);
    }
    return  (
            <PostRequestForm
                intent={Intent.PRIMARY}
                default={true}
                url= {""}
                label={new Txt().dict(props.instruments.entity.idAsString() && props.instruments.entity.idAsString().length>0 ? edit_course_structure :add_course_structure)}
                type={props.structure.getType()}
                data={props.structure}
                method={props.structure.getMethod()}
                level={0}
                cancel={close}
                ok={close}
                buttonText={do_add}
            />)
};

const mapStateToProps = (state :AppState)=> {
    let structure: BasicStructure = {
        modules : 1,
        units : 1
    };
    if (state.instruments.entity.data){
        const course: Course = new Course(state.instruments.entity.data);
        structure = {
            modules: course.contentData.modules.length,
            units: course.contentData.modules[0].lessons.length
        };
    }
    return{
        instruments : state.instruments,
        vocabulary : state.data.vocabulary,
        structure : new BasicStructureObject(structure)
    }
};

export default connect(mapStateToProps,{useInstrument,closeTool})(memo(CourseTemplateEditor));

