import {CourseMessageRequest} from "./CourseMessageRequest";
import {FirebaseMessage, FirebaseMessageContent, FirebaseMessageType} from "../common/FirebaseMessage";
import {Method} from "../Request";
import {teachers_message} from "../../text/Literals";

export class AnswerMessageRequest extends CourseMessageRequest{
    id : string;

    constructor(course: string, uuid: string, text: string='', id: string) {
        super(course, uuid, text);
        this.id = id;
        this.isArea =true;
        this.adaptor = this.createFormAdaptor();
    }

    getFirebaseMessage=(): FirebaseMessage =>{
        const messageContent : FirebaseMessageContent= {
            lesson : this.uuid,
            content : this.text,
            type : this.id,
        };
        return new FirebaseMessage(this.course,FirebaseMessageType.MessageAnswer,messageContent, Method.PUT);
    };

    getMessageLabel=():string=>{
        return teachers_message;
    }
}