import React, {FC, memo} from 'react'
import {scaleToScreen, Vector} from "../../service/SystemInfo";
import {ReactComponent as EnglishFlag} from "../../image/flags/English.svg";
import {ReactComponent as FrenchFlag} from "../../image/flags/French.svg";
import {ReactComponent as GermanFlag} from "../../image/flags/German.svg";
import {ReactComponent as JapaneseFlag} from "../../image/flags/Japanese.svg";
import {ReactComponent as PortoFlag} from "../../image/flags/Porto.svg";
import {ReactComponent as RussianFlag} from "../../image/flags/Russian.svg";
import {ReactComponent as ArmenianFlag} from "../../image/flags/Armenian.svg";
import {ReactComponent as Sky} from "../../image/art/sky.svg";
import {ReactComponent as Land} from "../../image/art/fields.svg";
import {ReactComponent as LoginShape} from "../../image/gui/login_shape.svg";

export const ScaledSVG : FC<IndexedSVGProps> =memo( props => {
    let x : number|string|undefined;
    let y : number|string|undefined;

    if(typeof (props.svgProps.width) === 'number' && typeof (props.svgProps.height) === 'number' ) {
        const scaled: Vector = scaleToScreen({x: props.svgProps.width, y: props.svgProps.height});
        x = scaled.x;
        y = scaled.y;
    }else {
        x = props.svgProps.width;
        y = props.svgProps.height;
    }

    return <IndexedSVG svgProps={{
            width : x,
            height : y,
            onClick : props.svgProps.onClick,
            alt : props.svgProps.alt,
            viewBox : props.svgProps.viewBox

        }} id={props.id}/>;
});

export interface SVGProps{
    width ?: number|string
    height ?: number|string
    onClick ?: ()=> void;
    alt ?: string;
    viewBox ?: string;
}

export interface IndexedSVGProps {
    id ?: string;
    svgProps : SVGProps
}

export const IndexedSVG : FC<IndexedSVGProps> = props => {
    switch (props.id) {
        default:
        case undefined:
            return null;
        case 'sky':
            return <Sky {...props.svgProps} />;
        case 'land':
            return <Land {...props.svgProps} />;
        case 'login-shape':
            return <LoginShape {...props.svgProps} />;


        /*
          Flags 4 languages
         */
        case 'EnglishFlag':
            return <EnglishFlag {...props.svgProps} />;
        case 'FrenchFlag':
            return <FrenchFlag {...props.svgProps}/>;
        case 'GermanFlag':
            return <GermanFlag {...props.svgProps}/>;
        case 'JapaneseFlag':
            return <JapaneseFlag {...props.svgProps}/>;
        case 'PortoFlag':
            return <PortoFlag {...props.svgProps}/>;
        case 'ArmenianFlag':
            return <ArmenianFlag {...props.svgProps}/>;
        case 'RussianFlag':
            return <RussianFlag {...props.svgProps}/>;
    }
};