import React,{FC,memo} from "react";
import {connect} from "react-redux";
import {filterMessages, FirebaseMessages} from "../../model/common/FirebaseMessage";
import {AppState} from "../../store/configureStore";
import MessageViewer from "./MessageViewer";

interface MessageBoxProps {
    firebaseMessages : FirebaseMessages;
}

const MessageBox : FC<MessageBoxProps>= props => {
        return <div>{
            filterMessages(props.firebaseMessages,()=>true)
            .map((content,index)=>
                <MessageViewer content={content} key={index} className='form-item-line'/>
            )}
        </div>
};

const mapStateToProps = (state: AppState) => ({
    firebaseMessages : state.data.firebaseMessages
});

export default connect(mapStateToProps)(memo(MessageBox));