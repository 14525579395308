import {
    FormAdaptor,
    IFormAdaptor,
} from "../adaptors/FormAdaptor";
import {ButtonAdaptor,IButtonAdaptor,RequestButtonAdapter} from "../adaptors/ButtonAdaptors";
import {AppData} from "../model/AppData";
import {DataType} from "../actions/data";
import {Intent} from "@blueprintjs/core";
import {Method, Request} from "../model/Request";
import {KV, PlainTextKV} from "../model/KV";
import {SimpleRequestData} from "../adaptors/RequestData";
import {
    EncodedObjectAdaptor,
    FieldAdaptor,
    FormFieldAdaptor,
    NotEmptyStringFiled,
    NotNegativeIntegerField, OptionsDescriptor, StringOptionsFieldAdaptor,
    UniqStringField
} from "../adaptors/FieldAdaptor";
import {
    archived_label, dlc_view_details,
    do_archive,
    do_unarchive, entity_id, included_label,
    included_short_label,
    not_empty_field,
    tier_label
} from "../text/Literals";
import {getFromVocabulary} from "../service/Vocabulary";
import {DlcDescriptor, getDlcDescriptor, IDlcDescriptor} from "./DlcDescriptor";
import {getCourseDescriptor, ICourse} from "../model/courses/Course";
import {decodeObject} from "../service/encoder";
import {CommonEntity} from "./course/Entity";

export interface IDlc {
    id : string;
    included : string | null;
    tier : number;
    archived : boolean;
    content ?: string;
    owner ?: string;
}

export interface IDlcContent{
    name : string;
    description : string;
    image ?: string;
    tags : string[];
}
export interface FreeDlcDescription extends IDlcContent, CommonEntity{

}

export enum DlcContentType {
    UserCourse= 'user created course',
    InGamePurchase ='in game dlc description'
}

export type DlcContent = IDlcDescriptor | ICourse;

export function parseContent(encoded : string) :DlcContent {
    return decodeObject(encoded);
}

export class Dlc extends SimpleRequestData implements IDlc{
    id : string;
    included : string | null;
    tier : number;
    archived : boolean;
    adaptor : IFormAdaptor;
    includedAdaptor ?: FormFieldAdaptor<string>;
    idAdaptor ?: NotEmptyStringFiled;
    tierAdaptor ?: NotNegativeIntegerField;
    contentAdaptor ?: EncodedObjectAdaptor;
    onDataChanged ?: (adaptor :IFormAdaptor) => void;
    isNew : boolean;
    owner ?: string;
    content ?: string;

    constructor(id: string, included: string|null = null, tier: number = 0,archived : boolean = false,content : string|undefined, owner : string|undefined=undefined) {
        super();
        this.id = id;
        this.included = included;
        this.tier = tier;
        this.archived = archived;
        this.isNew = id.length === 0;
        this.owner = owner;
        this.content = content;

        this.adaptor = this.createFormAdaptor();
    }

    createFormAdaptor=() : IFormAdaptor =>{
        return new FormAdaptor([
            this.initIdAdaptor() ,
            this.initIncludeAdaptor(),
            this.initTierAdaptor(),
            this.initContentAdaptor()
          ],this);
    };

    setId = (val : string): void =>{
        this.id = val;
        this.onDataChanged!(new FormAdaptor([
            this.initIdAdaptor(),
            this.includedAdaptor!,
            this.tierAdaptor!,
            this.contentAdaptor!
        ],this))
    };

    setTier = (val : number): void =>{
        this.tier = val;
        this.onDataChanged!(new FormAdaptor([
            this.idAdaptor!,
            this.includedAdaptor!,
            this.initTierAdaptor(),
            this.contentAdaptor!
        ],this))
    };

    setIncluded = (val : string): void =>{
        this.included = val.length === 0 ? null : val;
        this.onDataChanged!(new FormAdaptor([
            this.idAdaptor!,
            this.initIncludeAdaptor(),
            this.tierAdaptor!,
            this.contentAdaptor!
        ],this))
    };

    setContent = (val : string): void=>{
        this.content = val;
        this.onDataChanged!(new FormAdaptor([
            this.idAdaptor!,
            this.includedAdaptor!,
            this.tierAdaptor!,
            this.initContentAdaptor()
        ],this))
    };

    private initTierAdaptor() : FieldAdaptor{
        this.tierAdaptor =     new NotNegativeIntegerField(tier_label, this.setTier,this.tier);
        return this.tierAdaptor;
    }

    private initIdAdaptor() : FieldAdaptor{
        this.idAdaptor =  new UniqStringField(entity_id,this.setId,not_empty_field,this.id,Dlc.filterValues,!this.isNew);
        return this.idAdaptor;
    }

    private initIncludeAdaptor() : FieldAdaptor {
        this.includedAdaptor = new  StringOptionsFieldAdaptor(
            included_label,
            this.setIncluded,
            this.included === null ? undefined : this.included,
            Dlc.filterValues,
            true);
        return this.includedAdaptor;
    }

    private initContentAdaptor() {
        this.contentAdaptor = new EncodedObjectAdaptor(dlc_view_details,this.setContent,this.content,DlcDescriptor.convert,'id-number',true);
        return this.contentAdaptor;
    }

    static filterValues(appData : AppData) : string[]{
        return appData.dlc === undefined ? []: appData.dlc.map(dlc =>dlc.id);
    }

    static filterCustomValues(appData : AppData) : string[]{
        return appData.customDlc === undefined ? []: appData.customDlc.map(dlc =>dlc.id);
    }

    static filterSoftware(appData : AppData) : string[]{
        return appData.software === undefined ? []: appData.software.map(soft => soft.product);
    }

    static getDlcContentDescriptors (data : AppData) : OptionsDescriptor{
        return Dlc.getDlcDescriptors(data.customDlc , true);
    }
    static getDlcPurchaseDescriptors (data : AppData) : OptionsDescriptor{
        return Dlc.getDlcDescriptors(data.dlc);
    }
     static getDlcDescriptors (data :IDlc[]|undefined, isCustom : boolean | undefined = undefined) : OptionsDescriptor{
        const res : OptionsDescriptor ={};
        if (data === undefined) return res;
        data.forEach(dlc=>{
            if(!dlc.content) return;
            const content :DlcContent = parseContent(dlc.content);
            res[dlc.id] = {
                alias : content.name,
                views : isCustom ?
                    getCourseDescriptor(content as ICourse ,dlc.owner!):
                    getDlcDescriptor(content),
                isCustom : !!isCustom
            };
        });
        return res;
    }

    message=(): string=> {
        return JSON.stringify(this,['id','included','tier','archived','owner','content']);
    };

    getFormAdaptor(): IFormAdaptor {
        return this.adaptor;
    }

    getMethod(): Method {
        return this.isNew ? Method.POST : Method.PUT;
    }

    getType(): DataType {
        return DataType.Dlc;
    }
}

export function getDlcKeyValueList(dlc : IDlc): KV[]{
    const included = dlc.included ? '\n'+ getFromVocabulary(included_short_label)  +' ' +dlc.included : '';
    return [
        new PlainTextKV( dlc.id+(dlc.archived ? getFromVocabulary(archived_label) :''),
            getFromVocabulary(tier_label) + ' ' + dlc.tier + included, dlc.archived),
    ];
}

export function getDlcButtonAdapters(dlc : IDlc, clickHandle :(dlc : IDlc)=>void) : IButtonAdaptor[] {
    const archiveButton : IButtonAdaptor = dlc.archived ?
        new RequestButtonAdapter(
         do_unarchive,
         Intent.PRIMARY,
        token => new Request(undefined,DataType.UnarchiveDlc+dlc.id,Method.GET,token),
            DataType.UnarchiveDlc) :
        new RequestButtonAdapter(
            do_archive,
            Intent.NONE,
            token => new Request(undefined,DataType.ArchiveDlc+dlc.id,Method.GET,token),
            DataType.ArchiveDlc);
    return [
        new ButtonAdaptor('',Intent.NONE,()=>{clickHandle(dlc)},'edit'),
        archiveButton,
        new RequestButtonAdapter('',Intent.DANGER,
    token => new Request(undefined,DataType.Dlc+'/'+dlc.id,Method.DELETE,token)
        ,DataType.Dlc,'delete')
    ];
}