import {FormAdaptor, IFormAdaptor,} from "../adaptors/FormAdaptor";
import {ButtonAdaptor, IButtonAdaptor, RequestButtonAdapter} from "../adaptors/ButtonAdaptors";
import {Method, Request} from "../model/Request";
import {Intent} from "@blueprintjs/core";
import {DataType} from "../actions/data";
import {KV, PlainTextKV, StringArrayKV} from "../model/KV";
import {SearchRequest, SimpleRequestData} from "../adaptors/RequestData";
import {
    BooleanFieldAdaptor,
    ExpireTagsAdaptor,
    FieldAdaptor,
    FormFieldAdaptor,
    isEmail,
    LimitedTagsAdaptor,
    NotEmptyStringFiled,
    NotNegativeIntegerField,
    PhoneFieldAdaptor, PositiveIntegerField,
    StringFieldAdaptor,
    StringOptionsFieldAdaptor,
    TagsAdaptor,
    TextFieldAdaptor,
    ValidEmailFieldAdaptor, ValidValueAdaptor
} from "../adaptors/FieldAdaptor";
import {
    add_computers,
    additional_computers,
    address_label, cards_app_school,
    city_label,
    comment_label,
    contact_label, create_impersonal_schools_quantity,
    device_number,
    email_label, expired_licenses,
    hardware_shipment_type,
    has_master, logopedia, logopedia_trial,
    master_version,
    no_master,
    not_empty_field, not_selected_trial_product,
    phone_label,
    position_label,
    product_key,
    region_label,
    registered_this_month,
    registered_today,
    school_name,
    sellerLabel,
    sellerNameLabel, shd_trial,
    software_products,
    total_label,
    trial_period,
    will_expire_licenses,
    with_licenses,
    without_licenses
} from "../text/Literals";
import {AppData} from "../model/AppData";
import {Dlc} from "./Dlc";
import {Player} from "./Player";
import {Teacher} from "./Teacher";

export interface ISchool {
    username : string;
    name : string;
    city : string;
    address : string;
    association ?: string;
    description ?: string;
    master : boolean;
    email : string;
    phone : string;
    devices : number;
    additionalComputers : number;
    region : string;
    contact : string;
    position : string;
    licenses : string[];
    expire ?: IExpire;
    bounced ?: boolean;
    teachers ?: Teacher [];
    outdated ?: string[];
}

export interface SchoolWithTeachers extends ISchool{
    emails ?: string
    names ?: string
    expirations ?: string
}

export  function exportSchool(school : ISchool): SchoolWithTeachers {
    return {
        ...school,
        teachers : undefined,
        emails : school.teachers ? school.teachers.map(t => t.teacher).join(',') : undefined,
        names : school.teachers ? school.teachers.map(t => t.name).join(',') : undefined,
        expirations : school.expire ? Object.keys(school.expire).map(key => key + ":" +school.expire![key]).join(',') : undefined
    }
}

export function hasLicense(school: ISchool, product : string){
    return school.licenses && school.licenses.indexOf(product) > -1;
}

export interface IExpire {
    [key :string] :Date;
}

export interface SchoolsMetric {
    total : number;
    today : number;
    thismonth : number;
}

export interface SchoolUser{
    school : ISchool;
    user : Player
}

export function convertSchool(school: ISchool) {
    return new School(
        school.username,
        school.name,
        school.city,
        school.address,
        school.email,
        school.phone,
        school.devices,
        school.master,
        school.additionalComputers,
        school.association,
        school.description,
        school.region,
        school.contact,
        school.position,
        school.licenses,
        school.expire,
        school.bounced,
        school.teachers,
        school.outdated
    );
}

export function getOutdatedLicenses(school ?:ISchool):KV[] {
    if(school === undefined || ! school.outdated) return [];
    return school.outdated.map(product => ({key: product, value: product}));
}

export function getSchoolMetricKVList(metric : SchoolsMetric) {
    return[
        {key :total_label, value :metric.total},
        {key :registered_this_month,  value :metric.thismonth},
        {key :registered_today,value :metric.today}
    ]
}

export function getTeachersKV(teachers : Teacher[]) : KV[]{
    return teachers.map(teacher => new PlainTextKV(teacher.name,teacher.teacher))
}

export function getSchoolShortKVList(school: ISchool, full : boolean = false):KV[] {
    if(full){
        const res : KV[]= [
            new PlainTextKV(school.username,school.name),
            {key : region_label, value : school.region},
            {key : city_label, value : school.city},
            {key : address_label, value : school.address},
            {key : email_label, value : school.email, isNegative : school.bounced},
            {key : phone_label, value : school.phone},
            {key : device_number, value : school.devices},
            {key : contact_label, value : school.contact},
            {key : position_label, value : school.position},
            {key : hardware_shipment_type, value : school.description},
            new StringArrayKV(software_products,school.licenses)
        ];
        if(school.master){
            res.push({key: master_version,  value : true})
        }
        if(school.additionalComputers !==0){
            res.push({key : additional_computers, value : school.additionalComputers * 10})
        }
        return res;
    }
    return [
        new PlainTextKV(school.username,school.name),
        new PlainTextKV(school.email,school.phone)
    ];
}

export function getSchoolButtonsAdaptor(school : ISchool,
                                        clickHandle : (school: (ISchool | undefined)) => void,
                                        expandHandle : (school: ISchool) => void,
                                        isExpanded : boolean,
                                        mailTo : (recipients : string[]) => void ) :IButtonAdaptor[] {

    const invalid = !isEmail(school.email) || school.bounced;
    return [
        new ButtonAdaptor('',Intent.PRIMARY,()=>{clickHandle(school)},'edit'),
        new RequestButtonAdapter(add_computers,Intent.WARNING,token => new Request(
            undefined,
                DataType.SchoolAddComputers+school.username,
                Method.POST,
                token),DataType.SchoolAddComputers,'desktop'),
        new ButtonAdaptor('',
            invalid ? Intent.DANGER : Intent.PRIMARY,
            invalid? ()=>mailTo([]) : ()=> mailTo([school.email]),
            'envelope'),
        new ButtonAdaptor('',isExpanded ? Intent.DANGER : Intent.SUCCESS,()=>{expandHandle(school)}
        ,isExpanded? 'collapse-all':'expand-all')
    ]
}

export class School extends SimpleRequestData implements ISchool {
    username: string;
    name: string;
    city: string;
    address: string;
    email: string;
    phone: string;
    master: boolean;
    devices: number;
    association ?: string;
    description ?: string;
    region : string;
    contact : string;
    position : string;
    licenses : string[];
    trials : string [];
    expire ?: IExpire;
    isNew : boolean;
    bounced ?:boolean;
    teachers ?: Teacher[];
    outdated ?: string[];
    prevEmail : string;
    additionalComputers : number;
    onDataChanged ?:(adaptor: IFormAdaptor) => void;

    usernameAdaptor ?: FormFieldAdaptor<string>;
    nameAdaptor ?: FormFieldAdaptor<string>;
    cityAdaptor ?: FormFieldAdaptor<string>;
    addressAdaptor ?: FormFieldAdaptor<string>;
    emailAdaptor ?: FormFieldAdaptor<string>;
    phoneAdaptor ?: FormFieldAdaptor<string>;

    descriptionAdaptor ?: FormFieldAdaptor<string>;
    masterAdaptor ?: FormFieldAdaptor<boolean>;
    devicesAdaptor ?: FormFieldAdaptor<number>;
    regionAdaptor ?: FormFieldAdaptor<string>;
    contactAdaptor ?: FormFieldAdaptor<string>;
    positionAdaptor ?: FormFieldAdaptor<string>;
    associationAdaptor? : FormFieldAdaptor<string>;
    licensesAdaptor ?: TagsAdaptor;
    trialsAdaptor ?: TagsAdaptor;

    adaptor : FormAdaptor;

    constructor(username: string, name: string, city: string, address: string, email: string, phone: string,
                devices: number, master: boolean =false, additionalComputers : number = 0,
                association: string|undefined = undefined, description: string|undefined = undefined, region : string,
                contact : string, position : string, licenses : string[], expire ?: IExpire, bounced ?: boolean, teachers ?: Teacher[], outdated ?: string[]) {
        super();
        this.username = username;
        this.name = name;
        this.city = city;
        this.address = address;
        this.email = email;
        this.prevEmail = email;
        this.phone = phone;
        this.master = master;
        this.devices = devices;
        this.association = association;
        this.description = description;
        this.isNew = username.length === 0;
        this.additionalComputers = additionalComputers;
        this.region = region;
        this.contact = contact;
        this.position = position;
        this.licenses = licenses ? licenses : [];
        this.association = association ? association : '';
        this.trials = [];
        this.expire = expire;
        this.bounced = bounced;
        this.teachers = teachers;
        this.outdated = outdated;

        this.adaptor=this.createFormAdaptor();
    }


    setUsername=(value: string) =>{
        this.username = value;
        this.onDataChanged!(new FormAdaptor(
            [
                this.initUsernameAdaptor(),
                this.nameAdaptor!,
                this.regionAdaptor!,
                this.cityAdaptor!,
                this.addressAdaptor!,
                this.contactAdaptor!,
                this.positionAdaptor!,
                this.emailAdaptor!,
                this.phoneAdaptor!,
                this.descriptionAdaptor!,
                this.devicesAdaptor!,
                this.masterAdaptor!,
                this.licensesAdaptor!,
                this.trialsAdaptor!,
                this.associationAdaptor!
            ],
            this
        ) );
    };

    setName=(value: string) =>{
        this.name = value;
        this.onDataChanged!(new FormAdaptor(
            [
                this.usernameAdaptor!,
                this.initNameAdaptor()!,
                this.regionAdaptor!,
                this.cityAdaptor!,
                this.addressAdaptor!,
                this.contactAdaptor!,
                this.positionAdaptor!,
                this.emailAdaptor!,
                this.phoneAdaptor!,
                this.descriptionAdaptor!,
                this.devicesAdaptor!,
                this.masterAdaptor!,
                this.licensesAdaptor!,
                this.trialsAdaptor!,
                this.associationAdaptor!
            ],
            this
        ) );
    };

    setCity=(value: string) =>{
        this.city = value;
        this.onDataChanged!(new FormAdaptor(
            [
                this.usernameAdaptor!,
                this.nameAdaptor!,
                this.regionAdaptor!,
                this.initCityAdaptor(),
                this.addressAdaptor!,
                this.contactAdaptor!,
                this.positionAdaptor!,
                this.emailAdaptor!,
                this.phoneAdaptor!,
                this.descriptionAdaptor!,
                this.devicesAdaptor!,
                this.masterAdaptor!,
                this.licensesAdaptor!,
                this.trialsAdaptor!,
                this.associationAdaptor!
            ],
            this
        ) );
    };

    setAddress=(value: string) =>{
        this.address = value;
        this.onDataChanged!(new FormAdaptor(
            [
                this.usernameAdaptor!,
                this.nameAdaptor!,
                this.regionAdaptor!,
                this.cityAdaptor!,
                this.initAddressAdaptor(),
                this.contactAdaptor!,
                this.positionAdaptor!,
                this.emailAdaptor!,
                this.phoneAdaptor!,
                this.descriptionAdaptor!,
                this.devicesAdaptor!,
                this.masterAdaptor!,
                this.licensesAdaptor!,
                this.trialsAdaptor!,
                this.associationAdaptor!
            ],
            this
        ) );
    };

    setEmail=(value: string)=> {
        this.email = value;
        this.onDataChanged!(new FormAdaptor(
            [
                this.usernameAdaptor!,
                this.nameAdaptor!,
                this.regionAdaptor!,
                this.cityAdaptor!,
                this.addressAdaptor!,
                this.contactAdaptor!,
                this.positionAdaptor!,
                this.initEmailAdaptor(),
                this.phoneAdaptor!,
                this.descriptionAdaptor!,
                this.devicesAdaptor!,
                this.masterAdaptor!,
                this.licensesAdaptor!,
                this.trialsAdaptor!,
                this.associationAdaptor!
            ],
            this
        ) );
    };

    setPhone=(value: string)=> {
        this.phone = value;
        this.onDataChanged!(new FormAdaptor(
            [
                this.usernameAdaptor!,
                this.nameAdaptor!,
                this.regionAdaptor!,
                this.cityAdaptor!,
                this.addressAdaptor!,
                this.contactAdaptor!,
                this.positionAdaptor!,
                this.emailAdaptor!,
                this.initPhoneAdaptor(),
                this.descriptionAdaptor!,
                this.devicesAdaptor!,
                this.masterAdaptor!,
                this.licensesAdaptor!,
                this.trialsAdaptor!,
                this.associationAdaptor!
            ],
            this
        ) );
    };

    setMaster=(value: boolean) =>{
        this.master = value;
        this.onDataChanged!(new FormAdaptor(
            [
                this.usernameAdaptor!,
                this.nameAdaptor!,
                this.regionAdaptor!,
                this.cityAdaptor!,
                this.addressAdaptor!,
                this.contactAdaptor!,
                this.positionAdaptor!,
                this.emailAdaptor!,
                this.phoneAdaptor!,
                this.descriptionAdaptor!,
                this.devicesAdaptor!,
                this.initMasterAdaptor(),
                this.licensesAdaptor!,
                this.trialsAdaptor!,
                this.associationAdaptor!
            ],
            this
        ) );
    };

    setDevices=(value: number)=> {
        this.devices = value;
        this.onDataChanged!(new FormAdaptor(
            [
                this.usernameAdaptor!,
                this.nameAdaptor!,
                this.regionAdaptor!,
                this.cityAdaptor!,
                this.addressAdaptor!,
                this.contactAdaptor!,
                this.positionAdaptor!,
                this.emailAdaptor!,
                this.phoneAdaptor!,
                this.descriptionAdaptor!,
                this.initDevicesAdaptor(),
                this.masterAdaptor!,
                this.licensesAdaptor!,
                this.trialsAdaptor!,
                this.associationAdaptor!
            ],
            this
        ) );
    };

    setAssociation=(value: string)=> {
        this.association = value;
        this.onDataChanged!(new FormAdaptor(
            [
                this.usernameAdaptor!,
                this.nameAdaptor!,
                this.regionAdaptor!,
                this.cityAdaptor!,
                this.addressAdaptor!,
                this.contactAdaptor!,
                this.positionAdaptor!,
                this.emailAdaptor!,
                this.phoneAdaptor!,
                this.descriptionAdaptor!,
                this.devicesAdaptor!,
                this.masterAdaptor!,
                this.licensesAdaptor!,
                this.trialsAdaptor!,
                this.initAssociationAdapter()
            ],
            this
        ) );
    };

    setDescription=(value: string)=> {
        this.description = value;
        this.onDataChanged!(new FormAdaptor(
            [
                this.usernameAdaptor!,
                this.nameAdaptor!,
                this.regionAdaptor!,
                this.cityAdaptor!,
                this.addressAdaptor!,
                this.contactAdaptor!,
                this.positionAdaptor!,
                this.emailAdaptor!,
                this.phoneAdaptor!,
                this.initDescriptionAdapter(),
                this.devicesAdaptor!,
                this.masterAdaptor!,
                this.licensesAdaptor!,
                this.trialsAdaptor!,
                this.associationAdaptor!
            ],
            this
        ) );
    };

    setRegion=(value: string)=> {
        this.region = value;
        this.onDataChanged!(new FormAdaptor(
            [
                this.usernameAdaptor!,
                this.nameAdaptor!,
                this.initRegionAdaptor(),
                this.cityAdaptor!,
                this.addressAdaptor!,
                this.contactAdaptor!,
                this.positionAdaptor!,
                this.emailAdaptor!,
                this.phoneAdaptor!,
                this.descriptionAdaptor!,
                this.devicesAdaptor!,
                this.masterAdaptor!,
                this.licensesAdaptor!,
                this.trialsAdaptor!,
                this.associationAdaptor!
            ],
            this
        ) );
    };

    setContact=(value: string)=> {
        this.contact = value;
        this.onDataChanged!(new FormAdaptor(
            [
                this.usernameAdaptor!,
                this.nameAdaptor!,
                this.regionAdaptor!,
                this.cityAdaptor!,
                this.addressAdaptor!,
                this.initContactAdaptor(),
                this.positionAdaptor!,
                this.emailAdaptor!,
                this.phoneAdaptor!,
                this.descriptionAdaptor!,
                this.devicesAdaptor!,
                this.masterAdaptor!,
                this.licensesAdaptor!,
                this.trialsAdaptor!,
                this.associationAdaptor!
            ],
            this
        ) );
    };

    setPosition=(value: string)=> {
        this.position = value;
        this.onDataChanged!(new FormAdaptor(
            [
                this.usernameAdaptor!,
                this.nameAdaptor!,
                this.regionAdaptor!,
                this.cityAdaptor!,
                this.addressAdaptor!,
                this.contactAdaptor!,
                this.initPositionAdaptor(),
                this.emailAdaptor!,
                this.phoneAdaptor!,
                this.descriptionAdaptor!,
                this.devicesAdaptor!,
                this.masterAdaptor!,
                this.licensesAdaptor!,
                this.trialsAdaptor!,
                this.associationAdaptor!
            ],
            this
        ) );
    };

    initUsernameAdaptor =() : FormFieldAdaptor<string> =>{
        this.usernameAdaptor= new NotEmptyStringFiled(product_key,this.setUsername,not_empty_field,this.username,!this.isNew);
        return this.usernameAdaptor;
    };
    initNameAdaptor =() :FormFieldAdaptor<string> =>{
        this.nameAdaptor = new NotEmptyStringFiled(school_name,this.setName,not_empty_field,this.name);
        return this.nameAdaptor;
    };
    initCityAdaptor =() :FormFieldAdaptor<string> =>{
        this.cityAdaptor = new NotEmptyStringFiled(city_label,this.setCity,not_empty_field,this.city);
        return this.cityAdaptor;
    };
    initAddressAdaptor =(): FormFieldAdaptor<string> =>{
        this.addressAdaptor = new NotEmptyStringFiled(address_label,this.setAddress,not_empty_field,this.address);
        return this.addressAdaptor;
    };
    initEmailAdaptor =(): FormFieldAdaptor<string>=>{
        this.emailAdaptor = new ValidEmailFieldAdaptor(email_label,this.setEmail,not_empty_field,this.email, !!this.bounced && this.email === this.prevEmail);
        return this.emailAdaptor;
    };
    initPhoneAdaptor =(): FormFieldAdaptor<string>=>{
        this.phoneAdaptor = new PhoneFieldAdaptor(phone_label,this.setPhone,not_empty_field,this.phone);
        return this.phoneAdaptor;
    };

     initMasterAdaptor =(): FormFieldAdaptor<boolean>=>{
         this.masterAdaptor = new BooleanFieldAdaptor(master_version,this.setMaster,this.master,false,
             this.master ? has_master : no_master);
         return this.masterAdaptor;
     };
     initDevicesAdaptor =(): FormFieldAdaptor<number>=>{
         this.devicesAdaptor = new NotNegativeIntegerField(device_number,this.setDevices,this.devices);
         return this.devicesAdaptor;
     };

     initDescriptionAdapter=():FormFieldAdaptor<string>=>{
         this.descriptionAdaptor = new StringOptionsFieldAdaptor(hardware_shipment_type,this.setDescription,this.description,School.filterDataForOptions,false,true);
         return this.descriptionAdaptor;
     };

    initRegionAdaptor =(): FormFieldAdaptor<string> =>{
        this.regionAdaptor = new NotEmptyStringFiled(region_label,this.setRegion,not_empty_field,this.region);
        return this.regionAdaptor;
    };

    initContactAdaptor =(): FormFieldAdaptor<string> =>{
        this.contactAdaptor = new NotEmptyStringFiled(contact_label,this.setContact,not_empty_field,this.contact);
        return this.contactAdaptor;
    };

    initPositionAdaptor =(): FormFieldAdaptor<string> =>{
        this.positionAdaptor = new NotEmptyStringFiled(position_label,this.setPosition,not_empty_field,this.position);
        return this.positionAdaptor;
    };

     static filterDataForOptions(appData :AppData): string[]{
         return appData.schoolHardwareTags === undefined ? []: appData.schoolHardwareTags;
     }

    initLicenseAdaptor = ()=>{
        this.licensesAdaptor = new ExpireTagsAdaptor(software_products,this.licenses, this.addLicense,this.removeLicense,Dlc.filterSoftware,false, this.expire,this.outdated);
        return this.licensesAdaptor;
    };

    initTrialsAdaptor = ()=>{
        this.trialsAdaptor = new LimitedTagsAdaptor(trial_period,this.trials,
            this.addTrial,this.removeTrial,Dlc.filterSoftware, this.licenses ,false);
        return this.trialsAdaptor;
    };


    initAssociationAdapter=()=> {
        this.associationAdaptor = new TextFieldAdaptor(comment_label,this.setAssociation,this.association, 2);
        return this.associationAdaptor;
    };

    addLicense=(val : string)=>{
        this.licenses.push(val);
        this.initLicenseAdaptor();
        this.initTrialsAdaptor();
        this.updateAdaptor();
    };

    removeLicense=(val : string)=>{
        const index = this.licenses.indexOf(val);
        if (index < 0) return;
        this.licenses.splice(index,1);
        /*if (this.expire && this.expire[val]) { // todo for server-80
            delete this.expire[val];
        }*/
        this.initLicenseAdaptor();
        this.initTrialsAdaptor();
        this.updateAdaptor();
    };

    addTrial=(val : string)=>{
        this.trials.push(val);
        this.initTrialsAdaptor();
        this.updateAdaptor();
    };

    removeTrial=(val : string)=>{
        const index = this.trials.indexOf(val);
        if (index < 0) return;
        this.trials.splice(index,1);
        this.initTrialsAdaptor();
        this.updateAdaptor();
    };

    updateAdaptor=()=> {
        this.onDataChanged!(new FormAdaptor(
            [
                this.usernameAdaptor!,
                this.nameAdaptor!,
                this.regionAdaptor!,
                this.cityAdaptor!,
                this.addressAdaptor!,
                this.contactAdaptor!,
                this.positionAdaptor!,
                this.emailAdaptor!,
                this.phoneAdaptor!,
                this.descriptionAdaptor!,
                this.devicesAdaptor!,
                this.masterAdaptor!,
                this.licensesAdaptor!,
                this.trialsAdaptor!,
                this.associationAdaptor!
            ],
            this
        ) );
    };

    connect(connector: (adaptor: IFormAdaptor) => void): void {
        this.onDataChanged=connector;
    }

    createFormAdaptor() :FormAdaptor{
        return  new FormAdaptor(
            [
                this.initUsernameAdaptor(),
                this.initNameAdaptor(),
                this.initRegionAdaptor(),
                this.initCityAdaptor(),
                this.initAddressAdaptor(),
                this.initContactAdaptor(),
                this.initPositionAdaptor(),
                this.initEmailAdaptor(),
                this.initPhoneAdaptor(),
                this.initDescriptionAdapter(),
                this.initDevicesAdaptor(),
                this.initMasterAdaptor(),
                this.initLicenseAdaptor(),
                this.initTrialsAdaptor(),
                this.initAssociationAdapter()
            ],
            this
        )
    }

    getFormAdaptor(): IFormAdaptor {
        return this.adaptor;
    }

    getMethod(): Method {
        return this.isNew ? Method.POST : Method.PUT;
    }

    message=(): string=> {
        return JSON.stringify(this,['username','name','region','city','address','contact','position'
            ,'email','phone','master','devices','association','description','licenses','trials']);
    };

    getType=(): DataType=> {
        return this.isNew ? DataType.School : DataType.SchoolAll;
    };
}

export class SchoolSearchRequest extends SearchRequest{
    username: string;
    name: string;
    city: string;
    email: string;
    phone: string;
    description : string;
    region : string;
    contact : string;
    position : string;
    usernameAdaptor : FormFieldAdaptor<string>;
    nameAdaptor : FormFieldAdaptor<string>;
    cityAdaptor : FormFieldAdaptor<string>;
    emailAdaptor : FormFieldAdaptor<string>;
    phoneAdaptor : FormFieldAdaptor<string>;
    descriptionAdapter : FormFieldAdaptor<string>;
    regionAdaptor : FormFieldAdaptor<string>;
    contactAdaptor : FormFieldAdaptor<string>;
    positionAdaptor : FormFieldAdaptor<string>;
    readonly fields : string[] = ['username','name','city','email','phone','description','region','contact','position','association','licenses','noLicenses','expires', 'expired'];

    association: string;
    licenses: string[];
    noLicenses : string [];
    expires : string [];
    expired : string [];
    commentLabel: string;
    associationAdaptor: FormFieldAdaptor<string>;
    licensesAdaptor: TagsAdaptor;
    noLicensesAdaptor: TagsAdaptor;
    expiresAdaptor: TagsAdaptor;
    expiredAdaptor: TagsAdaptor;

    constructor() {
        super();
        this.username ='';
        this.name = '';
        this.city = '';
        this.email ='';
        this.phone = '';
        this.description = '';
        this.region = '';
        this.contact = '';
        this.position = '';
        this.association = '';
        this.licenses = [];
        this.noLicenses = [];
        this.expires = [];
        this.expired = [];
        this.commentLabel = comment_label;
        this.usernameAdaptor=this.initUsernameAdaptor();
        this.nameAdaptor = this.initNameAdaptor();
        this.cityAdaptor = this.initCityAdaptor();
        this.emailAdaptor = this.initEmailAdaptor();
        this.phoneAdaptor = this.initPhoneAdaptor();
        this.descriptionAdapter = this.initDescriptionAdapter();
        this.regionAdaptor = this.initRegionAdaptor();
        this.contactAdaptor = this.initContactAdaptor();
        this.positionAdaptor = this.initPositionAdaptor();
        this.associationAdaptor = this.initAssociationAdapter();
        this.licensesAdaptor = this.initLicenseAdaptor();
        this.noLicensesAdaptor = this.initNoLicensesAdaptor();
        this.expiresAdaptor = this.initExpiresAdaptor();
        this.expiredAdaptor = this.initExpiredAdaptor();
    }

    getFieldAdaptors(): FieldAdaptor[] {
        return [
            this.usernameAdaptor,
            this.nameAdaptor,
            this.regionAdaptor,
            this.cityAdaptor,
            this.contactAdaptor,
            this.positionAdaptor,
            this.emailAdaptor,
            this.phoneAdaptor,
            this.descriptionAdapter,
            this.associationAdaptor,
            this.licensesAdaptor,
            this.noLicensesAdaptor,
            this.expiresAdaptor,
            this.expiredAdaptor
        ];
    }

    initUsernameAdaptor=()=> {
        this.usernameAdaptor = new StringFieldAdaptor(product_key,this.setUsername,this.username);
        return this.usernameAdaptor;
    };

    setUsername=(value :string)=> {
        this.username  = value;
        this.initUsernameAdaptor();
        this.onDataChanged!(this.getFormAdaptor());
    };

    initNameAdaptor=()=> {
       this.nameAdaptor = new StringFieldAdaptor(school_name,this.setName,this.name);
       return this.nameAdaptor;
    };

    setName=(value :string)=> {
        this.name = value;
        this.initNameAdaptor();
        this.onDataChanged!(this.getFormAdaptor());
    };

    initCityAdaptor=() =>{
        this.cityAdaptor = new StringFieldAdaptor(city_label,this.setCity,this.city);
        return this.cityAdaptor;
    };

    setCity=(value: string)=> {
        this.city = value;
        this.initCityAdaptor();
        this.onDataChanged!(this.getFormAdaptor());
    };

    initEmailAdaptor=()=> {
        this.emailAdaptor = new StringFieldAdaptor(email_label,this.setEmail, this.email);
        return this.emailAdaptor;
    };

    setEmail=(value: string)=> {
        this.email= value;
        this.initEmailAdaptor();
        this.onDataChanged!(this.getFormAdaptor());
    };

    initPhoneAdaptor=()=> {
        this.phoneAdaptor = new StringFieldAdaptor(phone_label,this.setPhone,this.phone);
        return this.phoneAdaptor;
    };

    setPhone=(value : string)=> {
        this.phone = value;
        this.initPhoneAdaptor();
        this.onDataChanged!(this.getFormAdaptor());
    };

    setDescription=(value : string)=>{
        this.description = value;
        this.initDescriptionAdapter();
        this.onDataChanged!(this.getFormAdaptor());
    };

    initDescriptionAdapter=()=> {
        this.descriptionAdapter = new StringOptionsFieldAdaptor(hardware_shipment_type,this.setDescription,this.description,School.filterDataForOptions);
        return this.descriptionAdapter;
    };

    setRegion=(value : string)=>{
        this.region = value;
        this.initRegionAdaptor();
        this.onDataChanged!(this.getFormAdaptor());
    };

    setContact=(value : string)=>{
        this.contact = value;
        this.initContactAdaptor();
        this.onDataChanged!(this.getFormAdaptor());
    };

    setPosition=(value : string)=>{
        this.position = value;
        this.initPositionAdaptor();
        this.onDataChanged!(this.getFormAdaptor());
    };

    initRegionAdaptor =(): FormFieldAdaptor<string> =>{
        this.regionAdaptor = new StringFieldAdaptor(region_label,this.setRegion,this.region);
        return this.regionAdaptor;
    };

    initContactAdaptor =(): FormFieldAdaptor<string> =>{
        this.contactAdaptor = new StringFieldAdaptor(contact_label,this.setContact,this.contact);
        return this.contactAdaptor;
    };

    initPositionAdaptor =(): FormFieldAdaptor<string> =>{
        this.positionAdaptor = new StringFieldAdaptor(position_label,this.setPosition,this.position);
        return this.positionAdaptor;
    };

    setAssociation = (value: string) => {
        this.association = value;
        this.initAssociationAdapter();
        this.onDataChanged!(this.getFormAdaptor());
    };

    initAssociationAdapter = () => {
        this.associationAdaptor = new TextFieldAdaptor(this.commentLabel, this.setAssociation, this.association, 2);
        return this.associationAdaptor;
    };

    /*
        Licenses
     */
    initLicenseAdaptor = ()=>{
        this.licensesAdaptor = new LimitedTagsAdaptor(with_licenses,this.licenses,
            this.addLicense,this.removeLicense,Dlc.filterSoftware, [...this.noLicenses, ...this.expires, ...this.expired],false);
        return this.licensesAdaptor;
    };

    addLicense=(val : string)=>{
        this.licenses.push(val);
        this.initAllLicensesAdaptors();
        this.onDataChanged!(this.getFormAdaptor());
    };

    removeLicense=(val : string)=>{
        const index = this.licenses.indexOf(val);
        if (index < 0) return;
        this.licenses.splice(index,1);
        this.initAllLicensesAdaptors();
        this.onDataChanged!(this.getFormAdaptor());
    };

    /*
     noLicenses
     */
    initNoLicensesAdaptor = ()=>{
        this.noLicensesAdaptor = new LimitedTagsAdaptor(without_licenses,this.noLicenses,
            this.addNoLicenses,this.removeNoLicenses,Dlc.filterSoftware, [...this.licenses, ...this.expires],false,  Intent.DANGER);
        return this.noLicensesAdaptor;
    };

    addNoLicenses=(val : string)=>{
        this.noLicenses.push(val);
        this.initAllLicensesAdaptors();
        this.onDataChanged!(this.getFormAdaptor());
    };

    removeNoLicenses=(val : string)=>{
        const index = this.noLicenses.indexOf(val);
        if (index < 0) return;
        this.noLicenses.splice(index,1);
        this.initAllLicensesAdaptors();
        this.onDataChanged!(this.getFormAdaptor());
    };
    /*
     Expires
     */
    initExpiresAdaptor = ()=>{
        this.expiresAdaptor = new LimitedTagsAdaptor(will_expire_licenses,this.expires,
            this.addExpires,this.removeExpires,Dlc.filterSoftware, [...this.licenses, ...this.noLicenses, ...this.expired],false, Intent.WARNING);
        return this.expiresAdaptor;
    };

    addExpires=(val : string)=>{
        this.expires.push(val);
        this.initAllLicensesAdaptors();
        this.onDataChanged!(this.getFormAdaptor());
    };

    removeExpires=(val : string)=>{
        const index = this.expires.indexOf(val);
        if (index < 0) return;
        this.expires.splice(index,1);
        this.initAllLicensesAdaptors();
        this.onDataChanged!(this.getFormAdaptor());
    };

    /*
     Expired
     */
    initExpiredAdaptor = ()=>{
        this.expiredAdaptor = new LimitedTagsAdaptor(expired_licenses,this.expired,
            this.addExpired,this.removeExpired,Dlc.filterSoftware, [...this.licenses, ...this.expires],false, Intent.DANGER);
        return this.expiredAdaptor;
    };

    addExpired=(val : string)=>{
        this.expired.push(val);
        this.initAllLicensesAdaptors();
        this.onDataChanged!(this.getFormAdaptor());
    };

    removeExpired=(val : string)=>{
        const index = this.expired.indexOf(val);
        if (index < 0) return;
        this.expired.splice(index,1);
        this.initAllLicensesAdaptors();
        this.onDataChanged!(this.getFormAdaptor());
    };

    initAllLicensesAdaptors = ()=>{
        this.initLicenseAdaptor();
        this.initNoLicensesAdaptor();
        this.initExpiresAdaptor();
        this.initExpiredAdaptor();
    }

    getType(): DataType {
        return DataType.SchoolAll;
    }
}

export class SchoolRegisterRequest extends SchoolSearchRequest{
    address : string;
    master : boolean;
    masterAdaptor : FormFieldAdaptor<boolean>;
    addressAdaptor : FormFieldAdaptor<string>;

    readonly fields : string[] = ['username','name','city','email','phone','association','region','contact','position','address','description','licenses', 'master'];

    constructor(email: string, label : string = 'register_info_hint') {
        super();
        this.username = 'placeholder';
        this.description = 'RUS';
        this.email = email;
        this.association = "";
        this.address = "";
        this.master = false;
        this.licenses = [];
        this.commentLabel = label;
        this.associationAdaptor = this.initAssociationAdapter();
        this.emailAdaptor =  this.initEmailAdaptor();
        this.addressAdaptor = this.initAddressAdaptor();
        this.masterAdaptor = this.initMasterAdaptor();


        this.initNameAdaptor();
        this.initCityAdaptor();
        this.initPhoneAdaptor();
        this.initRegionAdaptor();
        this.initContactAdaptor();
        this.initPositionAdaptor();
    }

    setAddress=(value : string)=>{
        this.address = value;
        this.initAddressAdaptor();
        this.onDataChanged!(this.getFormAdaptor());
    };

    setMaster=(value : boolean)=>{
        this.master = value;
        this.initMasterAdaptor();
        this.onDataChanged!(this.getFormAdaptor());
    };

    initNameAdaptor =() :FormFieldAdaptor<string> =>{
        this.nameAdaptor = new NotEmptyStringFiled(school_name,this.setName,not_empty_field,this.name);
        return this.nameAdaptor;
    };
    initCityAdaptor =() :FormFieldAdaptor<string> =>{
        this.cityAdaptor = new NotEmptyStringFiled(city_label,this.setCity,not_empty_field,this.city);
        return this.cityAdaptor;
    };
    initAddressAdaptor =(): FormFieldAdaptor<string> =>{
        this.addressAdaptor = new NotEmptyStringFiled(address_label,this.setAddress,not_empty_field,this.address);
        return this.addressAdaptor;
    };
    initPhoneAdaptor =(): FormFieldAdaptor<string>=>{
        this.phoneAdaptor = new PhoneFieldAdaptor(phone_label,this.setPhone,not_empty_field,this.phone);
        return this.phoneAdaptor;
    };

    initEmailAdaptor=()=> {
        this.emailAdaptor = new StringFieldAdaptor(email_label,this.setEmail, this.email, true);
        return this.emailAdaptor;
    };

    initRegionAdaptor =(): FormFieldAdaptor<string> =>{
        this.regionAdaptor = new NotEmptyStringFiled(region_label,this.setRegion,not_empty_field,this.region);
        return this.regionAdaptor;
    };

    initContactAdaptor =(): FormFieldAdaptor<string> =>{
        this.contactAdaptor = new NotEmptyStringFiled(contact_label,this.setContact,not_empty_field,this.contact);
        return this.contactAdaptor;
    };

    initPositionAdaptor =(): FormFieldAdaptor<string> =>{
        this.positionAdaptor = new NotEmptyStringFiled(position_label,this.setPosition,not_empty_field,this.position);
        return this.positionAdaptor;
    };

    initMasterAdaptor =(): FormFieldAdaptor<boolean>=>{
        this.masterAdaptor = new BooleanFieldAdaptor(master_version,this.setMaster,this.master,false,
            this.master ? has_master : no_master);
        return this.masterAdaptor;
    };

    getType(): DataType {
        return DataType.SchoolApply;
    }

    getFieldAdaptors(): FieldAdaptor[] {
        return [
            this.associationAdaptor,
            this.nameAdaptor,
            this.regionAdaptor,
            this.cityAdaptor,
            this.addressAdaptor,
            this.contactAdaptor,
            this.positionAdaptor,
            this.emailAdaptor,
            this.phoneAdaptor
        ];
    }

    getMethod(): Method {
        return Method.PUT;
    }

    message=(): string=> {
        return JSON.stringify(this,this.fields);
    }
}

export class SchoolEditRequest extends SchoolRegisterRequest{
    devices : number;
    devicesAdaptor : FormFieldAdaptor<number>;
    expire ?: IExpire;
    outdated ?: string[]
    constructor(model : ISchool) {
        super(model.email);
        this.username = model.username;
        this.name = model.name;
        this.city = model.city;
        this.address = model.address;
        this.email = model.email;
        this.phone = model.phone;
        this.master = model.master;
        this.devices = model.additionalComputers;
        this.association = model.association ? model.association : '';
        this.description = model.description ? model.description : '';
        this.region = model.region;
        this.contact = model.contact;
        this.position = model.position;
        this.licenses = model.licenses ? model.licenses : [];
        this.expire = model.expire;
        this.outdated = model.outdated;
        this.associationAdaptor = this.initAssociationAdapter();
        this.emailAdaptor =  this.initEmailAdaptor();
        this.addressAdaptor = this.initAddressAdaptor();
        this.masterAdaptor = this.initMasterAdaptor();
        this.devicesAdaptor = this.initDevicesAdaptor();
        this.licensesAdaptor = this.initLicenseAdaptor();
        this.adaptor=this.createFormAdaptor();

        this.initUsernameAdaptor();
        this.initNameAdaptor();
        this.initCityAdaptor();
        this.initPhoneAdaptor();
        this.initRegionAdaptor();
        this.initContactAdaptor();
        this.initPositionAdaptor();
    }

    readonly fields : string[] = ['username','name','city','email','phone','association','region','contact','position','address','description','licenses', 'master'];
    
    getFieldAdaptors(): FieldAdaptor[] {
        return [
            this.usernameAdaptor,
            this.nameAdaptor,
            this.regionAdaptor,
            this.cityAdaptor,
            this.addressAdaptor,
            this.contactAdaptor,
            this.positionAdaptor,
            this.emailAdaptor,
            this.phoneAdaptor,
            this.licensesAdaptor,
            this.masterAdaptor,
            this.devicesAdaptor,
            this.associationAdaptor,
        ];
    }

    setDevices=(value : number)=>{
        this.devices = value;
        this.initDevicesAdaptor();
        this.onDataChanged!(this.getFormAdaptor());
    };

    initEmailAdaptor=()=> {
        this.emailAdaptor = new StringFieldAdaptor(email_label,this.setEmail, this.email);
        return this.emailAdaptor;
    };

    initUsernameAdaptor=()=> {
        this.usernameAdaptor = new StringFieldAdaptor(product_key,this.setUsername,this.username,true);
        return this.usernameAdaptor;
    };

    initMasterAdaptor =(): FormFieldAdaptor<boolean>=>{
        this.masterAdaptor = new BooleanFieldAdaptor(master_version,this.setMaster,this.master,true,
            this.master ? has_master : no_master);
        return this.masterAdaptor;
    };

    initDevicesAdaptor =(): FormFieldAdaptor<number>=>{
        this.devicesAdaptor = new NotNegativeIntegerField(additional_computers,this.setDevices,this.devices, true);
        return this.devicesAdaptor;
    };

    initLicenseAdaptor = ()=>{
        this.licensesAdaptor = new ExpireTagsAdaptor(software_products,this.licenses,
            tag => {},tag => {},appData=>[],true, this.expire, this.outdated,true );
        return this.licensesAdaptor;
    };

    getType(): DataType {
        return DataType.MySchool;
    }
    
    getMethod(): Method {
        return Method.POST;
    }
}

export function getTrialProductLabelId(product : string) {
    if (product === logopedia)
        return logopedia_trial;
    if (product === cards_app_school)
        return shd_trial;
    return '';
}

export class RegisterWithTrialRequest extends SchoolRegisterRequest{
    trials : string[];
    allTrials : string[];
    trialsState : boolean[];

    trialAdaptors : FormFieldAdaptor<boolean>[];

    onDataChanged ?: (adaptor : IFormAdaptor) => void;

    constructor(email: string, trials: string[], label: string  = 'register_info_hint') {
        super(email, label);
        this.trials = trials;
        this.allTrials = trials;
        this.trialsState = this.initTrialsState();

        this.trialAdaptors = this.initTrialsAdaptor();
    }

    readonly fields : string[] = ['username','name','city','email','phone','association','region','contact','position','address','description','licenses', 'master','trials'];

    initTrialsState = () => {
        let trialsState : boolean[] = [];
        this.allTrials.forEach(() => {
            trialsState.push(true);
        });
        return trialsState;
    };

    // todo disable toggles if trials products offered less two
    initTrialsAdaptor = () => {
        this.trialAdaptors = [];
        this.allTrials.forEach((trial, index) => {
            let setTrial = this.getTrialFunction(index);
            this.trialAdaptors.push(new ValidValueAdaptor<boolean>(getTrialProductLabelId(trial), setTrial, this.isSelectedProduct, not_selected_trial_product, this.trialsState[index],false));
        });
        return this.trialAdaptors;
    };

    getTrialFunction = (index : number) => {
        return (value : boolean) => {
            this.trialsState[index] = value;
            this.initTrialsAdaptor();
            this.updateTrials();
            this.onDataChanged!(this.getFormAdaptor());
        }
    }

    updateTrials = () => {
        this.trials = [];
        this.trialsState.forEach((state, index) => {
            if (state)
                this.trials.push(this.allTrials[index]);
        })
    }

    isSelectedProduct = () => {
        return this.trialsState.some(trialState => trialState);
    }

    getFieldAdaptors() : FieldAdaptor[] {
        let adaptors : FieldAdaptor[] = [
            this.associationAdaptor,
            this.nameAdaptor,
            this.regionAdaptor,
            this.cityAdaptor,
            this.addressAdaptor,
            this.contactAdaptor,
            this.positionAdaptor,
            this.emailAdaptor,
            this.phoneAdaptor
        ];
        adaptors.push(...this.trialAdaptors);
        return adaptors;
    }
}

export function createDefaultImpersonalSchoolsRequest(){
    return new CreateImpersonalSchoolsRequest(0, [], [], '', '', false);
}

export class CreateImpersonalSchoolsRequest extends SimpleRequestData {
    quantity : number;
    seller : string;
    sellerName : string;
    licenses : string[];
    trials : string[];
    master : boolean;

    onDataChanged ?: (adaptor : IFormAdaptor) => void;

    quantityAdaptor ?: FormFieldAdaptor<number>;
    sellerAdaptor ?: FormFieldAdaptor<string>;
    sellerNameAdaptor ?: FormFieldAdaptor<string>;
    licensesAdaptor ?: TagsAdaptor;
    trialsAdaptor ?: TagsAdaptor;
    masterAdaptor ?: FormFieldAdaptor<boolean>;

    adaptor : FormAdaptor;

    constructor(quantity : number, licenses : string[], trials : string[], seller : string, sellerName : string, master : boolean) {
        super();
        this.quantity = quantity;
        this.seller = seller;
        this.sellerName = sellerName;
        this.licenses = licenses;
        this.trials = trials;
        this.master = master;

        this.adaptor = this.createFormAdaptor();
    }

    setQuantity = (value : number)=> {
        this.quantity = value;
        this.onDataChanged!(new FormAdaptor(
            [
                this.initQuantityAdaptor(),
                this.sellerAdaptor!,
                this.sellerNameAdaptor!,
                this.licensesAdaptor!,
                this.trialsAdaptor!,
                this.masterAdaptor!
            ],
            this
        ));
    };

    setSeller = (value : string)=> {
        this.seller = value;
        this.onDataChanged!(new FormAdaptor(
            [
                this.quantityAdaptor!,
                this.initSellerAdaptor(),
                this.sellerNameAdaptor!,
                this.licensesAdaptor!,
                this.trialsAdaptor!,
                this.masterAdaptor!
            ],
            this
        ));
    };

    setSellerName = (value : string)=> {
        this.sellerName = value;
        this.onDataChanged!(new FormAdaptor(
            [
                this.quantityAdaptor!,
                this.sellerAdaptor!,
                this.initSellerNameAdaptor(),
                this.licensesAdaptor!,
                this.trialsAdaptor!,
                this.masterAdaptor!
            ],
            this
        ));
    };

    setMaster = (value : boolean)=> {
        this.master = value;
        this.onDataChanged!(new FormAdaptor(
            [
                this.quantityAdaptor!,
                this.sellerAdaptor!,
                this.sellerNameAdaptor!,
                this.licensesAdaptor!,
                this.trialsAdaptor!,
                this.initMasterAdaptor()
            ],
            this
        ));
    };

    addLicense = (val : string)=> {
        this.licenses.push(val);
        this.initLicenseAdaptor();
        this.initTrialsAdaptor();
        this.updateAdaptor();
    };

    removeLicense = (val : string)=> {
        const index = this.licenses.indexOf(val);
        if (index < 0)
            return;
        this.licenses.splice(index,1);
        this.initLicenseAdaptor();
        this.initTrialsAdaptor();
        this.updateAdaptor();
    };

    addTrial = (val : string)=> {
        this.trials.push(val);
        this.initTrialsAdaptor();
        this.updateAdaptor();
    };

    removeTrial = (val : string)=> {
        const index = this.trials.indexOf(val);
        if (index < 0)
            return;
        this.trials.splice(index,1);
        this.initTrialsAdaptor();
        this.updateAdaptor();
    };

    initQuantityAdaptor = () : FormFieldAdaptor<number> => {
        this.quantityAdaptor = new PositiveIntegerField(create_impersonal_schools_quantity, this.setQuantity, this.quantity);
        return this.quantityAdaptor;
    };

    initSellerAdaptor = () : FormFieldAdaptor<string> => {
        this.sellerAdaptor = new ValidEmailFieldAdaptor(sellerLabel, this.setSeller, not_empty_field, this.seller, false);
        return this.sellerAdaptor;
    };

    initSellerNameAdaptor =() : FormFieldAdaptor<string> => {
        this.sellerNameAdaptor = new NotEmptyStringFiled(sellerNameLabel, this.setSellerName, not_empty_field, this.sellerName);
        return this.sellerNameAdaptor;
    };

    initLicenseAdaptor = () => {
        this.licensesAdaptor = new TagsAdaptor(software_products, this.licenses, this.addLicense, this.removeLicense, Dlc.filterSoftware,false);
        return this.licensesAdaptor;
    };

    initTrialsAdaptor = () => {
        this.trialsAdaptor = new LimitedTagsAdaptor(trial_period, this.trials, this.addTrial, this.removeTrial, Dlc.filterSoftware, this.licenses ,false);
        return this.trialsAdaptor;
    };

    initMasterAdaptor = () : FormFieldAdaptor<boolean> => {
        this.masterAdaptor = new BooleanFieldAdaptor(master_version, this.setMaster, this.master,false, this.master ? has_master : no_master);
        return this.masterAdaptor;
    };

    updateAdaptor = ()=> {
        this.onDataChanged!(new FormAdaptor(
            [
                this.quantityAdaptor!,
                this.sellerAdaptor!,
                this.sellerNameAdaptor!,
                this.licensesAdaptor!,
                this.trialsAdaptor!,
                this.masterAdaptor!
            ],
            this
        ) );
    };

    connect(connector : (adaptor : IFormAdaptor) => void) : void {
        this.onDataChanged = connector;
    }

    createFormAdaptor() : FormAdaptor {
        return new FormAdaptor(
            [
                this.initQuantityAdaptor(),
                this.initSellerAdaptor(),
                this.initSellerNameAdaptor(),
                this.initMasterAdaptor(),
                this.initLicenseAdaptor(),
                this.initTrialsAdaptor()
            ],
            this
        )
    }

    getFormAdaptor() : IFormAdaptor {
        return this.adaptor;
    }

    getMethod() : Method {
        return Method.POST;
    }

    message = () : string => {
        return JSON.stringify(this,['quantity','seller','sellerName','licenses','trials','master']);
    };

    getType = () : DataType => {
        return DataType.CreateImpersonalSchool;
    };
}
