import {Protocol} from "../model/Request";

export const DEVELOPMENT_URL : string = 'localhost:8080/pub/';
//export const DEVELOPMENT_URL : string = 'nau-mag.com:8080/pub/';
//export const DEVELOPMENT_URL : string = '10.100.102.2:8080/pub/';

export const PRODUCTION_URL : string = 'nau-mag.com/pub/';
export const KAZ_PRODUCTION_URL : string = 'kz.nau-mag.com/pub/';
export const JAPAN_PRODUCTION_URL : string = 'savanto.me/pub/';
//export const PRODUCTION_URL : string = '54.93.73.56:8080/pub/';

export const STAT_PRODUCTION_URL : string = '62.109.29.18:8001';
export const STAT_DEVELOPMENT_URL : string = 'localhost:8001';

export const PRODUCTION_HOME_URL : string = 'nau-mag.com';
export const KAZ_PRODUCTION_HOME_URL : string = 'kz.nau-mag.com';
export const JAPAN_PRODUCTION_HOME_URL : string = 'savanto.me';

export const DEVELOPMENT_HOME_URL : string = 'localhost:3000';

export const REGISTER_URL = '/school/register';
export const MY_URL = '/school/my'
export const DOWNLOADS_URL = '/downloads'
export const APPLICATION_URL ='/admin/school/'
export const MANAGER_REGISTER = '/manager/register'

export const RUSSIAN = 'russian'
export const KAZAKHSTAN = 'kazakhstan'
export const JAPAN = 'japan'

export function getProductionUrl() : string {
    if (process.env.REACT_APP_LOCATION === RUSSIAN)
        return PRODUCTION_URL;
    if (process.env.REACT_APP_LOCATION === KAZAKHSTAN)
        return KAZ_PRODUCTION_URL;
    if (process.env.REACT_APP_LOCATION === JAPAN)
        return JAPAN_PRODUCTION_URL;
    return PRODUCTION_URL;
}

export function getProductionHomeUrl() : string {
    if (process.env.REACT_APP_LOCATION === RUSSIAN)
        return PRODUCTION_HOME_URL;
    if (process.env.REACT_APP_LOCATION === KAZAKHSTAN)
        return KAZ_PRODUCTION_HOME_URL;
    if (process.env.REACT_APP_LOCATION === JAPAN)
        return JAPAN_PRODUCTION_HOME_URL;
    return PRODUCTION_HOME_URL;
}

export function getBaseUrl():string {
    return process.env.NODE_ENV === 'development' ? DEVELOPMENT_URL : getProductionUrl();
}

export function getStatUrl():string {
    return process.env.NODE_ENV === 'development' ? STAT_DEVELOPMENT_URL : STAT_PRODUCTION_URL;
}

export function getHome():string {
    return getProtocol() + (process.env.NODE_ENV === 'development' ? DEVELOPMENT_HOME_URL : getProductionHomeUrl());
}

export function getProtocol():Protocol {
    return process.env.NODE_ENV === 'development' ? Protocol.Http : Protocol.Https;
}

export function getRegUrl(): string{
    return getHome() + REGISTER_URL;
}

export function getDownloadsUrl(): string{
    return getHome() + DOWNLOADS_URL;
}

export function getManagerRegUrl() : string {
    return getHome() + MANAGER_REGISTER;
}
