import {IClientInfo} from "../service/ClientInfo";

export interface ILoginRequest {
    username : string;
    password : string;
    browser : IClientInfo
    getMessage() : string;
}

export class LoginRequest implements ILoginRequest{
    username: string;
    password: string;
    browser: IClientInfo;

    constructor(username: string, password: string, browser: IClientInfo) {
        this.username = username;
        this.password = password;
        this.browser = browser;
    }
    getMessage(): string {
        return JSON.stringify(this);
    }
}

export class RegisterRequest extends LoginRequest{
    classId : number|null;

    constructor(username: string, password: string, browser: IClientInfo, classId: number | undefined) {
        super(username, password, browser);
        this.classId = classId === undefined ? null : classId;
    }
}