import React, {FC, PureComponent} from "react";
import {AppData, Vocabulary} from "../../model/AppData";
import {Button, Callout, Card, Classes, Dialog, FormGroup, InputGroup, Intent, ProgressBar} from "@blueprintjs/core";
import {
    Exception,
    ExceptionType,
    ExceptionViewInfo,
    getExceptionViewInfo,
    noException,
    onException
} from "../../actions/error";
import {ACCESS_CODE_LENGTH} from "./EmailLoginInstruments";
import {AppToaster} from "../../service/toaster";
import {
    access_code_description,
    code_accepted,
    continue_label,
    input_access_code,
    operation_success
} from "../../text/Literals";
import RequestButton from "../RequestButton";
import {DataType} from "../../actions/data";
import {Method, Request} from "../../model/Request";
import {connect} from "react-redux";
import {AppState} from "../../store/configureStore";
import {IManager} from "../../api/Manager";

export interface ManagerRegToken {
    exception : React.ReactNode,
    token ?: string;
    manager ?: IManager;
    onSuccess : (msg ?: string) => void;
    data : AppData;
    message : (msg: string|undefined, intent : Intent, plain : string | undefined) => void;
    reset : () => void;
}

interface ManagerRegTokenInstrumentProps {
    vocabulary : Vocabulary,
    exception : Exception,
    noException : any,
    onException : any,
    data : AppData,
    content : FC<ManagerRegToken>,
    title ?: string
}

interface InputOneTimeTokenInstrumentState {
    token ?: string
    tokenVerified : boolean
    manager ?: IManager;
}

class ManagerRegTokenInstrument extends PureComponent<ManagerRegTokenInstrumentProps, InputOneTimeTokenInstrumentState> {
    constructor(props : Readonly<ManagerRegTokenInstrumentProps>) {
        super(props);
        this.state = {
            token : '',
            tokenVerified : false,
            manager : undefined
        }
    }

    setAccessCode = (token : string) =>{
        this.setState({...this.state, token})
    }

    isAccessCodeValid = () :boolean =>{
        return !!this.state.token && this.state.token.length === ACCESS_CODE_LENGTH
    }

    message = (msg: string|undefined, intent : Intent, plain : string ="" )=>{
        let text = msg ? this.props.vocabulary[msg] + plain : plain;
        AppToaster.show({
            icon: "comment",
            intent: intent,
            message: text
        });
    }

    onTokenVerified = (msg : string | undefined) => {
        if (msg) {
            try {
                let managers : IManager [] = JSON.parse(msg)
                if (managers.length !== 1) {
                    this.props.onException(ExceptionType.ToManyOperations);
                } else {
                    this.setState({...this.state, tokenVerified : true, manager : managers[0]})
                    this.message(code_accepted, Intent.SUCCESS);
                    return
                }
            } catch (e) {
                console.log(e)
            }
        }
        this.setState({
            tokenVerified : false,
            token : '',
            manager : undefined
        })
    }

    exception = () => {
        if (this.props.exception.type === ExceptionType.NoException) {
            if (this.props.title && (!this.state.tokenVerified )){
                return  <Card  className='message--top info'>
                    {this.props.vocabulary[this.props.title]}
                </Card>
            }

            return null;
        }
        const viewInfo : ExceptionViewInfo = getExceptionViewInfo(this.props.exception);
        return <Card  className='message--top' interactive={true} onClick={this.props.noException}>
            <Callout
                intent={viewInfo.intent}
                icon={viewInfo.icon}
                title={viewInfo.title ? this.props.vocabulary[viewInfo.title] : undefined}>
                {this.props.vocabulary[viewInfo.message]}
            </Callout>
            {
                this.props.exception.type === ExceptionType.Processing ? <ProgressBar intent={Intent.PRIMARY}/> : null
            }
        </Card>
    }

    resetForm = () => {
        this.props.noException();
        this.setState({...this.state, tokenVerified : false, token : '', manager : undefined})
    }

    tokenForm = () => {
        return  <Dialog
            transitionDuration={200}
            isOpen={true}
            canEscapeKeyClose={false}
            canOutsideClickClose={false}
            className="login-container"
            backdropClassName='login-background'
        >
            {this.exception()}
            <div className="bp3-dialog-body">
                <FormGroup
                    label={<Callout intent={Intent.SUCCESS} className='login--text-span'>{this.props.vocabulary[access_code_description]}</Callout>}>
                    <InputGroup
                        type = 'number'
                        className="login-fields"
                        placeholder={this.props.vocabulary[input_access_code]}
                        rightElement={<Button icon='key' intent={Intent.SUCCESS}/>}
                        onChange={(event : React.FormEvent<HTMLInputElement>) => {this.setAccessCode(event.currentTarget.value)}}
                    />
                </FormGroup>
                <div className={Classes.DIALOG_FOOTER}>
                    < div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <RequestButton
                            disabled={!this.isAccessCodeValid() || this.props.exception.type === ExceptionType.Processing}
                            type={DataType.MyPartners}
                            request={new Request(undefined, DataType.MyPartners, Method.GET, this.state.token)}
                            intent={Intent.SUCCESS}
                            default={true}
                            simple={true}
                            onSuccess={this.onTokenVerified}
                            onError={console.log}>
                            {this.props.vocabulary[continue_label]}
                        </RequestButton>
                    </div>
                </div>
            </div>
        </Dialog>
    }

    loginSuccess = () => {
        this.message(operation_success, Intent.SUCCESS);
    }

    render() {
        return !this.state.tokenVerified ?
            this.tokenForm() :
            this.props.content({
                token : this.state.token,
                manager : this.state.manager,
                exception : this.exception(),
                onSuccess : () => {
                    this.resetForm()
                    this.loginSuccess()
                },
                message : this.message,
                data : this.props.data,
                reset : this.resetForm
            })
    }
}

export default connect((state : AppState) => ({
    data : state.data,
    vocabulary: state.data.vocabulary,
    exception : state.error,}),{noException, onException})
(ManagerRegTokenInstrument)