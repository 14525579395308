import {getBaseUrl, getProtocol, getStatUrl} from "../api/ServerAddress";
import {DEFAULT_CONTENT_TYPE, DEFAULT_TIME_OUT} from "../service/Connection";

export interface IRequest {
    getMessage() : string | undefined;
    getUrl() : string;
    getMethod() : Method;
    getAuthorization() : string | undefined;
    getContentType () : string;
    timeout : number;
}
export enum Method {
    GET,
    POST,
    PUT,
    DELETE,
    SAVE,
    HEAD,
    OPTIONS,
    CONNECT,
    CHECK
}
export enum Protocol{
    Http = 'http://',
    Https ='https://'
}

export class Request implements IRequest{
    message : string | undefined;
    url :  string;
    method : Method;
    authorization: string | undefined;
    _contentType: string;
    timeout : number;
    protected _protocol : Protocol;
    protected _baseUrl : string;

    constructor(request: string | undefined, url: string, method: Method,
                authorization : string | undefined=undefined, contentType : string = DEFAULT_CONTENT_TYPE) {
        this.message = request;
        this.url = url;
        this.method = method;
        this.authorization = authorization;
        this._protocol = getProtocol();
        this._baseUrl = getBaseUrl();
        this._contentType = contentType;
        this.timeout = DEFAULT_TIME_OUT;
    }

    getMessage(): string | undefined{
        return this.message;
    }

    getMethod(): Method {
        return this.method;
    }

    getUrl(): string {
        return this.protocol + this._baseUrl + this.url;
    }

    getAuthorization(): string | undefined {
        return this.authorization;
    }


    get protocol(): Protocol {
        return this._protocol;
    }

    set protocol(value: Protocol) {
        this._protocol = value;
    }


    get baseUrl(): string {
        return this._baseUrl;
    }

    set baseUrl(value: string) {
        this._baseUrl = value;
    }

    getContentType(): string {
        return this._contentType;
    }
}

export class StatRequest extends Request{

    constructor(request: string | undefined, url: string, method: Method,
                authorization : string | undefined=undefined, contentType : string = DEFAULT_CONTENT_TYPE) {
        super(request, url, method, authorization, contentType);
        this._protocol = Protocol.Http;
        this._baseUrl = getStatUrl();
        this.timeout = 0;
    }
}