import {BasicResponse, Vocabulary} from "../../model/AppData";
import {TokenHolder} from "../tools/ChangePasswordWindow";
import {match} from "react-router";
import React, {PureComponent} from "react";
import {sendRequest} from "../../thunks/sendRequest";
import {AppState} from "../../store/configureStore";
import {connect} from "react-redux";
import {Method, Request} from "../../model/Request";
import {DataType} from "../../actions/data";
import {Button, Callout, Classes, Dialog, Intent} from "@blueprintjs/core";
import {OK} from "../../service/Literals";
import {email_not_verified, email_verified, waiting_server_response} from "../../text/Literals";
import {getHome} from "../../api/ServerAddress";

interface EmailVerificationWindowProps{
    match : match<TokenHolder>;
    sendRequest : any;
    response ?: BasicResponse;
    vocabulary : Vocabulary;
}


class EmailVerificationWindow extends PureComponent<EmailVerificationWindowProps>{
    token : string;

    constructor(props: EmailVerificationWindowProps, context: any) {
        super(props, context);
        this.token = props.match.params.token;
        this.verify(this.token);
    }

    render(){
        const intent = this.props.response ? (this.props.response.value === OK ? Intent.SUCCESS :Intent.DANGER) : Intent.PRIMARY;
        const title = this.props.response  ? (this.props.response.value === OK ? email_verified:email_not_verified)  : waiting_server_response;
        const details = this.props.response ? (this.props.response.value === OK ? this.props.vocabulary[email_verified]: this.props.response.details)  : this.props.vocabulary[waiting_server_response];
        return <Dialog
            isOpen={true}
            canEscapeKeyClose={false}
            canOutsideClickClose={false}
            backdropClassName='login-background'
        >
            <div className="bp3-dialog-body">
                 <Callout intent={intent} title={this.props.vocabulary[title]}>
                     {details}
                 </Callout>
            </div>
            <div className={Classes.DIALOG_FOOTER}>
                <a href={getHome()}>
                    <Button icon='home' intent={Intent.PRIMARY} className='fill-width' />
                </a>
            </div>
        </Dialog>;
    }

    private verify(token: string) {
        this.props.sendRequest(new Request(undefined,DataType.VerifyEmail+token,Method.GET),DataType.VerifyEmail,undefined);
    }
}

const mapStateToProps = (state : AppState) =>({response : state.data.verificationResponse, vocabulary :state.data.vocabulary});

export default connect(mapStateToProps,{sendRequest})(EmailVerificationWindow);