import {ThunkAction} from "redux-thunk";
import {AppState} from "../store/configureStore";
import {receiveAuth} from "../actions/user";
import {send} from "../service/Connection";
import {createUserFromResponse, User} from "../model/User";
import {Action} from "redux";
import {RefreshRequest} from "../api/RefreshRequest";
import getClientInfo from "../service/ClientInfo";
import {Method, Request} from "../model/Request";
import {ExceptionType, onException} from "../actions/error";
import {AuthResponse} from "../api/AuthResponse";
import {becomeIdle} from "../actions/process";
import logout from "./logout";
import useInstrument from './useInstrument';
import {Instruments} from "../actions/instruments";
import {sendRequest} from "./sendRequest";
import {DataType} from "../actions/data";
import {changeMode} from "../actions/settings";
import firebaseLogin from "./firebaseLogin";
import {STORED_AUTH} from "../service/Literals";
import {isAdmin} from "../model/PathVariables";

export function getSavedRefreshToken() :string | undefined {
    const data: string | null =localStorage.getItem(STORED_AUTH);
    const json: AuthResponse | undefined =data ? JSON.parse(data) : undefined;
    return json ? json.refreshToken : undefined
}

export const REFRESH_INTERVAL : number = 1000000;
let timeoutID : number| undefined;

export function refresh (
    user : User,
    mode : string|undefined = undefined
    ) : Promise<AuthResponse>{
    console.log("refresh");
    const url = isAdmin(mode)  ? ADMIN_REFRESH_URL : USER_REFRESH_URL;
    const request : RefreshRequest = new RefreshRequest(getSavedRefreshToken() || user.refreshToken,getClientInfo());

    function clearTimeout() {
        if (timeoutID) {
            window.clearTimeout(timeoutID)
        }
    }

    return new Promise<AuthResponse>(async function (resolve, reject) {
        try {
            const response = await send(new Request(request.getMessage(), url, Method.POST, undefined));
            localStorage.setItem(STORED_AUTH, response.message);
            const json: AuthResponse = JSON.parse(response.message);
            clearTimeout();
            timeoutID = window.setTimeout(()=>{
                refresh(createUserFromResponse(json),mode);
            },REFRESH_INTERVAL);
            resolve(json);
        }catch (e) {
            clearTimeout();
            reject(e);
        }
    });
}

const refreshInitialAction= (
    user : User,
    instruments : Instruments|undefined,
    mode : string|undefined = undefined,
    id : number | undefined = undefined
): ThunkAction<void, AppState, null, Action> => async (dispatch, getState)  => {
    function onError(e : any) {
        console.log(e);
        if(e === undefined){
            dispatch(onException(0))
        } else{
            if(e.status === ExceptionType.Unauthorized || e.status === ExceptionType.DisabledUser){
                dispatch(logout());
                return;
            }
            dispatch(onException(e.status));
            dispatch(becomeIdle());
        }
    }
    const  inst : Instruments = instruments ? instruments : getState().instruments;
    try{
        dispatch(changeMode(mode));

        if(!user || !user.refreshToken){
            dispatch(useInstrument(inst.type,user,inst.entity));
            return
        }

        const auth : AuthResponse = await refresh(user,mode);
        dispatch(firebaseLogin(auth));
        dispatch(receiveAuth(auth));
        const newUser : User = createUserFromResponse(auth);
        if(id !== undefined){
            try{

                dispatch(sendRequest(new Request(undefined,DataType.AddClassroom+'/'+id,Method.PUT,newUser.token),DataType.AddClassroom, newUser));
            }catch (e) {
                onError(e);
            }
        }
        dispatch(useInstrument(inst.type,createUserFromResponse(auth),inst.entity));
    }catch (e) {
        dispatch(useInstrument(inst.type,user,inst.entity));
        onError(e);
    }
};

export default refreshInitialAction;

export const ADMIN_REFRESH_URL = 'admin/refresh';
export const USER_REFRESH_URL = 'user/refresh';