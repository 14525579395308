import {Vocabulary} from "../model/AppData";

 export  const baseVocabulary : Vocabulary = {
     downloads_label : 'Загрузки',
     start_download : 'Скачать',
     for_school_use :'Для школы',
     for_home_use:'Для частного использования',
     choose_platform:'Выберите операционую систему',
     platform_label:'Операционная система',
     choose_language:'Выберите язык',
     notify_new_version:'Получить уведомление о выходе новой версии',
     new_version:'Последняя версия',
     elder_versions:'Предыдущие версии',

     theory_label : "Теория",
     test_label : "Теситрование",
     presentation_label : "Доклад",
     video_label : "Видео",
     attachment_label : "Дополнительные материалы",

     server_unreachable : 'Не удалось связаться с сервером',
     no_network : 'Отсутствует соединение с сетью или сервер недоступен',
     operation_completed : 'Операция завершена',
     operation_success : 'Операция завершена успешно',
     authorization_failure : 'Не удалось авторизоваться на сервере',
     bad_credentials : 'Не верный логин или пароль',
     operation_denied : 'Операция не разрешена',
     permission_required : 'Недотаточно прав для выполнения операции',
     interface_label_301 : 'Пользователь заблокирован',
     contact_administration : 'Для выяснения причин свяжитесь с администрацией',
     operation_error : 'Ошибка операции',
     unprocessable_request : 'Запрос не может быть обработан на сервере',
     object_missing : 'Искомый объект не найден или не принадлежит вам',
     wrong_password : 'Неверный пароль',
     wrong_password_confirmation : 'Пароль и подтверждение не совпадают',
     bad_request : 'Неправильный запрос',
     bad_request_data : 'В запросе указаны некорректные данные',
     conflict : 'Конфликт',
     duplicated_id : 'Ресурс с таким идентификатором уже существует',
     data_in_use : 'Данные не могут быть удалены, пока на на них ссылаются другие данные',
     not_found : 'Ресурс не найден',
     no_data_found : 'По вашему запросу ничего не найдено',
     processing : 'Запрос обрабатывается',
     waiting_server_response : 'Запрос отправлен, программа ожидает ответ сервера...',

     wrong_format : ' Неверный формат',
     number_format_required : 'Значение должно быть целым числом больше нуля',
     not_empty_field : ' Поле не должно быть пустым',
     nothing_selected : 'Выберите элемент из списка',
     uniq_value_required : '. Значение дожно быть уникальным',
     not_valid_email : ' Неверный формат e-mail',
     not_valid_phone : ' Неверный формат номера телефона',

     no_homework: 'Ничего не задано',
     name_label: 'Название',
     entity_id : 'Идентификатор',
     message_for_class : 'Сообщение для класса',
     message_from_teacher : 'Сообщение от учителия',
     homework_label : 'Домашнее задание',
     edit_course : 'Редактировать курс',
     join_classroom_link : 'Ссылка для подключения учеников',
     picture_url:"Адрес изображения",
     teachers_nickname:"Ваше имя",
     school_label :"Учебное заведение",
     archived_class : "Класс перенесен в архив",


     username_label:'Имя пользователя/электронная почта',
     authorization_label_4 :'Пароль',
     delay_minutes : 'Отсрочка (минуты)',

     tier_label : 'Ценовая категория',
     included_label : 'Включен в другие товары',
     included_short_label : 'Включен в ',
     archived_label : ' (Архивный)',
     do_archive : 'Архивировать',
     do_unarchive : 'Активировать',

    // add_user_dlc : 'Добавить товар игроку',
     enabled_user : 'Не заблокирован',
     disable_user : 'Заблокировать',
     disabled_user : 'Заблокирован',
     enable_user : 'Разблокировать',
     email_verified : 'Почта подтверждена',
     email_not_verified : 'Почта не подтверждена',
     email_doesnt_exist : 'Почта не существует',
     email_exists : 'Почта существует',
     registration_date : 'Дата регистрации',
     device_model : 'Модель',
     software_products : 'Программные продукты',
     software_product_label : 'Программный продукт',
     authorization_enabled : 'Авторизация разрешена',
     disable_authorization : 'Запретить авторизацию',
     authorization_disabled : 'Авторизация запрещена',
     enable_authorization : 'Разрешить авторизацию',
     invalid_email_registration : 'Регистрации на несуществующие почты',
     invalid_email_limit : `Превышено количество ошибок \nверификации e-mail \nТекущее значение:`,// /n : new line
     decrease_counter : 'Уменьшить счетчик',
     operation_system : 'OS',
     processor_type : 'Процессор',
     memory_size : 'Объем памяти',
     gpu_device_name : 'Тип видеокарты',
     gpu_device_version : 'Версия видеокарты',
     gpu_memory_size : 'Объем видеопамяти',

     message_from_student : 'Сообщение учителю',

     total_label : 'Всего: ',
     registered_this_month : 'Зарегистрировано в этом месяце: ',
     registered_today : 'Зарегистрировано сегодня: ',

     product_key : 'Ключ продукта',
     city_label :'Город',
     address_label : 'Адрес',
     email_label :'e-mail',
     phone_label : 'Телефон',
     device_number : 'Количество оборудования',
     master_version : 'Расширенная версия',
     has_master : 'Есть расширенная версия',
     no_master : 'Нет расширенной версии',
     add_computers : '+ 10',
     additional_computers :"Дополнительные компьютеры",

     courses_label : `Учебная программа`,

     send_request : 'Отправить запрос',
     common_caption_26 : 'Удалить',
     add_label : 'Добавить',
     available_alternatives : 'Доступные варианты',
     create_dlc : 'Создание нового внутриигрового товара',
     edit_dlc : 'Редаткирование внутриигрового товара',
     dlc_id_required : 'Необходимо задать идентификатор товара \nостальные параметры опциональны',
     edit_label : 'Изменить',
     register_label : 'Зарегистрировать',
     logout_label : 'Выйти',

     change_message : 'Изменить сообщение',

     delete_user : 'Удаление пользователя',
     username_password_required : 'Нужно ввести имя пользователя и пароль',
     input_username : 'Введите имя пользователя',
     username_input_required : 'Необходимо ввести имя пользователя, информацию о котором нужно получить.',
     users_found : 'Найдено пользователей по запросу: ',
     license_purchases : 'Купленные лицензии ПО',
     license_purchase_date : 'Дата продажи',
     licenses_not_purchased : 'Лмцензий не куплено',
     seller : 'Продавец',
     cancelled : 'Отменена',
     active : 'Активна',
     canceller : 'Отменил',
     canceldate : 'Дата отмены',
     state : 'Состояние',

     search_for_school : 'Поиск школ',
     search_for_person : 'Поиск пользователей',
     school_search_help : 'В каждое поле можно ввести начало поисковой строки.\n' +
        'Пустая строка означает, что школы не будут отсеиваться по данному параметру.\n'+
        'Если все строки пусты, запрос вернет все зарегистрированные школы.',
     person_search_help : 'В каждое поле можно ввести начало поисковой строки.\n' +
         'Пустая строка означает, что пользователи не будут отсеиваться по данному параметру.\n'+
         'Если все строки пусты, запрос вернет все зарегистрированные пользователи.',
     register_school : 'Регистрация нового учебного заведения',
     edit_school : 'Редаткирование учебного заведения',
     schools_found : 'Найдено школ по запросу: ',
     information_about_schools : 'Информация о школах',

     create_impersonal_schools : 'Создать обезличенные школы',
     create_impersonal_schools_quantity : 'Количество создаваемых обезличенных школ',
     seller_name_label : 'Имя продавца',
     created_impersonal_schools : 'Создано обезличенных школ: ',
     download_impersonal_schools : 'Выгрузить обезличенные школы в файл',

     classroom_creation : 'Создание нового класса',
     classroom_edit : 'Изменение класса',
     classroom_edit_help : 'Необходимо задать название класса и ваше имя, которое будут видеть ученики\nостальные параметры опциональны',
     create_classroom : 'Создать класс',
     edit_classroom : 'Изменить класс',
     no_classroom_here : 'У вас нет классов в этой школе',
     press_create_classroom : 'Для продолжения работы нажмите кнопку "Создать новый класс"',

     cancel_purchase : 'Отключить товар',
     players_dlc : 'Куплено игроком:',
     no_players_dlc : 'У игрока нет внутриигровых товаров',
     add_player_dlc : 'Добавление товара игроку',
     select_dlc : 'Необходимо выбрать товар из списка',
     add_user_dlc : 'Добавить товар игроку',

     desktop_label : 'Компьютер',
     handheld_label : 'Смартфон или планшет',
     unknown_device_type : 'Неизвестный тип устройства',
     show_less : 'Показать меньше',
     show_more : 'Показать больше',

     bad_input : 'Введены некорректные данные',
     hide_label : "Скрыть",
     show_label : "Показать",
     authorization_label_54 : "Введите пароль",
     authorization_label_55 : "Повторите пароль",
     authorization_label_11 : 'Войти',
     registration_label : 'Регистрация',
     change_to_register : 'У меня нет учетной записи, я хочу зарегистрироваться',
     change_to_login : 'У меня уже есть учетная запись, я хочу войти',
     password_doesnt_match : 'Пароль и повтор не совпадают',

     press_enter_send : 'Нажмите Enter, чтобы отправить запрос',

     course_label :"Курс",
     draft_label :"Черновик",
     lessons_label : "Уроки",

     is_homework_active : 'Видят ли ученики урок',
     homework_task : 'Текст задания, который увидят ученики',
     homework_active : 'Урок доступен ученикам',
     homework_inactive : 'Урок не активен, ученики его не видят',
     task_description : 'Описание задания',
     pictures_set : 'Наборы картинок',
     task_label : 'Задание',
     cardsapp_label_2 : 'Тема',
     task_type : "Тип задания",
     all_course : 'Учебные программы',
     classroom_label : 'Класс',
     classrooms_label : 'Классы',
     school_classroom_control : 'Управление классами школы',
     schools_label : 'Школы',
     managers : 'Менеджеры',
     school_instrument : "Инструмент для работы со школами",
     school_administration : 'Администрирование школ',
     dlc_title : 'Внутриигровые товары',
     player_info : 'Информация об игроке',
     dlc_stock_list : "Номенклатура внутриигровых товаров",
     admin_tools_label : 'Администрирование',
     player_info_description : "Для получения данных нужно ввести логин игрока",
     player_devices_count : "Количество устройств пользователя",

     name_label_1 : "Наураша в стране Наурандии",
     interface_label_16 : "Сохранить",

     name_label_2 : "Вселенная Научных Развлечений",
     name_label_3  : "Школа профессора Дроздова",
     name_label_4 : "Демоверсия с эмулятором",
     name_label_5 : "Робот НАУСТИМ",
     name_label_6 : "Наураша в стране Наурандии. Расширенная версия",

     show_old_versions : "Показать старые версии",
     hide_old_versions : "Скрыть старые версии",
     upload_file : "Загрузить файл",
     choose_local_file: "Выбрать файл",
     cancel_label : "Отмена",
     override_file : "Перезаписать файл",
     additional_languages : "Дополнительные языки",
     course_description : 'Описание курса',
     do_activate  : 'Сделать активным',
     do_inactivate : 'Сделать не активным',
     open_file : 'Открыть',

     code_label :"Программирование",
     lesson_topic : "Тема урока",
     lesson_text : "Содержание урока",
     no_video_lesson : "К уроку не прикреплено видео",
     no_file_lesson :"К уроку не прикреплены файлы",
     lesson_file : "Дополнительные материалы к уроку",

     create_course : 'Создать курс',
     course_creation : 'Создание нового курса',
     modules_label : 'Разделы',

     homework_nothing : 'Урок без домашнего задания',
     homework_video : 'Снять видео и прислать преподавателю',
     homework_tests : 'Пройти тестирование',
     homework_file : 'Прислать файл с домашним заданием преподавателю',
     purchased_courses : 'Мои курсы',
     get_school_metrics : 'Получить информацию о регистрациях школ',
     hide_school_metrics : 'Скрыть информацию о регистрациях',
     hardware_shipment_type : 'Тип поставки оборудования',
     download_additional_content : 'Скачать дополнительные материалы к уроку',
     page_classwork : 'Учебные материалы',
     no_homework_file : "Не добавлен файл домашнего задания",
     no_homework_video : "Не добавлена видеозапись домашнего задания",
     homework_file_label :"Загрузите файл домашнего задания",
     homework_video_label :"Загрузите видео домашнего задания",
     messages_label : "Сообщения",
     students_message : "Отправьте свой вопрос или сообщение",
     description_label : 'Описание',
     full_picture : "Большая картинка",
     thumbnail_picture :'Маленькая картинка',
     dlc_view_details : 'Отображение в игре',
     key_not_found : 'Ключ отсутствует  в словаре',
     delete_user_form: 'Форма удаление пользователей',
     operation_time_out : 'Превышено время ожидания',
     server_time_out : 'Ответ от сервера не был получен в течении допустимого времени.',
     wrong_file_type : 'Выбран не верный тип файла',
     add_player_course : 'Добавление курса игроку',
     select_course_dlc : 'Необходимо выбрать курс из списка',
     add_user_course : 'Добавить курс игроку',
     course_owner: 'Преподаватель',
     total_lessons: 'Количество уроков',
     current_product_version: 'Текущая версия: ',
     lesson_caption : 'Урок',
     homework_of_student :  'Домашнее задание студента',
     all_students_homework : 'Домашние задания по всем курсам',
     current_course_homework : 'Домашние задания по выбранному курсу',
     current_lesson_homework : 'Домашние задания по выбранному уроку',
     course_structure : 'Структура курса',
     teachers_message : "Ответ студенту",
     teachers_message_homework : "Отзыв на домашнее задание",
     teachers_homework_review : "Отзыв преподавателя",
     teachers_answer : "Ответ преподавателя",
     do_answer : 'Ответить',
     preview_label : 'Просмотр',
     press_to_update: 'Нажмите, чтобы обновить web-приложение.',

     total_students_course : "Всего студентов на курсе: ",
     new_students_registered : "Зарегеистрированы новые студенты: ",
     new_homework_received : "Получены домашние работы от студентов: ",
     new_messages_received : "Получены сообщения от студентов",
     message_was_sent : "Сообщение отправлено",
     homework_was_sent : "Домашнее задание отправлено",
     structure_editor : "Изменить структуру",
     modules_quantity : "Количество разделов",
     lessons_quantity : "Количество уроков в разделе",
     module_caption : "Раздел",
     add_course_modules : "Добавить модули",
     add_course_structure : "Добавить в курс заданное количество разделов и уроков",
     do_create_dlc : "Создать новый товар",
     edit_course_structure : "Добавить в курс заданное количество разделов и уроков. Внимания! Если в курсе есть несохраненные изменения, они будут утеряны!",
     do_add : "Добавить",
     homework_viewer : "Домашние задания студентов",
     upload_video : "Загрузить видео",
     choose_local_video: "Выбрать видео",
     review_label : "Проверить",
     do_study : "Изучать",

     forgot_password : "Я забыл пароль",
     link_was_send : "Ссылка для восстановления пароля, выслана на указанную электронную почву",
     upload_failed : 'Не удалось загрузить файл',
     file_uploaded: 'Файл загружен',
     no_need_to_save: 'Если имя файла не изменилось, сохранять урок не нужно.',
     send_to_review : 'Опубликовать',
     review_description : 'Отправить курс на ревью модератору',
     review_reject_description : 'Отклонить курс или изменения в курсе',
     review_message: 'Описание изменнений, сделанных в курсе',
     review_sent : "Курс успешно отправлен на ревью",
     review_status : 'Статус запроса',
     review_status_none: 'Запрос не обнаружен',
     review_status_request: 'Запрос отправлен',
     review_status_process: 'Запрос на публикацию курса рассматривается',
     review_status_rejected: 'Запрос на публикацию курса отклонен',
     review_status_accepted: 'Запрос на публикацию курса одобрен',
     review_time: 'Статус обновлен в: ',
     review_list_label: 'Курсы, ожидающие ревью',
     review_start: 'Начать ревью',
     review_under_list: 'Курсы, которые я модерирую',
     review_pass: 'Отказаться',
     review_see_original : 'Смотреть предыдущую версию',
     review_see_draft  : 'Смотреть новую версию',
     review_no_original : 'У этого курса нет предыдущей версии. Это первая заявка на публикацию.',
     review_no_original_short : 'Нет предыдущей версии',
     review_deny: 'Отклонить',
     review_accept: 'Одобрить',
     review_reject_message : 'Причины отклонения курса',
     review_courses_added : 'Добавлены новые курсы на ревью',
     review_courses_changed : 'Изменился статус заявок на ревью',
     file_modification_deny: 'Изменение файла запрещено',
     file_modification_prohibited: 'Данный файл используется в одном или нескольких курсах. Попробуйте загрузить файл под другим именем.',
     file_new_name : 'Сохранить под новым именем',
     file_used_name : 'Файл с выбранным именем используется в одном из опубикованных курсов. Сохраните файл под новым именем или переименуйте его на свой устройстве.',

     analytics_label : 'Аналитика',
     events_label : 'События',
     from_label : 'От: ',
     to_label : 'До: ',
     select_products : 'Выберите продукты из списка',
     editor_record_label : 'Из редактора Unity',
     details_button_label : 'Подробно',
     free_courses : 'Бесплатные курсы',
     get_free_courses : 'Получить курсы бесплатно',
     free_label : 'Бесплатно',
     free_course_added : 'Курс успешно добавлен',
     delete_draft_course : 'Удалить черновик курса',
     course_delete : 'Удаление курса: ',

     code_label_backward : 'Назад',
     select_program_language: 'Искать программы на языке: ',



     vitrine_label : 'Реклама в игре',
     vitrine_info : 'Инструмент для настройки рекламы внути игры',
     picture_url_label : 'Адрес картинки',
     link_url_label : 'Ссылка на магазин',
     vitrine_button_label : 'Текст на кнопке',
     region_label : 'Регион',
     contact_label : 'Контактное лицо',
     start_analytics_processor : 'Запустить обработку статистики',
     interface_label_623 : 'Введите адрес электронной почты',
     authorization_label_12  : 'Зарегистрироваться',

     changes_saved:'Изменения сохранены',
     login_with_email : 'Для отправки запроса введите адресс электронной почты, на него будет выслан код доступа',
     continue_label : 'Продолжить',
     access_code_description: 'Код доступа вылан на ваш электронный адрес, введите его для входа в систему',
     input_access_code : 'Введите код доступа',
     code_accepted : 'Код доступа принят',
     register_info_hint : 'Укажите номер договора или любую другую информацию, которая поможет сотруднику службы поддержки идентифицировать ваше учебное заведение',
     school_register_success: 'Вы успешно зарегистрировались в системе. Оператор поддержки свяжется с вами по указанному адресу электронной почты и сообщит об активации вашего акаунта',
     your_product_key: 'Ваш лицензионный ключ доступа будет выслан вам службой поддержки',
     too_many_registrations: 'Превышено допустимое количество регистраций на данную электронную почту',
     comment_label : 'Комментарий',

     school_found_message : 'Количество учебных завдений, зарегестрированных на ваш электронный адресс:',
     school_not_found : 'На ваш адрес электронной почты не зарегистрированна ни одна школа',
     trial_period : 'Открыть пробный период на продукты: ',
     license_expiration  : 'Срок доступа к программному продукту истекает: ',
     cards_app_school : 'Школа профессора Дроздова',
     school : 'Наураша в стране Наурандии',
     robot_key : 'Цифровой робототехнический полигон для обучения программированию',
     trial_not_allowed : 'Пробный период не доступен или был использован ранее',
     register_shd : 'Заригистрируйтесь, чтобы получить пробный период в "Школе профессора Дроздова"',
     register_shd_new : 'Получить пробный период в "Школе профессора Дроздова" для нового учебного заведения',
     register_shd_comment : 'Как вы узнали о "Школе профессора Дроздова"?',
     shd_trial : 'Получить пробный период "Школы профессора Дроздова"',
     logopedia_trial : 'Получить пробный период «ЛогоТолк»',
     add_trial_school : 'Для учебного заведения: ',
     add_trial : 'Добавить пробный период',
     add_or_register : 'Заявка на пробный период',
     add_trial_success : 'Пробный период добавлен',
     shd_for_students : 'Школа дроздова для учеников',
     shd_for_teachers : 'Школа дроздова для учителей',
     download_from_site : 'Скачайте с нашего сайта',

     download_selected_schools : 'Выгрузить выбранные школы в файл',
     register_more_school : 'Зарегестрировать новое учебное заведение',
     interface_language : 'Язык интерфейса:',
     school_name : 'Название учебного завдедения',
     with_licenses : 'Искать школы с лицензиями на программные продукты:',
     without_licenses : 'Искать школы без лицензии на программные продукты:',
     will_expire_licenses : 'Искать школы с истекающими лицензиями на программные продукты:',



     email_bounced : 'Email отклоняет письма',
     email_subject : 'Тема письма',
     email_body : 'Текст письма',
     email_recipients : 'Адресаты сообщения',
     email_list_label : 'Отправка писем всем адресатам',
     email_list : 'Рассылка выбранным школам',
     invalid_or_bounced : 'Выбранный email введен некорректно или отклоняет письма',
     invalid_count_filtered : 'Отсеяно некорректных email: ',
     email_sent_count : 'Отправлено писем: ',
     invalid_count : 'Некорректных email: ',
     mx_not_found : 'Не найдено серверов почты: ',
     no_school_user : 'Некорректно зарегистрированные email: ',
     mail_sent_results : 'Результаты отправки рассылки',
     school_registration_form : 'Зарегистрируйте учебное заведение, чтобы получить доступ к программным продуктам компании "Научные развлечения"',
     drozdov_registration_form : 'Зарегистрируйте учебное заведение, чтобы получить пробный период в "Школе профессора Дроздова"',
     school_view_form: 'Получение сведений об учебном заведении',

     email_list_users : 'Рассылка выбранным пользователям',
     download_selected_users : 'Выгрузить выбранных пользователей в файл',

     all_product_downloads : 'Все программы производства компании "Научные развлечения" можно скачать на портале загрузок',
     all_downloads: 'Все загрузки',
     cardsapp_speech_61 : 'Устройства',

     input_hardware_id : 'Введите системный id устройства',
     hardware_search : 'Поиск устройств и их пользователей по идентефикатору железа',
     product_check_label : 'Проверка программного продукта. Будет осуществлен поиск устройств, которые эксплуатируются двумя и более пользователями',
     sensors_label : "Датчики",
     username_get_metrics : 'Введите имя пользователя для поиска данных по использованию датчиков',
     usage_metrics_label : 'Статистика использования датчиков выбранным пользователем',
     sensor_usage_count : 'Количество включений сцен с датчиком',
     last_sensor_usage : 'Время последнего использование датчика',
     sensor_type_label : 'Тип датчика',
     product_report_label : 'Проверка программного продукта. Будет осуществлен поиск и подсчет пользователей, у которых нет датчиков',
     sensor_report_title : 'Результаты проверки использования датчика с выбранным продуктом',
     not_user_count : 'Количество пользователей без датчиков',
     sensor_user_count : 'Количество пользователей с датчиками',
     not_user_label : 'Пользователи без датчиков',
     total_product_users : 'Всего пользователей выбранного продукта',

     data_copied : 'данные скопированы',
     copy_ids : 'копировать ключи в буфер обмена',
     copy_emails_label : 'Копировать емэйлы в буфер обмена',

     verify_user : 'Подтвердить email',
     un_verify_user : 'Отменить подтверждение email',

     /*
    new portion of labels
    */
     active_licenses : 'Активные лицензии:',
     expired_licenses : 'Истёкшие лицензии:',
     del_test_devices : 'Удаление тестовых устройств',
     del_test_help : 'Введите id железа, для удаления всех записей устройств'

 };
