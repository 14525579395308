import React, {FC, PureComponent, ReactElement} from "react";
import {AppData, Vocabulary} from "../../model/AppData";
import {connect} from "react-redux";
import {AppState} from "../../store/configureStore";
import {Button, Callout, Card, Classes, Dialog, FormGroup, InputGroup, Intent, ProgressBar} from "@blueprintjs/core";
import {
    access_code_description, code_accepted,
    continue_label, input_access_code,
    input_email, label_backward,
    login_with_email,
    message_was_sent, operation_success, school_register_success, your_product_key
} from "../../text/Literals";
import RequestButton from "../RequestButton";
import {DataType,receiveRawData} from "../../actions/data";
import {Method, Request} from "../../model/Request";
import {isEmail} from "../../adaptors/FieldAdaptor";
import {Exception, ExceptionType, ExceptionViewInfo, getExceptionViewInfo, noException, onException} from "../../actions/error";
import {AppToaster} from "../../service/toaster";
import {Txt,Text} from "../tools/Text";
import {ISchool} from "../../api/School";
import getDownloads from "../../thunks/getDownloads";

export interface LoggedInWithEmailProps {
    exception : React.ReactNode,
    email ?: string;
    token ?: string;
    onSuccess :(msg ?: string)=> void;
    reset :()=> void;
    data : AppData;
    message : (msg: string|undefined, intent : Intent, plain : string | undefined)=> void;
}

interface EmailLoginInstrumentProps {
    vocabulary : Vocabulary,
    exception : Exception,
    registeredKey ?: string,
    noException : any,
    onException : any,
    receiveRawData : any,
    getDownloads : any
    content : FC<LoggedInWithEmailProps>,
    finalContent ?: ReactElement,
    data : AppData,
    title ?: string
}

interface EmailLoginInstrumentState{
    email : string
    token ?: string
    tokenVerified : boolean
    emailSent : boolean
    raw ? :string
}

export const ACCESS_CODE_LENGTH = 6;
export const SCHOOL_PER_EMAIL_LIMIT = 5;

export type Initializer = ((email: string, token : string) => any)

class EmailLoginInstruments extends PureComponent<EmailLoginInstrumentProps, EmailLoginInstrumentState>{

    constructor(props: Readonly<EmailLoginInstrumentProps>) {
        super(props);
        this.state={
            email : '',
            token : '',
            tokenVerified : false,
            emailSent : false
        }
    }

    setEmail = (email : string) =>{
        this.setState({...this.state,email})
    }

    resetEmail = ()=>{
        this.props.noException();
        this.setState({...this.state, emailSent : false})
    }

    setAccessCode = (token : string) =>{
        this.setState({...this.state, token})
    }

    isAccessCodeValid = () :boolean =>{
        return !!this.state.token && this.state.token.length === ACCESS_CODE_LENGTH
    }

    message = (msg: string|undefined, intent : Intent, plain : string ="" )=>{
        let text = msg ? this.props.vocabulary[msg] + plain : plain;
        AppToaster.show({
            icon: "comment",
            intent: intent,
            message: text
        });
    }

    onEmailSent = ()=>{
        this.message(message_was_sent,Intent.SUCCESS);
        this.setState({...this.state,emailSent : true})
    }

    onTokenVerified = (msg : string|undefined)=> {
        if (msg){
            try {

                this.props.receiveRawData(DataType.MySchool,msg);
                let schools: ISchool [] = JSON.parse(msg)

                if (schools.length >= SCHOOL_PER_EMAIL_LIMIT){
                    this.props.onException(ExceptionType.ToManyOperations);
                    this.setState({
                        email : '',
                        tokenVerified : false,
                        emailSent : false,
                        token : ''
                    })
                    return
                }
            } catch (e) {
                console.log(e)
            }
        }
        this.props.getDownloads(false);
        this.message(code_accepted,Intent.SUCCESS);
        this.setState({...this.state,tokenVerified : true})
    }

    registrationSuccess = ()=>{
        this.message(operation_success,Intent.SUCCESS);
    }

    resetForm = ()=>{
        this.props.noException();
        this.setState({...this.state, emailSent : false, tokenVerified : false, token : ''})
    }

    rewriteRaw = (raw ?: string) => {
        this.setState({...this.state,raw })
    }

    emailForm =()=>{
        return  <Dialog
            isOpen={true}
            canEscapeKeyClose={false}
            canOutsideClickClose={false}
            className=" login-container"
            backdropClassName='login-background'
        >
            {this.exception()}
            <div className="bp3-dialog-body">
                <FormGroup
                    label={<Callout intent={Intent.PRIMARY} className='login--text-span'>{this.props.vocabulary[login_with_email]}</Callout>}>
                    <InputGroup
                        type = 'text'
                        className="login-fields"
                        placeholder={this.props.vocabulary[input_email]}
                        rightElement={<Button icon='envelope' intent={Intent.PRIMARY}/>}
                        onChange={(event : React.FormEvent<HTMLInputElement>)=>
                        {this.setEmail(event.currentTarget.value)}}
                    />
                </FormGroup>
                <div className={Classes.DIALOG_FOOTER}>
                    < div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <RequestButton
                            disabled={!isEmail(this.state.email) || this.props.exception.type === ExceptionType.Processing}
                            type={DataType.MySchool}
                            request={new Request(JSON.stringify({"email": this.state.email}),DataType.EmailLogin,Method.POST)}
                            intent={Intent.PRIMARY}
                            default={true}
                            simple={true}
                            onSuccess={this.onEmailSent}
                            onError={console.log}>
                            {this.props.vocabulary[continue_label]}
                        </RequestButton>
                    </div>
                </div>
            </div>
        </Dialog>
    }

    tokenForm =()=>{
        return  <Dialog
            transitionDuration={200}
            isOpen={true}
            canEscapeKeyClose={false}
            canOutsideClickClose={false}
            className="login-container"
            backdropClassName='login-background'
        >
            {this.exception()}
            <div className="bp3-dialog-body">
                <FormGroup
                    label={<Callout intent={Intent.SUCCESS} className='login--text-span'>{this.props.vocabulary[access_code_description]}</Callout>}>
                    <InputGroup
                        type = 'number'
                        className="login-fields"
                        placeholder={this.props.vocabulary[input_access_code]}
                        rightElement={<Button icon='key' intent={Intent.SUCCESS}/>}
                        onChange={(event : React.FormEvent<HTMLInputElement>)=>
                        {this.setAccessCode(event.currentTarget.value)}}
                    />
                </FormGroup>
                <div className={Classes.DIALOG_FOOTER}>
                    < div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Button
                            intent={Intent.WARNING}
                            icon='arrow-left'
                            onClick={this.resetEmail}>
                            {this.props.vocabulary[label_backward]}
                        </Button>
                        <RequestButton
                            disabled={!this.isAccessCodeValid() || this.props.exception.type === ExceptionType.Processing}
                            type={DataType.EmailLogin}
                            request={new Request(undefined,DataType.MySchool,Method.GET,this.state.token)}
                            intent={Intent.SUCCESS}
                            default={true}
                            simple={true}
                            onSuccess={this.onTokenVerified}
                            onError={console.log}>
                            {this.props.vocabulary[continue_label]}
                        </RequestButton>
                    </div>
                </div>
            </div>
        </Dialog>
    }

    done =()=>{
        return  <Dialog
            transitionDuration={200}
            isOpen={true}
            canEscapeKeyClose={false}
            canOutsideClickClose={false}
            className=" login-container"
            backdropClassName='login-background'
        >
            <div className="bp3-dialog-body">
                <Callout intent={Intent.SUCCESS} title={this.props.vocabulary[school_register_success]}>
                    <Text text={new Txt().dict(your_product_key)} />
                </Callout>
            </div>
            {this.props.finalContent }
        </Dialog>
    }

    exception =()=>{
        if(this.props.exception.type === ExceptionType.NoException) {
            if (this.props.title && (!this.state.emailSent || !this.state.tokenVerified )){
                return  <Card  className='message--top info'>
                        {this.props.vocabulary[this.props.title]}
                </Card>
            }

            return null;
        }
        const viewInfo : ExceptionViewInfo = getExceptionViewInfo(this.props.exception);
        return <Card  className='message--top' interactive={true} onClick={this.props.noException}>
            <Callout
                intent={viewInfo.intent}
                icon={viewInfo.icon}
                title={viewInfo.title ? this.props.vocabulary[viewInfo.title] : undefined}>
                {this.props.vocabulary[viewInfo.message]}
            </Callout>
            {
                this.props.exception.type === ExceptionType.Processing ? <ProgressBar intent={Intent.PRIMARY}/> : null
            }
        </Card>
    }

    render() {
        return this.props.registeredKey?
            this.done() :
            !this.state.emailSent ?
                this.emailForm() :
                !this.state.tokenVerified ?
                    this.tokenForm() :
                    this.props.content({
                        token : this.state.token,
                        email : this.state.email,
                        exception : this.exception(),
                        onSuccess : this.registrationSuccess,
                        reset : this.resetForm,
                        message : this.message,
                        data : this.props.data
                    })
    }
}

export default connect((state : AppState)=>({
    data : state.data,
    vocabulary: state.data.vocabulary,
    exception : state.error,
    registeredKey : state.data.registeredKey}),{noException, onException, receiveRawData,getDownloads})
(EmailLoginInstruments)