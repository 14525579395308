import {AppData} from "../model/AppData";
import {FieldAdaptor} from "./FieldAdaptor";
import {RequestData} from "./RequestData";
import {AppState} from "../store/configureStore";

/*
  Provides all data for creating request and save forms
 */

export interface IFormAdaptor {
    fields : FieldAdaptor[];
    isValid():boolean;
    message() : string;
    setData(appData : AppData) :void;
    save() :void;
    saveNoPost() :void;
    onExpand(sendRequest : any,  state: AppState):void;
    wasChanged() : boolean;
}

export class FormAdaptor implements IFormAdaptor{
    protected readonly _fields: FieldAdaptor[];
    protected readonly _data : RequestData;

    constructor(fields: FieldAdaptor[], data : RequestData) {
        this._fields = fields;
        this._data = data;
    }

    isValid(): boolean {
        for(let i=0; i<this._fields.length; i++) {
            if(this._fields[i].errorMessage() !== undefined) return false;
        }
        return true;
    }

    message(): string {
        return this._data.message();
    }

    setData(appData : AppData) :void{
        for(let i=0; i<this.fields.length; i++) this.fields[i].setData(appData);
    }

    add(adaptor : FieldAdaptor){
        this.fields.push(adaptor);
    }

    save =()=>{
        if(this._data.save !== undefined) this._data.save();
    };

    saveNoPost =()=>{
        if(this._data.saveNoPost !== undefined) this._data.saveNoPost();
    };

    get fields(): FieldAdaptor[] {
        return this._fields;
    }

    onExpand(sendRequest : any,  state: AppState): void {
        if(this._data.onExpand) this._data.onExpand(sendRequest,state);
    }

    wasChanged(): boolean {
        return this._data.wasChanged();
    }
}
