import {Action} from "redux";
import {IModule} from "../model/courses/Module";
import {isAdmin} from "../model/PathVariables";

export interface Settings {
    language : string;
    mode ?: string;
    coursesPositions :CoursesPositions;
    productsToAnalyze : string[];
    registrationFirst ?: boolean;
}

export enum SettingsType {
    SetLanguage,
    SetMode,
    SetCoursePosition,
    AddProductToAnalyze,
    RemoveProductToAnalyze,
    SetRegistrationFirst,
    SetLoginFirst
}

export interface CourseViewerPosition {
    selectedModule : number;
    selectedLesson : number;
    uuid : string;
}

export interface CoursesPositions {
    [key:string] : CourseViewerPosition;
}

export class CoursePosition implements CourseViewerPosition{
    selectedModule : number;
    selectedLesson : number;
    uuid : string;

    constructor(model : CourseViewerPosition|undefined = undefined,modules :IModule[]=[]) {
        if(model === undefined){
            this.selectedModule =0;
            this.selectedLesson =0;
        } else{
            this.selectedModule = model.selectedModule;
            this.selectedLesson = model.selectedLesson;
        }
        this.uuid = this.setUuid(modules);
    }

    is=(model : CourseViewerPosition)=>{
//        console.log(res);
        return model ? (this.selectedModule === model.selectedModule && this.selectedLesson === model.selectedLesson && model.uuid === this.uuid) : this.selectedModule === 0 && this.selectedLesson === 0 && this.uuid === '';
    };

    private setUuid(modules: IModule[]) {
        return this.uuid = modules[this.selectedModule]
            ? (modules[this.selectedModule].lessons[this.selectedLesson]
                ? modules[this.selectedModule].lessons[this.selectedLesson].uuid : ''): '';
    }

    backward=(modules :IModule[]):CoursePosition=>{
        const res : CoursePosition = this.copy(modules);
        if(res.selectedLesson > 0) {
            res.selectedLesson --;
        } else{
            res.selectedModule --;
            res.selectedLesson =modules[res.selectedModule].lessons.length-1
        }
        res.setUuid(modules);
        return res;
    };

    forward =(modules :IModule[]) :CoursePosition=>{
        const res : CoursePosition = this.copy(modules);
        if(res.selectedLesson < modules[res.selectedModule].lessons.length-1){
            res.selectedLesson ++;
        } else{
            res.selectedModule++;
            res.selectedLesson = 0;
        }
        this.setUuid(modules);
        return res;
    };

    isBackwardDisabled=():boolean=>{
        return this.selectedModule === 0 && this.selectedLesson === 0;
    };

    isForwardDisabled=(modules :IModule[]):boolean=>{
        return this.selectedModule >= modules.length -1 &&
            this.selectedLesson >= modules[modules.length -1 ].lessons.length -1;
    };

    copy=(modules :IModule[]):CoursePosition=>{
        return  new CoursePosition(this,modules);
    }
}


export interface SettingsAction extends Action<SettingsType>{

}
export interface SetStringAction extends SettingsAction{
    value: string
}

export interface SetCoursePositionAction extends SetStringAction{
    selectedModule : number;
    selectedLesson : number;
    uuid : string;
}

export function changeLanguage(value : string) : SetStringAction{
    return {type : SettingsType.SetLanguage, value}
}

export function changeMode(value : string="") : SetStringAction {
    return {type : SettingsType.SetMode, value}
}

export function addProduct(value : string) : SetStringAction {
    return {type : SettingsType.AddProductToAnalyze, value}
}

export function removeProduct(value : string) : SetStringAction {
    return {type : SettingsType.RemoveProductToAnalyze, value}
}

export function setCoursePosition(course : string, selectedModule : number, selectedLesson : number, uuid : string)  : SetCoursePositionAction{
    return {type : SettingsType.SetCoursePosition,value : course,selectedModule,selectedLesson, uuid }
}

export function getBackGroundStyle(mode: string | undefined) : string {
    return isAdmin(mode) ? 'bp3-dark' : '';
}

export function setRegistrationFirst(state : boolean) {
    return state ? {
        type : SettingsType.SetRegistrationFirst,
        value : ''
    } : {
        type : SettingsType.SetLoginFirst,
        value : ''
    }
}

export const ADMIN = 'admin';