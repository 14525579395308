import React, {PureComponent, ReactNode} from 'react';
import {connect} from "react-redux";
import {Button, IconName, Intent, Tooltip} from "@blueprintjs/core";
import {DataType} from "../actions/data";
import {MaybeElement} from "@blueprintjs/core/src/common/props";
import {Request} from "../model/Request";
import {sendRequest, simpleRequest} from "../thunks/sendRequest";
import {AppState} from "../store/configureStore";
import {press_enter_send} from "../text/Literals";
import {Vocabulary} from "../model/AppData";
import {FirebaseMessage} from "../model/common/FirebaseMessage";
import {firebaseSend} from "../thunks/firebase";
import {gotVoid,gotServerError} from "../actions/data";

interface GetRequestButtonProps {
    request : Request | FirebaseMessage;
    intent ?: Intent;
    icon ?: IconName | MaybeElement;
    type : DataType;
    sendRequest : any;
    disabled: boolean;
    default ?: boolean;
    className ?: string;
    onSuccess ?: (val : string|undefined)=>void;
    vocabulary : Vocabulary;
    firebaseSend : any;
    gotVoid : any;
    voidMessage ?: string;
    gotServerError: any;
    simpleRequest : any;
    simple ?: boolean;
    onError ?:(e : any)=>void;
    children?: ReactNode | undefined
}

class RequestButton extends PureComponent<GetRequestButtonProps>{

    componentDidMount(){
        if(this.props.default) document.body.addEventListener('keydown', this.handleKeyDown);
    };

    componentWillUnmount() {
        if(this.props.default) document.body.removeEventListener('keydown', this.handleKeyDown);
    };

    handleKeyDown=(event :KeyboardEvent) =>{
        if (event.code === 'Enter' && !this.props.disabled) {
            this.handleClick();
        }
    };

    handleClick=()=> {
        if (this.props.simple){
            this.props.simpleRequest(this.props.request, this.props.onSuccess, this.props.onError)
            return
        }

        const onSuccess :(val :string|undefined)=>void = (val :string|undefined)=>{
            if(this.props.voidMessage !== undefined){
                this.props.gotVoid(this.props.voidMessage);
            }
            if(this.props.onSuccess) {
                this.props.onSuccess(val);
            }
        }
        const onError : (e: any)=>void = e => {
            if(this.props.voidMessage === undefined)
                return;
            let msg : string = '';
            try{
                msg = JSON.parse(e.message).message;
            }catch (e) {

            }
            this.props.gotServerError(msg);
        }
        if(this.props.request instanceof Request){
            this.props.sendRequest(this.props.request, this.props.type, undefined, onSuccess,onError);
        }
        if(this.props.request instanceof FirebaseMessage){
            this.props.firebaseSend(this.props.request,onSuccess);
        }
    };
    render() {

        const button = <Button
            className={this.props.className}
            disabled={this.props.disabled}
            intent={this.props.intent}
            icon={this.props.icon}
            onClick={this.handleClick}>
            {this.props.children}
        </Button>;
        return (
             this.props.default ?
                <Tooltip content={this.props.vocabulary[press_enter_send]}>
                    {button}
                </Tooltip> : button
                );
    }

}
const mapStateToProps = (state: AppState) => ({
    vocabulary : state.data.vocabulary
});
export default connect(
    mapStateToProps,
    {sendRequest, simpleRequest, firebaseSend,gotVoid, gotServerError}
)(RequestButton);