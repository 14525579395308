
import {Vocabulary} from "../../model/AppData";
import {IImageAdaptor, ImageAdaptor, ImageSize} from "../../adaptors/ImageAdaptor";
import {getFromVocabulary} from "../../service/Vocabulary";
import {Intent} from "@blueprintjs/core";
import {CourseEntity} from "./Entity";

export interface LanguageValue {
    language : string;
    value : number;
}

export interface Slide {
    time : number;
    pictures : string[];
    times : LanguageValue [];
    text : string;
}

export interface IFact extends CourseEntity{
    image : string;
    speech : string;
    order : number;
    status : string;
    comment : string;
    tags : string[];
    resources : string[];
    slides : Slide [];
    require : string;
}

export interface SpeechHolder<T extends CourseEntity> {
    data : T;
    speeches :  Vocabulary;
}

export interface Fact extends SpeechHolder<IFact>{

}

export function getImageAdaptor(fact : Fact) : IImageAdaptor {
    return new ImageAdaptor(fact.data.image
        ,getFromVocabulary(fact.data.speech),getFromVocabulary(fact.data.label),
        Intent.PRIMARY,ImageSize.Large);
}

export function getPickImageAdaptor(fact : Fact) : IImageAdaptor {
    console.log(fact.data.speech);
    return new ImageAdaptor(fact.data.image
        ,getFromVocabulary(fact.data.speech),getFromVocabulary(fact.data.label),
        Intent.NONE,ImageSize.Normal);
}

export function getSpeech(fact : Fact) :string {
    return fact.speeches[fact.data.speech];
}