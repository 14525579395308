import React, {PureComponent} from "react";
import {AppState} from "../../store/configureStore";
import {connect} from "react-redux";
import PostRequestForm from "../forms/PostRequestForm";
import {Intent} from "@blueprintjs/core/lib/esm/common/intent";
import {DataType, onObjectDelete, onObjectWasUploaded} from "../../actions/data";
import {Txt} from "../tools/Text";
import {file_uploaded} from "../../text/Literals";
import {Method} from "../../model/Request";
import {S3Object, S3SearchRequest, toContentAdaptor} from "../../api/content/S3Search";
import ImageEditor from "../editors/ImageEditor";
import UploadFileDialog from "../tools/UploadFileDialog";
import {SaveFileResponse} from "../../api/SaveFileResponse";
import DeleteButton from "../buttons/DeleteButton";
import {IS3content} from "../../api/content/S3ContentType";
import ContentViewer from "../viewers/ContentViewer";

interface S3InstrumentProps{
    tagNames ?: string []
    images ?: S3Object []
    sounds ?: S3Object []
    currentUpload ?: SaveFileResponse
    onImageDelete : any
    onImageWasUploaded : any
    type : IS3content
}

interface S3InstrumentSate{
    request ?: S3SearchRequest
    object ?: S3Object
    uploadKey ?: string
}

class S3Instrument extends PureComponent<S3InstrumentProps,S3InstrumentSate>{

    constructor(props: Readonly<S3InstrumentProps>) {
        super(props);
        this.state ={
            request : props.tagNames ? new S3SearchRequest(props.tagNames) : undefined
        }
    }

    componentDidUpdate(prevProps: Readonly<S3InstrumentProps>, prevState: Readonly<S3InstrumentSate>, snapshot?: any) {
        if(!prevProps.tagNames && this.props.tagNames){
            this.createRequest()
        }
    }

    createRequest = ()=>{
        this.setState({...this.state, request :new S3SearchRequest(this.props.tagNames!)})
    }

    handleSelect =(object : S3Object|undefined) =>{
        this.setState({...this.state, object: object})
    }

    handleUploadSuccess = (uploadKey : string|undefined) =>{
        this.setState({...this.state,uploadKey})
    }

    handleUploadFinish=()=>{
        this.props.onImageWasUploaded(this.uploaded(), this.props.type.onUpdate)
        this.handleUploadSuccess(undefined);
    }

    uploaded= () : S3Object |undefined =>{
        return this.state.uploadKey && this.props.currentUpload && this.state.uploadKey === this.props.currentUpload.key ?
            {
                key : this.state.uploadKey,
                link : this.props.currentUpload.url,
                tags : this.props.currentUpload.tags!
            } : undefined
    }

    onDeleted = ()=>{
        this.props.onImageDelete(this.state.object!.key, this.props.type.onDelete)
        this.handleSelect(undefined)
    }

    render(){
        const uploadedObject = this.uploaded();
        let objects : S3Object []  = []
        if(this.props.type.type === DataType.Images){
            objects = this.props.images ? this.props.images : []
        } else  if(this.props.type.type === DataType.Sounds){
            objects = this.props.sounds ? this.props.sounds : []
        }
        return <div className='form--item-container--center'>
            { this.state.request === undefined || this.state.object || uploadedObject ? null :
                <div className='form-item-row'>
                    <PostRequestForm
                        default={true}
                        intent={Intent.SUCCESS}
                        url= {this.props.type.type}
                        label={new Txt().dict(this.props.type.title)}
                        type={this.props.type.type}
                        help={''}
                        data={this.state.request}
                        cancel={this.createRequest}
                        ok = {()=>{}}
                        method={Method.PUT}
                    />
                    <UploadFileDialog
                        intent={Intent.WARNING}
                        onSuccess={this.handleUploadSuccess}
                        type={this.props.type.getLink}
                        requiredType={this.props.type.fileType}
                        toast={file_uploaded}/>
                </div>
            }
            {
                objects === undefined || objects.length === 0 || this.state.object || uploadedObject ? null :
                    <div className='tiles-item-row tiles-container'>
                        {
                            objects.map(obj => <ContentViewer
                                    adaptor={toContentAdaptor(this.props.type,obj,()=>{this.handleSelect(obj)})}
                                    type={this.props.type}
                                    key={obj.key}
                                    title={obj.key}
                                />
                            )
                        }
                    </div>
            }
            {
                this.state.object === undefined || this.props.tagNames === undefined  || uploadedObject? null :
                    <div>
                        <ImageEditor
                            object={this.state.object}
                            onExit={()=> this.handleSelect(undefined)}
                            imagesTags={this.props.tagNames}
                            type={this.props.type}
                        />
                        <DeleteButton
                            url={DataType.Images}
                            id ={'/'+this.state.object.key}
                            onResult={this.onDeleted}
                            className='width'/>
                    </div>
            }
            {
                uploadedObject && this.props.tagNames ?
                    <ImageEditor
                        object={uploadedObject}
                        method={Method.POST}
                        url={this.props.type.type}
                        ok ={this.handleUploadFinish}
                        imagesTags={this.props.tagNames}
                        type={this.props.type}
                    />
                    : null
            }
        </div>
    }
}

const mapStateToProps = (state : AppState)=>({
    images : state.data.images,
    sounds : state.data.sounds,
    tagNames : state.data.tagNames,
    currentUpload : state.data.currentUpload
})

export default connect(mapStateToProps,{onImageDelete: onObjectDelete,onImageWasUploaded: onObjectWasUploaded})(S3Instrument);