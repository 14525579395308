import React,{Component} from 'react';
import {IImageAdaptor} from "../../adaptors/ImageAdaptor";
import {Intent} from "@blueprintjs/core";
import ImageViewer from "../viewers/ImageViewer";

interface ImagePickerProps {
    adaptors ?: IImageAdaptor[];
    intent : Intent
    pick(position : number) : void;
}

interface ImagePickerState {
    position : number;
}

export class ImagePicker extends Component<ImagePickerProps,ImagePickerState>{

    constructor(props: Readonly<ImagePickerProps>) {
        super(props);
        this.state = {position : -1}
    }

    handlePick=(position : number)=>{
        this.setState({position});
        this.props.pick(position);
    };
    render(){
        return this.props.adaptors === undefined ? null :
            <div className='tiles-container-scroll'>
                {this.props.adaptors.map((adaptor,index)=> {
                    adaptor.intent = this.state.position === index ? this.props.intent : Intent.NONE;
                    adaptor.onClick =()=>{this.handlePick(index)};
                    return <ImageViewer adaptor={adaptor} key={index} className='tiles-item-small'/>
                })}
            </div>
    }
}