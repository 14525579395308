import {Dlc, DlcContentType, IDlcContent} from "./Dlc";
import {EncodedObject} from "../model/EncodedObject";
import {FormAdaptor, IFormAdaptor} from "../adaptors/FormAdaptor";
import {
    FormFieldAdaptor,
    NotEmptyStringFiled, TagsAdaptor,
    VocabularyStringField
} from "../adaptors/FieldAdaptor";
import {
    description_label,
    full_picture,
    name_label,
    not_empty_field,
    software_products,
    thumbnail_picture
} from "../text/Literals";
import {KV, VocabularyTextKV} from "../model/KV";

export interface IDlcDescriptor extends IDlcContent{
    thumbnail ?: string;
    product ?: string[];
    type :string;
}

export function getDlcDescriptor(data : IDlcDescriptor): KV[] {
    return [
        new VocabularyTextKV(data.name, data.description),
        {key :software_products, value : data.product}
    ]
}

export class DlcDescriptor extends EncodedObject implements IDlcDescriptor{
    name: string;
    description: string;
    image: string;
    thumbnail: string;
    product : string[];
    readonly type :string =DlcContentType.InGamePurchase;
    tags: string[];
    nameAdaptor ?: FormFieldAdaptor<string>;
    descriptionAdaptor ?:FormFieldAdaptor<string>;
    imageAdaptor ?: FormFieldAdaptor<string>;
    thumbnailAdaptor ?:FormFieldAdaptor<string>;
    productAdaptor ?:TagsAdaptor;

    constructor(encoded : string|undefined = undefined,onSave:(val : string)=>void = ()=>{} ) {
        super(encoded,['name','description','image','tags','thumbnail', 'tags','type','product'],onSave);
        if(this.model === undefined){
            this.name = '';
            this.description ='';
            this.image ='';
            this.thumbnail = '';
            this.product = [];
            this.tags =[];
        } else {
            this.name = this.model.name;
            this.description =this.model.description;
            this.image =this.model.image;
            this.thumbnail = this.model.thumbnail;
            this.tags =this.model.tags;
            this.product= this.model.product ?  this.model.product : [];
        }

        this.adaptor = this.createFormAdaptor();
    }


    expandModel(): void {
        if(this.model === undefined){
            this.name = '';
            this.description ='';
            this.image ='';
            this.thumbnail = '';
            this.tags =[];
            this.product = [];
        } else {
            this.name = this.model.name;
            this.description =this.model.description;
            this.image =this.model.image;
            this.thumbnail = this.model.thumbnail;
            this.tags =this.model.tags;
            this.product= this.model.product ?  this.model.product : [];
        }

        this.adaptor = this.createFormAdaptor();
    }

    updateModel(): void {
        this.model.name = this.name;
        this.model.description =this.description;
        this.model.image =this.image;
        this.model.thumbnail = this.thumbnail;
        this.model.tags =this.tags;
        this.model.product = this.product;
    }

    createFormAdaptor(): IFormAdaptor {
        this.initNameAdaptor();
        this.initDescriptionAdaptor();
        this.initImageAdaptor();
        this.initThumbnailAdaptor();
        this.initProductAdaptor();
        return new FormAdaptor([
            this.nameAdaptor!,
            this.descriptionAdaptor!,
            this.imageAdaptor!,
            this.thumbnailAdaptor!,
            this.productAdaptor!
        ],this);
    }

    updateAdaptor=()=>{
        this.onDataChanged!(new FormAdaptor([
            this.nameAdaptor!,
            this.descriptionAdaptor!,
            this.imageAdaptor!,
            this.thumbnailAdaptor!,
            this.productAdaptor!
        ],this));
    };

    toString(): string {
        return this.name;
    }

    setName=(val : string)=>{
      this.name = val;
      this.initNameAdaptor();
      this.updateAdaptor();
    };

    setDescription=(val : string)=>{
      this.description = val;
      this.initDescriptionAdaptor();
      this.updateAdaptor();
    };

    setImage=(val : string)=>{
      this.image = val;
      this.initImageAdaptor();
      this.updateAdaptor();
    };

    setThumbnail=(val : string)=>{
      this.thumbnail = val;
      this.initThumbnailAdaptor();
      this.updateAdaptor();
    };

    addProduct=(val : string)=>{
        this.product.push(val);
        this.initProductAdaptor();
        this.updateAdaptor();
    };

    removeProduct=(val : string)=>{
        const index = this.product.indexOf(val);
        if (index < 0) return;
        this.product.splice(index,1);
        this.initProductAdaptor();
        this.updateAdaptor();
    };


    initNameAdaptor=()=>{
        this.nameAdaptor = new VocabularyStringField(name_label,this.setName,this.name);
    };

    initDescriptionAdaptor=()=>{
        this.descriptionAdaptor = new VocabularyStringField(description_label,this.setDescription,this.description);
    };

    initImageAdaptor=()=>{
        this.imageAdaptor = new NotEmptyStringFiled(full_picture,this.setImage,not_empty_field,this.image);
    };

    initThumbnailAdaptor=()=>{
        this.thumbnailAdaptor = new NotEmptyStringFiled(thumbnail_picture,this.setThumbnail,not_empty_field,this.thumbnail);
    };

    initProductAdaptor = ()=>{
        this.productAdaptor = new TagsAdaptor(software_products,this.product,
            this.addProduct,this.removeProduct,Dlc.filterSoftware,false);
    };

    static convert(encoded : string | undefined, set:(val: string)=>void) :DlcDescriptor{
        return new DlcDescriptor(encoded,set);
    }


    wasChanged(): boolean {
        return true;
    }
}