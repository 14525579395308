import {TextArrayKV, ButtonKV, KV, VocabularyTextKV} from "../../model/KV";
import {Intent} from "@blueprintjs/core/lib/esm/common/intent";
import {
    last_sensor_usage,
    not_user_count,
    not_user_label,
    sensor_usage_count, sensor_user_count,
    total_product_users
} from "../../text/Literals";

export interface SensorUsageMetrics{
    type: string,
    count : number,
    time : string
}

export function metricsKV(metrics : SensorUsageMetrics): KV[]{
    return [
        new ButtonKV(metrics.type,"",Intent.PRIMARY,key=>{}),
        new VocabularyTextKV(sensor_usage_count,metrics.count+''),
        new VocabularyTextKV(last_sensor_usage,metrics.time.replaceAll("T"," ").split('.')[0]),
    ];
}

export interface SensorProductReport{
    count : number,
    total : number,
    notUsers : string[]
}

export function reportKV(report : SensorProductReport):KV[]{
    return  [
        new VocabularyTextKV(total_product_users,report.total + ''),
        new VocabularyTextKV(sensor_user_count,report.count + ''),
        new VocabularyTextKV(not_user_count,(report.total - report.count) + ''),
        new TextArrayKV(not_user_label,report.notUsers)
    ]
}
