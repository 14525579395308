import React, {Component} from 'react';
import {connect} from "react-redux";
import {FullBucketDescription, getDownloadObjectKV, getTagValue, S3TaggedObject} from "../../service/S3";
import {AppState} from "../../store/configureStore";
import {Button, Card, Pre} from "@blueprintjs/core";
import KeyValueViewer from "../viewers/KeyValueViewer";
import {getFilteredByOsAndLanguage, getProductTitle, LANGUAGE} from "../../service/BuildTags";
import {Intent} from "@blueprintjs/core/lib/esm/common/intent";
import {
    hide_old_versions, interface_language,
    label_backward,
    select_program_language,
    show_old_versions
} from "../../text/Literals";
import LanguagePicker from "./LanguagePicker";
import {Vocabulary} from "../../model/AppData";
import {ImageSize} from "../../adaptors/ImageAdaptor";
import {LanguageAdaptor} from "../../adaptors/LanguageAdaptor";
import {FlagSVG} from "../viewers/FlagSVG";
import {ScaledSVG} from "./ScaledSvg";
import {getHome} from "../../api/ServerAddress";
import {closeInstrument} from "../../actions/instruments";
import {setRegistrationFirst} from "../../actions/settings";
import changeLanguage from "../../thunks/changeLanguage";
import {Elevation} from "@blueprintjs/core/lib/esnext";

interface DownloadsControlProps {
    downloads ?: FullBucketDescription,
    language : string,
    vocabulary : Vocabulary,
    closeInstrument : any,
    setRegistrationFirst : any,
    autonomous : boolean;
    changeLanguage : any;
}
interface BooleanValues{
    [key : string] : boolean;
}
interface DownloadsControlState {
    expandProducts : BooleanValues;
    language ?: string;
    os ?: string;
}

class DownloadsControl extends Component<DownloadsControlProps,DownloadsControlState>{

    constructor(props: Readonly<DownloadsControlProps>) {
        super(props);
        this.state = {expandProducts:{}, language: props.language, os : undefined};
    }
    handleExpand=(key : string, value : boolean)=>{
        const expandProducts = {...this.state.expandProducts};
        expandProducts[key]=value;
        this.setState({...this.state,expandProducts});
    };

    getBackPanel = () =>{
        return <Card className='language-panel night tiles-container-between round' elevation={Elevation.THREE}>
            <div>
                {this.props.vocabulary[interface_language]}
                <LanguagePicker onSelect={(language)=>this.props.changeLanguage(language)}
                                selected={this.props.language}
                                intent={Intent.PRIMARY}
                                size={ImageSize.Tiny}/>
            </div>
            <div>
                {this.props.vocabulary[select_program_language]}
                <LanguagePicker onSelect={(language) => this.setState({...this.state, language})}
                                selected={this.state.language}
                                intent={Intent.PRIMARY}
                                size={ImageSize.Tiny}/>
            </div>
        </Card>
    }

    render(){
        return <div className='back-container'>
            <Card className='card-top-right night round-bottom'>
                {this.getBackLoginButton()}
            </Card>

            <div className='back-middle'>
                {this.props.downloads === undefined ? null :
                    <div className='tiles-container'>
                        {Object.entries(getFilteredByOsAndLanguage(this.props.downloads.Objects, this.state.language, this.state.os))
                            .map((prod, index) =>
                            <Card className='tiles-half-sparse product-card' key={index}  elevation={Elevation.THREE}>
                                <Pre>
                                    {this.props.vocabulary[getProductTitle(prod[0])]}
                                </Pre>

                                {this.state.expandProducts[prod[0]] ?
                                    <div>
                                        {
                                            prod[1].map((object, index2) =>
                                                this.getDownloadCard(index2, object, this.state.expandProducts[prod[0]]))
                                        }
                                        <Button
                                            className='product-expand'
                                            intent={Intent.DANGER}
                                            minimal={false}
                                            title={this.props.vocabulary[hide_old_versions]}
                                            icon='collapse-all'
                                            onClick={() => this.handleExpand(prod[0], false)}
                                        >{this.props.vocabulary[hide_old_versions]}</Button>
                                    </div>
                                    :
                                    <div>
                                        {
                                            prod[1].map((object, index2) => index2 !== 0 ? null :
                                                this.getDownloadCard(index2, object,this.state.expandProducts[prod[0]]))
                                        }
                                        {prod[1].length > 1 ?
                                            <Button
                                                className='product-expand'
                                                intent={Intent.PRIMARY}
                                                minimal={false}
                                                icon='expand-all'
                                                title={this.props.vocabulary[show_old_versions]}
                                                onClick={() => this.handleExpand(prod[0], true)}
                                            >{this.props.vocabulary[show_old_versions]}</Button> : null
                                        }
                                    </div>

                                }
                            </Card>
                        )}
                    </div>
                    }
                {this.getBackPanel()}
            </div>

            <div className='back-top'>
                <ScaledSVG id='sky' svgProps={{
                    viewBox: "0 90 1920 375",
                    width: '100%',
                }}/>
            </div>

            <div className='back-bottom'>
                <ScaledSVG id='land' svgProps={{
                    viewBox: "180 110 1950 270",
                    width: '120%'
                }}/>
            </div>
        </div>
    }



    private getBackLoginButton() {
        const isDownloadsUrl = window.location.href.endsWith('downloads');
        return isDownloadsUrl ? <a href={getHome()}>
                <span onClick={this.props.closeInstrument} className='text-button  login-area night'>
                            {this.props.vocabulary[label_backward]}
                        </span>
            </a> :
            <span onClick={this.props.closeInstrument} className='text-button  login-area night'>
                        {this.props.vocabulary[label_backward]}
                    </span>;
    }

    private getDownloadCard=(index2 : number, object : S3TaggedObject, expanded : boolean)=> {
        const flag : LanguageAdaptor = new LanguageAdaptor(getTagValue(LANGUAGE,object.Tagging) );
        return <div
                     className='tiles-one-third' key={index2}>
            { expanded ?
                <div className='card--title-icon inline'>
                    <FlagSVG id={flag.imageId} alt={flag.id} size={ImageSize.XTiny}/>
                </div> : null
            }
            <div className='tiles-container'>
                {getDownloadObjectKV(this.props.downloads!.bucket, object, expanded).map((kv, index3) =>
                    <KeyValueViewer
                        key={index3}
                        token={''}
                        inline={true}
                        kv={kv}/>
                )
                }
            </div>
        </div>;
    }
}
const mapStateToProps = (state: AppState) => ({
    downloads : state.data.downloads,
    language : state.settings.language,
    vocabulary : state.data.vocabulary
});

export default connect(mapStateToProps,{closeInstrument,setRegistrationFirst,changeLanguage})(DownloadsControl);