import {SimpleRequestData} from "../adaptors/RequestData";
import {FormAdaptor, IFormAdaptor} from "../adaptors/FormAdaptor";
import {EmailFieldAdaptor} from "../adaptors/FieldAdaptor";
import {email_label, not_empty_field} from "../text/Literals";
import {Method} from "../model/Request";
import {DataType} from "../actions/data";

export interface ICreateOperationTokenRequest {
    value : string;
    language : string;
}

export class CreateOperationTokenRequest extends SimpleRequestData implements ICreateOperationTokenRequest{
    value : string;
    language : string;

    valueAdaptor ?: EmailFieldAdaptor;

    constructor(value: string, language: string) {
        super();
        this.value = value;
        this.language = language;
        this.adaptor = this.createFormAdaptor();
    }

    setValue=(val : string)=>{
      this.value = val;
        this.onDataChanged!(this.createFormAdaptor());
    };

    initValueAdaptor=()=>{
        this.valueAdaptor = new EmailFieldAdaptor(email_label,this.setValue,not_empty_field,this.value);
        return this.valueAdaptor;
    };

    createFormAdaptor(): IFormAdaptor {
        return new FormAdaptor([this.initValueAdaptor()],this);
    }

    getMethod(): Method {
        return Method.POST;
    }

    message(): string {
        return JSON.stringify(this,['value','language' ]);
    }

    getType(): DataType {
        return DataType.CreateOperationToken;
    }
}