import {copyUser, createUserFromResponse, User} from "../model/User";
import {ReceiveAuthAction, SetPasswordAction, SetUsernameAction, UserAction, UserActionType} from "../actions/user";
import {DataType, RawDataAction} from "../actions/data";
import {ISaveClassroomValueRequest} from "../api/SaveHomeworkRequest";
import {Classroom} from "../api/Classroom";
import {CourseData} from "../model/courses/Course";

// eslint-disable-next-line import/no-anonymous-default-export
export default function  (user: User = new User(''), action: UserAction|RawDataAction) : User{
    let newUser : User = copyUser(user);
    switch (action.type) {
        case UserActionType.LogOut:
            return new User('');
        case UserActionType.SetPassword:
            const pa : SetPasswordAction = action as SetPasswordAction;
            newUser.password = pa.password;
            return newUser;
        case UserActionType.SetUsername:
            const ua : SetUsernameAction = action as SetUsernameAction;
            newUser.username = ua.username;
            return newUser;
        case UserActionType.ReceiveAuth:
            const ra : ReceiveAuthAction = action as ReceiveAuthAction;
            newUser = createUserFromResponse(ra.auth);
            return newUser;
        case DataType.AddClassroom:
            newUser.classrooms = JSON.parse(action.raw);
            newUser.save();
            return newUser;
        case DataType.SaveHomeWork :
            const response : ISaveClassroomValueRequest = JSON.parse(action.raw);
            const classroom : Classroom  | undefined= newUser.getClassroomById(response.id);
            if(classroom !== undefined){
                classroom.myHomework = response.value;
            }
            newUser.save();
            return newUser;
        case DataType.SaveClassroomMessage :
            const resp : ISaveClassroomValueRequest = JSON.parse(action.raw);
            const classroom1 : Classroom  | undefined= newUser.getClassroomById(resp.id);
            if(classroom1 !== undefined){
                classroom1.myMessage = resp.value;
            }
            newUser.save();
            return newUser;
        case DataType.TeachersCourses :
            const res : CourseData = JSON.parse(action.raw);
            newUser.onTeachersCourseEdit(res);
            newUser.save();
            return newUser;
        case DataType.DeleteDraft :
            newUser.onTeachersCourseDelete(action.raw);
            newUser.save();
            return newUser;
        case DataType.TakeFreeCourse:
            const courseData : CourseData = JSON.parse(action.raw);
            newUser.onGotStudentsCourse(courseData);
            newUser.save();
            return newUser;
        default: return user;
    }
}