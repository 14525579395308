export function addTailSymbol(object: any, symbol: string) {
    for(let key in object){
        if(object.hasOwnProperty(key) && typeof object[key] ==='string'){
            object[key] +=symbol;
        }
    }
}

export function removeTailSymbol(object: any) {
    for(let key in object){
        if(object.hasOwnProperty(key) && typeof object[key] ==='string'){
            object[key] = object[key].slice(0,-1);
        }
    }
}