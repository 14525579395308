import {ITools, IToolsAction} from "../actions/tools";
import {InstrumentType} from "../actions/instruments";

// eslint-disable-next-line import/no-anonymous-default-export
export default function (data : ITools={}, action : IToolsAction):ITools {
    if (action.instrument === undefined) return  data;
    if(action.type){
        let previous : InstrumentType[] = data[action.instrument];
        if(previous === undefined) previous =[];
        if (previous.indexOf(action.tool) < 0) {
            previous.push(action.tool);
            data[action.instrument] = previous;
        }
        const res : ITools = {...data};
        res[action.instrument] = previous;
        return res;
    }
    let previous : InstrumentType[] = data[action.instrument];
    if(previous !== undefined) {
        const index = previous.indexOf(action.tool);
        if(index >= 0){
            previous.splice(index,1);
        }
    }
    const res : ITools = {...data};
    res[action.instrument] = previous;
    return res;
}