import {SimpleRequestData} from "../../adaptors/RequestData";
import {FormAdaptor, IFormAdaptor} from "../../adaptors/FormAdaptor";
import {Method} from "../Request";
import {DataType} from "../../actions/data";
import {NotEmptyStringFiled} from "../../adaptors/FieldAdaptor";
import {entity_id, not_empty_field} from "../../text/Literals";

export class AbstractValueRequest extends SimpleRequestData{
    value ?:string;
    valueAdaptor ?: NotEmptyStringFiled;
    name : string =entity_id;
    disabled : boolean = false;

    constructor(value: string|undefined = undefined) {
        super();
        this.value = value;
        this.adaptor = this.createFormAdaptor();
    }

    setValue=(val : string)=>{
        this.value = val;
        this.onDataChanged!(this.createFormAdaptor());
    };

    initValueAdaptor=()=>{
        this.valueAdaptor = new NotEmptyStringFiled(this.name,this.setValue,not_empty_field,this.value,this.disabled);
        return this.valueAdaptor;
    };

    createFormAdaptor(): IFormAdaptor {
        return new FormAdaptor([this.initValueAdaptor()],this);
    }

    message(): string {
        return JSON.stringify(this,['value']);
    }
}

export class ValueRequestData extends AbstractValueRequest{
    method : Method;
    type : DataType;

    constructor(type: DataType, method: Method = Method.POST, name: string = entity_id, disabled : boolean = false, value : string|undefined = undefined) {
        super(value);
        this.method = method;
        this.type = type;
        this.name = name;
        this.disabled = disabled;
        this.adaptor = this.createFormAdaptor();
    }

    getType(): DataType {
        return this.type;
    }

    getMethod(): Method {
        return this.method;
    }
}