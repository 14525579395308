import detectBrowser from '../service/DetectBrowser';
import {ENGLISH, FRENCH, GERMAN, JAPANESE, PORTO, RUSSIAN} from "./Literals";

export interface IClientInfo {
    browser : string,
    language : string,
    os : string,
    userAgent: string
}
export class ClientInfo implements IClientInfo{
    browser: string;
    language: string;
    os: string;
    userAgent: string;


    constructor(browser: string, language: string, os: string, userAgentString: string) {
        this.browser = browser;
        this.language = language;
        this.os = os;
        this.userAgent = userAgentString;
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default () =>{
    return new ClientInfo(
        detectBrowser(),
        window.navigator.language,
        window.navigator.platform,
        window.navigator.userAgent
    )
}

export function systemLanguage() :string {
    const locale : string = window.navigator.language;
    if (locale.startsWith("en")||locale.startsWith('he')) return ENGLISH;
    if (locale.startsWith("ja")) return JAPANESE;
    if (locale.startsWith("fr")) return FRENCH;
    if (locale.startsWith('pt')) return PORTO;
    if (locale.startsWith("de")||locale.startsWith('gsw')) return GERMAN;
    return RUSSIAN;

}