import React, {FC} from "react";
import {IContentAdaptor, IImageAdaptor} from "../../adaptors/ImageAdaptor";
import {IS3content} from "../../api/content/S3ContentType";
import ImageViewer from "./ImageViewer";
import SoundViewer from "./SoundViewer";

interface ContentViewerProps{
    adaptor : IContentAdaptor
    type : IS3content
    className ?: string
    title ?: string
}

const ContentViewer : FC<ContentViewerProps> = props => {
    function viewer(){
        if((props.adaptor as IImageAdaptor).size){
            return <ImageViewer className={props.className} adaptor={props.adaptor as IImageAdaptor}/>
        }
        return <SoundViewer className={props.className} adaptor={props.adaptor}/>
    }

    return  <div className={props.className} >
        {props.title}
        {viewer()}
    </div>
}

export default ContentViewer;