import {SimpleRequestData} from "../adaptors/RequestData";
import {Method} from "../model/Request";
import {FormAdaptor} from "../adaptors/FormAdaptor";
import {EmailFieldAdaptor, FormFieldAdaptor, NotEmptyStringFiled, StringFieldAdaptor} from "../adaptors/FieldAdaptor";
import {full_name_label, not_empty_field, product_key, username_label} from "../text/Literals";
import {DataType} from "../actions/data";

export interface Teacher{
    teacher : string
    name : string
}

export interface TeacherRequest {
    username: string;
    name: string;
    school : string
}

export class CreateTeacherRequest extends SimpleRequestData implements TeacherRequest{
    username: string;
    name: string;
    school : string;
    isNew :boolean;

    schoolAdaptor : FormFieldAdaptor<string>;
    usernameAdaptor ?: FormFieldAdaptor<string>;
    nameAdaptor ?: FormFieldAdaptor<string>;

    constructor(school: string, username: string='', name: string='', isNew : boolean = true) {
        super();
        this.username = username;
        this.name = name;
        this.school = school;
        this.isNew = isNew;
        this.fields = ["username","name","school"];

        this.schoolAdaptor = this.initSchoolAdaptor();
        this.adaptor = this.createFormAdaptor();
    }

    setUsername =(val : string)=>{
        this.username = val;
        this.onDataChanged!(this.createFormAdaptor());
    }

    setName =(val : string)=>{
        this.name = val;
        this.onDataChanged!(this.createFormAdaptor());
    }

    initSchoolAdaptor =() : FormFieldAdaptor<string> =>{
        this.schoolAdaptor= new NotEmptyStringFiled(product_key,val => {},not_empty_field,this.school,true);
        return this.schoolAdaptor;
    };

    initUsernameAdaptor =() : FormFieldAdaptor<string> =>{
        this.usernameAdaptor= new EmailFieldAdaptor(username_label,this.setUsername,not_empty_field,this.username);
        return this.usernameAdaptor;
    };

    initNameAdaptor =() :FormFieldAdaptor<string> =>{
        this.nameAdaptor = this.isNew ?
            new NotEmptyStringFiled(full_name_label,this.setName,not_empty_field,this.name):
            new StringFieldAdaptor(full_name_label,this.setName,this.name);
        return this.nameAdaptor;
    };

    getMethod(): Method {
        return this.isNew ? Method.POST : Method.PUT;
    }

    getType=(): DataType=> {
        return DataType.SchoolTeacher;
    };

    createFormAdaptor() :FormAdaptor{
        return  new FormAdaptor(
            [
                this.schoolAdaptor,
                this.initUsernameAdaptor(),
                this.initNameAdaptor()
            ],
            this
        )
    }
}

export class DeleteTeacherRequest extends SimpleRequestData{
    username: string;
    school : string;

    schoolAdaptor : FormFieldAdaptor<string>;
    usernameAdaptor ?: FormFieldAdaptor<string>;

    constructor(school: string) {
        super();
        this.school = school;
        this.username = ''
        this.fields = ["username","school"];

        this.schoolAdaptor = this.initSchoolAdaptor();
        this.adaptor = this.createFormAdaptor();
    }

    setUsername =(val : string)=>{
        this.username = val;
        this.onDataChanged!(this.createFormAdaptor());
    }


    initSchoolAdaptor =() : FormFieldAdaptor<string> =>{
        this.schoolAdaptor= new NotEmptyStringFiled(product_key,val => {},not_empty_field,this.school,true);
        return this.schoolAdaptor;
    };

    initUsernameAdaptor =() : FormFieldAdaptor<string> =>{
        this.usernameAdaptor= new EmailFieldAdaptor(username_label,this.setUsername,not_empty_field,this.username);
        return this.usernameAdaptor;
    };

    getMethod(): Method {
        return Method.DELETE;
    }

    getType=(): DataType=> {
        return DataType.SchoolTeacher;
    };

    createFormAdaptor() :FormAdaptor{
        return  new FormAdaptor(
            [
                this.schoolAdaptor,
                this.initUsernameAdaptor()
            ],
            this
        )
    }
}