export function getDisplayDateTime(stamp :number) {
    const date : Date = new Date(stamp);
    return date.toLocaleDateString() +" "+date.toLocaleTimeString();
}

export function getIntervalString(interval : number) {
    const hh = Math.floor(interval / 1000 / 60 / 60);
    interval -= hh * 1000 * 60 * 60;
    const mm = Math.floor(interval / 1000 / 60);
    interval -= mm * 1000 * 60;
    const ss = Math.floor(interval / 1000);
    return numToClock(hh,':') + numToClock(mm,':') + numToClock(ss);
}

function numToClock(val: number, delimiter : string='') : string {
    if(val ===0) return '';
    return val > 9 ? val +delimiter : '0'+val +delimiter;
}

export function getFormattedDateString(date : Date) :string{
    const divider="-";
    let dd = date.getDate();
    let mm = date.getMonth()+1;
    const y = date.getFullYear();

    const ds : string = dd < 10 ? '0' + dd : dd + '';
    const ms : string = mm < 10 ? '0'+ mm : mm + '';

    return y + divider + ms + divider + ds;
}