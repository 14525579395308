import parser from 'fast-xml-parser';
import {ListBucketResult, Tagging} from "./S3";

export function parseList(data : string) : ListBucketResult{
    const json = parser.parse(data);
    return json.ListBucketResult;
}

export function parseTagging(data : string) : Tagging {
    return parser.parse(data).Tagging;
}

