/* View for editing embedded arrays */
import React, {PureComponent} from 'react';
import { ObjectArrayAdaptor} from "../../adaptors/FieldAdaptor";
import {Button, Card, Elevation, Intent} from "@blueprintjs/core";
import PostRequestForm from "./PostRequestForm";
import EmbeddedObjectMinimal from "./EmbeddedObjectMinimal";
import {sendRequest} from "../../thunks/sendRequest";
import {AppState} from "../../store/configureStore";
import {connect} from "react-redux";
import {Txt} from "../tools/Text";

interface ArraySaveFormProps {
    adaptor : ObjectArrayAdaptor,
    intent : Intent;
    level ?: number;
    sendRequest : any;
    enableInsert ?: boolean;
}

interface ArraySaveFormState {
    extended : boolean [];
}

class ArraySaveForm extends PureComponent<ArraySaveFormProps,ArraySaveFormState>{
    constructor(props: Readonly<ArraySaveFormProps>) {
        super(props);
        this.state ={ extended : this.props.adaptor.value === undefined ? [] : this.props.adaptor.value.map(object=>false)}
    }
    handleEdit=(index : number,state :boolean)=>{
        const extended = this.state.extended;
        extended.splice(index,1,state);
        this.setState({...this.state,extended : [...extended]});
    };
    handleInsert=(index : number)=>{
        this.props.adaptor.onInsert(index);
    };

    render(){
        const isDark : boolean = this.props.level !== undefined && this.props.level % 2 ===0;
        const theme : string = isDark ? 'middle-back' : '';
        return this.props.adaptor.value === undefined ? null :
            <div>
                {this.props.adaptor.value.map((object,index)=>
                <div key={index}>
                    <Button icon='small-plus' minimal={true} onClick={()=>{this.handleInsert(index)}} small={true} fill={true} intent={Intent.SUCCESS}/>
                    {this.state.extended[index] ?
                        <Card interactive={false} elevation={Elevation.THREE}  className={theme}>
                            <PostRequestForm
                                intent={this.props.intent}
                                default={true}
                                url={object.getUrl()}
                                label={new Txt().txt(object.toString())}
                                type={object.getType()}
                                data={object}
                                method={object.getMethod()}
                                cancel={() => this.handleEdit(index, false)}
                                level={this.props.level}
                                masterPost={object.post}
                            >
                            </PostRequestForm>
                        </Card> :
                        <EmbeddedObjectMinimal
                            icon={this.props.adaptor.icon}
                            handler={() => this.handleEdit(index, true)}
                            isNegative={object.isNegative()}
                            text={object.toString()}
                            intent={this.props.intent}
                            changer={() => {
                                object.not();
                                this.forceUpdate();
                            }}
                            remover={()=>this.props.adaptor.onDelete(index)}
                        />
                    }
                </div>
            )}
                <Button icon='small-plus' minimal={true} onClick={()=>{this.handleInsert(this.props.adaptor.value!.length)}} small={true} fill={true} intent={Intent.SUCCESS}/>
            </div>;
    }
}
const mapStateToProps = (state: AppState) => ({
});
export default connect(mapStateToProps,{sendRequest})(ArraySaveForm);