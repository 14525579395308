import {FirebaseRequestData} from "../../adaptors/RequestData";
import {FirebaseMessage, FirebaseMessageContent, FirebaseMessageType} from "../common/FirebaseMessage";
import {Method} from "../Request";
import {FormAdaptor, IFormAdaptor} from "../../adaptors/FormAdaptor";
import {NotEmptyStringFiled, TextFieldAdaptor} from "../../adaptors/FieldAdaptor";
import {not_empty_field, students_message} from "../../text/Literals";

export class CourseMessageRequest extends FirebaseRequestData{
    course : string;
    uuid : string;
    text : string;
    isArea ?:  boolean;

    constructor(course: string, uuid: string,  text: string='') {
        super();
        this.uuid = uuid;
        this.course = course;
        this.text = text;

        this.adaptor = this.createFormAdaptor();
    }

    createFormAdaptor(): IFormAdaptor {
        const field = this.isArea ?
            new TextFieldAdaptor(this.getMessageLabel(),this.setText,this.text) :
            new NotEmptyStringFiled(this.getMessageLabel(),this.setText,not_empty_field,this.text);
        return new FormAdaptor([
            field
        ],this);
    }

    setText=(val : string)=>{
      this.text = val;
      this.onDataChanged!(this.createFormAdaptor());
    };

    getFirebaseMessage=(): FirebaseMessage =>{
        const messageContent : FirebaseMessageContent= {
            lesson : this.uuid,
            content : this.text,
            type : FirebaseMessageType.Text,
        };
        return new FirebaseMessage(this.course,FirebaseMessageType.Text,messageContent, Method.POST);
    };

    getMessageLabel=():string=>{
        return students_message;
    }
}