export interface MongoEntity {
    _id : string;
}

export interface CommonEntity {
    id : string;
}

export interface CourseEntity {
    version : number;
    type : string;
    label : string;
}

export function updateIndex(entity: CommonEntity, array :CommonEntity[]|undefined) {
    if (array=== undefined) array = [];
    const index = array.findIndex(data=>data.id === entity.id);
    if(index >=0) {
        array.splice(index,1,entity);
    }else {
        array.push(entity);
    }
}