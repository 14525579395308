import {EncodedObject} from "../EncodedObject";
import {FormAdaptor, IFormAdaptor,} from "../../adaptors/FormAdaptor";
import {DataType} from "../../actions/data";
import {CommonEntity} from "../../api/course/Entity";
import {
    BooleanFieldAdaptor,
    EncodedObjectAdaptor,
    FormFieldAdaptor,
    NotEmptyStringFiled,
    ObjectArrayAdaptor,
    StringFieldAdaptor
} from "../../adaptors/FieldAdaptor";
import {SimpleRequestData} from "../../adaptors/RequestData";
import cloneDeep from 'lodash/cloneDeep';
import {
    course_description,
    course_label, course_owner,
    draft_label, lesson_caption, module_caption,
    modules_label,
    name_label,
    not_empty_field, total_lessons
} from "../../text/Literals";
import {Method} from "../Request";
import {IdModule, IModule, Module} from "./Module";
import {DlcContentType, FreeDlcDescription, IDlcContent} from "../../api/Dlc";
import {KV, PlainTextKV} from "../KV";
import {ILesson, Lesson} from "./Lesson";
import {Vocabulary} from "../AppData";
import {decodeObject} from "../../service/encoder";

export interface CourseData extends  CommonEntity{
    content : string,
    draft : string,
    isDraft : boolean,
    owner ?: string
}

export interface ReviewContent {
    [key : string] : CourseData;
}

export interface ICourse extends IDlcContent{
    modules : IModule[];
    requires ?:string;
    type :string;
}

export type CourseRepresentation = CourseData | FreeDlcDescription;

export function getCourseDescriptor(data : ICourse, owner :string): KV[] {
    let lessonCount : number = 0;
    if(data.modules) for(let mod of data.modules){
        if(mod.lessons) lessonCount += mod.lessons.length;
    }
    return [
        new PlainTextKV(data.name,data.description),
        {key :course_owner, value : owner},
        {key : total_lessons, value : lessonCount}
    ]
}

export class CourseContent extends EncodedObject implements ICourse{
    modules : IModule[];
    name : string;
    description : string;
    image ?: string;
    tags : string[];
    requires ?:string;
    readonly type : string = DlcContentType.UserCourse;

    nameAdaptor ?: FormFieldAdaptor<string>;
    descriptionAdaptor ?:FormFieldAdaptor<string>;
    modulesAdaptor ?: ObjectArrayAdaptor;

    /*
      It's important to have all fields in the list
      also inner objects fields are required
     */
    constructor(encoded : string|undefined = undefined,onSave:(val : string)=>void = ()=>{} ) {
        super(encoded,['lessons','name','description','image','tags','requires',
            'task','tags','description','isActive','type','video','topic','file','modules','uuid'],onSave);

        if (this.model === undefined) {
            this.modules = [new Module()];
            this.name = "";
            this.description = "";
            this.tags = [];
            this.image = "";
            this.adaptor = this.createFormAdaptor();
            return;
        }
        this.modules = this.model.modules ? [...this.model.modules] : [];
        this.name = this.model.name ? this.model.name : "";
        this.description = this.model.description ? this.model.description : "";
        this.tags = this.model.tags ? this.model.tags : [];
        this.image = this.model.image;
        this.requires = this.model.requires;

        this.adaptor = this.createFormAdaptor();
    }

     expandModel() {
        if (this.model === undefined) {
            this.modules =  [new Module()];
            this.name = "";
            this.description = "";
            this.tags = [];
            this.image = "";

            this.adaptor = this.createFormAdaptor();
            return;
        }
        this.modules = this.model.modules ? [...this.model.modules] : [];
        this.name = this.model.name ? this.model.name : "";
        this.description = this.model.description ? this.model.description : "";
        this.tags = this.model.tags ? this.model.tags : [];
        this.image = this.model.image;
        this.requires = this.model.requires;

        this.adaptor = this.createFormAdaptor();
    }

    updateModel(): void {
        this.model.modules = this.modules ? [...this.modules] : [];
        this.model.name  = this.name ? this.name : "";
        this.model.description = this.description ? this.description :  "";
        this.model.tags = this.tags ? this.tags : [];
        this.model.image = this.image;
        this.model.requires = this.requires;
    }

    setName=(value : string)=>{
        this.name = value;
        this.onDataChanged!(new FormAdaptor([
            this.initNameAdaptor(),
            this.descriptionAdaptor!,
            this.modulesAdaptor!
        ],this));
    };

    setDescription=(value : string)=>{
        this.description = value;
        this.onDataChanged!(new FormAdaptor([
            this.nameAdaptor!,
            this.initDescriptionAdaptor(),
            this.modulesAdaptor!
        ],this));
    };

    setModules =(value :  any)=>{
        this.modules = cloneDeep(value);
        this.updateModulesAdaptor();
    };

    private updateModulesAdaptor() {
        this.onDataChanged!(new FormAdaptor([
            this.nameAdaptor!,
            this.descriptionAdaptor!,
            this.initLessonsAdapter()
        ], this));
    }

    initNameAdaptor =() : FormFieldAdaptor<string> => {
        this.nameAdaptor = new NotEmptyStringFiled(name_label,this.setName,not_empty_field,this.name);
        return this.nameAdaptor;
    };

    initDescriptionAdaptor =() : FormFieldAdaptor<string> => {
        this.descriptionAdaptor = new StringFieldAdaptor(course_description, this.setDescription,this.description);
        return this.descriptionAdaptor;
    };

    initLessonsAdapter = (): ObjectArrayAdaptor =>{
        this.modulesAdaptor = new ObjectArrayAdaptor(modules_label,this.setModules,
                this.modules.map((model,index) => new Module(new IdModule(index,model),this.updateModules))
                    ,'folder-open',this.insertAt,this.deleteAt
            );
        return this.modulesAdaptor;
    };

    insertAt=(index : number)=>{
        this.modules.splice(index,0, new Module());
        this.updateModulesAdaptor();
    };

    deleteAt=(index : number)=>{
        this.modules.splice(index,1);
        this.updateModulesAdaptor();
    };

    updateModules =(mod : IdModule)=>{
        this.modules.splice(+mod.id,1,mod);
    };

    createFormAdaptor(): IFormAdaptor {
        this.adaptor =  new FormAdaptor([
            this.initNameAdaptor(),
            this.initDescriptionAdaptor(),
            this.initLessonsAdapter()
        ],this);
        return  this.adaptor;
    }

    toString(): string {
        return this.name;
    }

    static convert(encoded : string | undefined, set:(val: string)=>void) : CourseContent{
        return new CourseContent(encoded,set);
    }

    static getInitial() :string{
        const course : CourseContent = new CourseContent();
        return course.message();
    }

    getActiveContent(): string{
        const modules : IModule [] = this.modules.filter(mod=>mod.isActive);
        modules.forEach(mod=>{
            mod.lessons = mod.lessons.filter(lesson=>lesson.isActive);
        });
        const course : CourseContent = new CourseContent();
        course.modules = modules;
        course.name = this.name;
        course.description = this.description;
        course.tags = this.tags;
        return course.message();
    }

    findLessonById=(uuid: string) :ILesson| undefined=>{
        if(!this.modules) return undefined;
        for(let mod of this.modules){
            if(!mod.lessons) continue;
            for(let lesson of mod.lessons) {
                if(lesson.uuid === uuid) return lesson;
            }
        }
        return undefined
    }
}

export function addModules(content: CourseContent, modules : number, lessons : number,  vocabulary : Vocabulary) {
    const currentModule : number = content.modules.length;
    for(let i = 1;i <= modules;i++){
        const mod :Module = new Module();
        mod.name = vocabulary[module_caption] + " "+ (currentModule+i) + ".";
        mod.lessons =[];
        content.modules.push(mod);
        for(let  j = 1; j <= lessons; j++){
            const lesson = new Lesson();
            lesson.topic = vocabulary[lesson_caption] + " "+(currentModule+i) + "."+ j + ".";
            mod.lessons.push(lesson);
        }
    }
}

export class Course extends SimpleRequestData implements CourseData{
    id : string;
    content : string;
    draft : string;
    contentData : CourseContent;
    isDraft : boolean;
    isNew : boolean;
    private readonly _prevDraft : boolean;
    draftAdaptor ?: BooleanFieldAdaptor;
    contentAdaptor ?: EncodedObjectAdaptor;

    constructor(data : CourseData|undefined=undefined) {
        super();
      //  console.trace(data);
        if (data === undefined){
            this.id = "";
            this.content = CourseContent.getInitial();
            this.draft = CourseContent.getInitial();
            this.isDraft = true;
            this.isNew = true;
        } else {
            this.id = data.id;
            this.content = data.content;
            this.draft = data.draft;
            this.isDraft = data.isDraft;
            this.isNew = data.id ==="";
        }

        this.contentData = new CourseContent(this.draft);
        this.fields= this.isNew ?
            ['isDraft','draft'] :
            ['id','content','isDraft','draft'];

        this._prevDraft = this.isDraft;
        this.adaptor = this.createFormAdaptor();
    }

    setDraft=(value : boolean)=>{
        this.isDraft= value;
        this.onDataChanged!(new FormAdaptor([
            this.contentAdaptor!,
            this.initDraftAdaptor()
        ],this));
    };

    setContent=(value : string)=>{
        this.draft = value;
        this.contentData = new CourseContent(this.draft);
        this.onDataChanged!(new FormAdaptor([
            this.initContentAdaptor(),
            this.draftAdaptor!
        ],this));
    };

    initDraftAdaptor = ()=>{
        this.draftAdaptor = new BooleanFieldAdaptor(draft_label,this.setDraft,this.isDraft,true);
        return this.draftAdaptor;
    };

    initContentAdaptor =()=>{
      this.contentAdaptor = new EncodedObjectAdaptor(course_label,this.setContent,this.draft,CourseContent.convert,'document-open',true);
      return this.contentAdaptor;
    };

    getName=()=>{
        return this.contentData.name;
    };

    getType(): DataType {
        return DataType.TeachersCourses;
    };

    createFormAdaptor=()=>{
        this.adaptor = new FormAdaptor([
            this.initContentAdaptor(),
            this.initDraftAdaptor()
        ],this);
        return  this.adaptor;
    };

    getMethod(): Method {
        return this.isNew ? Method.POST : Method.PUT;
    }

    isSameContent=(data :CourseData |undefined):boolean=>{
        if(data === undefined) return false;
        // const other = new Course(data);
        // console.log(other.contentData.message());
        // console.log(this.contentData.message());
        return new Course(data).contentData.message() === this.contentData.message();
    };

    getActiveContent(): Course{
        return new Course({
                id : this.id,
                isDraft : this.isDraft,
                content : this.contentData.getActiveContent(),
                draft : this.contentData.getActiveContent(),
            })
    }

    hasPrevious(): boolean{
        return !!this.content;
    }

    getPrevious=():Course=>{
        return new Course({
            id : this.id,
            isDraft : this.isDraft,
            draft : this.content,
            content : this.content
        })
    };

    wasChanged(): boolean {
        return this.isDraft !== this._prevDraft || this.contentData.wasChanged();
    }
}

export function decodeContent(data: CourseData) : ICourse{
    return decodeObject(data.draft);
}

export function getCourseName(data : CourseData|undefined) {
    if(!data) return undefined;
    try {
        const content : ICourse = decodeContent(data);
        return content.name;
    }catch (e) {
        console.log(e);
        return ''
    }

}