import {Action} from "redux";

export enum ProcessType {
    Idle = 'System is idle',
    WaitingForServerResponse = 'System waits for server'
}
export interface UploadProgress {
    started : boolean;
    loaded : number;
    total : number;
    completed : boolean;
}

export interface Process extends Action<ProcessType>{
    upload ?: UploadProgress;
}

export function onRequestSent() : Process {
    return {type : ProcessType.WaitingForServerResponse}
}

export function becomeIdle() : Process {
    return {type : ProcessType.Idle, upload : undefined}
}

export function onUploadProgress(loaded: number, total: number):Process {
    return {type : ProcessType.WaitingForServerResponse, upload:{
             started : true,
             loaded,
             total,
             completed : false
        }}
}
export function onUploadEnd(loaded: number, total: number):Process {
    return {type : ProcessType.WaitingForServerResponse, upload:{
            started : true,
            loaded,
            total,
            completed : true
        }}
}