import React, {FC, useState} from 'react';
import RequestButton from "../RequestButton";
import {Checkbox, Intent} from "@blueprintjs/core";
import {Method, Request} from "../../model/Request";
import {DataType} from "../../actions/data";
import {connect} from "react-redux";
import {AppState} from "../../store/configureStore";
import {Text, Txt} from "../tools/Text";
import {delete_label} from "../../text/Literals";
import {Vocabulary} from "../../model/AppData";

interface DeleteButtonProps{
    url : DataType
    id : string
    token : string
    vocabulary : Vocabulary
    onResult : (res : string|undefined)=>void
    className ?: string;
}

const DeleteButton : FC<DeleteButtonProps> = props => {
    const [disabled, setDisabled] = useState(true);

    return <div className='tiles-container-fixed'>
        <div>
            <Checkbox checked={!disabled} label={props.vocabulary[delete_label]} onChange={event => {setDisabled(!event.currentTarget.checked)}} />
            <RequestButton request={new Request(undefined,props.url + props.id,Method.DELETE,props.token)}
                           intent={Intent.DANGER}
                           type={props.url}
                           disabled={disabled}
                           icon='trash'
                           onSuccess={props.onResult}
                           className={props.className}>
                <Text text = {new Txt().dict(delete_label)}/>
            </RequestButton>
        </div>
    </div>
}

export default connect((state : AppState)=>({token: state.user.token, vocabulary :state.data.vocabulary})) (DeleteButton)