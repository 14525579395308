import {IInstruments, Instruments, InstrumentType} from "../actions/instruments";
import {DataType, RawDataAction} from "../actions/data";
import {Course} from "../model/courses/Course";

// eslint-disable-next-line import/no-anonymous-default-export
export default function (instrument : Instruments = new Instruments(InstrumentType.No), action : IInstruments|RawDataAction) :Instruments {
    switch (action.type) {
        case InstrumentType.PlayerViewer:
        case InstrumentType.DlcEditor:
        case InstrumentType.No:
        case InstrumentType.SchoolViewer:
        case InstrumentType.ManagerAdminViewer:
        case InstrumentType.PersonManagerViewer:
        case InstrumentType.ClassRoom:
        case InstrumentType.CourseReview:
        case InstrumentType.StudentsCourses:
        case InstrumentType.TeachersCourses:
        case InstrumentType.TeachersCabinet:
        case InstrumentType.Downloads:
        case InstrumentType.Notifications:
        case InstrumentType.Analytics:
        case InstrumentType.FreeCourses:
        case InstrumentType.Vitrines:
        case InstrumentType.SchoolUser:
        case InstrumentType.SchoolRegister:
        case InstrumentType.MySchool:
        case InstrumentType.TrialShd:
        case InstrumentType.Hardware:
        case InstrumentType.Sensors:
        case InstrumentType.Images:
        case InstrumentType.Sounds:
        case InstrumentType.ManagerReg:
                return new Instruments(action.type,action.entity);
        case DataType.TeachersCourses:
            const course : Course = JSON.parse(action.raw);
            return new Instruments(InstrumentType.TeachersCourses, {id : course.id,label : ''} );
        default:
            return instrument;
    }
}