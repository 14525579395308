import React, {PureComponent} from "react";
import {connect} from "react-redux";
import {AppState} from "../../store/configureStore";
import {Callout, Card} from "@blueprintjs/core";
import {Elevation} from "@blueprintjs/core/lib/esnext";
import GetRequestForm from "../forms/GetRequestForm";
import {Intent} from "@blueprintjs/core/lib/esm/common/intent";
import {DataType} from "../../actions/data";
import {
    hardware_search,
    product_report_label, sensor_report_title,
    software_products, usage_metrics_label,
    username_label
} from "../../text/Literals";
import {Software} from "../../api/admin/Software";
import {metricsKV, reportKV, SensorProductReport, SensorUsageMetrics} from "../../api/analytics/SensorAnalytics";
import KeyValueViewer from "../viewers/KeyValueViewer";
import {Text, Txt} from "../tools/Text";


interface SensorInstrumentProps{
    software : Software [],
    metrics ?: SensorUsageMetrics [],
    report ?: SensorProductReport,
}

interface SensorInstrumentState{

}

class SensorInstrument extends PureComponent<SensorInstrumentProps,SensorInstrumentState>{
    render(){
        return <div>
            <div className='tiles-container-space'>
                <Card className='tiles-half' elevation={Elevation.THREE}>
                    <GetRequestForm
                        intent={Intent.SUCCESS}
                        url={DataType.SensorProductReport}
                        label={software_products}
                        type={DataType.SensorProductReport}
                        help={product_report_label}
                        options={this.props.software.map(soft=>soft.product)}
                    />
                </Card>
                <Card className='tiles-half' elevation={Elevation.THREE}>
                    <GetRequestForm
                        intent={Intent.SUCCESS}
                        url={DataType.SensorUsageMetrics}
                        label={username_label}
                        type={DataType.SensorUsageMetrics}
                        help={hardware_search}
                    />
                </Card>
                {this.props.metrics ?
                    <Card className='tiles-item-row tiles-container-space' elevation={Elevation.THREE}>
                        <Callout intent={Intent.PRIMARY} icon={null}>
                            <Text text={new Txt().dict(usage_metrics_label)}/>
                        </Callout>
                        {this.props.metrics.map(metric=><div className='tiles-item-row tiles-container-space' key={metric.type} >
                            {metricsKV(metric).map(kv=>
                                <div className='tiles-one-third' key={kv.key}>
                                    <KeyValueViewer
                                        kv={kv}
                                        token={""}
                                    />
                                </div>
                            )}
                        </div>)}
                    </Card>: null}

                {this.props.report ?
                    <Card className='tiles-item-row tiles-container-space' elevation={Elevation.THREE}>
                        <Callout intent={Intent.WARNING} icon={null}>
                            <Text text={new Txt().dict(sensor_report_title)}/>
                        </Callout>
                        {reportKV(this.props.report).map(kv=>
                            <div className='tiles-item-row' key={kv.key}>
                            <KeyValueViewer
                                kv={kv}
                                token={""}
                            />
                        </div>)}
                    </Card>: null
                }

            </div>

        </div>
    }
}

const mapStateToProps = (state : AppState)=>({
    software : state.data.software,
    metrics : state.data.sensorUsageMetrics,
    report : state.data.sensorsProductReport
})

export default connect(mapStateToProps)(SensorInstrument)