import { ThunkAction } from "redux-thunk";
import {AppState} from "../store/configureStore";
import {receiveAuth} from "../actions/user";
import {send} from "../service/Connection";
import {LoginRequest, RegisterRequest} from "../api/LoginRequest";
import getClientInfo from "../service/ClientInfo";
import {Method, Request} from "../model/Request";
import {ExceptionType, noException, onException} from "../actions/error";
import {AuthResponse} from "../api/AuthResponse";
import {createUserFromResponse, User} from "../model/User";
import {Action} from "redux";
import {onRequestSent,becomeIdle} from "../actions/process";
import {sendRequest} from "./sendRequest";
import {DataType} from "../actions/data";
import firebaseLogin from "./firebaseLogin";
import {STORED_AUTH} from "../service/Literals";
import {isAdmin} from "../model/PathVariables";

export const login = (
   user : User,
   mode : string|undefined = undefined,
   id : number|undefined = undefined,
   register : boolean = false
): ThunkAction<void, AppState, null, Action> => async dispatch => {

    function onError(e : any) {
        console.log(e);
        if(e === undefined){
            dispatch(onException(0))
        } else{
            dispatch(onException(e.status));
        }
        dispatch(becomeIdle());
    }
    const request: LoginRequest = register ? new RegisterRequest(user.username,user.password,getClientInfo(),id) :
        new LoginRequest(user.username, user.password, getClientInfo());
    try {
        const url = register ? USER_REGISTER_URL : (isAdmin(mode) ? ADMIN_LOGIN_URL : USER_LOGIN_URL);
        dispatch(onRequestSent());
        dispatch(onException(ExceptionType.Processing));
        const response = await send(new Request(request.getMessage(), url, Method.POST, undefined));
        const json: AuthResponse = JSON.parse(response.message);
        dispatch(firebaseLogin(json));
        localStorage.setItem(STORED_AUTH,response.message);
        dispatch(receiveAuth(json));
        dispatch(noException());
        dispatch(becomeIdle());
        if(id !== undefined && !register){
            try{
                const newUser : User = createUserFromResponse(json);
                dispatch(sendRequest(new Request(undefined,DataType.AddClassroom+'/'+id,Method.PUT,newUser.token),DataType.AddClassroom, newUser));
            }catch (e) {
                onError(e);
            }
        }

    }catch (e) {
        onError(e);
    }
};


export const ADMIN_LOGIN_URL = 'admin/login';
export const USER_LOGIN_URL = 'user/login';
export const  USER_REGISTER_URL ='user/register';