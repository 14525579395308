import React, {Component} from "react";
import {ILesson} from "../../model/courses/Lesson";
import {AppState} from "../../store/configureStore";
import {connect} from "react-redux";
import {Vocabulary} from "../../model/AppData";
import {Button, Divider, Intent, Pre} from "@blueprintjs/core";
import {
    common_label_156,
    homework_label,
    messages_label,
    page_classwork,
    teachers_homework_review
} from "../../text/Literals";
import PostRequestForm from "../forms/PostRequestForm";
import {HomeworkRequest} from "../../model/courses/HomeworkRequest";
import {
    FirebaseHomework,
    FirebaseMessage,
    FirebaseMessages,
    FirebaseMessageType,
    getMessagesByLesson
} from "../../model/common/FirebaseMessage";
import {CourseMessageRequest} from "../../model/courses/CourseMessageRequest";
import {firebaseSend} from "../../thunks/firebase";
import {VAR_DELIMITER} from "../../service/Literals";
import MessageViewer from "../tools/MessageViewer";
import {isImage, isPdf} from "../../service/FileService";
import {getScreenSize} from "../../service/SystemInfo";
import {Text, Txt} from "../tools/Text";
import coverLesson from "../../thunks/coverLesson";

interface LessonPlayerProps {
    lesson : ILesson;
    videoLinks : Vocabulary;
    fileLinks : Vocabulary;
    onBackward :()=>void;
    onForward :()=>void;
    disableBackward : boolean;
    disableForward : boolean;
    courseId : string;
    firebaseHomework : FirebaseHomework;
    firebaseMessages : FirebaseMessages;
    firebaseSend : any;
    page ?: LessonPage;
    coverLesson : any;
}

export type LessonPage = "page_classwork" | "homework_label" | "messages_label"

interface LessonPlayerState {

}

class LessonPlayer extends Component<LessonPlayerProps,LessonPlayerState>  {
    startSent : boolean;
    firstUpdate : boolean;
    nextUpdate : boolean;

    constructor(props: Readonly<LessonPlayerProps>) {
        super(props);
        this.state ={page : page_classwork};
        this.startSent = false;
        this.firstUpdate = true;
        this.nextUpdate = false;
    }

    handleChangeTab=(page: LessonPage)=>{
        this.setState({page})
    };

    getLessonMessages=()=>{
        if(!this.props.courseId || !this.props.lesson) return;
        this.props.firebaseSend(FirebaseMessage.getMyLessonMessages(this.props.courseId, this.props.lesson.uuid));
    };

    cover=()=>{
        if(!this.props.courseId || !this.props.lesson) return;
        this.props.coverLesson(this.props.courseId,this.props.lesson.uuid,true);
    };

    start=()=>{
        if(this.startSent || !this.props.courseId || !this.props.lesson) return;
        this.props.coverLesson(this.props.courseId,this.props.lesson.uuid,false);
        this.startSent = true;
    };

    shouldComponentUpdate(nextProps: Readonly<LessonPlayerProps>, nextState: Readonly<LessonPlayerState>, nextContext: any): boolean {
        if(!nextProps.courseId ||!nextProps.lesson) return false;

        if(this.firstUpdate) {
            this.firstUpdate = false;
            return true;
        }

        //we set lesson covered on next update only
        //we shouldn't set covered on first update because this lesson was seen earlie and also it causes error
        this.nextUpdate = true;

        if(!this.props.courseId ||  !this.props.lesson) return true;

        //different lesson
        if(this.props.lesson.uuid !== nextProps.lesson.uuid) return true;

        // different page
        if(this.props.page !== nextProps.page) return true;

        if(this.props.page === page_classwork ){
            return  this.props.lesson.description !== nextProps.lesson.description || this.props.lesson.video !== nextProps.lesson.video || this.props.lesson.file !== nextProps.lesson.file;
        }
        if(this.props.page === "homework_label"){
            const fromFirebase = this.getStoredHomework();
            if (fromFirebase !== (nextProps.firebaseHomework[nextProps.courseId] ?
                nextProps.firebaseHomework[nextProps.courseId][nextProps.lesson.uuid] : undefined)) return true;

            const answer : string | undefined = this.getHomeWorkReview();

            if(answer !==  (nextProps.firebaseHomework[nextProps.courseId] ?
                nextProps.firebaseHomework[nextProps.courseId][FirebaseMessageType.HomeworkReview + VAR_DELIMITER + nextProps.lesson.uuid] : undefined)) return true;
        }
        if(this.props.page === messages_label ){
            if (!this.props.firebaseMessages || !nextProps.firebaseMessages) return false;
            return Object.keys(this.props.firebaseMessages).length !==  Object.keys(nextProps.firebaseMessages).length
        }
        return false;
    }

    render() {
//        console.trace("___render course lesson player ____");
        if(!this.props.lesson) return null;
        const videoUrl: string | undefined = this.props.lesson.video ? this.props.videoLinks[this.props.lesson.video] : undefined;
        const fileUrl: string | undefined = this.props.lesson.file ? this.props.fileLinks[this.props.lesson.file] : undefined;
        this.startSent = false;
        return <div className='sidebar-content'>
            <div className='player-container'>
            <Button minimal={true} icon='step-backward' intent={Intent.PRIMARY} large={true}
                    className='player-side-buttons-start' disabled={this.props.disableBackward} onClick={this.props.onBackward}/>
                {this.props.page === page_classwork ? this.getClasswork(videoUrl,fileUrl,this.props.lesson.file) : null}
                {this.props.page === "homework_label" ? this.getHomework() : null}
                {this.props.page === messages_label ? this.getMessages() : null}
            <Button minimal={true} icon='step-forward' intent={Intent.PRIMARY} large={true}
                    className='player-side-buttons-end' disabled={this.props.disableForward} onClick={this.props.onForward}/>
            </div>
        </div>;
    }

    private getClasswork(videoUrl: string | undefined, fileUrl: string | undefined, fileKey: string | undefined) {
        const image: boolean = fileKey ? isImage(fileKey) :false;
        const pdf : boolean = fileKey ? isPdf(fileKey) : false;
       // console.log(videoUrl);
        if(!videoUrl && this.nextUpdate){
            //if no video only docs this lesson covered on render
            this.cover();
        }
        return <div className='player'>
                    {videoUrl ? <video src={videoUrl} controls width='100%' onEnded={event => {
                        this.cover();
                    }} onPlay={event => {
                        this.start();
                    }}/> : null}

                    {this.props.lesson.description ?
                            <div className='text-wrapper'>
                                <Divider/>
                                <span className='wrap-text'>
                                        {this.props.lesson.description}
                                </span>
                                <Divider/>
                            </div>
                            : <Divider/>}

                    {fileUrl ?
                        <div>
                            {image ? <img src={fileUrl} width='100%' alt={fileUrl}/> : null }
                            {pdf ? <embed  src={fileUrl} key={fileKey} width='100%' height={getScreenSize().y}/> : null }
                        </div> : null}

            </div>;
    }

    private getHomework() {
        const fromFirebase = this.getStoredHomework();
        const answer : string | undefined = this.getHomeWorkReview();
        const homeWork = new HomeworkRequest(this.props.lesson,this.props.courseId, fromFirebase);
        return <div className='player'>
            {
                this.props.lesson.task ?
                    <Pre>
                        {this.props.lesson.task}
                    </Pre>
                    : null
            }
            <PostRequestForm
                default={true}
                intent={Intent.PRIMARY}
                url= {''}
                label={new Txt().dict(homework_label)}
                type={homeWork.getType()}
                data={homeWork}
                method={homeWork.getMethod()}
                buttonText={common_label_156}
                level={0}
                ok={()=>{
                    this.props.firebaseSend(FirebaseMessage.getMyHomework(this.props.courseId))
                }}
            />{
                answer ? <div >
                    <Text text={new Txt().dict(teachers_homework_review).txt(': ')} />
                    <span className='message-review'>
                        <Text text={new Txt().txt(answer)} />
                    </span>
                </div> :null
            }
        </div>;
    }

    private getHomeWorkReview() {
        return this.props.courseId && this.props.lesson.uuid ?
            (this.props.firebaseHomework[this.props.courseId] ?
                this.props.firebaseHomework[this.props.courseId][FirebaseMessageType.HomeworkReview + VAR_DELIMITER + this.props.lesson.uuid] : undefined) : undefined;
    }

    private getStoredHomework() {
        return this.props.courseId && this.props.lesson.uuid ?
            (this.props.firebaseHomework[this.props.courseId] ?
                this.props.firebaseHomework[this.props.courseId][this.props.lesson.uuid] : undefined) : undefined;
    }

    private getMessages() {
        const request = new CourseMessageRequest(this.props.courseId,this.props.lesson.uuid);
        return <div className='player'>
            <PostRequestForm
                default={true}
                intent={Intent.PRIMARY}
                url= {''}
                label={new Txt().dict(messages_label)}
                type={request.getType()}
                data={request}
                method={request.getMethod()}
                buttonText={common_label_156}
                level={0}
                ok ={()=>{
                        this.getLessonMessages();
                        request.setText('');
                    }}
            />
            <div className='tiles-container-messages'>
            {
                getMessagesByLesson(this.props.firebaseMessages, this.props.courseId,this.props.lesson.uuid)
                    .map((content,index)=>
                        <MessageViewer content={content} key={index} className='form-item-line' embedded={true}/>
                    )
            }
            </div>
        </div>;
    }
}
const mapStateToProps = (state: AppState) => ({
    videoLinks : state.data.videoLinks,
    fileLinks : state.data.fileLinks,
    firebaseHomework : state.data.firebaseHomework,
    firebaseMessages : state.data.firebaseMessages,
});
export default connect(
    mapStateToProps,{firebaseSend,coverLesson}
)(LessonPlayer);