import React,{Component} from 'react';
import {User} from "../../model/User";
import {AppState} from "../../store/configureStore";
import {connect} from "react-redux";
import {Entity} from "../../model/Entity";
import {Classroom, TeachersClassroom} from "../../api/Classroom";
import KeyValueViewer from "../viewers/KeyValueViewer";
import PostRequestForm from "../forms/PostRequestForm";
import {SaveMessageRequest} from "../../api/SaveHomeworkRequest";
import {Intent} from "@blueprintjs/core";
import {change_message, message_from_student} from "../../text/Literals";
import {Vocabulary} from "../../model/AppData";
import {Txt} from "../tools/Text";

interface ClassroomInstrumentProps {
    user : User;
    entity : Entity;
    vocabulary : Vocabulary
}

interface ClassroomInstrumentState {

}

class ClassroomInstrument extends Component<ClassroomInstrumentProps,ClassroomInstrumentState>{
    render(){
        const classroom : Classroom | undefined = this.props.user.getClassroomById(this.props.entity.idAsNumber());
        if (classroom === undefined) return null;
        const teachersClassroom = new TeachersClassroom(classroom);
        const saveMessageRequest = new SaveMessageRequest(classroom);
        return (<div className='form--item-container--center'>
            {
                teachersClassroom.getStudentsKV()
                    .map(kv=>
                        <div className='form-item-row' key={kv.key}>
                            <KeyValueViewer
                                token={this.props.user.token}
                                inline={false}
                                kv={kv}/></div>)
            }
            <div className='form-item-row'>
            <PostRequestForm
                default={false}
                intent={Intent.PRIMARY}
                url= {saveMessageRequest.getType()}
                label={new Txt().dict(message_from_student)}
                type={saveMessageRequest.getType()}
                data={saveMessageRequest}
                method={saveMessageRequest.getMethod()}
                buttonText={change_message}
            />
            </div>
        </div>);
    }
}

const mapStateToProps = (state: AppState) => ({
    user : state.user,
    entity : state.instruments.entity,
    vocabulary : state.data.vocabulary
});
export default connect(
    mapStateToProps
)(ClassroomInstrument);