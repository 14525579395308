export interface ILanguageAdaptor {
    id : string;
    imageId: string;
}
export const FLAG='Flag';

export class LanguageAdaptor implements ILanguageAdaptor{
    id: string;
    imageId: string;

    constructor(id: string) {
        this.id = id;
        this.imageId = id +FLAG;
    }
}