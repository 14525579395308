import {Action} from "redux";
import {InstrumentType} from "./instruments";

export interface ITools {
    [key :string] :InstrumentType[];
}
export function contains(tools : ITools, tool : InstrumentType) : boolean {
    let res = false;
    Object.values(tools).forEach(arr=>{
        arr.forEach(type=>{
            if(type as string === tool as string) res = true;
        })
    });
    return res;
}

export interface IToolsAction extends Action<boolean>{
    instrument : InstrumentType;
    tool : InstrumentType;
}

export function useTool(instrument: InstrumentType, tool: InstrumentType) : IToolsAction{
    return {
        type : true,
        instrument,
        tool
    }
}

export function closeTool(instrument: InstrumentType, tool: InstrumentType) : IToolsAction{
    return {
        type : false,
        instrument,
        tool
    }
}

export function isToolActive(tool : InstrumentType, currentInstrument : InstrumentType, tools : ITools ) {
    const currentTools = tools[currentInstrument];
    return currentTools !== undefined  && currentTools.indexOf(tool) >=0;
}