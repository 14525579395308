import {parseList, parseTagging} from "./S3XmlParser";
import {ConnectionResponse, sendAsync} from './Connection';
import {Method} from "../model/Request";
import {DownloadKV, ImageAdaptorsKV, KV, PlainTextKV} from "../model/KV";
import {additional_languages, platform_label, start_download} from "../text/Literals";
import {
    addIdentity, additionalLanguagesMapper, LANGUAGES, OS,
    PRODUCT,
    ProductObjects,
    sortProductObjects,
    TITLE,
    VERSION,
    WHATS_NEW
} from "./BuildTags";

export const BASIC_DELIMITER =',';

export enum S3Buckets {
    Builds ='https://s3.eu-central-1.amazonaws.com/naura-builds/',
    BetaBuilds = 'https://s3.eu-central-1.amazonaws.com/naura-beta-builds/',
    Images = 'https://s3.eu-central-1.amazonaws.com/naurasha-web-images/'
}

interface BucketDescription {
    Name : string,
    KeyCount : number,
    MaxKeys : number,
    IsTruncated : boolean,
    bucket : S3Buckets;
}

export interface FullBucketDescription extends BucketDescription{
    Objects : ProductObjects;
}

export interface ListBucketResult extends BucketDescription{
    Contents : S3Object []
}

export interface S3Object {
    Key: string,
    LastModified : string,
    ETag : string,
    Size : number,
    StorageClass : string
}

export interface S3TaggedObject extends S3Object{
    Tagging : Tagging,
    date : Date;
}

export interface Tag {
    Key: string,
    Value : string
}

export function getTagValue(key : string, tagging : Tagging) : string {
    let index = -1;
    try {
        index = tagging.TagSet.Tag.findIndex(tag => tag.Key.toLocaleLowerCase() === key);
    }catch (e) {

    }
    return index < 0 ? "" : tagging.TagSet.Tag[index].Value;
}

export function getTagArrayValues(key: string, tagging: Tagging, delimiter: string = BASIC_DELIMITER) : string[]{
    const data : string = getTagValue(key,tagging);
    if(data.length === 0) return [];
    return data.split(delimiter);
}

export function setTagValue(key: string, value :string, tagging: Tagging) {
    try {
        const index = tagging.TagSet.Tag.findIndex(tag => tag.Key.toLocaleLowerCase() === key);
        if(index < 0) return;
        tagging.TagSet.Tag[index].Value = value;
    }catch (e) {

    }
}

export interface XMLTag {
    Tag : Tag[]
}

export interface Tagging {
    TagSet :XMLTag
}

export class S3TuggedBucketList implements FullBucketDescription {
    Name: string;
    KeyCount: number;
    MaxKeys: number;
    IsTruncated: boolean;
    Objects : ProductObjects;
    bucket : S3Buckets;

    constructor(data: BucketDescription) {
        this.Name = data.Name;
        this.KeyCount = data.KeyCount;
        this.MaxKeys = data.MaxKeys;
        this.IsTruncated = data.IsTruncated;
        this.bucket = data.bucket;
        this.Objects = {};
    }

    add=(object : S3Object) : Promise<void>=>{
       return new Promise<void>((resolve, reject) => {
           getObjectTagging(this.bucket, object.Key).then(
               result => {
                   if(getTagValue(PRODUCT,result)!==""){
                       addIdentity({...object, Tagging: result, date : new Date(object.LastModified)},this.Objects);
                   }
                   resolve();
               }
           );
       });
    }
}

export function getDownloadObjectKV(baseUrl: string,object : S3TaggedObject, expanded : boolean):KV[] {
    const languages = getTagArrayValues(LANGUAGES,object.Tagging,' ');
    const res:KV[] = [
        new PlainTextKV(getTagValue(TITLE,object.Tagging), getTagValue(WHATS_NEW,object.Tagging)),

    ];
    if (expanded) res.push( new PlainTextKV( 'v'+getTagValue(VERSION, object.Tagging),  object.date.toLocaleDateString()),
        {key : platform_label,value : getTagValue(OS,object.Tagging)});
    if(languages.length > 0 && expanded) res.push(new ImageAdaptorsKV(additional_languages,
        getTagArrayValues(LANGUAGES,object.Tagging,' '),additionalLanguagesMapper));
    res.push(new DownloadKV(baseUrl+object.Key,start_download));
    return res;
}


export async function initBuildListing(isBeta : boolean = false)   {
        const bucketListing: ListBucketResult | undefined = await listBucket(isBeta ? S3Buckets.BetaBuilds : S3Buckets.Builds);
        if(bucketListing === undefined) return undefined;
        const bucketWithTagging = new S3TuggedBucketList(bucketListing);
        await Promise.all(bucketListing.Contents.map(obj=>bucketWithTagging.add(obj)));
        sortProductObjects(bucketWithTagging.Objects);
        return bucketWithTagging;
}

export enum S3RequestType {
    List ='?list-type=2',
    Tagging='?tagging'
}

export function listBucket (bucket : S3Buckets) :Promise<ListBucketResult | undefined> {
    return new Promise(function (resolve, reject) {
        sendAsync(Method.GET, getRequestUrl(bucket, S3RequestType.List)).then(
            (res: ConnectionResponse) => {
                try{
                    const bucketResult : ListBucketResult = parseList(res.message);
                    bucketResult.bucket = bucket;
                    resolve(bucketResult);
                }catch (e) {
                    console.log(e);
                    reject(undefined);
                }
            }, (res: ConnectionResponse) => {
                reject(undefined);
            })
    });
}

export function getObjectTagging(bucket :S3Buckets, key :string) : Promise<Tagging>{
    return new Promise(function (resolve, reject) {
        sendAsync(Method.GET, getRequestUrl(bucket, S3RequestType.Tagging,key)).then(
            (res: ConnectionResponse) => {
           //     console.log(res);
                try{
                    resolve(parseTagging(res.message));
                }catch (e) {
                    console.log(e);
                    reject({TagSet:[]});
                }
            }, (res: ConnectionResponse) => {
                console.log(res);
                reject({TagSet:[]});
            })
    });
}

export const getRequestUrl = (bucket : S3Buckets, type : S3RequestType, key : string ='') : string=>{
    return ''+bucket + key + type;
};

