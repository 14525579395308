import React, {FC} from "react";
import {getTrialProductLabelId, hasLicense, ISchool, RegisterWithTrialRequest} from "../../api/School";
import EmailLoginInstruments, {LoggedInWithEmailProps} from "./EmailLoginInstruments";
import {NoProps} from "./SchoolRegisterInstrument";
import {Callout, Card, Dialog, Intent} from "@blueprintjs/core";
import {DataType} from "../../actions/data";
import {
    add_or_register,
    add_trial,
    add_trial_school, add_trial_success,
    cards_app_school, drozdov_registration_form, logopedia,
    register_link,
    register_shd,
    register_shd_comment, register_shd_new
} from "../../text/Literals";
import {Elevation} from "@blueprintjs/core/lib/esnext";
import RequestButton from "../RequestButton";
import {Method, Request} from "../../model/Request";
import {TokenRequestForm} from "../forms/PostRequestForm";
import {Txt} from "../tools/Text";
import DownloadPanel from "../tools/DownLoadPanel";

const DrozdovTrial  : FC<LoggedInWithEmailProps> = props => {
    if (props.email == null)
        return null;
   // props.data.schools && console.log(props.data.schools.length)
    let trialProducts = [ cards_app_school, logopedia ];
    let schools : ISchool[] = props.data.schools
        ? props.data.schools.filter(school => trialProducts.filter(product => !hasLicense(school, product)).length > 0)
        : []

    function getRequest(school : ISchool, trialProduct : string) : Request{
        return new Request(JSON.stringify({username: school.username, product : trialProduct}),"school/trial", Method.PUT, props.token);
    }

    function getDownloads(){
        return <DownloadPanel product='cards_app'/>
    }

    function getTrialRequestButtons(school : ISchool) {
        return trialProducts.filter(product => !hasLicense(school, product)).map(product =>
            <RequestButton
                className='inline'
                disabled={false}
                intent={Intent.PRIMARY}
                type={DataType.MySchool}
                icon='plus'
                request={getRequest(school, product)}
                onSuccess={() => {
                    props.message(add_trial_success, Intent.SUCCESS, undefined)
                }}>
                {props.data.vocabulary[getTrialProductLabelId(product)]}
            </RequestButton>);
    }

    return  <Dialog
        title={schools.length > 0 ? props.data.vocabulary[add_or_register] : undefined}
        transitionDuration={200}
        isOpen={true}
        canEscapeKeyClose={false}
        canOutsideClickClose={false}
        className="login-container"
        backdropClassName='login-background'
    >

        {schools.map(school => <Card key={school.username}  elevation={Elevation.ONE}>
            <Callout title={props.data.vocabulary[add_trial]} intent={Intent.SUCCESS} icon='box'>
                {props.data.vocabulary[add_trial_school] + ' ' + school.name}
            </Callout>
            {getTrialRequestButtons(school)}
        </Card>)}
        {props.exception}
        <div className="bp3-dialog-body">
            <TokenRequestForm
                default={true}
                intent={Intent.PRIMARY}
                url= {DataType.SchoolApply + "/" + cards_app_school + logopedia}
                label={new Txt().dict(schools.length > 0 ? register_shd_new : register_shd)}
                type={DataType.SchoolApply}
                help={''}
                data={new RegisterWithTrialRequest(props.email, [ cards_app_school, logopedia ], register_shd_comment)}
                method={Method.PUT}
                token = {props.token!}
                buttonText={register_link}
                ok ={props.onSuccess}
                cancel={props.reset}
            />
        </div>
        { props.data.schools && props.data.schools.length  ?
            getDownloads() : null}
    </Dialog>
}

const DrozdovTrialInstrument : FC<NoProps> = () => {
    return <EmailLoginInstruments content={DrozdovTrial} title={drozdov_registration_form} finalContent={<DownloadPanel product='cards_app'/>}/>
}

export default DrozdovTrialInstrument;