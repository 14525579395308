export const schoolCSV =  [
    { label: 'Ключ продукта', key: 'username' },
    { label: 'Название', key: 'name' },
    { label: 'Город', key: 'city' },
    { label: 'Адрес', key: 'address' },
    { label: 'Комплект поставки', key: 'description' },
    { label: 'Мастер', key: 'master' },
    { label: 'Email', key: 'email' },
    { label: 'Телефон', key: 'phone' },
    { label: 'Контактное лицо', key: 'contact' },
    { label: 'Должность', key: 'position' },
    { label: 'Дополнительные устройства', key: 'devices' },
    { label: 'Лицензии', key: 'licenses' },
    { label: 'Лицензии заканачиваются', key: 'expirations' },
    { label: 'Email учителей', key: 'emails' },
    { label: 'Имена учителей', key: 'names' },
    { label: 'Комментарий', key: 'association' },
]

export const personsCSV =  [
    { label: 'Email', key: 'username' },
    { label: 'ФИО пользователя', key: 'name' },
    { label: 'Телефон', key: 'phone' },
    { label: 'Лицензии', key: 'licenses' },
    { label: 'Дополнительная информация', key: 'description' }
]

export const impersonalSchoolsCSV =  [
    { label: 'Обезличенная школа', key: '' }
]