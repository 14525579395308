import {ThunkAction} from "redux-thunk";
import {AppState} from "../store/configureStore";
import {Action} from "redux";
import {Coverage, LessonsCoverage} from "../model/common/LessonCoverage";
import {FirebaseMessage} from "../model/common/FirebaseMessage";
import {COVERED, STARTED} from "../service/Literals";
import {firebaseSend} from "./firebase";
import {receiveCoursesCoverage} from "../actions/data";

// eslint-disable-next-line import/no-anonymous-default-export
export default(course : string,  lesson : string, isCovered : boolean = false): ThunkAction<void, AppState, null, Action> => async (dispatch,getState) => {
    let lessonsCoverage : LessonsCoverage|undefined = getState().data.coursesCoverage[course];
    lessonsCoverage = lessonsCoverage ? lessonsCoverage : {};
    let coverage : Coverage|undefined = lessonsCoverage[lesson];
    coverage = coverage ? coverage : {};
    //do nothing if we don't cover more
    if(coverage.covered || (coverage.started && !isCovered))
        return ;
    console.log(isCovered ?  "covered" : "started");
    //store progress in google cloud
    dispatch(firebaseSend(FirebaseMessage.coverLesson(course, lesson, isCovered ? COVERED : STARTED),()=>{},false));

    //update local data store
    let newCoverage : Coverage = {};
    newCoverage.started = true;
    newCoverage.covered = isCovered;

    lessonsCoverage[lesson] = newCoverage;
    dispatch(receiveCoursesCoverage(course,lessonsCoverage))
}