import {Component, RefObject} from "react";
import {Course} from "../../model/courses/Course";
import {CoursePosition} from "../../actions/settings";
import {AppState} from "../../store/configureStore";
import {LessonPage} from "./LessonPlayer";
import {InstrumentType} from "../../actions/instruments";

export interface CourseViewerProps {
    position : CoursePosition;
    changeCoursePosition : any;
    course : Course;
    page ?: LessonPage;
    containerRef ?: RefObject<HTMLDivElement>;
}

export interface CourseViewerState {

}

export abstract class AbstractCourseViewer extends Component<CourseViewerProps,CourseViewerState>{
    shouldComponentUpdate(nextProps: Readonly<CourseViewerProps>, nextState: Readonly<CourseViewerState>, nextContext: any): boolean {
        if(!this.props.position.is(nextProps.position)){
            console.log("new position");
            return  true;
        }
        if(this.props.page !== nextProps.page){
            console.log("new page");
            return true;
        }
        if(this.props.course !== nextProps.course){
            console.log("new course");
            return true;
        }

        return false;
    }

    setPositions=(position : CoursePosition)=>{
        const uuid :string= this.props.course.contentData.modules[position.selectedModule].lessons[position.selectedLesson].uuid;
        this.props.changeCoursePosition(this.props.course.id,position.selectedModule,position.selectedLesson, uuid);
    };

    handleSelect=(chosenModule : number, chosenLesson: number)=>{
        const position = this.props.position.copy(this.props.course.contentData.modules);
        position.selectedLesson = chosenLesson;
        position.selectedModule = chosenModule;
        this.setPositions(position);
    };

    onBackward=()=>{
        this.setPositions(this.props.position.backward(this.props.course.contentData.modules));
    };

    onForward=()=>{
        this.setPositions(this.props.position.forward(this.props.course.contentData.modules));
    };

    isBackwardDisabled=():boolean=>{
        return this.props.position.isBackwardDisabled();
    };

    isForwardDisabled=():boolean=>{
        return this.props.position.isForwardDisabled(this.props.course.contentData.modules);
    };
}

let course : Course;
let position : CoursePosition;

export function checkCourse(course :Course,state: AppState) {
    if (!course || course.id !== state.instruments.entity.idAsString()
        || !course.isSameContent(state.user.getCourseById(state.instruments.entity.idAsString()))) {
        if(!course){
           // console.log("course is undefined");
        } else if ( course.id !== state.instruments.entity.idAsString()){
         //   console.log("id is changed");
           // console.log((course.id !== state.instruments.entity.idAsString()) +": "+course.id +" -> " +state.instruments.entity.idAsString());
        } else{
        //    console.log("content is changed");
       //     console.log("is same content: " + course.isSameContent(state.user.getCourseById(state.instruments.entity.idAsString())));
        }
       // console.log("create new course from data");
        course = new Course(state.user.getCourseById(state.instruments.entity.idAsString())).getActiveContent();
    }
    return course;
}

let prevType : InstrumentType;

export function checkCourseId(course :Course,state: AppState, type : InstrumentType) {
    if (!course.id){
       console.log("empty course");
        return course
    }
    if (!course || (state.instruments.entity.idAsString() && course.id !== state.instruments.entity.idAsString()) || type !== prevType) {
        //console.log(course);
        // console.log(state.instruments.entity.idAsString());
        const isReview = type === InstrumentType.CourseReview;
        if(isReview && !state.data.reviewContent[state.instruments.entity.idAsString()])
            return course;
        console.log("create new for student");
        course = isReview ?
            new Course(state.data.reviewContent[state.instruments.entity.idAsString()]) :
            new Course(state.user.getCourseById(state.instruments.entity.idAsString())).getActiveContent();
    }
    prevType = type;
    return course;
}
export const mapStateToCourseViewerProps = (state: AppState) => {
    course = checkCourse(course,state);
    position = position && position.is(state.settings.coursesPositions[course.id]) ? position : new CoursePosition(state.settings.coursesPositions[course.id], course.contentData.modules);
    return {
        course,
        position
    }
};
