import React, {PureComponent} from "react";
import {IModule} from "../../model/courses/Module";
import {Alignment, Classes, Icon, IconName, Intent, ITreeNode, Navbar, Tree} from "@blueprintjs/core";
import {MaybeElement} from "@blueprintjs/core/src/common/props";
import LessonIcon from "./LessonIcon";

interface LessonNavigatorProps {
    modules : IModule [];
    intent ?: Intent;
    onSelect : (chosenModule : number, chosenLesson: number)=>void;
    selectedModule : number;
    selectedLesson : number;
}

interface LessonNavigatorState {
    expandedModules :  boolean[] ;
}

class LessonNavigator extends PureComponent<LessonNavigatorProps,LessonNavigatorState> {

    constructor(props: Readonly<LessonNavigatorProps>) {
        super(props);
        this.state={expandedModules : this.getExpandedModules(props)}
    }

    private getExpandedModules(props: Readonly<LessonNavigatorProps>) {
        return props.modules.map((mod, index) => index === this.props.selectedModule);
    }

    handleExpand=(index : number , state :  boolean|undefined = undefined)=>{
        const expandedModules = [...this.state.expandedModules];
        expandedModules[index] =state === undefined ? !expandedModules[index] :state;
        this.setState({expandedModules})
    };

    private handleNodeCollapse = (nodeData: ITreeNode) => {
        this.handleExpand(nodeData.id as number, false);
    };

    private handleNodeExpand = (nodeData: ITreeNode) => {
        this.handleExpand(nodeData.id as number, true);
    };

    getNodes=(modules: IModule[], intent : Intent | undefined)=> {
        const moduleIcon : IconName | MaybeElement = 'folder-open';
        const moduleIconClosed : IconName | MaybeElement = 'folder-close';
        return modules.map((mod,index)=>({
            id : index,
            icon : (<Icon intent={Intent.NONE} icon={this.state.expandedModules[index] ? moduleIcon : moduleIconClosed} className={Classes.TREE_NODE_ICON}/>),
            label :mod.name,
            className : 'navigation-text-wrapper',
            isExpanded : this.state.expandedModules[index],
            childNodes : mod.lessons.map((lesson,index2)=>({
                id : index2,
                isSelected : this.props.selectedLesson === index2 && this.props.selectedModule === index,
                icon : (<LessonIcon intent={intent? intent : Intent.NONE} lesson={lesson}/>),
                label :lesson.topic
            }))
        }));
    };

    componentDidUpdate(prevProps: Readonly<LessonNavigatorProps>, prevState: Readonly<LessonNavigatorState>, snapshot?: any): void {
        if(prevState.expandedModules === this.state.expandedModules){
            this.setState({...this.state,expandedModules : this.getExpandedModules(this.props)})
        }
    }

    render() {
        // console.trace("___render course lesson navigator ____");
        const nodes: ITreeNode[] = this.getNodes(this.props.modules, this.props.intent);
        return <div className='sidebar tiles-container-vertical'>
            {this.props.children ?
                <div className='tiles-item-full-row sidebar-bottom--heading'>
                <Navbar >
                    <Navbar.Group align={Alignment.LEFT}  className='sidebar-inner'>
                        {this.props.children}
                    </Navbar.Group>
                </Navbar>
                </div>: null
            }
            <Tree className='tiles-item-row  bp3-text-large text-wrapper' contents={nodes}
                  onNodeCollapse={this.handleNodeCollapse}
                  onNodeExpand={this.handleNodeExpand}
                  onNodeClick={(nodeData: ITreeNode, _nodePath: number[], e: React.MouseEvent<HTMLElement>) => {
                      if (_nodePath.length ===0) {
                          return;
                      }
                      if(_nodePath.length ===1){
                          this.handleExpand(_nodePath[0]);
                          return;
                      }
                      this.props.onSelect(_nodePath[0], _nodePath[1]);
                  }}/>
        </div>;
    }
}

export default LessonNavigator;