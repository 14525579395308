/*
  Master class for displaying data as view form
 */

import {Method} from "../model/Request";
import {DataType} from "../actions/data";
import {addTailSymbol, removeTailSymbol} from "../service/addSearchSymbol";
import { FormAdaptor, IFormAdaptor} from "./FormAdaptor";
import {FieldAdaptor} from "./FieldAdaptor";
import {AppState} from "../store/configureStore";
import {FirebaseMessage} from "../model/common/FirebaseMessage";

export interface RequestData {
    getFormAdaptor() : IFormAdaptor;
    message(): string;
    connect(connector: (adaptor:IFormAdaptor)=>void) :void;
    getMethod() : Method;
    getType() : DataType;
    save ?:()=>void;
    onExpand ?:(sendRequest : any,  state: AppState)=>void;
    post ?:()=>void;
    saveNoPost ?:()=>void;
    wasChanged :()=> boolean;
}

export abstract class SimpleRequestData implements RequestData{
    onDataChanged ?:(adaptor: IFormAdaptor) => void;
    adaptor ?: IFormAdaptor;
    fields : string[]=[];
    post ?:()=>void;
    saveNoPost ?:()=>void;

    connect(connector: (adaptor: IFormAdaptor) => void): void {
        this.onDataChanged = connector;
    }

    getFormAdaptor(): IFormAdaptor {
        return this.adaptor!;
    }

    getMethod() : Method {
        return Method.PUT;
    }

    message(): string {
        return JSON.stringify(this,this.fields);
    };

    getType(): DataType {
        return DataType.Unknown;
    }

    wasChanged():boolean {
        return true;
    }

    abstract createFormAdaptor():IFormAdaptor;
}

export abstract class FirebaseRequestData extends SimpleRequestData{
    abstract getFirebaseMessage():FirebaseMessage;
}

export class SearchRequest extends SimpleRequestData{
    onDataChanged ?:(adaptor: IFormAdaptor) => void;
    fields : string[]=[];
    readonly searchSymbol : string ='%';

    getFormAdaptor(): IFormAdaptor {
        return this.createFormAdaptor();
    }

    getMethod(): Method {
        return Method.PUT;
    }

    getFieldAdaptors():FieldAdaptor[]{
        return [];
    }

    message=(): string=> {
        addTailSymbol(this,this.searchSymbol);
        const res: string =JSON.stringify(this,this.fields);
        removeTailSymbol(this);
        return res;
    };

    getType(): DataType {
        return DataType.Unknown;
    }

    createFormAdaptor=(): IFormAdaptor=> {
        return new FormAdaptor( this.getFieldAdaptors(),this);
    }
}