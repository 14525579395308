import React, {PureComponent} from 'react';
import {match} from "react-router";
import {connect} from "react-redux";
import {Button, Callout, Classes, Dialog, InputGroup, Intent, Tooltip} from "@blueprintjs/core";
import {Text, Txt} from "./Text";
import {
    authorization_label_4,
    authorization_label_54,
    do_change_password,
    hide_label,
    password_change, password_changed,
    password_doesnt_match_confirmation,
    show_label,
    wrong_password_length
} from "../../text/Literals";
import changeLanguage, {getVocabulary} from "../../thunks/changeLanguage";
import LanguagePicker from "./LanguagePicker";
import {ImageSize} from "../../adaptors/ImageAdaptor";
import {AppState} from "../../store/configureStore";
import {Vocabulary} from "../../model/AppData";
import RequestButton from "../RequestButton";
import {Method, Request} from "../../model/Request";
import {DataType} from "../../actions/data";
import ServerExceptionInfo from "../ServerExceptionInfo";
import {b64_to_utf8} from "../../service/encoder";

interface ChangePasswordWindowProps {
    getVocabulary : any;
    changeLanguage :any;
    browserLanguage :string;
    vocabulary : Vocabulary;
    match : match<Identifiable>
}

export interface TokenHolder{token: string;}

interface Identifiable extends TokenHolder {language ?:string }

interface ChangePasswordRequest{
    token : string,
    password : string
}

interface ChangePasswordWindowState {
    language : string,
    showPassword : boolean;
    password : string;
    confirm : string;
    success : boolean;
    valid : boolean
}

class ChangePasswordWindow extends PureComponent<ChangePasswordWindowProps,ChangePasswordWindowState>{


    constructor(props: ChangePasswordWindowProps, context: any) {
        super(props, context);
        let valid = false
        try {
            b64_to_utf8(this.props.match.params.token);
            valid = true
        }catch (e) {
            console.log("token not valid")
        }

        this.state = {
            language : props.match.params.language ? props.match.params.language : props.browserLanguage,
            showPassword : false,
            password : '',
            confirm : '',
            success :false,
            valid
        };
        this.props.changeLanguage(this.state.language);
    }

    changeLanguage = (language :string)=>{
        this.setState({...this.state,language}) ;
        this.props.changeLanguage(language);
    };
    private handleLockClick = () => this.setState({ ...this.state,showPassword: !this.state.showPassword });

    render(){

        const lockButton = (
            <Tooltip content={`${this.props.vocabulary[this.state.showPassword ? hide_label : show_label]} ${this.props.vocabulary[authorization_label_4]}`} >
                <Button
                    icon={this.state.showPassword  ? "unlock" : "lock"}
                    intent={Intent.PRIMARY}
                    minimal={true}
                    onClick={this.handleLockClick}
                />
            </Tooltip>
        );

        return (
            <Dialog
            isOpen={true}
            canEscapeKeyClose={false}
            canOutsideClickClose={false}
            backdropClassName='login-background'
            >
                <div className="bp3-dialog-body">
                    <div className='card--title-icon inline'>
                        <LanguagePicker onSelect={this.changeLanguage}
                                        selected={this.state.language}
                                        size={ImageSize.XTiny}
                                        intent={Intent.PRIMARY}
                        />
                    </div>
                </div>
                <Callout
                    className='login--text-span'
                    intent={this.isPasswordNotMatched() && !this.state.success? Intent.DANGER :Intent.PRIMARY }
                    title={this.getTitle()}
                > {this.getMessage()}
                </Callout>
                {this.state.success || this.isPasswordNotMatched()  ? null :
                    <ServerExceptionInfo className='login--text-span'/>}
                <InputGroup
                    className="login-fields"
                    intent={this.isPasswordNotMatched() ? Intent.DANGER :Intent.PRIMARY}
                    placeholder={this.props.vocabulary[authorization_label_54]}
                    rightElement={lockButton}
                    autoComplete="new-password"
                    value={this.state.password}
                    type={this.state.showPassword ? "text" : "password"}
                    onChange={(event : React.FormEvent<HTMLInputElement>)=>
                    {
                        this.handlePasswordChange(event.currentTarget.value);
                    }}
                />
                <InputGroup
                    className="login-fields"
                    intent={this.isPasswordNotMatched() ? Intent.DANGER :Intent.PRIMARY}
                    placeholder={this.props.vocabulary[authorization_label_54]}
                    rightElement={lockButton}
                    value={this.state.confirm}
                    autoComplete="new-password"
                    type={this.state.showPassword ? "text" : "password"}
                    onChange={(event : React.FormEvent<HTMLInputElement>)=>
                    {
                        this.handlePasswordRepeatChange(event.currentTarget.value);
                    }}
                />
                <div className={Classes.DIALOG_FOOTER}>
                    < div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <RequestButton
                            type={DataType.ChangePassword}
                            default={true}
                            disabled={this.isPasswordNotMatched() || this.state.success || !this.state.valid}
                            icon='tick'
                            intent={Intent.SUCCESS}
                            request={this.getRequest()}
                            onSuccess={this.onSuccess}
                        >
                            <Text text = {new Txt().dict(do_change_password)}/>
                        </RequestButton>
                    </div>
                </div>
            </Dialog>)
    }

    private handlePasswordChange=(password: string) => {
        this.setState({...this.state,password})
    };

    private handlePasswordRepeatChange=(confirm: string) => {
        this.setState({...this.state,confirm})
    };

    private isPasswordNotMatched=()=> {
        return this.state.password.length <3 || this.state.password.length >16 || this.state.password !== this.state.confirm;
    };

    private getTitle() {
        return this.props.vocabulary[password_change];
    }

    private getMessage() {
        return <Text text = {new Txt().txt(this.getErrorTitle(),false )}/>
    };

    private getErrorTitle() :string | undefined{
        if (this.state.success) return password_changed;
        if (this.state.password.length <3 || this.state.password.length >16 ) return wrong_password_length;
        if (this.state.password !== this.state.confirm) return  password_doesnt_match_confirmation;
        return undefined;
    }

    private getRequest() : Request{
        const msg : ChangePasswordRequest = {
            token : this.state.valid ? b64_to_utf8(this.props.match.params.token) : '',
            password : this.state.password
        };
        return new Request(JSON.stringify(msg),DataType.ChangePassword,Method.POST);
    }

    onSuccess=(msg :string|undefined) =>{
        this.setState({...this.state,success : true,password : "",confirm :""})
    }
}
const mapStateToProps = (state: AppState) => ({
    browserLanguage: state.settings.language,
    vocabulary : state.data.vocabulary
});
export default connect(mapStateToProps,{getVocabulary,changeLanguage}) (ChangePasswordWindow);