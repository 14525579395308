import React,{FC} from "react";
import {
    ISchool,
    SchoolEditRequest
} from "../../api/School";
import EmailLoginInstruments, {LoggedInWithEmailProps} from "./EmailLoginInstruments";
import {NoProps} from "./SchoolRegisterInstrument";
import {Button, Callout, Dialog, Divider, Intent} from "@blueprintjs/core";
import {TokenRequestForm} from "../forms/PostRequestForm";
import {DataType} from "../../actions/data";
import {Txt} from "../tools/Text";
import {
    edit_label,
    edit_school,
    register_more_school,
    school_found_message,
    school_not_found, school_view_form
} from "../../text/Literals";
import {Method} from "../../model/Request";
import {getRegUrl} from "../../api/ServerAddress";
import DownloadPanel from "../tools/DownLoadPanel";


const MySchool : FC<LoggedInWithEmailProps> = props => {
    let schools : ISchool[] = props.data.schools ? props.data.schools : []

    let intent : Intent = schools.length ? Intent.SUCCESS : Intent.WARNING;
    let text = schools.length ? props.data.vocabulary[school_found_message] + " " + schools.length :
            props.data.vocabulary[school_not_found];

    return <Dialog
        transitionDuration={200}
        isOpen={true}
        canEscapeKeyClose={false}
        canOutsideClickClose={false}
        className="login-container-wide"
        backdropClassName='login-background'>

        {props.exception}
        <div className="bp3-dialog-body" >
            <Callout intent={intent}>
                {text}
                <Divider/>
                <a href={getRegUrl()} className='block'>
                    <Button intent={Intent.PRIMARY}>
                        {props.data.vocabulary[register_more_school]}
                    </Button>
                </a>
            </Callout>
        {
            schools.map((school, index) =>(
                <TokenRequestForm
                    key={index}
                    default={true}
                    intent={Intent.PRIMARY}
                    url= {DataType.MySchool}
                    label={new Txt().dict(edit_school).txt(" " + school.name)}
                    type={DataType.MySchool}
                    help={''}
                    data={new SchoolEditRequest(school)}
                    method={Method.PUT}
                    token = {props.token!}
                    buttonText={edit_label}
                    ok ={()=>{}}
                    cancel={props.reset}
                />
            ))
        }
        {
            props.data.schools && props.data.schools.length  ?
                <DownloadPanel/> : null
        }
        </div>
    </Dialog>
}

const MySchoolInstrument : FC<NoProps> = () => {
    return <EmailLoginInstruments content={MySchool} title={school_view_form}/>
}

export default MySchoolInstrument;
