import {SimpleRequestData} from "../adaptors/RequestData";
import {Method} from "./Request";
import {AppData} from "./AppData";

export abstract class EmbeddedObject extends SimpleRequestData {
    model ?: any;
    /*
  What to do on save
  injected via constructor
 */
    onSave :(val : any)=>void;

    protected constructor(model : any,fields : string [] = [],onSave:(val : any)=>void = ()=>{}) {
        super();
        this.model = model;
        this.onSave = onSave;
        this.fields = fields;
    }

    toString():string{
        return 'model: ' + this.model;
    };

    message(): string {
        return JSON.stringify(this,this.fields);
    }
    getUrl():string{
        return '';
    }
    getMethod(): Method {
        return Method.SAVE;
    };
    /*
      Object is converted and saved
     */
    save=()=>{
        this.savePlain();
        if(this.post) this.post();
    };

    saveNoPost =()=>{
        this.savePlain();
    };

    savePlain() {
        this.updateModel();
        this.onSave(this.model);
    }

    abstract updateModel():void;

    abstract expandModel():void;

    revert():void{
        this.expandModel();
        this.onDataChanged!(this.adaptor!);
    }

    isNegative():boolean{
        return false;
    }

    setData(appData: AppData) {
        this.adaptor!.setData(appData);
    }

    not(){
        this.save();
    }

    wasChanged():boolean {
        return JSON.stringify(this.model,this.fields) !== JSON.stringify(this,this.fields);
    }

}