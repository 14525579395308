import React,{FC} from "react";
import {Vocabulary} from "../../model/AppData";
import {Button, Card, Intent, Spinner} from "@blueprintjs/core";
import {
    all_downloads,
    all_product_downloads,
    download_from_site,
    shd_for_students,
    shd_for_teachers
} from "../../text/Literals";
import {getDownloadsUrl} from "../../api/ServerAddress";
import {AppState} from "../../store/configureStore";
import {connect} from "react-redux";
import {FullBucketDescription} from "../../service/S3";

interface DownloadPanelProps{
    vocabulary : Vocabulary,
    downloads ?: FullBucketDescription,
    product ?: string
}

const DownloadPanel : FC<DownloadPanelProps> = props => {
    console.log(props.downloads)
    return <Card className='product-download dark-back'>
        {props.product === 'cards_app' ? props.vocabulary[shd_for_teachers] : null}
        {
            props.product && props.downloads && props.downloads.Objects[props.product] && props.downloads.Objects[props.product].length > 0?
                <a href={props.downloads.bucket + props.downloads.Objects[props.product][0].Key} className='block' target="_blank" rel="noopener noreferrer">
                    <Button intent={Intent.PRIMARY} className='fill-width base-margin'>
                        {props.vocabulary[download_from_site]}
                    </Button>
                </a> : props.product ?  <Spinner intent={Intent.PRIMARY}/> : null
        }

        {props.product === 'cards_app' ? <div> {props.vocabulary[shd_for_students]}
                <a className='block' target="_blank" href='https://play.google.com/store/apps/details?id=com.ScientificEntertainment.SchoolDrozdov&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1' rel="noopener noreferrer">
                    <img alt='Доступно в Google Play' width='200px' src='https://play.google.com/intl/en_us/badges/static/images/badges/ru_badge_web_generic.png'/></a>
            </div>
            : null}
        {props.vocabulary[all_product_downloads]}
        <a href={getDownloadsUrl()} className='block' target="_blank" rel="noopener noreferrer">
            <Button intent={Intent.PRIMARY} className='fill-width base-margin'>
                {props.vocabulary[all_downloads]}
            </Button>
        </a>
    </Card>
}

const mapStateToProps = (state : AppState)=>({vocabulary : state.data.vocabulary, downloads : state.data.downloads})

export default connect(mapStateToProps)(DownloadPanel);