import {IdLesson, ILesson, Lesson} from "./Lesson";
import {EmbeddedObject} from "../EmbededObject";
import {
    FormFieldAdaptor,
    NotEmptyStringFiled,
    ObjectArrayAdaptor,
    StringFieldAdaptor
} from "../../adaptors/FieldAdaptor";
import {FormAdaptor, IFormAdaptor} from "../../adaptors/FormAdaptor";
import {course_description, lessons_label, name_label, not_empty_field} from "../../text/Literals";
import cloneDeep from 'lodash/cloneDeep';

export interface IModule {
    name : string;
    description : string;
    lessons : ILesson[];
    isActive : boolean
}

export class IdModule implements IModule{
    id : string;
    name: string;
    description: string;
    lessons: ILesson[];
    isActive : boolean;

    constructor(id: string | number, model : IModule) {
        this.id = id + '';
        this.name = model.name;
        this.description = model.description;
        this.lessons = [...model.lessons];
        this.isActive = model.isActive;
    }
}

export class Module extends EmbeddedObject implements IModule{
    name : string;
    description : string;
    lessons : ILesson[];
    isActive : boolean;

    nameAdaptor ?: FormFieldAdaptor<string>;
    descriptionAdaptor ?:FormFieldAdaptor<string>;
    lessonsAdaptor ?: ObjectArrayAdaptor;
    isActiveAdaptor ?: FormFieldAdaptor<boolean>;

    /*
      It's important to have all fields in the list
      also inner objects fields are required
     */
    constructor(model : IModule|undefined = undefined,onSave:(val : any)=>void = ()=>{} ) {
        super(model,['lessons','name','description','image','tags','requires',
            'task','tags','description','isActive','type','video','topic','file'],onSave);

        if (this.model === undefined) {
            this.lessons = [new Lesson()];
            this.name = "";
            this.description = "";
            this.adaptor = this.createFormAdaptor();
            this.isActive = true;
            return;
        }
        this.lessons = this.model.lessons ? [...this.model.lessons] : [];
        this.name = this.model.name ? this.model.name : "";
        this.description = this.model.description ? this.model.description : "";
        this.isActive = this.model.isActive === undefined ? true :  this.model.isActive;

        this.adaptor = this.createFormAdaptor();
    }

    expandModel() {
        if (this.model === undefined) {
            this.lessons = [new Lesson()];
            this.name = "";
            this.description = "";
            this.isActive = true;
            this.adaptor = this.createFormAdaptor();
            return;
        }
        this.lessons = this.model.lessons ? [...this.model.lessons] : [];
        this.name = this.model.name ? this.model.name : "";
        this.description = this.model.description ? this.model.description : "";
        this.isActive = this.model.isActive === undefined ? true :  this.model.isActive;

        this.adaptor = this.createFormAdaptor();
    }

    updateModel(): void {
        this.model.lessons = this.lessons ? [...this.lessons] : [];
        this.model.name  = this.name ? this.name : "";
        this.model.description = this.description ? this.description :  "";
         this.model.isActive = this.isActive;
    }

    setName=(value : string)=>{
        this.name = value;
        this.onDataChanged!(new FormAdaptor([
            this.initNameAdaptor(),
            this.descriptionAdaptor!,
            this.lessonsAdaptor!
        ],this));
    };

    setDescription=(value : string)=>{
        this.description = value;
        this.onDataChanged!(new FormAdaptor([
            this.nameAdaptor!,
            this.initDescriptionAdaptor(),
            this.lessonsAdaptor!
        ],this));
    };

    setLessons =(value :  any)=>{
        this.lessons = cloneDeep(value);
        this.updateLessonsAdaptor();
    };

    private updateLessonsAdaptor() {
        this.onDataChanged!(new FormAdaptor([
            this.nameAdaptor!,
            this.descriptionAdaptor!,
            this.initLessonsAdapter()
        ], this));
    }

    initNameAdaptor =() : FormFieldAdaptor<string> => {
        this.nameAdaptor = new NotEmptyStringFiled(name_label,this.setName,not_empty_field,this.name);
        return this.nameAdaptor;
    };

    initDescriptionAdaptor =() : FormFieldAdaptor<string> => {
        this.descriptionAdaptor = new StringFieldAdaptor(course_description, this.setDescription,this.description);
        return this.descriptionAdaptor;
    };

    initLessonsAdapter = (): ObjectArrayAdaptor =>{
        this.lessonsAdaptor = new ObjectArrayAdaptor(lessons_label,this.setLessons,
            this.lessons.map((lesson,index) => Lesson.fromPlainObject(new IdLesson(index,lesson), this.updateLesson)),
            'document', this.insertAt, this.deleteAt
        );
        return this.lessonsAdaptor;
    };

    insertAt=(index : number)=>{
        this.lessons.splice(index,0, new Lesson());
        this.updateLessonsAdaptor();
    };

    deleteAt=(index : number)=>{
        this.lessons.splice(index,1);
        this.updateLessonsAdaptor();
    };

    updateLesson =(lesson : IdLesson)=>{
        this.lessons.splice(+lesson.id,1,lesson);
    };

    createFormAdaptor(): IFormAdaptor {
        this.adaptor =  new FormAdaptor([
            this.initNameAdaptor(),
            this.initDescriptionAdaptor(),
            this.initLessonsAdapter()
        ],this);
        return  this.adaptor;
    }

    toString(): string {
        return this.name;
    }

    isNegative():boolean{
        return !this.isActive;
    }

    not=()=> {
        this.isActive = ! this.isActive;
        this.save();
    };
}