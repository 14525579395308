import React, {FC, ReactElement, memo, ReactNode, RefObject} from 'react';
import {connect} from "react-redux";
import {AppState} from "../store/configureStore";
import {Instruments, InstrumentType} from "../actions/instruments";
import {Drawer, Position, Intent, IconName, Button, Tooltip} from "@blueprintjs/core";
import {closeInstrument} from "../actions/instruments";
import ServerExceptionInfo from "./ServerExceptionInfo";
import WaitServerProgressBar from "./WaitServerProgressBar";
import {getBackGroundStyle} from "../actions/settings";
import ProcessDisplay from "./tools/ProcessDisplay";
import {MaybeElement} from "@blueprintjs/core/src/common/props";
import {useTool} from "../actions/tools";
import {Txt,Text} from "./tools/Text";
import {abandonData} from "../actions/data";
import {DataType} from "../actions/data";

interface InstrumentContainerProps {
    instruments : Instruments;
    instrument : InstrumentType;
    title : Txt;
    closeInstrument : typeof  closeInstrument;
    intent ?:Intent
    onTop ?: boolean;
    mode ?: string;
    autonomous ?:boolean;
    fullscreen ?: boolean;
    background ?: string;
    onClose ?:()=>void;
    toolButtons ?: ToolButtonProps [];
    useTool : any;
    titleFactory ?: ElementFactory;
    children ?: ReactNode;
    containerRef ?: RefObject<HTMLDivElement>;
    plain ?:boolean;
    dataToErase ?: DataType;
    abandonData : any;
}

export interface ToolButtonProps {
    intent ?: Intent;
    icon ?: IconName | MaybeElement;
    title ?: string;
    tool : InstrumentType
    minimal ?: boolean;
}

export type ElementFactory = ()=>ReactElement;

const InstrumentContainer : FC<InstrumentContainerProps> = props => {
    function getToolButtons() {
        return props.toolButtons ?
            <div className='instruments-tool-button-container tiles-container'>
                {props.toolButtons.map((p,index)=> <Tooltip content={p.title} key={index}>
                                        <Button
                                            className='instruments-tool-button'
                                            minimal={p.minimal}
                                            icon={p.icon}
                                            intent={p.intent}
                                            onClick={()=>props.useTool(props.instrument,p.tool)}/>
                                    </Tooltip>
            ) }</div>:null
    }
    const isOpen = props.instruments.matches(props.instrument);
    const title =props.plain ? undefined : (props.titleFactory ? props.titleFactory() : <div className=''>
        <div  className='landscape-only'><Text text={new Txt(props.title).txt(props.instruments.entity.label ? ": "+props.instruments.entity.label : "")}/></div>
        {getToolButtons()}
    </div>);
    return (<Drawer
            isOpen={isOpen}
            className={getBackGroundStyle(props.mode)+' instruments-content '+(props.background ? props.background : '') }
            title={title}
            size={props.fullscreen ? '100%':'95%'}
            isCloseButtonShown={!props.autonomous && !props.plain && title !== undefined}
            canOutsideClickClose={!props.autonomous}
            position={Position.TOP}
            onClose={()=>{
                if(props.dataToErase){
                    props.abandonData(props.dataToErase)
                }
                if(props.onClose){
                    props.onClose();
                } else{
                    props.closeInstrument();
                }
            }}>
            <div className={props.onTop? 'instruments-container-top':'instruments-container'} ref={props.containerRef}>
                <ProcessDisplay>
                    <ServerExceptionInfo className='instruments-info-element' logoutButton={true}/>
                    <WaitServerProgressBar intent={props.intent ? props.intent : Intent.PRIMARY} className='instruments-info-element'/>
                </ProcessDisplay>
                    {props.children}
            </div>
        </Drawer>
    );
};

const mapStateToProps = (state: AppState) => ({
    instruments : state.instruments,
    mode : state.settings.mode
});

export default connect(
    mapStateToProps, {closeInstrument,useTool,abandonData}
)(memo(InstrumentContainer));