import {IClientInfo} from "../service/ClientInfo";

export interface IRefreshRequest {
    token : string,
    browser : IClientInfo
}

export class RefreshRequest implements IRefreshRequest{
    token: string;
    browser: IClientInfo;

    constructor(token: string, browser: IClientInfo) {
        this.token = token;
        this.browser = browser;
    }

    getMessage(): string {
        return JSON.stringify(this);
    }
}