import React, {FC,memo} from 'react';
import {connect, Provider} from "react-redux";
import PostRequestForm from "../forms/PostRequestForm";
import {Intent} from "@blueprintjs/core/lib/esm/common/intent";
import {Text, Txt} from "../tools/Text";
import {operation_success, review_description, review_reject_description} from "../../text/Literals";
import {CourseReviewRejectRequest, CourseReviewRequest} from "../../model/courses/CourseReviewRequest";
import {InstrumentType,closeInstrument} from "../../actions/instruments";
import {AppState} from "../../store/configureStore";
import {closeTool} from "../../actions/tools";
import {AppToaster} from "../../service/toaster";
import {getCourseStatusKV, ICourseStatus} from "../../model/courses/CourseReviewStatus";
import KeyValueViewer from "../viewers/KeyValueViewer";
import store from "../../store/store";
import {getCourseName} from "../../model/courses/Course";
import {subscribeForStatus} from "../../thunks/showReviewTool";

export enum ReviewOperation{
    Request,
    Reject
}

interface CourseReviewProps extends BasicCourseReviewProps{
    operation : ReviewOperation
}

interface BasicCourseReviewProps {
    id : string;
    closeTool : any;
    closeInstrument :any;
    subscribeForStatus : any;
    name: string;
    status ?: ICourseStatus;
}

const CourseReview : FC<CourseReviewProps> = props => {
    function close() {
        if (props.operation === ReviewOperation.Request) {
            props.closeTool(InstrumentType.TeachersCourses, InstrumentType.ReviewWindow);
        } else {
            props.closeTool(InstrumentType.CourseReview, InstrumentType.ReviewRejectWindow);
        }
    }
    function ok() {
        AppToaster.show({
            intent: props.operation === ReviewOperation.Request ? "success" : "danger",
            icon: "globe",
            message:    <Provider store={store}> <Text text={new Txt().dict(operation_success)}/></Provider>
        });
        if (props.operation === ReviewOperation.Request){
            props.subscribeForStatus();
        }
        close();
        if (props.operation === ReviewOperation.Reject){
            props.closeInstrument();
        }
    }
    const request : CourseReviewRequest = props.operation === ReviewOperation.Request ?   new CourseReviewRequest(props.id,props.name) : new CourseReviewRejectRequest (props.id, props.name);
    return  (
        <div>
            {props.operation === ReviewOperation.Request ?
                getCourseStatusKV(props.status).map(
                (kv,index)=> <div className='form-item-row' key={index}>
                    <KeyValueViewer
                        token=''
                        inline={false}
                        kv={kv}/></div>)
                : null}
        <PostRequestForm
            intent={Intent.PRIMARY}
            default={true}
            url= {request.getType()}
            label={new Txt().dict(ReviewOperation.Request ? review_description : review_reject_description)}
            type={request.getType()}
            data={request}
            method={request.getMethod()}
            level={0}
            cancel={close}
            ok={ok}
        />
        </div>)
};

const mapStateToProps = (state: AppState) => {
    const id : string =state.instruments.entity.idAsString();
    return {
        id ,
        name : getCourseName(state.user.getTeachersCourseById(id))!,
        status : id ? state.data.courseStatuses[id] : undefined
    }
};

const RequestReview : FC<BasicCourseReviewProps> = props => {
    return <CourseReview {...props} operation={ReviewOperation.Request}/>
};

const RejectReview : FC<BasicCourseReviewProps> = props => {
    return <CourseReview {...props} operation={ReviewOperation.Reject}/>
};

export const CourserRequestReview= connect(mapStateToProps,{closeTool,closeInstrument,subscribeForStatus})(memo(RequestReview));

export const  CourseRejectReview = connect(mapStateToProps,{closeTool,closeInstrument,subscribeForStatus})(memo(RejectReview));

