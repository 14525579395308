import React, {FC} from 'react';
import {connect} from "react-redux";
import logout from '../../thunks/logout';
import {Alignment, Button, Intent, Navbar, NavbarDivider, NavbarGroup, NavbarHeading, Tooltip} from "@blueprintjs/core";
import InstrumentButton from "../InstrumentButton";
import {InstrumentType} from "../../actions/instruments";
import {AppState} from "../../store/configureStore";
import LanguagePicker from "./LanguagePicker";
import changeLanguage from "../../thunks/changeLanguage";
import {ImageSize} from "../../adaptors/ImageAdaptor";
import {current_product_version, messages_label} from "../../text/Literals";
import {clearCache, getVersion} from "../../service/CacheService";
import {Txt,Text} from "./Text";
import {newMessageCount} from "../../model/common/FirebaseMessage";

interface ToolBarProps {
    logout : any;
    changeLanguage : any;
    language : string;
    newMessageCount : number
}

const Toolbar : FC<ToolBarProps> = props => {
    return (
        <Navbar className='tool-bar'>
        <NavbarGroup align={Alignment.LEFT}>
            <NavbarHeading>
            <LanguagePicker onSelect={(language)=>props.changeLanguage(language)}
                            selected={props.language}
                            size={ImageSize.Tiny}
                            />
            </NavbarHeading>
            <NavbarDivider />

            <InstrumentButton
                minimal={true}
                intent={props.newMessageCount? Intent.DANGER :Intent.PRIMARY}
                icon='envelope'
                type={InstrumentType.Notifications}
                description={new Txt().dict(messages_label).txt(props.newMessageCount? ': '+props.newMessageCount : null)}>
                <Text landscape={true} text={new Txt().dict(messages_label).txt(props.newMessageCount? ': '+props.newMessageCount : null)}/>
            </InstrumentButton>
            <InstrumentButton
                        minimal={true}
                        intent={ Intent.SUCCESS}
                        icon='download'
                        type={InstrumentType.Downloads}
                        description={new Txt().dict("downloads_label")}>
                <Text landscape={true} text={new Txt().dict("downloads_label")}/>
            </InstrumentButton>
        </NavbarGroup>
            <Navbar.Group align={Alignment.RIGHT}>
                <Tooltip content={<Text text={new Txt().dict(current_product_version).txt(getVersion())}/>} intent={Intent.PRIMARY}>
                    <Button minimal={true} intent={Intent.PRIMARY} icon="info-sign" onClick={clearCache}/>
                </Tooltip>
                <Button minimal={true} intent={Intent.PRIMARY} icon="log-out" onClick={props.logout} >
                    <Text landscape={true} text={new Txt().dict("logout_label")}/>
                </Button>
            </Navbar.Group>
        </Navbar>
        )
};
const mapStateToProps = (state: AppState) => ({
    language : state.settings.language,
    newMessageCount : newMessageCount(state.data.firebaseMessages)
});
export default connect(mapStateToProps,{logout,changeLanguage})(Toolbar)
