import React, {FC} from 'react';
import {IButtonAdaptor, ButtonType, RequestButtonAdapter,ButtonAdaptor} from "../../adaptors/ButtonAdaptors";
import RequestButton from "../RequestButton";
import {Button} from "@blueprintjs/core";
import {AppState} from "../../store/configureStore";
import {connect} from "react-redux";
import {Vocabulary} from "../../model/AppData";

interface FormButtonProps {
    adaptor : IButtonAdaptor
    token ?: string
    className ?: string;
    vocabulary : Vocabulary
}
const FormButton : FC<FormButtonProps> = props => {
    switch (props.adaptor.type) {
        case ButtonType.Functional:
            const buttonAdaptor : ButtonAdaptor = props.adaptor as ButtonAdaptor;
            return (
                <Button
                    className={props.className}
                    intent={buttonAdaptor.intent}
                    icon={buttonAdaptor.icon}
                    onClick={buttonAdaptor.click}
                >
                    {props.vocabulary[buttonAdaptor.label]}
                </Button>
            );
        case ButtonType.Request:
            const adaptor : RequestButtonAdapter = props.adaptor as RequestButtonAdapter;
            return (
                <RequestButton
                    key = {adaptor.label}
                    className={props.className}
                    disabled={false}
                    intent={adaptor.intent}
                    icon={adaptor.icon}
                    request={adaptor.getRequest(props.token!)}
                    type={adaptor.dataType}
                    >
                {props.vocabulary[adaptor.label]}
            </RequestButton>
            );
        default: return null;
    }
};
const mapStateToProps = (state: AppState) => ({
    vocabulary : state.data.vocabulary
});
export default connect (mapStateToProps) (FormButton);