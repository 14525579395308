import {EncodedObject} from "../EncodedObject";
import {FormAdaptor, IFormAdaptor,} from "../../adaptors/FormAdaptor";
import {AppData} from "../AppData";
import {KV, PNG} from "../KV";
import {IImageAdaptor, ImageAdaptor, ImageSize} from "../../adaptors/ImageAdaptor";
import {Intent} from "@blueprintjs/core";
import {dispatchShowFacts} from "../../store/store";
import {
    BooleanFieldAdaptor,
    EnumOptionsAdaptor,
    FieldDivider,
    FileFieldAdaptor,
    FormFieldAdaptor,
    NotEmptyStringFiled,
    SimpleOptionsFieldAdaptor,
    TagsAdaptor,
    TextFieldAdaptor,
    VideoFieldAdaptor
} from "../../adaptors/FieldAdaptor";
import {CommonEntity} from "../../api/course/Entity";
import {
    homework_active,
    homework_file,
    homework_inactive,
    homework_label,
    homework_nothing,
    homework_task,
    homework_tests,
    homework_video,
    is_homework_active,
    lesson_file,
    lesson_text,
    lesson_topic,
    no_file_lesson,
    no_video_lesson,
    not_empty_field,
    pictures_set,
    task_description,
    task_label,
    task_type,
    video_label
} from "../../text/Literals";
import {DataType} from "../../actions/data";
import {uuid} from "../../service/uuid";

export enum HomeworkType {
    homework_nothing = "homework_nothing",
    homework_video = "homework_video",
    homework_file = 'homework_file',
    homework_tests = "homework_tests"
}

export type HomeworkKey = keyof typeof HomeworkType;

export interface ILesson {
    task : string,
    description : string,
    tags : string[],
    isActive : boolean,
    type : HomeworkKey,
    topic : string,
    order : number,
    video ?: string,
    file ? : string
    uuid : string;
}

export function hasClasswork(lesson : ILesson) {
    return lesson.video  || lesson.file || lesson.description;
}

export function hasHomework(lesson : ILesson) {
 return lesson.type !== HomeworkType.homework_nothing;
}

export class IdLesson implements ILesson, CommonEntity{
    id : string;
    description: string;
    isActive: boolean;
    order: number;
    tags: string[];
    task: string;
    topic : string;
    type: HomeworkKey;
    video ?: string;
    file ? : string;
    uuid : string;

    constructor(id: number| string, model : ILesson) {
        this.id = id +'';
        this.description = model.description;
        this.isActive = model.isActive;
        this.order = model.order;
        this.tags = model.tags === undefined ? [] : [...model.tags];
        this.task = model.task;
        this.topic = model.topic;
        this.type = model.type;
        this.video = model.video;
        this.file = model.file;
        this.uuid = model.uuid;
    }
}

export class Lesson extends EncodedObject implements ILesson{
    task: string;
    tags: string[];
    description : string;
    isActive: boolean;
    type: HomeworkKey;
    topic : string;
    order: number;
    video ?: string;
    file ? : string;
    uuid : string;

    allTags : string[];
    topicAdaptor ?: FormFieldAdaptor<string>;
    taskAdaptor ?: FormFieldAdaptor<string>;
    isActiveAdaptor ?: BooleanFieldAdaptor;
    descriptionAdaptor ?: FormFieldAdaptor<string>;
    tagsAdaptor ?: TagsAdaptor;
    typeAdaptor ?: SimpleOptionsFieldAdaptor;
    videoAdaptor ?: VideoFieldAdaptor;
    fileAdaptor ?: FileFieldAdaptor;
    dividerAdaptor : FieldDivider;
    /*
      It's important to have all fields in the list
      also inner objects fields are required
      fields should also be present if CourseContent or other container classes
     */
    constructor(encoded : string|undefined = undefined,onSave:(val : any)=>void = ()=>{},
         plain : boolean = false, model : any = undefined) {
        super(encoded,['task','tags','description','isActive','type','video','topic','file','uuid'],onSave,plain,model);
        this.allTags = [];
        this.dividerAdaptor = new FieldDivider(homework_label);
        if(this.model === undefined){
            this.task = '';
            this.tags = [];
            this.isActive = false;
            this.description = '';
            this.type = HomeworkType.homework_nothing;
            this.order = 1;
            this.topic= '';
            this.uuid = uuid();
            return;
        }
        this.tags = this.model!.tags === undefined ? [] : this.model!.tags;
        this.task = this.model!.task;
        if(!this.task) this.task = '';
        this.description = this.model!.description;
        if(!this.description) this.description = '';
        this.isActive = !!this.model.isActive;
        this.type = this.model.type ? this.model.type : HomeworkType.homework_nothing;
        this.order = this.model.order ? this.model.order : 1;
        this.topic = this.model.topic ? this.model.topic : '';
        this.video = this.model.video;
        this.file = this.model.file;
        this.uuid = this.model.uuid ? this.model.uuid : uuid();
        this.adaptor = this.createFormAdaptor();
    }

    init(model : ILesson) {
        this.tags = model.tags === undefined ? [] : model.tags;
        this.task = model.task;
        if (!this.task) this.task = '';
        this.description = model.description;
        if (!this.description) this.description = '';
        this.isActive = model.isActive;
        this.type = model.type ? model.type : HomeworkType.homework_video;
        this.order = model.order ? model.order : 1;
        this.topic = model.topic ? model.topic : '';
        this.video = model.video;
        this.file = model.file;
        this.uuid = this.model.uuid ? this.model.uuid : uuid();
    }

    expandModel(): void {
        this.init(this.model);
    }

    getStudentKV = ():KV[]=>{
        if (! this.isActive) return [];
        return [
            {key: task_label,value :this.task},
            {key :task_description, value : this.description}
        ];
    };

    createFormAdaptor(): IFormAdaptor {
        return new FormAdaptor([
            this.initTopicAdaptor(),
            this.initDescriptionAdaptor(),
            this.initVideoAdaptor(),
            this.initFileAdaptor(),
            this.initIsActiveAdaptor(),
            this.dividerAdaptor,
            this.initTypeAdaptor(),
            this.initTaskAdaptor()
        ],this);
    }

    toString(): string {
        return this.topic;
    }

    setTask = (value : string)=>{
        this.task = value;
        this.adaptor =new FormAdaptor([
            this.topicAdaptor!,
            this.descriptionAdaptor!,
            this.videoAdaptor!,
            this.fileAdaptor!,
            this.isActiveAdaptor!,
            this.dividerAdaptor,
            this.typeAdaptor!,
            this.initTaskAdaptor(),
        ],this);
        this.onDataChanged!(this.adaptor);
    };

    setTopic = (value : string)=>{
        this.topic = value;
        this.adaptor =new FormAdaptor([
            this.initTopicAdaptor(),
            this.descriptionAdaptor!,
            this.videoAdaptor!,
            this.fileAdaptor!,
            this.isActiveAdaptor!,
            this.dividerAdaptor,
            this.typeAdaptor!,
            this.taskAdaptor!,
        ],this);
        this.onDataChanged!(this.adaptor);
    };

    setType = (value : string)=>{
        this.type = Lesson.nameToHomeworkKey(value);
        this.adaptor =new FormAdaptor([
            this.topicAdaptor!,
            this.descriptionAdaptor!,
            this.videoAdaptor!,
            this.fileAdaptor!,
            this.isActiveAdaptor!,
            this.dividerAdaptor,
            this.initTypeAdaptor(),
            this.taskAdaptor!,
        ],this);
        this.onDataChanged!(this.adaptor);
    };

    setDescription = (value : string)=>{
        this.description = value;
        this.adaptor =new FormAdaptor([
            this.topicAdaptor!,
            this.initDescriptionAdaptor(),
            this.videoAdaptor!,
            this.fileAdaptor!,
            this.isActiveAdaptor!,
            this.dividerAdaptor,
            this.typeAdaptor!,
            this.taskAdaptor!

        ],this);
        this.onDataChanged!(this.adaptor);
    };

    setActive = (value : boolean)=>{
        this.isActive = value;
        this.adaptor =new FormAdaptor([
            this.topicAdaptor!,
            this.descriptionAdaptor!,
            this.videoAdaptor!,
            this.fileAdaptor!,
            this.initIsActiveAdaptor(),
            this.dividerAdaptor,
            this.typeAdaptor!,
            this.taskAdaptor!
        ],this);
        this.onDataChanged!(this.adaptor);
    };

    setVideo= (value : string|undefined)=>{
        this.video = value;
        this.adaptor =new FormAdaptor([
            this.topicAdaptor!,
            this.descriptionAdaptor!,
            this.initVideoAdaptor(),
            this.fileAdaptor!,
            this.isActiveAdaptor!,
            this.dividerAdaptor,
            this.typeAdaptor!,
            this.taskAdaptor!
        ],this);
        this.onDataChanged!(this.adaptor);
    };

    setFile= (value : string|undefined)=>{
        this.file = value;
        this.adaptor =new FormAdaptor([
            this.topicAdaptor!,
            this.descriptionAdaptor!,
            this.videoAdaptor!,
            this.initFileAdaptor(),
            this.isActiveAdaptor!,
            this.dividerAdaptor,
            this.typeAdaptor!,
            this.taskAdaptor!
        ],this);
        this.onDataChanged!(this.adaptor);
    };

    initTaskAdaptor=() :FormFieldAdaptor<string>=> {
        this.taskAdaptor =  new TextFieldAdaptor(homework_task,this.setTask,this.task,2);
        return this.taskAdaptor;
    };

    initTopicAdaptor=() :FormFieldAdaptor<string>=> {
        this.topicAdaptor =  new NotEmptyStringFiled(lesson_topic,this.setTopic,not_empty_field,this.topic);
        return this.topicAdaptor;
    };

    initTypeAdaptor = () : SimpleOptionsFieldAdaptor =>{
        this.typeAdaptor = new EnumOptionsAdaptor(task_type,this.setType,this.type,HomeworkType);
        return this.typeAdaptor;
    };

    initIsActiveAdaptor=() :BooleanFieldAdaptor=> {
        this.isActiveAdaptor = new BooleanFieldAdaptor(is_homework_active,this.setActive,this.isActive,false,
            this.isActive ? homework_active : homework_inactive);
        return this.isActiveAdaptor;
    };

    initDescriptionAdaptor =():FormFieldAdaptor<string>=> {
        this.descriptionAdaptor = new TextFieldAdaptor(lesson_text,this.setDescription, this.description);
        return this.descriptionAdaptor;
    };

    initVideoAdaptor = () :VideoFieldAdaptor =>{
        this.videoAdaptor = new VideoFieldAdaptor(video_label,this.setVideo,this.video,no_video_lesson, DataType.GetUploadVideoLink);
        return this.videoAdaptor;
    };

    initFileAdaptor = () :FileFieldAdaptor =>{
        this.fileAdaptor = new FileFieldAdaptor(lesson_file,this.setFile,this.file,no_file_lesson, DataType.GetUploadFileLink);
        return this.fileAdaptor;
    };

    isNegative():boolean{
        return !this.isActive;
    }

    not=()=> {
        this.isActive = ! this.isActive;
        this.save();
    };

    initTagsAdaptor =() : TagsAdaptor=>{
        this.tagsAdaptor = new TagsAdaptor(pictures_set,this.tags,
            this.addTag,this.removeTag,Lesson.filterValues);
        return this.tagsAdaptor;
    };

    removeTag=(tag: string): void=>{
        const index : number = this.tags.indexOf(tag);
        if(index <0) return;
        this.tags.splice(index,1);
        this.updateTagAdaptor();
    };

    private updateTagAdaptor() {
        this.adaptor = new FormAdaptor([
            this.topicAdaptor!,
            this.descriptionAdaptor!,
            this.videoAdaptor!,
            this.dividerAdaptor,
            this.typeAdaptor!,
            this.taskAdaptor!,
            this.isActiveAdaptor!
        ], this);
        this.onDataChanged!(this.adaptor);
    }

    addTag=(tag : string):void =>{
        const index : number = this.tags.indexOf(tag);
        if(index >=0 )return;
        this.tags.push(tag);
        this.updateTagAdaptor();
    };

    updateModel(): void {
        this.model = new IdLesson(this.model.id,this);
    }

    static mapDeckImages(val : string):IImageAdaptor{
        const title : string = Lesson.getDeckTitle(val);
        return new ImageAdaptor(DECK_BASE_ULR+val+PNG,title,title,Intent.SUCCESS,ImageSize.Normal,dispatchShowFacts);
    }

    static filterValues(appData : AppData) : string[]{
        return appData.factTags === undefined ? []: appData.factTags;
    }

    static nameToHomeworkKey=(val :string) :HomeworkKey=>{
        return val as HomeworkKey;
    };

    static fromEncodedString(encoded : string | undefined, set:(val: string)=>void) : Lesson{
        return new Lesson(encoded,set)
    }

    static fromPlainObject(model : any, set:(val: any)=>void) : Lesson{
        return new Lesson(undefined,set,true,model);
    }

    static getDeckTitle(val: string) {
            switch (val) {
                case "air" : return "cardsapp_speech_47";
                case "animals" : return "cardsapp_speech_59";
                case "climate" : return "cardsapp_speech_48";
                case "craft" : return "cardsapp_speech_62";
                case "device" : return "cardsapp_speech_61";
                case "electricity" : return "cardsapp_speech_46";
                case "geography" : return "cardsapp_speech_54";
                case "kamchatka" : return "cardsapp_speech_52";
                case "light" : return "cardsapp_speech_53";
                case "magnet" : return "cardsapp_speech_50";
                case "minerals" : return "cardsapp_speech_40";
                case "ph" : return "cardsapp_speech_57";
                case "planet" : return "cardsapp_speech_43";
                case "plants" : return "cardsapp_speech_55";
                case "power" : return "cardsapp_speech_60";
                case "puls" : return "cardsapp_speech_58 ";
                case "sound" : return "cardsapp_speech_44";
                case "space" : return "cardsapp_speech_51";
                case "sputnik" : return "cardsapp_speech_42";
                case "star" : return "cardsapp_speech_41";
                case "temperature" : return "cardsapp_speech_56";
                case "volcanoes" : return "cardsapp_speech_45";
                case "water"  : return "Acidity_caption_6";
                case "physics"  : return "cardsapp_speech_63";
                case "code"  : return "code_label";
                default:  return "";
            }
    }

}
export function mapHomeWorkTypes(index : string) : string {
    switch (index) {
        case HomeworkType.homework_nothing : return homework_nothing;
        case HomeworkType.homework_tests  : return homework_tests;
        case HomeworkType.homework_video : return homework_video;
        case HomeworkType.homework_file : return homework_file;
        default: return '';
    }
}


export const DECK_BASE_ULR = 'https://naurasha-decks.s3.eu-central-1.amazonaws.com/';