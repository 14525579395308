import {Method} from "../Request";
import {DataType} from "../../actions/data";
import {Vocabulary} from "../AppData";
import {getMyTeachersCoursesIds, getSubscriptionIds} from "../User";
import {AuthResponse} from "../../api/AuthResponse";

export interface FirebaseMessageContent{
    content : string;
    time ?: number;
    lesson : string;
    type : string;
    user ?: string;
    topic ?: string;
    new ?: boolean;
    id ?: string;
    answer ?: string;
}

export type FirebaseCourseData = FirebaseMessageContent[];

export interface FirebaseMessages {
    [key :string] :FirebaseCourseData;
}

export interface FirebaseHomework {
    [key :string] :Vocabulary;
}
export interface FirebaseSignedHomework {
    student : string;
    time : number;
    homework : Vocabulary;
    course ?: string;
}

export interface FirebaseStudentsHomework {
    [key : string] : FirebaseSignedHomework [];
}

export interface Students {
    [key : string] : string[];
}

export function filterMessages(messages : FirebaseMessages, fn:(course :string, content : FirebaseMessageContent)=>boolean) : FirebaseMessageContent[]{
    const res = [];
    for (let data  in messages){
        res.push(...messages[data]
            .filter(content=> fn(data,content))
            .map(msg=>{
                msg.topic=data;
                return msg
            }));
    }
    res.sort(isAfter);
    return res;
}

export function newMessageCount(messages : FirebaseMessages) : number {
  return filterMessages(messages,((course, content) => (!!content.new))).length;
}

export function isAfter(content1 :FirebaseMessageContent, content2:FirebaseMessageContent) {
    return  content2.time! - content1.time!;
}

export function findContent(data: FirebaseMessages, topic: string, lesson : string) : FirebaseMessageContent | undefined{
    if(!data[topic]) return undefined;

    const thatLesson : FirebaseCourseData = data[topic].filter(content => content.lesson === lesson && content.time);
    if(thatLesson.length ===0)  return undefined;
    thatLesson.sort(isAfter);
    return thatLesson[0];
}

export function getMessagesByLesson(data: FirebaseMessages, topic: string, lesson : string) : FirebaseMessageContent[] {
    const result= data[topic] ? data[topic].filter(content => content.lesson ===lesson) : [];
    result.sort((content1,content2)=>content2.time! -content1.time!);
    return result;
}

export enum FirebaseMessageType {
    Text = "messages",
    Video = "video",
    File = "files",
    HomeworkReview = "home work review",
    MessageAnswer = "answer",
    Tests = "tests",
    NewMessages = "new messages",
    MyHomework = "my homework",
    InitialHomework = "initial homework",
    LessonMessages ='lesson messages',
    Subscribers ='subscribers',
    NewSubscribers ='new subscribers',
    LessonCovered = "covered lessons"
}

export interface IFirebaseQuery {
    dataType : DataType,
    params : any[]
}

export class FirebaseQuery implements IFirebaseQuery{
    dataType: DataType;
    params: any[];

    constructor(dataType: DataType, params: any[]=[]) {
        this.dataType = dataType;
        this.params = params;
    }
}

export interface IFirebaseMessage {
    topic : string,
    type : FirebaseMessageType;
    content : FirebaseMessageContent;
    method : Method;
    query ?: IFirebaseQuery;
}

const DEFAULT_CONTENT ={
    content : '',
    lesson : '',
    type : 'unknown',
};

export class FirebaseMessage implements IFirebaseMessage{
    topic : string;
    type : FirebaseMessageType;
    content : FirebaseMessageContent;
    method : Method;
    query ?: IFirebaseQuery;

    constructor(topic: string, type: FirebaseMessageType, content: FirebaseMessageContent| undefined = undefined,
                method : Method = Method.POST, query : IFirebaseQuery|undefined = undefined) {
        this.topic = topic;
        this.type = type;
        this.content = content ? content : DEFAULT_CONTENT;
        this.method = method;
        this.query = query;
        this.setTime();
    }

    setUser=(user : string)=>{
        this.content.user = user;
    };

    setTime=()=>{
        const date =  new Date();
        this.content.time = date.getTime();
    };

    static getMyHomework(topic : string) : FirebaseMessage{
       return  new FirebaseMessage(
            topic,
            FirebaseMessageType.MyHomework,
            undefined,
            Method.HEAD,
            new FirebaseQuery(DataType.FirebaseHomework));
    }
    static getMyLessonMessages(topic : string, lesson : string) : FirebaseMessage{
        return  new FirebaseMessage(
            topic,
            FirebaseMessageType.LessonMessages,
            {lesson, content :'',type:FirebaseMessageType.LessonMessages},
            Method.GET,
            new FirebaseQuery(DataType.FirebaseMessages));
    }

    static coverLesson(topic : string, lesson : string, state : string) : FirebaseMessage{
        return  new FirebaseMessage(
            topic,
            FirebaseMessageType.LessonCovered,
            {lesson, content :'',type:state},
            Method.SAVE,
            new FirebaseQuery(DataType.FirebaseMessages));
    }

    static getAllMessages(user : AuthResponse, isInitial : boolean = false):FirebaseMessage[]{
        const ids = getMyTeachersCoursesIds(user);
        const studentsIds = getSubscriptionIds(user);
        const messages =   studentsIds.map(id =>new FirebaseMessage(
            id ,
            FirebaseMessageType.NewMessages,
            undefined,
            Method.GET,
            new FirebaseQuery(DataType.FirebaseMessages,FirebaseMessage.getReadMessageParams(isInitial))));

        if(isInitial) {
            messages.push(...ids.map(id => new FirebaseMessage(
                id,
                FirebaseMessageType.MyHomework,
                undefined,
                Method.CONNECT,
                new FirebaseQuery(DataType.FirebaseStudentsHomework, FirebaseMessage.getReadMessageParams(isInitial))
            )));
            messages.push(...studentsIds.map(id => new FirebaseMessage(
               id,
               FirebaseMessageType.LessonCovered,
               undefined,
               Method.CHECK,
               undefined
            )))
        }
        messages.push(...ids.map(id=>FirebaseMessage.getSubscribers(id, isInitial)));
        return messages;
    }

    static getSubscribers(topic : string, isInitial : boolean = false) : FirebaseMessage{
        return new FirebaseMessage(topic,isInitial ? FirebaseMessageType.NewSubscribers : FirebaseMessageType.Subscribers,undefined,Method.OPTIONS,
            new FirebaseQuery(DataType.FirebaseSubscribers,FirebaseMessage.getReadMessageParams(isInitial)))
    }
    static getReadMessageParams(isInitial : boolean) {
        return isInitial ?  [A_YEAR, MESSAGE_LIMIT] : [TWO_MINUTES, MESSAGE_LIMIT];
    }
}

export const A_YEAR : number = 31622400000;
export const TWO_MINUTES : number = 120000;
export const MESSAGE_LIMIT : number = 60000;