import {Intent} from "@blueprintjs/core";

export interface IContentAdaptor{
    link : string;
    intent ?: Intent;
    hint ?: string;
    onClick : ()=>void;
    rawHint ?: boolean;
}

export interface IImageAdaptor extends IContentAdaptor{
    alt : string;
    size : ImageSize;
    isSvg : boolean;
}

export enum ImageSize {
    XTiny,
    Tiny,
    Small,
    Normal,
    Medium,
    Large,
    XLarge
}

export class ImageAdaptor implements IImageAdaptor{
    link : string;
    alt : string;
    intent : Intent;
    size: ImageSize;
    hint ?: string;
    onClick : () => void;
    isSvg: boolean;
    rawHint : boolean;

    constructor(link: string, alt: string, hint: string|undefined = undefined,
                intent: Intent = Intent.NONE, size: ImageSize = ImageSize.Normal,
                onClick: () => void = ()=>{},isSvg : boolean = false, rawHint: boolean = false) {
        this.link = link;
        this.alt = alt;
        this.intent = intent;
        this.size = size;
        this.hint = hint;
        this.onClick = onClick;
        this.isSvg = isSvg;
        this.rawHint = rawHint;
    }
}