import {b64_to_utf16, utf16_to_b64} from "./b64utf16";

export function utf8_to_b64(str : string) {
        return utf16_to_b64(str);
}

export function b64_to_utf8(str : string) {
    return b64_to_utf16(str);
}

export function encodeObject(obj : any, fields : string[]=[]) {
    if(fields.length ===0)
        return utf8_to_b64(JSON.stringify(obj));
    return utf8_to_b64(JSON.stringify(obj,fields));
}

export function decodeObject<T> (str: string):T {
    return JSON.parse(b64_to_utf8(str));
}