import React, {PureComponent} from 'react';
import {EncodedObjectAdaptor} from "../../adaptors/FieldAdaptor";
import {Card, Elevation, Intent} from "@blueprintjs/core";
import PostRequestForm from "./PostRequestForm";
import EmbeddedObjectMinimal from "./EmbeddedObjectMinimal";
import {Vocabulary} from "../../model/AppData";
import {AppState} from "../../store/configureStore";
import {connect} from "react-redux";
import {sendRequest} from "../../thunks/sendRequest";

/*
 *  View for editing embedded objects
 */

interface FieldSaveFormProps {
    adaptor :EncodedObjectAdaptor,
    intent : Intent,
    vocabulary : Vocabulary;
    level ?: number;
    sendRequest : any;
}

interface FieldSaveFormState {
    extended : boolean;
}

 class FieldSaveForm extends PureComponent<FieldSaveFormProps,FieldSaveFormState>{
    constructor(props: Readonly<FieldSaveFormProps>) {
        super(props);
        this.state ={extended:props.adaptor.expanded}
    }

    handleClose=()=> {
        this.props.adaptor.object.revert();
        this.setState({extended: false});
        if(this.props.adaptor.expanded){
            this.forceUpdate(()=>{this.setState({extended: true})});
        }
    };

    handleOpen = () =>{
      this.setState({extended: true});
    };

    render(){
        const isDark : boolean = this.props.level !== undefined && this.props.level % 2 ===0;
        const theme : string = isDark ? 'middle-back' : '';
        return (
            this.state.extended ?
                <Card interactive={false} elevation={Elevation.THREE} className={theme}>
                    <PostRequestForm
                        intent={this.props.intent}
                        default={true}
                        url= {this.props.adaptor.object.getUrl()}
                        // label={this.props.vocabulary[this.props.adaptor.name]}
                        type={this.props.adaptor.object.getType()}
                        data={this.props.adaptor.object}
                        method={this.props.adaptor.object.getMethod()}
                        cancel={this.handleClose}
                        level={this.props.level}
                        masterPost={this.props.adaptor.object.post}
                    />
                </Card>
                :
                <EmbeddedObjectMinimal
                    icon={this.props.adaptor.icon}
                    handler={this.handleOpen}
                    isNegative={this.props.adaptor.isNegative()}
                    text={this.props.adaptor.getText()}
                    intent={this.props.intent}
                    changer={()=>{this.props.adaptor.object.not();this.forceUpdate();}}
                />

        );
    }

}
const mapStateToProps = (state: AppState) => ({
    vocabulary : state.data.vocabulary,
});
export default connect(mapStateToProps,{sendRequest})(FieldSaveForm)