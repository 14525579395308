import React, {FC} from 'react';
import PostRequestForm from "../forms/PostRequestForm";
import {Intent} from "@blueprintjs/core/lib/esm/common/intent";
import {Txt} from "../tools/Text";
import {Entity} from "../../model/Entity";
import {AppState} from "../../store/configureStore";
import {ValueRequestData} from "../../model/common/AbstractValueRequest";
import {DataType} from "../../actions/data";
import {Method} from "../../model/Request";
import {course_delete, entity_id} from "../../text/Literals";
import {connect} from "react-redux";
import {InstrumentType} from "../../actions/instruments";
import {closeTool} from "../../actions/tools";
import {closeInstrument} from "../../actions/instruments";

interface CourseDeleteDialogProps {
    entity : Entity;
    closeTool : any;
    closeInstrument : any;
}

const CourseDeleteDialog : FC<CourseDeleteDialogProps> = props => {
    function close() {
        props.closeTool(InstrumentType.TeachersCourses, InstrumentType.DeleteCourseTool);
        props.closeInstrument();
    }
    function ok() {
        close();
        props.closeInstrument();
    }
    const request :ValueRequestData = new ValueRequestData(DataType.DeleteDraft,Method.DELETE,entity_id,true,props.entity.idAsString());
    return(<div>
        <PostRequestForm
            intent={Intent.DANGER}
            default={false}
            url= {request.getType()}
            label={new Txt().dict(course_delete).txt(props.entity.label)}
            type={request.getType()}
            data={request}
            method={request.getMethod()}
            level={0}
            cancel={close}
            ok={ok}
        />
    </div>);
};

const mapStateToProps = (state : AppState)=>({
   entity : state.instruments.entity
});

export default connect(mapStateToProps,{closeTool,closeInstrument})(CourseDeleteDialog);